import React from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import useGlobalStyles from '../../hooks/useGlobalStyles'
import { qaAttr } from '../../helpers/qaHelpers'
import useButtonApmStyles from '@/hooks/useButtonApmStyles'

interface IProps {
  disabled: boolean
  requestInProgress: boolean
  amount: number
  currency?: string
  onClick?: any
  showApmView?: boolean
  apm?: any
}

export default function PayButton({
  disabled,
  requestInProgress,
  amount,
  currency,
  onClick,
  showApmView,
  apm,
}: IProps) {
  const globalClasses = useGlobalStyles()
  const buttonStyles = useButtonApmStyles({ apm: apm || 'default' })
  const { t } = useTranslation()
  const buttonLabel = `${t('Pay')} ${amount} ${currency}`

  return (
    <Button
      className={clsx(globalClasses.button, showApmView && buttonStyles.root)}
      type="submit"
      variant="contained"
      color="primary"
      disabled={requestInProgress || disabled}
      id="new-order-form-pay"
      onClick={onClick}
      translate="no"
      {...qaAttr('card-pay-btn')}
    >
      {requestInProgress ? (
        <CircularProgress size={22} color="inherit" />
      ) : (
        <span>{buttonLabel}</span>
      )}
    </Button>
  )
}
