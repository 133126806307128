import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import cardValidator from 'card-validator'
import clsx from 'clsx'
import IMask from 'imask'
import InputMask from 'react-input-mask'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Switch from '@material-ui/core/Switch'
import { IOrder, useOrderCardData } from '../../api/order'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InputAdornment from '@material-ui/core/InputAdornment'
import AddIcon from '@material-ui/icons/Add'
import { ClickAwayListener } from '@material-ui/core'
import {
  composeValidators,
  required,
  isCardNumber,
  isValidCardExpires,
  lengthRequired,
  maxLength,
} from '../../validators'
import AddressAndTerms, { IAddressAndTermsProps } from './AddressAndTerms'
import useGlobalStyles from '../../hooks/useGlobalStyles'
import VisaIcon from '../Payment/img/visa.svg'
import McIcon from '../Payment/img/mcc.svg'
import MsIcon from '../Payment/img/ms.svg'
import Cvvicon from '../Payment/img/cvvicon.svg'
import OrderDetails from './Details'
import PayButton from './PayButton'
import Loader from '../../components/Loader'
import { qaAttr } from '../../helpers/qaHelpers'
import { IconButton } from '@material-ui/core'
import {
  trackAddPaymentMethod,
  trackClickPay,
  trackErrorScreen,
} from '@/helpers/analytics'
// import debounce from '../../helpers/debounce';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  option: {
    'display': 'flex',
    'alignItems': 'center',
    '& .opt-type': {
      flex: '0 0 50px',
    },
    '& .description': {
      flex: '1 1 100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&:not(:last-child):hover': {
      backgroundColor: '#F8F8FA',
    },
    '&:not(:last-child)': {
      cursor: 'pointer',
      padding: '18px 24px',
    },
    '&:last-child': {
      borderTop: '1px solid #F8F8FA',
      padding: '16px',
    },
  },
  select: {
    'paddingBottom': 0,
    '& .MuiFilledInput-input': {
      cursor: 'default',
      paddingTop: '6px !important',
    },
    '& .MuiInputAdornment-root': {
      marginTop: '0 !important',
    },
  },
  type: {
    'width': 38,
    'height': 24,
    '&.visa': {
      background: `url("${VisaIcon}") no-repeat center / 100% auto`,
    },
    '&.mastercard': {
      background: `url("${McIcon}") no-repeat center / 100% auto`,
    },
    '&.maestro': {
      background: `url("${MsIcon}") no-repeat center / 100% auto`,
    },
  },
  selectBtn: {
    'width': '100%',
    'backgroundColor': 'rgba(14, 85, 252, 0.05)',
    'color': '#0E55FC',
    'padding': '16px 12px 16px 16px',
    'borderRadius': '4px',
    'border': 'none',
    'fontSize': '14px',
    'lineHeight': 1.14,
    'display': 'flex',
    'justifyContent': 'center',
    'fontWeight': 500,
    'cursor': 'pointer',
    'alignItems': 'center',
    'transition': 'all 0.3s linear 0s',
    '&:hover': {
      transition: 'all 0.3s linear 0s',
      backgroundColor: 'rgba(14, 85, 252, 0.2)',
    },
  },
  btnText: {
    display: 'inline-block',
  },
  btnArrow: {
    transform: 'rotate(180deg)',
  },
  selectWrapper: {
    position: 'relative',
    marginBottom: '16px',
  },
  cardsList: {
    'margin': '0px',
    'overflow': 'auto',
    'width': '100%',
    'display': 'none',
    'padding': '16px 0px 0px',
    'listStyle': 'none',
    'borderRadius': '6px',
    'backgroundColor': '#FFF',
    'zIndex': 2,
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D9D9D9',
      borderRadius: '10px',
    },
    'height': 'auto',
    'maxHeight': '342px',
    'position': 'absolute',
    'left': '0px',
    'top': '54px',
    '&.active': {
      display: 'block',
    },
    'boxShadow':
      '0px 16px 32px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.08)',
  },
  cvvHelper: {
    display: 'grid',
    gridTemplateColumns: '43px 1fr',
    width: '100%',
    alignItems: 'center',
    columnGap: '16px',
  },
}))

const getMaskedDateFromNumbers = (
  month: number | undefined,
  year: number | undefined
) => {
  if (!month || !year) {
    return ''
  }
  let monthStr = String(month)
  let yearStr = String(year)
  if (month < 10) {
    monthStr = `0${month}`
  }
  if (year < 10) {
    yearStr = `0${year}`
  }
  return `${monthStr}/${yearStr}`
}

interface IProps extends IAddressAndTermsProps {
  cardId?: number
  setCardId: any
  classes: any
  cards: any[]
  setPaymentError: any
  paymentError: string
  disabled: boolean
  order: IOrder
  validateCountryAndTerms(valid: boolean, focus: boolean): [boolean, boolean]
  setFormData(data: any): void
  validateBillingAddress(valid: boolean): boolean
  buttonDisabled: boolean
  requestInProgress: boolean
  replaceApplePayStarted(): void
}

export default function CardForm({
  cardId,
  setCardId,
  classes,
  cards,
  setPaymentError,
  paymentError,
  disabled,
  customer,
  order,
  validateCountryAndTerms,
  setFormData,
  validateBillingAddress,
  setConfirmFormChange,
  setTermsError,
  setTermsAction,
  termsError,
  termsAcceptField,
  addressAcceptField,
  buttonDisabled,
  requestInProgress,
  replaceApplePayStarted,
}: IProps) {
  const localClasses = useStyles()
  const globalClasses = useGlobalStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [cardType, setCardType] = useState('')
  const [cvvInput, setCvvInput] = useState('')
  const [openSelect, setOpenSelect] = useState(false)

  const numberField = useRef<HTMLInputElement>(null)
  const expireField = useRef<HTMLInputElement>(null)
  const cvvField = useRef<HTMLInputElement>(null)
  const rememberField = useRef<HTMLInputElement>(null)
  const cardholderField = useRef<HTMLInputElement>(null)
  const formRef = useRef(null)
  const [numberError, setNumberError] = useState<string | undefined>()
  const [expireError, setExpireError] = useState<string | undefined>()
  const [cvvError, setCvvError] = useState<string | undefined>()
  const [cardholderError, setCardholderError] = useState<string | undefined>()
  const [{ data: cardData }, isLoadingCardData, loadCardData] =
    useOrderCardData()

  useEffect(() => {
    if (
      numberField.current?.value &&
      !isCardNumber(numberField.current?.value) &&
      !numberError &&
      !expireError &&
      !cvvError &&
      !cardholderError
    ) {
      trackAddPaymentMethod('add_card_info_success', order, 'Bank card')
    }
  }, [numberField.current?.value])

  useEffect(() => {
    if (paymentError) {
      trackErrorScreen('error_screen', order, t(paymentError), 'Bank card')
    }
  }, [paymentError])

  function getCardFormData() {
    let res: any
    const securityCode = cvvField.current!.value

    if (cardId !== undefined) {
      res = { cardId: parseInt(String(cardId)), securityCode }
      window.localStorage.setItem('cardId', String(cardId))
    } else {
      const expireVal = expireField.current!.value
      const expire = expireVal.split('/')
      const ccn = `${numberField.current!.value.slice(
        0,
        4
      )}${numberField.current!.value.slice(-4)}`
      window.localStorage.setItem('ccn', ccn)
      res = {
        securityCode,
        number: numberField.current!.value.replace(/\s/g, ''),
        monthExpire: parseInt(expire[0]),
        yearExpire: parseInt(
          expire[1].length === 2 ? expire[1] : expire[1].substring(2, 4)
        ),
        cardholder: cardholderField.current!.value,
        save: rememberField.current!.checked,
      }
    }
    return res
  }

  useEffect(() => {
    if (order.hasCard) {
      loadCardData({ id: order.id })
    }
  }, [])

  useEffect(() => {
    setNumberError(undefined)
    setExpireError(undefined)
    setCardholderError(undefined)
    setCvvError(undefined)
    setCardType('')
    if (cvvField.current) {
      cvvField.current.value = ''
      setCvvInput('')
    }
  }, [cardId])

  useEffect(() => {
    if (numberField.current && !isLoadingCardData) {
      IMask(numberField.current, { mask: '0000 0000 0000 000000' })
    }
  }, [numberField.current, isLoadingCardData])

  useEffect(() => {
    if (expireField.current && !isLoadingCardData) {
      IMask(expireField.current, { mask: '00/0000' })
    }
  }, [expireField.current, isLoadingCardData])

  useEffect(() => {
    if (cvvField.current && !isLoadingCardData) {
      IMask(cvvField.current, { mask: '000' })
    }
    if (cardData?.number) {
      validateForm()
    }
  }, [cvvField.current, isLoadingCardData])

  useEffect(() => {
    if (numberError && numberField.current) {
      const v = numberField.current.value
      setNumberError(
        composeValidators(
          () => required(v),
          () => isCardNumber(v)
        )
      )
    }
    if (expireError && expireField.current) {
      const v = expireField.current.value
      setExpireError(
        composeValidators(
          () => required(v),
          () => isValidCardExpires(v)
        )
      )
    }
    if (cvvError && cvvField.current) {
      const v = cvvField.current.value
      setCvvError(
        composeValidators(
          () => required(v),
          () => lengthRequired(v, 3)
        )
      )
    }
    if (cardholderError && cardholderField.current) {
      const v = cardholderField.current.value
      setCardholderError(required(v))
    }
  }, [language])

  useEffect(() => {
    if (paymentError.length) {
      if (!!cardId) {
        cvvField.current?.focus()
        return
      }
      numberField.current?.focus()
    }
  }, [paymentError])

  const selected = cards.length
    ? cards.find((c: any) => c.id === cardId)
    : undefined
  const selectedCData =
    selected && cardValidator.number(selected.number.slice(0, 4))

  // @ts-ignore
  let timerId

  const validateForm = () => {
    if (!cvvField.current) {
      return
    }

    let valid = true
    let errorFocused = false

    const securityCode = cvvField.current.value
    const cvvErr = composeValidators(required, (v: any) =>
      lengthRequired(v, 3)
    )(securityCode)

    if (!!cvvErr) {
      setCvvError(cvvErr)
      valid = false
    }
    if (!cardId) {
      const expireVal = expireField.current!.value
      const expErr = composeValidators(required, isValidCardExpires)(expireVal)
      const numVal = numberField.current!.value
      const numErr = composeValidators(required, isCardNumber)(numVal)
      const holderVal = cardholderField.current!.value
      const holderErr = composeValidators(required, (v: any) =>
        maxLength(v, 64)
      )(holderVal)

      if (!!numErr) {
        setNumberError(numErr)
        valid = false
        numberField.current!.focus()
        errorFocused = true
      }
      if (!!expErr) {
        setExpireError(expErr)
        valid = false
        if (!errorFocused) {
          expireField.current!.focus()
          errorFocused = true
        }
      }
      if (!!cvvErr && !errorFocused) {
        cvvField.current!.focus()
        errorFocused = true
      }
      if (!!holderErr) {
        setCardholderError(holderErr)
        valid = false
        if (!errorFocused) {
          cardholderField.current!.focus()
          errorFocused = true
        }
      }
    }
    valid = validateBillingAddress(
      validateCountryAndTerms(valid, errorFocused)[0]
    )
    return valid
  }

  useEffect(() => {
    const storageCardId = window.localStorage.getItem('cardId')
    const findId = cards.find((card) => card.id === Number(storageCardId))
    if (findId) {
      setPaymentError('')
      setCardId(Number(storageCardId))
    }
  }, [])

  return (
    <Loader loading={isLoadingCardData}>
      <form
        ref={formRef}
        className={localClasses.root}
        onSubmit={(e: any) => {
          e.preventDefault()
          replaceApplePayStarted()
          if (cvvField.current) {
            const valid = validateForm()

            if (valid) {
              setFormData(getCardFormData())
            }
          }
        }}
      >
        <div className={classes.blockTitle}>{t('Card information')}</div>
        {cards.length > 0 && (
          <ClickAwayListener onClickAway={() => setOpenSelect(false)}>
            <div className={localClasses.selectWrapper}>
              {' '}
              <div className={classes.row}>
                <TextField
                  name="cardId"
                  className={clsx(classes.field, localClasses.select)}
                  value={selected ? selected.number : t('Add new card')}
                  InputProps={{
                    readOnly: true,
                    startAdornment: cardId && (
                      <InputAdornment position="start">
                        <div
                          className={clsx(
                            localClasses.type,
                            selectedCData?.card?.type
                          )}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ExpandMoreIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="filled"
                  onClick={() => setOpenSelect(!openSelect)}
                />
              </div>
              <ul
                className={clsx(localClasses.cardsList, openSelect && 'active')}
              >
                {cards.map((c: any) => {
                  const cdata = cardValidator.number(c.number.slice(0, 4))
                  return (
                    <li
                      className={localClasses.option}
                      key={c.id}
                      onClick={() => {
                        setPaymentError('')
                        setCardId(c.id)
                        setOpenSelect(false)
                      }}
                    >
                      <div className="opt-type">
                        <div
                          className={clsx(localClasses.type, cdata?.card?.type)}
                        ></div>
                      </div>
                      <div className="description">
                        <div>
                          <Typography variant="body1" component="div">
                            &#183;&#183;&#183;&#183; {c.number.slice(-4)}
                          </Typography>
                        </div>
                      </div>
                    </li>
                  )
                })}
                <li className={localClasses.option}>
                  <button
                    className={localClasses.selectBtn}
                    onClick={() => {
                      setPaymentError('')
                      setCardId(undefined)
                      setOpenSelect(false)
                    }}
                  >
                    <AddIcon fontSize="small" />
                    {t('Add new card')}
                  </button>
                </li>
              </ul>
            </div>
          </ClickAwayListener>
        )}
        {!cardId && (
          <div className={classes.formGroup}>
            <div className={classes.row}>
              <TextField
                className={classes.field}
                name="cardnumber"
                id="cardnumber"
                label={t('Card number')}
                fullWidth
                type="text"
                variant="filled"
                placeholder="0000 0000 0000 0000"
                // @ts-ignore
                defaultValue={
                  cardData.number ??
                  (!!cardId
                    ? cards.find((c: any) => c.id === parseInt(String(cardId)))
                        .number
                    : '')
                }
                inputRef={numberField}
                onKeyUp={(e: any) => {
                  const val = e.target.value
                  const cData = cardValidator.number(val)
                  if (cData && cData.card) {
                    const type = cData.card.type
                    setCardType(type)
                    if (!['visa', 'mastercard', 'maestro'].includes(type)) {
                      return setPaymentError('cardNotSupported')
                    }
                    setPaymentError('')
                    if (isCardNumber(val) === undefined) {
                      expireField.current!.focus()
                    }
                  } else {
                    setCardType('')
                  }
                }}
                onBlur={(e: any) => {
                  const v = e.target.value
                  setNumberError(
                    composeValidators(
                      () => required(v),
                      () => isCardNumber(v)
                    )
                  )
                }}
                error={!cardId && !!numberError}
                helperText={!cardId && numberError}
                tabIndex={1}
                inputProps={{
                  inputMode: 'numeric',
                  ...qaAttr('card-number-input'),
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={clsx(localClasses.type, cardType)} />
                    </InputAdornment>
                  ),
                }}
                disabled={disabled}
              />
            </div>
            <div className={classes.row}>
              <TextField
                className={classes.field}
                name="exp-date"
                id="expires"
                label={t('MM/YY')}
                fullWidth
                type="text"
                disabled={!!cardId || disabled}
                variant="filled"
                inputRef={expireField}
                placeholder="00/00"
                defaultValue={getMaskedDateFromNumbers(
                  cardData.monthExpire,
                  cardData.yearExpire
                )}
                onKeyUp={(e: any) => {
                  const val = e.target.value
                  if (
                    val &&
                    val.length === 5 &&
                    isValidCardExpires(val) === undefined
                  ) {
                    cvvField.current!.focus()
                  }
                }}
                onBlur={(e: any) => {
                  const val = e.target.value
                  setExpireError(
                    composeValidators(
                      () => required(val),
                      () => isValidCardExpires(val)
                    )
                  )
                }}
                error={!cardId && !!expireError}
                helperText={!cardId && expireError}
                tabIndex={2}
                inputProps={{
                  inputMode: 'numeric',
                  ...qaAttr('card-exp-date-input'),
                }}
              />
              <TextField
                className={clsx(classes.field, 'cvv')}
                name="secureCode"
                label={t('CVV')}
                id="cvvField"
                type="text"
                fullWidth
                placeholder="000"
                required={false}
                inputRef={cvvField}
                variant="filled"
                onKeyUp={(e: any) => {
                  const val = e.target.value
                  if (val && val.length === 3 && !cardId) {
                    setCvvError(undefined)
                    cardholderField.current!.focus()
                  }
                }}
                onBlur={(e: any) => {
                  const val = e.target.value
                  setCvvError(
                    composeValidators(
                      () => required(val),
                      () => lengthRequired(val, 3)
                    )
                  )
                }}
                error={!!cvvError}
                helperText={cvvError}
                autoFocus={!!cardId}
                inputProps={{
                  inputMode: 'numeric',
                  ...qaAttr('card-cvv-input'),
                }}
                disabled={disabled}
              />
            </div>
            <div className={classes.row}>
              <TextField
                className={classes.field}
                name="cardholder"
                id="cardholder"
                label={t('Cardholder name')}
                fullWidth
                type="text"
                variant="filled"
                placeholder="JOHN DOE"
                inputRef={cardholderField}
                defaultValue={cardData.cardHolder}
                onBlur={(e: any) => {
                  const val = e.target.value
                  setCardholderError(
                    composeValidators(
                      () => required(val),
                      () => maxLength(val, 64)
                    )
                  )
                }}
                onKeyUp={(e: any) => {
                  if (e.target.value?.length) {
                    setPaymentError('')
                  }
                }}
                inputProps={qaAttr('card-holder-name-input')}
                error={!!cardholderError}
                helperText={cardholderError}
                disabled={disabled}
              />
            </div>
            {/* ZHW-2957 */}
            <div className={classes.switch}>
              <Typography component="div" variant="body2" color="textSecondary">
                {t('Save card details')}
              </Typography>
              <Switch inputRef={rememberField} defaultChecked color="primary" />
            </div>
            {/* ZHW-2957 */}
          </div>
        )}
        {!!cardId && (
          <div className={classes.formGroup}>
            <div className={classes.row}>
              <InputMask
                mask="999"
                maskChar=""
                onBlur={(e: any) => {
                  const val = e.target.value
                  setCvvError(
                    composeValidators(
                      () => required(val),
                      () => lengthRequired(val, 3)
                    )
                  )
                }}
                disabled={disabled}
                beforeMaskedValueChange={(newState, oldState, userInput) => {
                  return {
                    value: cvvInput.length || !!userInput ? newState.value : '',
                    selection: newState.selection,
                  }
                }}
              >
                {() => (
                  <TextField
                    className={clsx(classes.field, 'cvv')}
                    name="secureCode"
                    label={t('CVV')}
                    id="cvvField"
                    type="text"
                    fullWidth
                    placeholder="000"
                    required={false}
                    inputRef={cvvField}
                    variant="filled"
                    onKeyUp={(e: any) => {
                      const val = e.target.value
                      setCvvInput(val)
                      if (val && val.length === 3) {
                        setPaymentError('')
                        setCvvError(undefined)
                      }
                    }}
                    error={!!cvvError}
                    helperText={cvvError}
                    autoFocus
                    inputProps={{
                      inputMode: 'numeric',
                      ...qaAttr('card-cvv-input'),
                    }}
                  />
                )}
              </InputMask>
              <div className={localClasses.cvvHelper}>
                <img src={Cvvicon} alt="CVV Icon" />
                <Typography
                  variant="body2"
                  component="div"
                  className="helper"
                  color="textSecondary"
                >
                  {t('cvvHelp')}
                </Typography>
              </div>
            </div>
          </div>
        )}
        <OrderDetails order={order} styles={classes} />
        <AddressAndTerms
          customer={customer}
          setConfirmFormChange={setConfirmFormChange}
          addressAcceptField={addressAcceptField}
          termsError={termsError}
          setTermsError={setTermsError}
          setTermsAction={setTermsAction}
          termsAcceptField={termsAcceptField}
          order={order}
        />
        <div className={classes.payButtonWrapper}>
          <PayButton
            disabled={buttonDisabled}
            requestInProgress={requestInProgress}
            amount={order.paymentAmount}
            currency={order.paymentCurrency}
            onClick={function (e: any) {
              e.preventDefault()
              trackClickPay('click_pay', order, 'Bank card', 'Card')
              // @ts-ignore
              clearTimeout(timerId)
              timerId = setTimeout(() => {
                //@ts-ignore
                formRef.current.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true })
                )
              }, 500)
            }}
          />
        </div>
      </form>
    </Loader>
  )
}
