import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Field } from 'react-final-form';

export interface ISelectOption {
  value: string;
  label: any;
  className?: any;
}

interface ISelectProps {
  input: any;
  label: string;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  options: ISelectOption[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    position: 'relative',
    padding: '8px 12px',
  },
  label: {
    marginLeft: 0
  },
  check: {
    padding: '0 6px 0 0',
    marginTop: 0,
  },
  selectAll: {
    color: theme.palette.success.main,
  },
  selectNone: {
    color: theme.palette.error.main
  },
  apply: {
    paddingRight: 16,
    paddingTop: theme.spacing(1),
    textAlign: 'right'
  }
}));

const SelectWrapper = (props: ISelectProps) => {
  const classes = useStyles();
  const {
    input: {
      name, value, onChange, ...restInput
    },
    label,
    fullWidth,
    margin,
    options,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  let opts = [...options];

  return (
    <FormControl variant="filled" fullWidth={fullWidth} margin={margin}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        name={name}
        inputProps={restInput}
        value={value || []}
        renderValue={(val: any) => {
          const selected = val.map((v: string) => options.find((o) => o.value === v)?.label || '');
          return selected.join(', ');
        }}
        onChange={(e) => onChange(e.target.value)}
        // labelWidth={labelWidth}
        fullWidth
        // input={<Input />}
        variant="filled"
        multiple
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        {...rest}
      >
        {opts.map((o) => (
          <MenuItem value={o.value} key={o.value} className={clsx(classes.item, o.className)}>
            <FormControlLabel
              control={<Checkbox checked={value.includes(o.value)} className={classes.check} />}
              label={o.label}
              className={classes.label}
              onChange={(e) => e.preventDefault()}
              onClick={(e) => e.preventDefault()}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface ISelectFieldProps {
  name: string;
  label: string;
  options: ISelectOption[];
  validate?: any;
  disabled?: boolean;
  loading?: boolean;
  inputRef?: any;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
}

function Loading() {
  return <CircularProgress size={20} style={{ marginRight: 10 }} />;
}

export default function MultiSelectField({ name, loading, disabled, options, ...rest}: ISelectFieldProps) {

  return (
    <Field
      name={name}
      // @ts-ignore
      component={SelectWrapper}
      IconComponent={loading ? Loading : disabled ? () => null : ExpandMoreIcon}
      multiple
      options={options}
      {...rest}
    />
  );
}
