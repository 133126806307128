import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import Typography from '@material-ui/core/Typography';
import Loader from '../../../components/Loader';
import OrdersFilter from './OrdersFilter';
import { useCustomerOrders } from '../../../api/private/orders';
import useFilter from '../../../hooks/useFilter';
import { emptyFilter } from './MerchantOrders';
import TXList from './TxList';
import TxMobileList from './TxMobileList';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flex: 1
  },
  ordersFilter: {
    '& button': {
      top: -6,
    },
    '& button.active': {
      top: -65,
      width: 40,
      height: 40,
    },
    '& .react-datepicker__navigation': {
      top: 10,
    },
  },
  dialogWrapper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 480
    },
    '& .MuiDialogContent-root': {
      textAlign: 'center'
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center'
    }
  },
  dialogButton: {
    height: 36,
    flex: `0 0 260px`,
  },
  button: {
    height: 36,
    flex: `0 0 160px`,
  }
}));

export default function Orders() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [{ items: orders, cursor, full, error }, loading, load] = useCustomerOrders();
  const [setFilter, filter] = useFilter('orders', emptyFilter);
  const { user } = useSelector((state: any) => state.account);
  const { t } = useTranslation();

  useEffect(() => {
    user.id && setFilter({ acceptedById: user.id });
  }, [user])

  return (
    <div>
      <div className={classes.row}>
        <Typography variant="h4" className={clsx(globalClasses.title, classes.title)}>{t('Personal orders')}</Typography>
        <OrdersFilter
          filter={filter}
          onChange={setFilter}
          onSubmit={() => load({ filter })}
          onClear={() => {
            setFilter(emptyFilter);
            load({ filter: emptyFilter });
          }}
          type="customer"
          load={load}
          className={classes.ordersFilter}
        />
      </div>

      <div className={globalClasses.desktopShow}>
        <TXList orders={orders} loading={loading} />
      </div>
      <div className={globalClasses.mobileShow}>
        <TxMobileList orders={orders} loading={loading} />
      </div>
      <Loader loading={loading} height={orders.length > 0 ? 100 : undefined} />
      {user && !loading && !error && !full && <Waypoint onEnter={() => load({ filter, cursor, limit: 30 })} />}
    </div>
  );
}
