import makeStyles from '@material-ui/core/styles/makeStyles';

export const useAccountStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  pageTitle: {
    marginBottom: theme.spacing(2)
  },
  button: {
    borderRadius: 0,
    background: theme.palette.primary.main,
    boxShadow: 'none',
    color: '#fff'
  },
  tooltiped: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    fontStyle: 'italic',
  }
}));
