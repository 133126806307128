import React from 'react'

import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import { ActiveForm } from '@/store/reducers/routing'
import { KycStatus } from '@/api/kyc'
import { getLayoutApmView } from '@/helpers/getLayoutApmView'
import { isChainValley } from '@/helpers/mirrorHelper'
import { defineNFTFlow } from '@/helpers/nftHelpers/nftFlow'
import useApmViewStyles from '@/hooks/useApmViewStyles'

import SkrillLogo from './img/Skrill.svg'
import NftOrderInfo from './NftOrderInfo'
import OrderInfo from './OrderInfo'
import SiteLogo from '../Logo'
import Loader from '../Loader'
import CurrencyNameMapper from '../CurrencyNameMapper/CurrencyNameMapper'

interface IStylesProps {
  width?: number
}

const useStyles = makeStyles((theme) => ({
  root: ({ width }: IStylesProps) => ({
    width: width || '560px',
    position: 'relative',
    '@media (max-width: 599.95px)': {
      width: '100%',
      borderRadius: 0,
    },
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '32px 40px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 0',
    },
  },
  center: {
    justifyContent: 'center',
  },
  withHeader: {
    paddingTop: 25,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 16,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  marginBottom: {
    marginBottom: 24,
  },
  payoutLogo: {
    width: 48,
    height: 48,
    background: `url(${SkrillLogo}) no-repeat center`,
  },
  payoutDelimiter: {
    margin: '32px 40px 0',
    height: 1,
    backgroundColor: '#D9DCE4',
  },
  orderInfoWrapper: {
    display: 'flex',
  },
  orderInfoImage: {
    marginLeft: theme.spacing(2),
  },
  ccwChainValleyHeader: {
    padding: '42px 40px 0',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 20px 0',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '16px',
    },
  },
}))

export interface IApmPanel {
  selected?: string
  apmAmount: number
}

interface IProps {
  width?: number
  title?: string
  step?: string
  children: any
  className?: any
  logoPullRight?: boolean
  exchangeRate: number
  paymentCurrency?: string
  currency?: string
  order?: any
  activeForm?: ActiveForm | ''
  kycStatus: string
  hideLogo?: boolean
  apm?: IApmPanel
  hideRightSideInfo?: boolean
  isPayout?: boolean
}

function Panel({
  width,
  title,
  step = '',
  children,
  className,
  logoPullRight,
  paymentCurrency,
  currency,
  exchangeRate,
  order,
  activeForm,
  kycStatus,
  hideLogo,
  hideRightSideInfo,
  apm,
  isPayout,
}: IProps) {
  const classes = useStyles({ width })
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const apmStyles = useApmViewStyles({ apm: order.apm })

  //@ts-ignore
  const orderShowing = ![ActiveForm.OTP, ActiveForm.RESULT].includes(activeForm)
  const showLogo =
    !hideLogo &&
    ![
      /*'card', 'verification', 'pre-verification', */ 'kyc-corrections',
    ].includes(step)
  const showOrderAmount =
    !['success', 'error', 'interrupted'].includes(step) &&
    //@ts-ignore
    ![KycStatus.WAITING_FOR_APPROVAL, KycStatus.PENDING].includes(kycStatus)

  const showApmView = getLayoutApmView(step, order)

  const isNFTFlow = defineNFTFlow(order?.currency)

  return (
    <Card
      className={clsx(
        classes.root,
        className,
        showApmView && !isChainValley && apmStyles.root
      )}
    >
      <div
        className={clsx(
          classes.header,
          !showOrderAmount ? classes.marginBottom : '',
          showApmView &&
            (isChainValley ? classes.ccwChainValleyHeader : classes.center)
        )}
      >
        {!showApmView && (title || showLogo) && (
          <>
            {logoPullRight && <Typography variant="body1">{title}</Typography>}
            {showLogo && <SiteLogo />}
            {!logoPullRight && <Typography variant="body1">{title}</Typography>}
          </>
        )}
        {isPayout && <div className={classes.payoutLogo} />}
        {!isPayout && !hideRightSideInfo && !order.id && paymentCurrency && (
          <div>
            {!exchangeRate ? (
              <Loader loading height={60} />
            ) : (
              <div>
                <Typography variant="h6" component="div">
                  {t('Buy')} {t('crypto')}
                </Typography>
                {exchangeRate > 0 && (
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                    translate="no"
                  >
                    <span className="exchangeRate">
                      {exchangeRate} {paymentCurrency} ≈
                    </span>{' '}
                    1 <CurrencyNameMapper currency={currency ?? ''} />
                  </Typography>
                )}
              </div>
            )}
          </div>
        )}
        {!isPayout && !hideRightSideInfo && order.id && orderShowing && (
          <>
            {isNFTFlow && <NftOrderInfo order={order} apm={apm} step={step} />}
            {!isNFTFlow && (
              <OrderInfo
                showOrderAmount={showOrderAmount}
                order={order}
                apm={apm}
                step={step}
              />
            )}
          </>
        )}
      </div>
      {isPayout && <div className={classes.payoutDelimiter} />}
      <CardContent
        className={clsx({ [classes.withHeader]: title || showLogo })}
      >
        {children}
      </CardContent>
    </Card>
  )
}

const mapStateToProps = (state: any) => {
  return {
    order: state.appData.order,
    apm: state.appData.apm,
    exchangeRate: state.appData.exchangeRate,
    activeForm: state.routing.activeForm || '',
    kycStatus: state.routing.kycStatus || '',
  }
}

export default connect(mapStateToProps)(Panel)
