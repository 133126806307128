interface IHotJarUserInfo {
  userId: number
  orderId: number
  apm: string
  merchantId: string
}

export const hotJarSendUserInfo = ({
  userId,
  orderId,
  apm,
  merchantId,
}: IHotJarUserInfo) => {
  // @ts-ignore
  if (!window.hj) {
    return
  }

  // @ts-ignore
  window.hj('identify', userId, {
    orderId,
    apm,
    merchant_id: merchantId,
  })
}

export const hotJarSendEvent = () => {
  // @ts-ignore
  if (!window.hj) {
    return
  }

  // @ts-ignore
  window.hj('event', 'start_recording')
}
