import { useCallback, useState } from 'react'
import * as Sentry from '@sentry/react'
import { IOrder, OrderStatus, useOrderInfo } from '../api/order'

export interface ISCData {
  sessionToken: string
  merchantId: string
  merchantSiteId: string
  env: string
}

interface ISCCallbacks {
  applePay(callback: any, checkStatus?: boolean): any
  googlePay(
    googleToken: string,
    checkStatus?: boolean,
    callback?: (res: any) => void,
    externalTokenProvider?: 'GooglePay' | 'ApplePay'
  ): any
}

export type TScPaymentResult = 'success' | 'error'

interface ISCReturn {
  isSCLoaded: boolean
  initSC(data: ISCData, customer: any, order: any): any
  sfc: any
  scPaymentProcessing: boolean
  scPaymentResult?: TScPaymentResult
  callbacks: ISCCallbacks
}

export default function useSafeCharge(
  setServerError?: any,
  onLoad?: any
): ISCReturn {
  const [loaded, setLoaded] = useState(() => {
    return Boolean(document.querySelector('#sc-script'))
  })
  const [sfc, setSfc] = useState<any>()
  const [sfcData, setSfcData] = useState<any>()
  const [customer, setCustomer] = useState<any>()
  const [order, setOrder] = useState<any>()
  const [processing, setProcessing] = useState(false)
  const [, , loadUpdatedOrder] = useOrderInfo()
  const [result, setResult] = useState<undefined | TScPaymentResult>()

  async function checkOrderStatus(id: any) {
    try {
      const o = await loadUpdatedOrder({ id })
      if (o.status !== OrderStatus.PROCESSING) {
        setResult(
          [
            OrderStatus.EXECUTED,
            OrderStatus.SUCCESS,
            OrderStatus.VERIFICATION,
            OrderStatus.SUSPICIOUS,
          ].includes(o.status)
            ? 'success'
            : 'error'
        )
        setProcessing(false)
      } else {
        setTimeout(() => checkOrderStatus(id), 2000)
      }
    } catch {
      setTimeout(() => checkOrderStatus(id), 2000)
    }
  }

  function add(cb?: any) {
    const e = document.getElementById('sc-script')
    if (e) {
      return
    }
    const s = document.createElement('script')
    s.src =
      'https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js'
    s.id = 'sc-script'
    s.onload = () => {
      setLoaded(true)
      if (typeof cb === 'function') {
        cb()
      }
    }
    document.body.appendChild(s)
  }

  function init(data: ISCData, customer: object, order: object) {
    setSfcData(data)
    setCustomer(customer)
    setOrder(order)
    // @ts-ignore
    setSfc(window.SafeCharge(data))
  }

  const applePay = useCallback(
    (callback: any, checkStatus = true) => {
      if (sfc && sfcData) {
        const req = {
          ...sfcData,
          countryCode: customer.country,
          currencyCode: order.paymentCurrency,
          amount: order.paymentAmount,
          userTokenId: customer.id.toString(),
          clientUniqueId: order.id,
          billingAddress: {
            country: customer.country,
            email: customer.email,
          },
          total: {
            label: 'Utorg',
            amount: order.paymentAmount,
          },
        }
        try {
          sfc.createApplePayPayment(req, function (res: any) {
            setProcessing(true)
            checkStatus && checkOrderStatus(order.id)
            callback && callback(res)
          })
        } catch (e) {
          if (process.env.REACT_APP_SENTRY_URL) {
            Sentry.captureException(e, {
              tags: { section: 'SAFECHARGE APPLE PAY' },
            })
          }
          if (setServerError) {
            setServerError('safeCharge applePayError')
          }
        }
      }
    },
    [sfc, sfcData, customer, order]
  )

  const googlePay = useCallback(
    (
      googleToken: string,
      checkStatus = true,
      callback?: (res: any) => void,
      externalTokenProvider = 'GooglePay',
    ) => {
      if (sfc && sfcData && googleToken) {
        const req = {
          ...sfcData,
          countryCode: customer.country,
          currencyCode: order.paymentCurrency,
          amount: order.paymentAmount,
          userTokenId: customer.id.toString(),
          clientUniqueId: order.id,
          paymentOption: {
            card: {
              externalToken: {
                externalTokenProvider,
                mobileToken: googleToken,
              },
            },
          },
          billingAddress: {
            country: customer.country,
            email: customer.email,
          },
          total: {
            label: 'Utorg',
            amount: order.paymentAmount,
          },
        }
        try {
          sfc.createPayment(req, function (res: any) {
            setProcessing(true)
            checkStatus && checkOrderStatus(order.id)
            callback && callback(res)
          })
        } catch (e) {
          if (process.env.REACT_APP_SENTRY_URL) {
            Sentry.captureException(e, {
              tags: { section: 'SAFECHARGE GOOGLE PAY' },
            })
          }
          if (setServerError) {
            setServerError('safeCharge googlePayError')
          }
        }
      }
    },
    [sfc, sfcData, customer, order]
  )

  add(onLoad)

  return {
    isSCLoaded: loaded,
    initSC: init,
    sfc,
    scPaymentProcessing: processing,
    scPaymentResult: result,
    callbacks: {
      applePay,
      googlePay,
    },
  }
}
