import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import Loader from '../../../components/Loader';
import Typography from '@material-ui/core/Typography';
import ServerMessage from '../../../components/UI/ServerMessage';
import clsx from 'clsx';

interface IProps {
  onConfirm: any;
  onCancel: any;
  deleting: boolean;
  card: any;
  error: string;
}

export default function CardDeletePopup({ onConfirm, onCancel, deleting, card, error }: IProps) {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  return (
    <Dialog open onClose={onCancel}>
      <div style={{ maxWidth: '430px', width: '100%', padding: '12px 16px' }}>
        <Loader loading={deleting} width={400} height={160}>
          <DialogContent>
            <Typography variant="h6" className={globalClasses.title}>
              {t('Delete card')} {card.number}
            </Typography>
            {error.length > 0
              ? <ServerMessage type="error" text={error} isAccount />
              : <Typography variant="body2">{t('cardDeleteConfirm')}</Typography>}
          </DialogContent>
          <DialogActions>
            {!error.length && (
              <Button
                className={clsx(globalClasses.button, 'small')}
                variant="contained"
                color="primary"
                onClick={onConfirm}
              >
                {t('Delete card')}
              </Button>
            )}
            <Button
              className={clsx(globalClasses.button, 'small')}
              variant="text"
              color="primary"
              onClick={onCancel}
            >
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Loader>
      </div>
    </Dialog>
  )
}
