import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import clsx from 'clsx'
import { Form } from 'react-final-form'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import Loader from '@/components/Loader'
import ServerMessage from '@/components/UI/ServerMessage'
import PayFinish from '@/views/components/Finished'
import OrderDetails from '@/views/components/Details'
import { Currency, Input } from '@/components/Form'
import { useAcceptOrder, IOrder, OrderProcessingError } from '@/api/order'
import { usePaymentInit } from '@/api/payment'
import { ICustomer } from '@/types/customer'
import { ICurrency } from '@/types/currency'
import { setOrderDataRdx, setCustomerAction } from '@/store/actions/appData'
import captchaRequest from '@/helpers/captcha'
import useFormStyles from '@/views/Exchange/forms/useFormStyles'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import usePaymentFormStyles from '@/views/components/usePaymentFormStyles'
import { currencyOptionMapper } from '@/helpers/currency'
import { composeValidators, required, isEmail } from '@/validators'
import { PayoutAlert } from './components'
import { trackPayout } from '@/helpers/analytics'
import TXLimitError from '@/components/TxLimitsError'
import ResidenceRestricted from '../Account/Profile/ResidenceRestricted'

const useStyles = makeStyles((theme) => ({
  orderInfo: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(4),
  },
  alerts: {
    marginTop: 12,
    '& > div:last-child': {
      borderBottom: 'none',
      marginBottom: 0,
    }
  },
}))

interface IPayout {
  token: string
  currencies: ICurrency[]
  loadOrder: (data: any) => void
  setProcessingError(err: OrderProcessingError): void
}

export const Payout: FC<IPayout> = ({
  token,
  currencies,
  setProcessingError,
  loadOrder,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { step } = useParams<any>()
  const { order, customer } = useSelector((state: any) => state.appData)
  const { ui } = useSelector((state: any) => state.common)
  const nextStep = (s: 'success' | 'error') => history.push(
    `/order/${order.id}/${s}${window.location.search}`
  )

  const {
    form: {
      buttonColor,
      borderRadius,
    },
    global: {
      palette: { border },
    },
  } = ui

  const {
    accept,
    accepting,
    limitError,
    kycRejected,
    countryNotSupported,
  } = useAcceptOrder({
    setOrder: (data: IOrder) => dispatch(setOrderDataRdx(data)),
    setCustomer: (data: ICustomer) => dispatch(setCustomerAction(data)),
    loadOrder,
    setProcessingError,
    order,
    customer,
  })
  const [{ data: paymentData, error }, proceeding, proceed] = usePaymentInit(String(order.id), false)
  const [sending, setSending] = useState(false)

  const formClasses = useFormStyles({ buttonColor, borderRadius, border })
  const globalClasses = useGlobalStyles()
  const classes = usePaymentFormStyles()
  const payoutClasses = useStyles()

  useEffect(() => {
    if (order.id) {
      captchaRequest(
        '/api/widget/v1/order/accept',
        accept,
        {
          email: order.acceptedByEmail,
          id: order.id,
          authToken: token,
        }
      )
    }
  }, [order.id])

  useEffect(() => {
    if (
      step !== 'success' &&
      ['SUSPICIOUS', 'EXECUTED', 'SUCCESS'].includes(order.status)
    ) {
      nextStep('success')
    } else if (step !== 'error' && order.status === 'ERROR') {
      nextStep('error')
    }
  }, [step, order])

  useEffect(() => {
    if (paymentData.id) {
      nextStep('success')
    }
  }, [paymentData])

  useEffect(() => {
    setSending(false)
    if (error.type === 'PROVIDER_ERROR') {
      nextStep('error')
    }
  }, [error])

  const proceedPayment = ({ email }: any) => {
    trackPayout('click_withdraw', order)
    if (sending) {
      return
    }
    setSending(true)
    window.localStorage.setItem('skrillEmail', email)
    proceed({
      orderId: order.id,
      source: 'APM',
      apm: {
        id: order.apm,
        inputs: { email },
      },
    })
  }

  const isFinished = ['success', 'error'].includes(step)

  const errText = error?.message?.toLowerCase() === 'account not found'
    ? 'payout.accountNotFound'
    : 'somethingWentWrong'

  useEffect(() => {
    trackPayout('view_sell_crypto', order)
  }, [])

  if (limitError?.error) {
    return <TXLimitError error={limitError} failUrl={order.failUrl} />
  }

  if (countryNotSupported) {
    return <ResidenceRestricted country={countryNotSupported} order={order} />
  }

  return (
    <Loader loading={accepting || (!isFinished && !customer.id)}>
      <div className={classes.root}>
        <div className={classes.progress}>
          <div className="step active" />
          <div className={clsx('step', { active: isFinished })} />
        </div>
        <div className={payoutClasses.orderInfo}>
          <div>
            <Typography variant="body2" style={{ color: '#535F84' }}>
              {t('Exchange order')}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 400 }}>
              #{order.id}
            </Typography>
          </div>
        </div>
        {isFinished && (
          <PayFinish
            step={step}
            nextStep={() => null}
            order={order}
            customer={customer}
            error={error.type}
          />
        )}
        {!isFinished && (
          <div>
            {error && Object.keys(error).length > 0 && (
              <ServerMessage type="error" text={errText} />
            )}
            <Form
              onSubmit={proceedPayment}
              initialValues={{
                amount: order.amount,
                currency: order.currency,
                email: order.acceptedByEmail,
              }}
              render={({ handleSubmit, submitting, values }) => {
                if (values.email && !isEmail(values.email)) {
                  trackPayout('add_email', order)
                }
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={formClasses.row}>
                      <Currency
                        inputName="amount"
                        selectName="currency"
                        currencies={currencies
                          .filter((c) => c.type === 'FIAT')
                          .map(currencyOptionMapper)}
                        label={t('labels.Buy')}
                        currenciesLoading={false}
                        locked
                      />
                    </div>
                    <div className={formClasses.row}>
                      <Input
                        name="email"
                        label={t('payout.skrillEmail')}
                        validate={composeValidators(required, isEmail)}
                        fullWidth
                        autoComplete="new-password"
                        inputProps={{
                          spellCheck: false,
                        }}
                      />
                    </div>
                    <Button
                      className={globalClasses.button}
                      disabled={!values.email || submitting || sending}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {sending && (
                        <CircularProgress size={22} color="secondary" />
                      )}
                      {!sending && t('payout.withdrawToSkrill')}
                    </Button>
                  </form>
                )
              }}
            />
            <div className={payoutClasses.alerts}>
              <PayoutAlert
                title={t('payout.thirdPartyAlertTitle')}
                text={t('payout.thirdPartyAlert')}
              />
            </div>
            <OrderDetails
              order={order}
              styles={classes}
            />
          </div>
        )}
      </div>
    </Loader>
  )
}
