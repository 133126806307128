import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Form } from 'react-final-form'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useLoginStyles } from '@/views/Account/Login'
import AutocompleteInput, {
  IAutocompleteOption,
} from '../Form/AutocompleteField'
import Loader from '../Loader'
import countryNames from './../../helpers/country'
import { required } from '@/validators'
import { useCountries } from '@/api/settings'
import { Input } from '../Form'
import { IOrder } from '@/api/order'
import usePaymentFormStyles from '@/views/components/usePaymentFormStyles'

interface ICountryWithoutIP {
  order: IOrder
  setIsSaveCountry: (save: boolean) => void
}

export default function CountryWithoutIP({
  order,
  setIsSaveCountry,
}: ICountryWithoutIP) {
  const classes = useLoginStyles()
  const classesLocales = usePaymentFormStyles()

  const [{ data: countriesData }, loadingCountries, loadCountries] =
    useCountries()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const lang = language.split('-')[0]

  const countryOptions: IAutocompleteOption[] = []
  const [options, setOptions] = useState(countryOptions)
  const [code, setCode] = useState('')

  useEffect(() => {
    loadCountries()
  }, [])

  useEffect(() => {
    if (countriesData.length) {
      const countryList = countryNames.getNames(lang)
      countriesData.forEach((c: any) => {
        countryOptions.push({
          value: c.code,
          label: countryList[c.code] || c.name,
          name: c.name,
        })
      })
      setOptions(countryOptions)
    }
  }, [countriesData, lang])

  return (
    <Loader loading={loadingCountries}>
      <Form
        onSubmit={(values) => {
          window.localStorage.setItem(
            'residence',
            JSON.stringify({ code: values.residence, id: order.id })
          )
          setIsSaveCountry(true)
        }}
        mutators={{
          setCountry: (args, state, { changeValue }) => {
            const [country] = args
            changeValue(state, 'residence', () => (country ? country.name : ''))
            setCode(country && country.value)
          },
        }}
        initialValues={{
          residence: code,
        }}
        render={({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Typography component="div" className={classesLocales.blockTitle}>
                {t('Country Of Residence')}
              </Typography>
              {options.length > 0 && (
                <div>
                  <AutocompleteInput
                    onChange={form.mutators.setCountry}
                    label={t('place holder Country Of Residence')}
                    options={options}
                    value={code}
                    required={false}
                  />
                  <Input
                    name="residence"
                    label=""
                    type="hidden"
                    validate={required}
                  />
                </div>
              )}
              <div>
                <Button
                  type="submit"
                  disabled={!code}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          )
        }}
      />
    </Loader>
  )
}
