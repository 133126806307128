import enUS from './en/translation.json'
import ruRU from './ru/translation.json'
import frFR from './fr/translation.json'
import ptPT from './pt/translation.json'
import plPL from './pl/translation.json'

export type ILangType = 'en' | 'fr' | 'pt' | 'pl';

const resources = {
  en: {
    translation: enUS
  },
  fr: {
    translation: frFR
  },
  pt: {
    translation: ptPT
  },
  pl: {
    translation: plPL,
  },
}

export default resources