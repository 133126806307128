import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import resources from './locales'

let lang = window.localStorage.getItem('lang')

if (window.location.search.includes('lang=')) {
  lang = window.location.search.split('lang=')[1].substr(0, 5)
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: lang || 'en-US',
})

export default i18n
