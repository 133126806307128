import { useTranslation } from 'react-i18next'

import { IAPM } from '@/api/settings'
import { IOrder } from '@/api/order'

interface IUseFinalErrorTextProps {
  apm?: IAPM
  error?: string
  isPayout?: boolean
  isUnavailableApm: boolean
  order?: IOrder | undefined
}

function useFinalErrorText({
  apm,
  error,
  order,
  isPayout,
  isUnavailableApm,
}: IUseFinalErrorTextProps) {
  const { t } = useTranslation()

  if (
    order?.apm === 'OBI' &&
    error &&
    ['PROVIDER_ERROR', 'PROVIDER_ERROR_SF'].includes(error)
  ) {
    return {
      titleText: t('paymentResult.obiProviderErrorTitle'),
      descriptionText: t('paymentResult.obiProviderErrorDescription'),
    }
  }

  let titleText = t('paymentResult.errorTitle')
  let descriptionText = t('paymentResult.errorReason')

  if (error) {
    titleText = t(`paymentErrorTitle.${error}`)
    descriptionText = t(`${error}`)
  }

  if (isPayout && error === 'PROVIDER_ERROR') {
    titleText = t('payout.providerErrorTitle')
    descriptionText = t('payout.providerErrorDescription')
  } else if (!isUnavailableApm && !error && apm?.id) {
    titleText = t('paymentResult.apmErrorTitle', { apm: apm.name })
    descriptionText = t('paymentResult.apmErrorDescription', { apm: apm.name })
  }

  return { titleText, descriptionText }
}

export default useFinalErrorText
