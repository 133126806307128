import { useState, useRef, useEffect } from 'react';
import LedgerLiveApi, { WindowMessageTransport, Currency } from "@ledgerhq/live-app-sdk";
import queryString from 'query-string';
import * as Sentry from '@sentry/react';
import { isLedgerIframe } from '../../../helpers/iframe';

export function useLedger(): [boolean, Currency[], (setAddress: (address: string) => void, currency?: string) => void] {
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const { ledger } = queryString.parse(window.location.search);
  const isOnLedger = !!ledger || isLedgerIframe();
  const ledgerApi = useRef<LedgerLiveApi>();

  const getCurrencies = async () => {
    if (!ledgerApi.current) return;

    try {
      const res = await ledgerApi.current?.listCurrencies();
      setCurrencies(res);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  useEffect(() => {
    if (isOnLedger) {
      const llapi = new LedgerLiveApi(new WindowMessageTransport());
      llapi.connect();
      if (llapi) {
        ledgerApi.current = llapi;
        getCurrencies();
      }

      return () => {
        ledgerApi.current = undefined;
        void llapi.disconnect();
      };
    }
  }, [isOnLedger]);

  const requestLedgerAccount = async (setAddress: (address: string) => void, currency?: string) => {
    if (!ledgerApi.current) {
      return;
    }

    try {
      const result = await ledgerApi.current.requestAccount({
        // @ts-ignore
        currencies: currency,
        allowAddAccount: true,
        includeTokens: false,
      });

      if (result.address) {
        setAddress(result.address);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return [isOnLedger, currencies, requestLedgerAccount];
}
