import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useCustomerBillingAddressSet,
  useCustomerCountrySet,
  useCustomerStateSet,
} from '../../api/customer'

interface IUseResidenceParams {
  billingSaveCb(res: any): void
  customer: any
  billing: boolean
}

export interface IBillingAddress {
  city: string
  zipCode: string
  address: string
}

interface IUseResidence {
  billingAddress: IBillingAddress
  setBillingAddress(value: IBillingAddress): void
  saveBilling(data: any): Promise<any>
  saveCountry(data: {
    country: string
  }): Promise<{ data: { success: boolean } }>
  saveState(data: { state: string }): Promise<{ data: { success: boolean } }>
  residenceSaving: boolean
  validateCountry(formValid: boolean, errorFocused: boolean): [boolean, boolean]
  validateBillingAddress(formValid: boolean, showErrors?: boolean): boolean

  countryError?: string
  setCountryError(err?: string): void
  cityError?: string
  setCityError(err?: string): void
  streetAddressError?: string
  setStreetAddressError(err?: string): void
  zipcodeError?: string
  setZipcodeError(err?: string): void
}

export default function useResidence({
  billingSaveCb,
  customer,
  billing,
}: IUseResidenceParams): IUseResidence {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [billingAddress, setBillingAddress] = useState<IBillingAddress>({
    city: '',
    zipCode: '',
    address: '',
  })
  const [countryError, setCountryError] = useState<string | undefined>()
  const [cityError, setCityError] = useState<string | undefined>()
  const [streetAddressError, setStreetAddressError] = useState<
    string | undefined
  >()
  const [zipcodeError, setZipcodeError] = useState<string | undefined>()
  const [saveBilling, savingBilling] =
    useCustomerBillingAddressSet(billingSaveCb)
  const [saveCountry, countrySaving] = useCustomerCountrySet()
  const [saveState, stateSaving] = useCustomerStateSet()

  useEffect(() => {
    if (countryError) {
      setCountryError(t('validation.Required field'))
    }
    if (cityError) {
      setCityError(t('validation.Required field'))
    }
    if (zipcodeError) {
      setZipcodeError(t('validation.Required field'))
    }
    if (streetAddressError) {
      setStreetAddressError(t('validation.Required field'))
    }
  }, [language])

  function validateCountry(
    formValid: boolean,
    errorFocused: boolean
  ): [boolean, boolean] {
    let valid = formValid
    const focus = errorFocused
    if (!customer.country) {
      setCountryError(t('validation.Required field'))
      valid = false
    }

    return [valid, focus]
  }

  function validateBillingAddress(
    formValid: boolean,
    showErrors = true
  ): boolean {
    let valid = formValid
    if (billing) {
      if (!billingAddress.city || !billingAddress.city.length) {
        if (showErrors) setCityError(t('validation.Required field'))
        valid = false
      }
      if (!billingAddress.zipCode || !billingAddress.zipCode.length) {
        if (showErrors) setZipcodeError(t('validation.Required field'))
        valid = false
      }
      if (!billingAddress.address || !billingAddress.address.length) {
        if (showErrors) setStreetAddressError(t('validation.Required field'))
        valid = false
      }
    }
    return valid
  }

  return {
    billingAddress,
    setBillingAddress,
    saveBilling,
    saveCountry,
    saveState,
    residenceSaving: savingBilling || countrySaving || stateSaving,
    validateCountry,
    validateBillingAddress,
    cityError,
    setCityError,
    countryError,
    setCountryError,
    streetAddressError,
    setStreetAddressError,
    zipcodeError,
    setZipcodeError,
  }
}
