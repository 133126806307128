import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import queryString from 'query-string'
import isValidHostname from 'is-valid-hostname'

export default function RedirectToDirectFlow() {
  const { sid, wallet } = useParams()
  const history = useHistory()
  // { currency, paymentCurrency, paymentId, amount, paymentAmount, accountId }
  const query = queryString.parse(window.location.search)

  useEffect(() => {
    let invalidReferrer = false
    if (sid && sid.includes('.') && isValidHostname(sid)) {
      const ref = document.referrer
      if (ref) {
        const refHostName = ref.split('://')[1].replace('/', '')
        if (sid !== refHostName) {
          invalidReferrer = true
        }
      } else {
        if (sid !== 'wallet.near.org') {
          invalidReferrer = true
        }
      }
    }
    const queryString = `${
      query.account_id ? '&account_id=' + query.account_id : ''
    }${query.public_key ? '&public_key=' + query.public_key : ''}${
      query.all_keys ? '&all_keys=' + query.all_keys : ''
    }`
    history.replace(
      `/exchange/transaction${queryString ? '?' + queryString : ''}`,
      {
        sid,
        wallet,
        ...query,
        fromCurrency: query.paymentCurrency,
        toCurrency: query.currency,
        lockCrypto: !!query.currency,
        invalidReferrer,
      }
    )
  }, [sid, wallet, query])

  return <div />
}
