import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IOrder } from '../../../api/order';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DateView from '../../../components/DateView';
import OrderStatusView from '../../../components/Order/Status';
import OrderTypeView from '../../../components/Order/Type';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import ShortString from '../../../components/UI/ShortString';
import BlockchainLink from '../../../components/BlockchainLink';
import referralFees from '../../../config/referralFee.json';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import CurrencyNameMapper from '../../../components/CurrencyNameMapper/CurrencyNameMapper'
import ordersListConfig from '@/config/ordersList.json'
import { WIIDGET_ENV } from '@/helpers/constants'

interface IProps {
  orders: IOrder[];
  loading?: boolean;
  type?: 'merchant' | 'client';
}

export default function TXList({ orders, loading, type }: IProps) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const { user } = useSelector((state: any) => state.account);
  let refFee = 0;
  if (type === 'merchant' && user.id) {
    // @ts-ignore
    refFee = referralFees[user.id];
  }

  if (loading && !orders.length) return null;

  if (!orders.length) {
    return (
      <Typography variant="body1" align="center">
        {t('Order history is empty')}
      </Typography>
    )
  }

  const isMerchant = type === 'merchant'

  // @ts-ignore
  const showEmail = !ordersListConfig.hideEmailForMerchants[WIIDGET_ENV]?.includes(String(user.id))

  return (
    <div>
      <Table className={globalClasses.tableSimple}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2" component="span">
                {isMerchant && `${t('Created')}`}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="span">
                {t('Order ID')}
                {showEmail && <span>/ {t('Email')}</span>}
              </Typography>
            </TableCell>
            {isMerchant && (
              <TableCell>
                <Typography variant="body2" component="span">
                  {t('Order Type')}
                </Typography>
              </TableCell>
            )}
            {isMerchant && (
              <>
                <TableCell>
                  <Typography variant="body2" component="span">
                    {t('External ID')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" component="span">
                    {t('Payment Method')}
                  </Typography>
                </TableCell>
              </>
            )}
            <TableCell>
              <Typography variant="body2" component="span">{t('Status')}{isMerchant && ` / ${t('Postback status')}`}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="span">{t('Transaction')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" component="span">{t('Paid')} / {t('Sent')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((i) => (
            <TableRow key={i.id}>
              <TableCell>
                <Typography variant="body2" component="div">
                  <DateView value={i.createdAt} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" component="span" color="textSecondary">
                  {i.id} {showEmail && <span> / <br />{i.acceptedByEmail && i.acceptedByEmail.length > 30 
                  ? i.acceptedByEmail?.slice(0,25) + '...'
                  : i.acceptedByEmail} </span>}
                </Typography>
              </TableCell>
              {isMerchant && (
                <TableCell>
                  <OrderTypeView type={i.type} />
                </TableCell>
              )}
              {isMerchant && (
                <>
                <TableCell>
                  <Typography variant="body2" component="span" color="textSecondary">
                    <ShortString chars={20} text={i.externalId} copy />
                  </Typography>
                </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      component="span"
                      color="textSecondary"
                    >
                      {i.apmData?.lastId}
                    </Typography>
                  </TableCell>
                </>
              )}
              <TableCell>
                <Typography variant="body2" component="div"><OrderStatusView status={i.status} type={type} /></Typography>
                {isMerchant && <Typography variant="body2" component="div">{i.postbackStatus}</Typography>}
              </TableCell>
              <TableCell>
                {i.blockchainTxId && <BlockchainLink type="tx" value={i.blockchainTxId} currency={i.currency} charsLimit={20} />}
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" component="div" translate="no"><strong>{i.paymentAmount} {i.paymentCurrency}</strong></Typography>
                <Typography variant="body2" component="div" color="textSecondary" translate="no">{i.amount} <CurrencyNameMapper currency={i.currency}/></Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
