import makeStyles from '@material-ui/core/styles/makeStyles'

export const useMasterBalanceRowStyles = makeStyles((theme) => ({
  root: {
    'fontWeight': 600,
    '& td:first-child': {
      paddingLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
  totalCell: {
    fontWeight: 600,
  },
  arrowCell: {
    width: '54px',
    cursor: 'pointer',
  },
  emptyCell: {
    'borderBottom': 'unset',
    '.&:last-child': {
      borderBottom: 'unset',
    },
    'width': '54px',
  },
  percentageCell: {
    width: '174px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  amountCell: {
    width: '149.5px',
    paddingLeft: theme.spacing(1),
  },
  totalReqAmount: {
    paddingLeft: theme.spacing(1),
  },
  availableCell: {
    paddingRight: 0,
    paddingLeft: theme.spacing(1),
    width: '141.5px',
  },
  checkboxCell: {
    paddingLeft: 0,
  },
  lockedCell: {
    width: '149.5px',
  },
  field: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    'paddingBottom': '0px',
    'verticalAlign': 'bottom',
    'borderRadius': '8px',
    'background': 'rgba(125, 147, 168, 0.08)',
    '& .MuiOutlinedInput-input': {
      padding: '6px 2px 6px 8px',
      height: '36px',
      boxSizing: 'border-box',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(125, 147, 168, 0.08)',
    },
    '& .MuiInputBase-input': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiFormHelperText-root': {
      top: 37,
      left: '-11px',
      whiteSpace: 'nowrap',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '6px',
    },
  },
  inputAmount: {
    width: '133.5px',
  },
  inputPercentage: {
    width: '84px',
  },
  selectedRow: {
    backgroundColor: '#edf4fb',
  },
  checked: {
    'backgroundColor': '#FFF',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.12)',
    },
    '& .MuiInputBase-input': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  available: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  disabled: {
    opacity: '0.5',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#707070',
  },
  checkbox: {
    '&.MuiCheckbox-indeterminate': {
      color: '#2F66E3',
    },
  },
  subrow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
}))
