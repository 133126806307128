import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CustomBadge from '@/components/CustomBadge'
import { ICheckedSidRequest } from '@/types/settlements'

export const useStyles = makeStyles((theme) => ({
  root: {},
  staticRow: {
    'paddingBottom': theme.spacing(3),
    'cursor': 'pointer',
    '& td': {
      paddingLeft: 0,
    },
    'borderBottom': 'unset',
  },
  cellIcon: {
    width: theme.spacing(5.5),
    paddingBottom: '10px',
  },
  cellEmpty: {
    borderBottom: 'unset',
    width: theme.spacing(5.5),
  },
  expandableRow: {
    '& td': {
      paddingLeft: 0,
    },
  },
  cellText: {
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.02em',
  },
  cellExpandable: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  cellTotal: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.02em',
  },
  icon: {
    color: '#707070',
  },
}))

interface ISettlementConfirmationRow {
  row: { currency: string; sids: ICheckedSidRequest[] }
}

function SettlementConfirmationRow({ row }: ISettlementConfirmationRow) {
  const [isExpanded, setIsExpanded] = useState(false)

  const classes = useStyles()

  const totalBalance = row.sids.reduce(
    (acc: number, item) => acc + item.amount,
    0
  )

  return (
    <>
      <TableRow
        className={classes.staticRow}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <TableCell className={classes.cellIcon}>
          {isExpanded ? (
            <KeyboardArrowUpIcon className={classes.icon} />
          ) : (
            <KeyboardArrowDownIcon className={classes.icon} />
          )}
        </TableCell>
        <TableCell>
          <CustomBadge content={row.sids.length}>
            <Typography
              variant="body2"
              component="span"
              className={classes.cellTotal}
            >
              {row.currency}
            </Typography>
          </CustomBadge>
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="body2"
            component="span"
            className={classes.cellTotal}
          >
            {totalBalance.toFixed(2)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.expandableRow}>
        <TableCell colSpan={3} className={classes.cellExpandable}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box>
              <Table aria-label="confirm">
                <TableBody>
                  {row.sids.map((subrow) => (
                    <TableRow key={subrow.sid}>
                      <TableCell className={classes.cellEmpty} />
                      <TableCell>
                        <Typography
                          className={classes.cellText}
                          variant="body2"
                          component="span"
                        >
                          {subrow.sid}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          className={classes.cellText}
                          variant="body2"
                          component="span"
                        >
                          {subrow.amount}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
export default SettlementConfirmationRow
