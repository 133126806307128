import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { IOrder } from '@/api/order'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import AlertIcon from '@/icons/alert.svg'
import OrderDetails from '../Details'
import usePaymentFormStyles from '../usePaymentFormStyles'

interface IProps {
  order: IOrder
}

export function DocumentsVerification({ order }: IProps) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const paymentFormClasses = usePaymentFormStyles()
  const { t } = useTranslation()

  return (
    <div>
      <div className={classes.kycStatusWrapper}>
        <Typography variant="h3" component="div" align="left" className="title">
          {t('cardKycWaitingStatus.Document verification')}
        </Typography>
        <p>
          <Typography
            variant="body1"
            component="div"
            align="left"
            className="subtitle"
          >
            {t('cardKycWaitingStatus.We are verifying your documents')}
          </Typography>
        </p>
        <div className={classes.waitingForKyc}>
          <div className={classes.innerDescription}>
            <Typography
              variant="body1"
              component="div"
              align="left"
              className="title"
            >
              {t('cardKycWaitingStatus.Please note')}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              align="left"
              className="subtitle"
            >
              {t('cardKycWaitingStatus.Balance change notifications')}
            </Typography>
          </div>
        </div>
        <div className={classes.statusDetails}>
          <div className={paymentFormClasses.orderDetailsRow}>
            <Typography
              component="div"
              variant="body2"
              color="textSecondary"
              className="dTitle"
            >
              {t('cardKycWaitingStatus.Reserved')}
            </Typography>
            <Typography
              component="div"
              variant="body2"
              align="right"
              className="dValue"
            >
              {order.finalPaymentAmount || order.paymentAmount} {order.paymentCurrency}
            </Typography>
          </div>
          <div className={paymentFormClasses.orderDetailsRow}>
            <Typography
              component="div"
              variant="body2"
              color="textSecondary"
              className="dTitle"
            >
              {t('cardKycWaitingStatus.Status')}
            </Typography>
            <Typography
              component="div"
              variant="body2"
              align="right"
              className="dValue"
            >
              {t('cardKycWaitingStatus.Verification pending')}
            </Typography>
          </div>
          <div className={paymentFormClasses.orderDetailsRow}>
            <Typography
              component="div"
              variant="body2"
              color="textSecondary"
              className="dTitle"
            >
              {t('cardKycWaitingStatus.Waiting time')}
            </Typography>
            <Typography
              component="div"
              variant="body2"
              align="right"
              className="dValue"
            >
              ~ 1 {t('cardKycWaitingStatus.min')}
            </Typography>
          </div>
        </div>
      </div>
      <div className={paymentFormClasses.delim} />
      <OrderDetails order={order} styles={paymentFormClasses} />
      <div className={clsx(classes.button, classes.kycStatusBtn)}>
        <Typography variant="body2" align="center" style={{ marginTop: 16 }}>
          <Button
            className={globalClasses.button}
            href={order.successUrl}
            variant="contained"
            color="primary"
          >
            {t('cardKycWaitingStatus.Processing')}
          </Button>
        </Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  kycStatusWrapper: {
    '&>.title': {
      marginBottom: 12,
      fontSize: 34,
      lineHeight: '39px',
    },
    '&>p>.subtitle': {
      marginBottom: 24,
      fontSize: 18,
    },
  },
  resultKyc: {
    background: '#fefaed',
    margin: '0 -40px',
    padding: '26px 40px',
  },
  waitingForKyc: {
    position: 'relative',
    marginBottom: 32,
    width: 'calc(100% + 80px)',
    top: 0,
    left: -40,
    backgroundColor: 'rgba(242, 201, 76, 0.1)',
  },
  innerDescription: {
    'display': 'flex',
    'flexDirection': 'column',
    'paddingTop': 22,
    'paddingBottom': 22,
    'paddingRight': 32,
    'paddingLeft': 74,
    'position': 'relative',

    '& .title': {
      marginBottom: 8,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
      lineHeight: '24px',
    },
    '& .subtitle': {
      // marginBottom: 10
      color: 'rgba(0, 0, 0, 0.6)',
      lineHeight: '21px',
      fontSize: 14,
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 24,
      left: 34,
      width: 24,
      height: 24,
      background: `url("${AlertIcon}") no-repeat center center`,
    },
  },
  statusDetails: {
    '& .dTitle': {
      fontSize: 16,
      color: 'rgba(0,0,0,0.6)',
      lineHeight: '18.75px',
    },
    '& .dValue': {
      fontSize: 16,
      color: 'rgba(0,0,0,0.87)',
      lineHeight: '18.75px',
    },
  },
  button: {
    marginTop: theme.spacing(4),
  },
  kycStatusBtn: {
    marginTop: 8,
  },
}))
