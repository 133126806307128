import React, { FC, useEffect, useRef } from 'react'
import clsx from 'clsx'

import CircularProgress from '@material-ui/core/CircularProgress'

interface IGPayButtonProps {
  globalClasses: any
  classes: any
  disabled: boolean
  initGPayButton(): HTMLDivElement
}

const GPayButton: FC<IGPayButtonProps> = ({
  initGPayButton,
  globalClasses,

  classes,
  disabled,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gPayButtonRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (disabled) {
      gPayButtonRef.current?.remove()
      return
    }
    const gPayButtonConteiner = initGPayButton()

    gPayButtonRef.current = gPayButtonConteiner
    gPayButtonConteiner.style.height = '100%'
    gPayButtonConteiner.style.width = '100%'
    const gPayButton = gPayButtonConteiner.querySelector(
      '.gpay-card-info-container'
    ) as HTMLButtonElement

    if (gPayButton) {
      gPayButton.style.height = '100%'
      gPayButton.style.width = '100%'
      const gPayBtn = gPayButtonConteiner.querySelector(
        '.gpay-card-info-animation-container'
      ) as HTMLButtonElement
      const iframe = gPayButtonConteiner.querySelector(
        '.gpay-card-info-iframe'
      ) as HTMLButtonElement
      gPayBtn.style.height = '100%'
      iframe.style.paddingTop = '10px'
    }
    if (!wrapperRef.current) {
      return
    }
    wrapperRef.current.appendChild(gPayButtonConteiner)
  }, [disabled])

  return (
    <div
      id="google-pay-button-wrapper"
      ref={wrapperRef}
      className={clsx(globalClasses.button, classes.googlePay, 'processing')}
    >
      {disabled && (
        <div className={classes.progressWrapper}>
          <CircularProgress size={22} color="secondary" />
        </div>
      )}
    </div>
  )
}

export default GPayButton
