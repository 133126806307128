import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import { IOrder } from '@/api/order'
import CurrencyNameMapper from '@/components/CurrencyNameMapper/CurrencyNameMapper'

const useStyles = makeStyles({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 12,
  },
  buttonIcon: {
    width: 18,
    height: 18,
  },
  detailsWrapper: {
    padding: '0px 25px',
  },
})

interface IProps {
  order: IOrder
  styles: any
}

function PayoutDetailsError({ styles, order }: IProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [detailsOpen, setDetailsOpen] = useState(false)
  const ButtonIcon = detailsOpen ? ExpandLess : ExpandMore

  return (
    <>
      <div className={clsx(styles.delim, 'payout')} />
      <div className={styles.formGroup}>
        <div
          onClick={() => setDetailsOpen(!detailsOpen)}
          className={clsx(styles.blockTitle, classes.button)}
        >
          {t('payout.detailsTitle')}
          <ButtonIcon className={classes.buttonIcon} />
        </div>
        {detailsOpen && (
          <>
            <div className={classes.detailsWrapper}>
              <div className={clsx(styles.orderDetailsRow, 'payout')}>
                <Typography
                  component="div"
                  variant="body2"
                  color="textSecondary"
                  className="dTitle"
                >
                  {t('payout.amountTitle')}
                </Typography>
                <Typography
                  component="div"
                  variant="body2"
                  align="right"
                  translate="no"
                >
                  <strong>
                    {order.paymentAmount} <CurrencyNameMapper currency={String(order.paymentCurrency)} />
                  </strong>
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}
export default PayoutDetailsError
