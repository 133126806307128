import React from 'react'

import { useTranslation } from 'react-i18next'

import { Link, List, ListItem } from '@material-ui/core'

import LanguageSelect from '@/components/LanguageSelect'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import { ILangType } from '@/locales'
import { INTERNAL_LINK_TARGET_BLANK } from '@/helpers/internalTarget'
import {
  getKycAmlPolicyLink,
  getPrivacyPolicyLink,
  getTermsOfUseLink,
} from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'
import { useTypedSelector } from '@/hooks/useTypedSelector'

interface IMenuProps {
  isCCWChainValley?: boolean
}

function Menu({ isCCWChainValley }: IMenuProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const lang = language.split('-')[0] as ILangType

  const {
    ui: {
      global: {
        footer: { menu },
        locale,
      },
    },
  } = useTypedSelector((state) => state.common)

  const globalClasses = useGlobalStyles({ isChainValley: isCCWChainValley })

  return (
    <div className={globalClasses.bottom}>
      <List component="nav" className={globalClasses.menu}>
        <ListItem disableGutters className={globalClasses.bottomMenuLink}>
          {menu?.privacy?.url?.length ? (
            <Link
              href={menu.privacy.url}
              target={INTERNAL_LINK_TARGET_BLANK}
              color="secondary"
            >
              {menu.privacy?.text[lang]?.length
                ? menu.privacy.text[lang]
                : t('Privacy policy')}
            </Link>
          ) : (
            <Link
              href={getPrivacyPolicyLink(MIRROR)}
              target={INTERNAL_LINK_TARGET_BLANK}
              color="secondary"
            >
              {t('Privacy policy')}
            </Link>
          )}
        </ListItem>
        <ListItem disableGutters className={globalClasses.bottomMenuLink}>
          {menu?.terms?.url?.length ? (
            <Link
              href={menu.terms.url}
              target={INTERNAL_LINK_TARGET_BLANK}
              color="secondary"
            >
              {menu.terms?.text[lang]?.length
                ? menu.terms.text[lang]
                : t('Terms of use')}
            </Link>
          ) : (
            <Link
              href={getTermsOfUseLink(MIRROR)}
              target={INTERNAL_LINK_TARGET_BLANK}
              color="secondary"
            >
              {t('Terms of use')}
            </Link>
          )}
        </ListItem>
        <ListItem disableGutters className={globalClasses.bottomMenuLink}>
          <Link
            href={getKycAmlPolicyLink(MIRROR)}
            target={INTERNAL_LINK_TARGET_BLANK}
            color="secondary"
          >
            {t('KYC / AML policy')}
          </Link>
        </ListItem>
      </List>
      {!locale.langChangeDisable && (
        <div className={globalClasses.langSelectorWrapper}>
          <LanguageSelect />
        </div>
      )}
    </div>
  )
}
export default Menu
