import makeStyles from '@material-ui/core/styles/makeStyles'

export const usePopupSelectStyles = makeStyles((theme) => ({
  root: {
    'paddingBottom': theme.spacing(3),
    '&.divider': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  countryField: {
    '& .MuiFilledInput-root': {
      height: 48,
    },
    '& .MuiFilledInput-underline.Mui-error': {
      backgroundImage: 'linear-gradient(#F5F6F7 46px, #e53935 2px) !important',
    },
  },
  apmField: {
    '& .MuiFilledInput-input': {
      paddingTop: '21px !important',
      paddingBottom: '20px !important',
    },
    'paddingBottom': '0px',
  },
  countries: {
    height: '90vh',
  },
  apms: {
    maxHeight: '90vh',
  },
  search: {
    paddingLeft: theme.spacing(2),
  },
  apmImage: {
    maxWidth: '40px',
    width: '100% !important',
    maxHeight: '26px',
    objectFit: 'contain',
    marginRight: '0px !important',
  },
  ccwOrderImage: {
    width: '120px',
    height: '77px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '13px',
    padding: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  ccwImage: {
    objectFit: 'contain',
    maxHeight: 57,
    maxWidth: 100,
  },
  apmAdornment: {
    width: '56px !important',
    height: 36,
    padding: '3px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    backgroundColor: '#fff',
    marginRight: '12px',
  },
  apmWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  option: {
    'height': 48,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'paddingLeft': theme.spacing(2),
    'paddingRight': theme.spacing(2),
    'borderRadius': 8,
    'transition': 'all 300ms ease-out 0s',
    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(125, 147, 168, 0.08)',
    },
    '&.active': {
      '& > *, & .detected-mark': {
        fontWeight: 700,
      },
    },
    '&.detected': {
      // fontWeight: 'bold'
    },
    '& .details': {
      'display': 'flex',
      'alignItems': 'center',
      '& img': {
        width: 22,
        marginRight: 12,
      },
    },
    '& .detected-mark': {
      textTransform: 'uppercase',
      lineHeight: '16px',
      fontSize: 10,
      [theme.breakpoints.down('xs')]: {
        fontSize: 8,
      },
    },
    '& .check': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(1),
      },
    },
  },
  grad: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 64,
    height: 50,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
  },
  iconOBI: {
    width: '110px',
    height: '88px',
  },
  emptyBlock: {
    height: '47px',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(0),
    },
  },
}))
