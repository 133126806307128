import React, { FC } from 'react'

import useCurrencyNameMapper from '../../hooks/useCurrencyNameMapper'

interface ICurrencyNameMapperProps {
  currency?: string
}

const CurrencyNameMapper: FC<ICurrencyNameMapperProps> = ({ currency }) => {
  const currencyNameMapper = useCurrencyNameMapper()

  return currency ? currencyNameMapper(currency) : ''
}

export default CurrencyNameMapper
