import { ICurrency } from '../types/currency'
import { ISelectOption } from '../types/select'

export const currencyOptionMapper = (curr: ICurrency): ISelectOption => {
  const option: ISelectOption = {
    value: curr.currency,
    label: curr.symbol,
  }

  if (curr.display) {
    option.label = curr.display
  }

  const isCrypto = curr.type === 'CRYPTO'
  if (isCrypto && curr.caption) {
    option.caption = curr.caption
  }

  return option
}

export const currencyMapper = (currencies: ICurrency[]) => {
  return currencies.reduce((result, curr) => {
    result[curr.currency] = curr
    return result
  }, {} as Record<string, ICurrency>)
}
