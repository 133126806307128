import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Input, Select } from '../../components/Form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useFormStyles from '../Exchange/forms/useFormStyles';
import {composeValidators, mustBeNumber, required} from '../../validators';
import { useDemoInit } from '../../api/demo';
import { useConvert } from '../../api/merchant/order';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    padding: '50px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box'
  },
  wrapper: {
    width: '400px',
    margin: '0 auto',

    '& > div': {
      width: '100%',
    }
  },
  genId: {
    height: '36px',
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginTop: '16px',
    '&:hover': {
      boxShadow: 'none',
    }
  },
  amount: {
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(2),
    '&.convertError': {
      color: theme.palette.error.main
    }
  }
}));

const formPassword = 'muIrT34-mmI.)vQcc';
const initialPwd = {
  password: null
};
const initialForm = {
  xAuth: window.localStorage.getItem('xAuth-demo') || 'egismerch',
  amount: '50',
  currency: 'EUR',
  address: 'mn9f7HwYQCEpHfWBoPPzQhVVdKjjPkVwQZ',
  externalId: '',
  email: undefined,
  postbackUrl: 'https://widget-dev.utorg.pro/test',
  type: 'FIAT_TO_CRYPTO'
};

export default function DemoInitPage() {
  const classes = useStyles();
  const formClasses = useFormStyles({});
  let [amount, setAmount] = useState<number | undefined>();
  const [showForm, setShowForm] = useState(false);
  const [sid, setSid] = useState(initialForm.xAuth);
  const [convertError, setConvertError] = useState(false);
  const [init] = useDemoInit((res: any) => {
    if (res.data && res.data.url) {
      window.open(res.data.url);
    }
  });
  const [convertCrypto] = useConvert(sid, (res: any) => {
    if (res.success) {
      setAmount(res.data);
      setConvertError(false);
    } else if (!res.success) {
      setAmount(undefined);
      setConvertError(true);
    }
  });
  let convertTimeout: any;

  useEffect(() => {
    convertCrypto({
      fromCurrency: initialForm.currency,
      toCurrency: 'BTC',
      paymentAmount: initialForm.amount,
      type: 'DEPOSIT'
    });
  }, [])

  if (!showForm) {
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Form
            initialValues={initialPwd}
            onSubmit={(values) => {
              if (values.password === formPassword) {
                setShowForm(true);
              }
            }}
            render={({ handleSubmit, invalid }) => (
              <form onSubmit={handleSubmit}>
                <div className={formClasses.row}>
                  <Input
                    name="password"
                    type="password"
                    label="Password"
                    validate={(v: string) => v === formPassword ? undefined : 'Incorrect password'}
                  />
                </div>
                <div className={formClasses.row}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={invalid}
                    size="small"
                  >Unlock</Button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Form
          initialValues={initialForm}
          onSubmit={(values) => {
            window.localStorage.setItem('xAuth-demo', values.xAuth);
            init(values.xAuth, values);
          }}
          mutators={{
            makeId: (args, state, { changeValue }) => {
              let id = '';
              const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
              const charactersLength = characters.length;
              for ( var i = 0; i < 10; i++ ) {
                id += characters.charAt(Math.floor(Math.random() * charactersLength));
              }
              changeValue(state, 'externalId', () => id);
            }
          }}
          render={({ handleSubmit, invalid, form, values }) => (
            <form onSubmit={handleSubmit}>
              <Input type="hidden" name="type" label="" />
              <div className={formClasses.row}>
                <Input
                  name="xAuth"
                  type="text"
                  label="X-AUTH-SID"
                  validate={required}
                />
                <OnChange name="xAuth">
                  {(v: any) => v && v.length ? setSid(v) : undefined}
                </OnChange>
              </div>
              <div className={formClasses.row}>
                <div className={formClasses.group}>
                  <Input
                    name="amount"
                    type="text"
                    label="Amount"
                    fullWidth
                    validate={composeValidators(required, mustBeNumber)}
                  />
                  <OnChange name="amount">
                    {(val: any) => {
                      clearTimeout(convertTimeout);
                      convertTimeout = setTimeout(() => {
                        convertCrypto({
                          fromCurrency: values.currency,
                          toCurrency: 'BTC',
                          paymentAmount: val,
                          type: 'DEPOSIT'
                        });
                      }, 300)
                    }}
                  </OnChange>
                  <Select
                    name="currency"
                    label=" "
                    options={[
                      { value: 'EUR', label: 'EUR' },
                      { value: 'UAH', label: 'UAH' },
                      { value: 'RUB', label: 'RUB' },
                      { value: 'USD', label: 'USD' },
                    ]}
                    validate={required}
                  />
                  <OnChange name="currency">
                    {(val: any) => {
                      clearTimeout(convertTimeout);
                      convertCrypto({
                        fromCurrency: val,
                        toCurrency: 'BTC',
                        paymentAmount: values.amount,
                        type: 'DEPOSIT'
                      });
                    }}
                  </OnChange>
                </div>
              </div>
              {(amount || convertError) && (
                <Typography className={clsx(classes.amount, formClasses.amountBottom, { convertError })} component="div" variant="body2">
                  {convertError ? 'Error on converting amount' : `≈${amount} BTC`}
                </Typography>
              )}
              <div className={formClasses.row}>
                <Input
                  name="address"
                  type="text"
                  label="Crypto wallet"
                  fullWidth
                  validate={required}
                />
              </div>
              <div className={formClasses.row}>
                <div className={formClasses.group}>
                  <Input
                    name="externalId"
                    type="text"
                    label="External ID"
                    fullWidth
                    disabled
                    validate={required}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={form.mutators.makeId}
                    className={classes.genId}
                  >
                    Generate
                  </Button>
                </div>
              </div>
              <div className={formClasses.row}>
                <Input
                  name="email"
                  type="text"
                  label="Email"
                  fullWidth
                  required={false}
                />
              </div>
              <div className={formClasses.row}>
                <Input
                  name="postbackUrl"
                  type="text"
                  label="Postback URL"
                  fullWidth
                  required={false}
                />
              </div>
              <div className={formClasses.row}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={invalid}
                  size="small"
                >Pay</Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
}
