import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

export default function RedirectToDirectFlow() {
  const { sid, wallet } = useParams();
  const history = useHistory();

  useEffect(() => {
    history.push('/cbc/exchange/transaction', { sid, wallet });
  }, [sid, wallet])

  return <div />;
}
