import red from '@material-ui/core/colors/red';

export default function (palette: any) {
  return {
    root: {
      borderRadius: '8px !important',
      backgroundColor: '#F5F6F7',
      // iOS Safari
      height: 60,
      transition: 'background-image 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      '&.Mui-disabled': {
        backgroundColor: '#F5F6F7'
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        }
      },
      '& input:-internal-autofill-selected': {
        backgroundColor: 'rgba(0,0,0,0) !important',
        backgroundImage: `none !important`
      },
      '& input:-webkit-autofill, input:-webkit-autofill:focus, input:-webkit-autofill:hover, input:-webkit-autofill:active': {
        backgroundColor: 'rgba(0,0,0,0) !important',
        backgroundImage: `none !important`,
        transition: 'background-color 9999s ease-out 9999s'
      }
    },
    underline: {
      '&.Mui-focused': {
        backgroundImage: `linear-gradient(#F5F6F7 58px, ${palette.primary.main} 2px) !important`
      },
      '&.Mui-error': {
        backgroundImage: `linear-gradient(#F5F6F7 58px, ${red[600]} 2px) !important`
      },
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    },
    input: {
      padding: '28px 12px 11px'
    }
  };
}