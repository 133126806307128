import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import ServerMessage from '../../../components/UI/ServerMessage';
import { IOrder } from '../../../api/order';
import { getSupportEmail } from '@/helpers/mirrorHelper';
import { MIRROR } from '@/helpers/constants';

interface IProps {
  classes: any;
  t: any;
  order: IOrder;
  type: 'kyc' | 'walletAddress';
}

export default function KycRejected({ classes, t, order, type }: IProps) {
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {type === 'kyc'
          ? (
            <Typography variant="body1" align="center" component="div">
              <p>{t(type === 'kyc' ? 'kycRejectedError' : 'somethingWentWrong')}</p>
            </Typography>
          ) : <ServerMessage type="error" text="somethingWentWrong" />
        }
        <Typography variant="body1" align="center" component="div">
          <p>
            <Link className={clsx(classes.send, 'wide')} component={Button} href={getSupportEmail(MIRROR)}>
              {t('Contact support')}
            </Link>
          </p>
          {order.failUrl && (
            <p>
              <Link className={clsx(classes.send, 'wide')} component={Button} href={order.failUrl}>
                {t('Return to merchant')}
              </Link>
            </p>
          )}
        </Typography>
      </div>
    </div>
  );
}
