import React, { FC } from 'react'
import { Field } from 'react-final-form'
import InputMask from 'react-input-mask'

import TextField from '@material-ui/core/TextField'

interface IInputProps {
  name: string
  type: string
  label?: string
  required?: boolean
  fullWidth?: boolean
  validate?: any
  helperText?: string
  disabled?: boolean
  inputRef?: any
  onFocus?: any
  onBlur?: any
  onInput?: any
  placeholder?: string
  masktype?: 'card' | 'phone' | 'cardExpires'
  customMask?: string
  autoFocus?: boolean
  inputProps?: any
  id?: string
}

const masks = {
  card: '9999 9999 9999 999999',
  cardExpires: '99/99',
  phone: '+9 (999) 999-99-99',
}

const MaskedInputBase = (props: any) => {
  const hasError = props.meta && props.meta.invalid && props.meta.touched
  let helperText = ''
  if (hasError) {
    helperText = props.meta.error || props.meta.submitError
  }

  return (
    <InputMask
      // @ts-ignore
      mask={props.customMask ?? masks[props.masktype || 'card']}
      {...props.input}
      maskChar=""
    >
      {() => (
        <TextField
          {...props}
          error={hasError}
          helperText={helperText}
          variant="filled"
          inputProps={{
            ...props.inputProps,
            inputMode: 'numeric',
          }}
        />
      )}
    </InputMask>
  )
}

const MaskedInput: FC<IInputProps> = ({ name, type, required, ...rest }) => (
  <Field
    name={name}
    type={type}
    component={MaskedInputBase}
    required={required !== undefined ? required : true}
    InputLabelProps={{ shrink: true }}
    {...rest}
  />
)

export default MaskedInput
