import { ethers } from 'ethers'
import axios, { AxiosPromise } from 'axios'

import nftHelpersAbi from './nftHelpers.abi'
import { ICloudflareIpfs, INftData } from './nftHelpers.types'

import nftConfigs from '../../config/nftConfigs.json'

const env = process.env.REACT_APP_WIDGET_ENV || 'dev'

// @ts-ignore
const infuraNetwork = nftConfigs.infura[env].network
// @ts-ignore
const infuraApiKey = nftConfigs.infura[env].apiKey

export const initInfuraProvider = () => {
  return new ethers.providers.InfuraProvider(infuraNetwork, infuraApiKey)
}

export const initContract = (
  contractAddress: string,
  infuraProvider: ethers.providers.InfuraProvider
) => {
  return new ethers.Contract(contractAddress, nftHelpersAbi, infuraProvider)
}

export const getNftHexId = (nftId: number | string, withPrefix: boolean) => {
  const prefix = withPrefix ? '0x' : ''
  const hexNftId = Number(nftId).toString(16)
  return prefix + '00'.slice(0, -String(hexNftId).length) + hexNftId
}

export const getURI = async (id: number | string, sc: ethers.Contract) => {
  const res = await sc.uri(id)
  const hexId = getNftHexId(id, false)
  const nftId =
    '0000000000000000000000000000000000000000000000000000000000000000'.slice(
      0,
      -hexId.length
    ) + hexId
  return res.replace(`{id}`, nftId)
}

const getCloudflareIpfsURI = (ipfsURI: string) => {
  const uri = ipfsURI.replace('ipfs://', '')
  return `https://cloudflare-ipfs.com/ipfs/${uri}`
}

export const getCloudflareIpfs = (
  ipfsURI: string
): AxiosPromise<ICloudflareIpfs> => {
  const uri = getCloudflareIpfsURI(ipfsURI)
  return axios.get(uri)
}

export const getNftImageById = async (
  id: number | string,
  contractAddress: string
): Promise<INftData> => {
  const provider = initInfuraProvider()
  const contract = initContract(contractAddress, provider)
  const ipfsURI = await getURI(id, contract)
  const { data } = await getCloudflareIpfs(ipfsURI)

  const totalSupply = await contract.totalSupply(id)
  const maxSupply = await contract.maxSupply()
  const isSoldOut = maxSupply.toNumber() === totalSupply.toNumber()

  const url = getCloudflareIpfsURI(data.image)

  return {
    imageSrc: url,
    author: data.artist_url,
    description: data.description,
    title: data.name,
    externalUrl: data.external_url,
    totalSupply: totalSupply.toNumber(),
    maxSupply: maxSupply.toNumber(),
    isSoldOut,
  }
}
