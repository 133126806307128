import Link from '@material-ui/core/Link';
import React from 'react';
import ShortString from '../UI/ShortString';
import {ICurrency} from "../../types/currency";
import {useSelector} from "react-redux";

export enum BlockchainTxProvider {
  INTERNAL_BTC = 'INTERNAL_BTC',
  COINS_PAID = 'COINS_PAID'
}

interface IProps {
  type: 'tx' | 'address';
  value: string;
  charsLimit?: number;
  currency?: string;
}

export default function BlockchainLink({ type, value, charsLimit, currency }: IProps) {
  const currenciesMap = useSelector((store: any) => store.appData.currencies.map)

  if (!value) return null;

  const currencyObject = (currency ? currenciesMap[currency] : {}) as ICurrency
  const explorerAddr = currencyObject?.explorerAddr || currencyObject?.defExplorerAddr
  const explorerTx = currencyObject?.explorerTx || currencyObject?.defExplorerTx
  let baseUrl = type === 'tx' ? explorerTx : explorerAddr

  if (value.includes('CP:')) {
    baseUrl = 'https://explorer.coinspaid.com/query/';
  }

  return (
    <Link href={baseUrl && `${baseUrl}${value}`} target="_blank">
      {charsLimit
        ? <ShortString chars={charsLimit} text={value} />
        : value}
    </Link>
  );
}
