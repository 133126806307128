import React, { FC, useState } from 'react'
import clsx from "clsx";
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import useStyles from './WhisperStyles'

interface IWhisperProps {
  title?: string;
  text?: string;
}

const Whisper: FC<IWhisperProps> = ({
  title,
  text,
  children,
}) => {

  const [showWindow, setShowWindow] = useState(false)

  const classes = useStyles()

  const toggleWhisper = () => {
    setShowWindow((value) => {
      return !value
    })
  }

  const hideWhisper = () => {
    setShowWindow(false)
  }


  return (
      <div
        className={classes.wrapper}
      >
        <ClickAwayListener onClickAway={hideWhisper}>
          <span className={classes.textWrapper} onClick={toggleWhisper}>
            {children}
          </span>
        </ClickAwayListener>
        <div
          onClick={hideWhisper}
          className={clsx(classes.window, showWindow && classes.showWindow )}
        >
          <h3 className={classes.title}>{title}</h3>
          <p>{text}</p>
        </div>
      </div>

  )
}

export default Whisper
