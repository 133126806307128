const languageMap = {
  "AF": "fa",
  "AX":	"sv",
  "AL":	"sq",
  "DZ":	"ar",
  "AS":	"en",
  "AD":	"ca",
  "AG":	"en",
  "AI":	"en",
  "AR":	"es",
  "AM":	"hy",
  "AW":	"nl",
  "AU":	"en",
  "AT":	"de",
  "AZ":	"az",
  "AO": "pt",
  "BS":	"en",
  "BH":	"ar",
  "BD":	"bn",
  "BB":	"en",
  "BY":	"ru",
  "BE":	"nl",
  "BZ":	"en",
  "BJ":	"fr",
  "BM":	"en",
  "BT": "dz",
  "BO":	"es",
  "BQ":	"nl",
  "BA": "bs",
  "BW": "en",
  "BR":	"pt",
  "IO":	"en",
  "VG":	"en",
  "BN":	"ms",
  "BG":	"bg",
  "BF": "fr",
  "BI": "fr",
  "CV":	"pt",
  "KH":	"km",
  "CM": "fr",
  "CA": "en",
  "KY":	"en",
  "CF": "fr",
  "TD": "fr",
  "CL":	"es",
  "CN":	"zh",
  "CX":	"en",
  "CC":	"en",
  "CO": "es",
  "KM": "ar",
  "CD": "fr",
  "CG": "fr",
  "CK": "en",
  "CR": "es",
  "CI": "fr",
  "HR":	"hr",
  "CU": "es",
  "CW": "nl",
  "CY": "el",
  "CZ":	"cs",
  "DK":	"da",
  "DJ": "fr",
  "DM": "en",
  "DO": "es",
  "EC": "es",
  "EG":	"ar",
  "SV": "es",
  "GQ": "es",
  "ER":	"ar",
  "EE":	"et",
  "ET":	"am",
  "FK":	"en",
  "FO":	"fo",
  "FJ":	"en",
  "FI":	"fi",
  "FR": "fr",
  "PF":	"fr",
  "GF":	"fr",
  "GA": "fr",
  "GM":	"en",
  "PS":	"ar",
  "GE":	"ka",
  "DE":	"de",
  "GH":	"en",
  "GI":	"en",
  "GL":	"kl",
  "GD":	"en",
  "GP":	"fr",
  "GU":	"en",
  "GT":	"es",
  "GG":	"en",
  "GN":	"fr",
  "GW":	"pt",
  "GY":	"en",
  "HT": "fr",
  "HN":	"es",
  "HK":	"zh-tw",
  "HU":	"hu",
  "IS":	"is",
  "IN":	"hi",
  "ID":	"id",
  "IR":	"fa",
  "IQ":	"ar",
  "IE":	"en",
  "IM":	"en",
  "IL":	"he",
  "IT":	"it",
  "JM":	"en",
  "JP":	"ja",
  "JE":	"en",
  "JO":	"ar",
  "KZ": "ru",
  "KE":	"en",
  "KI":	"en",
  "XK":	"en",
  "KW":	"ar",
  "KG":	"ru",
  "LA":	"lo",
  "LV":	"lv",
  "LB":	"ar",
  "LS":	"en",
  "LR":	"en",
  "LY":	"ar",
  "LI":	"de",
  "LT":	"lt",
  "LU":	"fr",
  "MO":	"zh-tw",
  "MK":	"mk",
  "MG":	"fr",
  "MW":	"en",
  "MY":	"ms",
  "MV":	"dv",
  "ML":	"fr",
  "MT":	"en",
  "MH":	"en",
  "MQ":	"fr",
  "MR":	"ar",
  "MU":	"fr",
  "YT":	"fr",
  "MX":	"es",
  "FM":	"en",
  "MD":	"ro",
  "MC":	"fr",
  "MN":	"mn",
  "ME":	"sr",
  "MS":	"en",
  "MA":	"fr",
  "MZ":	"pt",
  "MM":	"my",
  "NA":	"en",
  "NR":	"en",
  "NP":	"en",
  "NL":	"nl",
  "NC":	"fr",
  "NZ":	"en",
  "NI":	"es",
  "NE":	"fr",
  "NG":	"en",
  "NU":	"en",
  "NF":	"en",
  "KP":	"ko",
  "MF":	"fr",
  "NO":	"en",
  "OM":	"ar",
  "PK":	"en",
  "PW":	"en",
  "PA":	"es",
  "PG":	"en",
  "PY":	"es",
  "PE":	"es",
  "PH":	"en",
  "PN":	"en",
  "PL":	"pl",
  "PT":	"pt",
  "PR":	"es",
  "QA":	"ar",
  "RE":	"fr",
  "RO":	"ro",
  "RU":	"ru",
  "RW":	"fr",
  "BL":	"fr",
  "PM":	"fr",
  "SR":	"nl",
  "WS":	"en",
  "SM":	"it",
  "ST":	"pt",
  "SA":	"ar",
  "SN": "fr",
  "RS": "sr",
  "SC": "fr",
  "SL": "en",
  "SG":	"zh",
  "SK":	"en",
  "SI":	"sl",
  "SB":	"en",
  "SO":	"ar",
  "KR":	"ko",
  "SS":	"en",
  "ZA":	"en",
  "ES":	"es",
  "LK":	"si",
  "KN":	"en",
  "LC":	"en",
  "SX": "nl",
  "VC": "en",
  "SD":	"ar",
  "SJ":	"no",
  "SZ":	"en",
  "SE":	"sv",
  "CH": "de",
  "SY": "ar",
  "TW":	"zh-tw",
  "TJ":	"ru",
  "TZ":	"en",
  "TH":	"th",
  "TL":	"pt",
  "TG":	"fr",
  "TK":	"en",
  "TO":	"en",
  "TT":	"en",
  "TN":	"ar",
  "TR":	"tr",
  "TM":	"tk",
  "TC":	"en",
  "TV":	"en",
  "UG":	"en",
  "UA":	"uk",
  "AE":	"ar",
  "GB":	"en",
  "US":	"en",
  "UY":	"es",
  "VI":	"en",
  "UZ":	"uz",
  "VU":	"en",
  "VA":	"it",
  "VE":	"es",
  "VN":	"vi",
  "WF":	"fr",
  "EH":	"ar",
  "YE":	"ar",
  "ZM":	"en",
  "ZW":	"en",
  "GR": "el",
}

export default function getLanguage() {
  let navigatorLang;
  if (navigator.languages && navigator.languages.length) {
    navigatorLang = navigator.languages[0];
  } else {
    navigatorLang = navigator.language;
  }
  const lang = navigatorLang ? navigatorLang.split('-')[0] : 'en';
  return Object.values(languageMap).includes(lang) ? lang : 'en';
}
