import React, { useRef } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Field } from 'react-final-form';

interface ISelectOption {
    value: string;
    label: string;
}

interface ISelectProps {
    label: string;
    name: string;
    options: ISelectOption[];
    helperText?: string;
    validate?: any;
    disabled?: boolean;
    loading?: boolean;
    inputRef?: any;
    onFocus?: any;
    onChange?: any;
    onBlur?: any;
    useEmptyOption?: boolean;
    className?: any;
    value?: any;
    inputProps?: any;
}

function Loading() {
    return <CircularProgress size={20} style={{ marginRight: 10 }} />;
}

const SelectWrapper = (props: ISelectProps) => {
    const inputLabel = useRef(null);
    const {
        name,
        value,
        onChange,
        label,
        helperText,
        useEmptyOption,
        options,
        ...rest
    } = props;
    return (
        <FormControl fullWidth variant="filled">
            {label && <InputLabel htmlFor={name} ref={inputLabel} shrink variant="filled">{label}</InputLabel>}
            <Select
                native
                name={name}
                value={value}
                onChange={onChange}
                variant="filled"
                IconComponent={props.loading ? Loading : props.disabled ? () => null : ArrowDropDownIcon}
                {...rest}
            >
                {useEmptyOption && <option />}
                {options.map((o) => <option value={o.value} key={o.value}>{o.label}</option>)}
            </Select>
            {helperText && <FormHelperText variant="filled">{helperText}</FormHelperText>}
        </FormControl>
    );
}

export default SelectWrapper;
