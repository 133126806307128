import React from 'react'

import Badge from '@material-ui/core/Badge'
import { withStyles, createStyles } from '@material-ui/core/styles'

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      right: -18,
      top: 11,
      padding: '0 4px',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      fontSize: '0.75rem',
      lineHeight: 1.3,
      letterSpacing: '0.03em',
      color: 'rgba(0, 0, 0, 0.75)',
      opacity: 0.75,
    },
  })
)(Badge)

interface ICustomBadge {
  content: number
  children: React.ReactNode
}

export default function CustomBadge({ content, children }: ICustomBadge) {
  return (
    <StyledBadge badgeContent={content} color="secondary">
      {children}
    </StyledBadge>
  )
}
