import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OrderStatusView from '../../../../components/Order/Status';
import DateView from '../../../../components/DateView';
import { IOrder } from '../../../../api/order';
import useGlobalStyles from '../../../../hooks/useGlobalStyles';
import BlockchainLink from '../../../../components/BlockchainLink';
import CurrencyNameMapper from "../../../../components/CurrencyNameMapper/CurrencyNameMapper";
import { splitAddress } from "../../../../helpers/address";

interface IProps {
  order: IOrder;
  onClose: any;
  type?: 'merchant' | 'client';
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: '#fff',
    padding: '34px 0',
    zIndex: 10000
  },
  close: {
    position: 'absolute',
    right: 21,
    top: 15,
    padding: 0
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(3),
    '& td:first-child': {
      paddingLeft: 18
    },
    '& td:last-child': {
      paddingRight: 18
    },
  }
}));

export default function OrderDetails({ order, onClose, type }: IProps) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const [addressPart, memoTagPart] = splitAddress(order.address)

  return (
    <div className={classes.root}>
      <IconButton onClick={onClose} className={classes.close}>
        <CloseIcon />
      </IconButton>
      <Typography variant="body2" align="center" component="div" className={classes.title}>
        {t(`${order.type}`)}
      </Typography>
      <Typography variant="h4" align="center" translate="no">{order.paymentAmount} {order.paymentCurrency}</Typography>
      <Typography variant="body2" align="center" component="div" color="textSecondary" translate="no">
        {/* {order.amount} {order.currency} */}
        {order.amount} <CurrencyNameMapper currency={order.currency} />
      </Typography>
      <Table className={clsx(classes.table, globalClasses.tableSimple)}>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">{t('Created')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2">
                <DateView value={order.createdAt} />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">{t('Status')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2">
                <OrderStatusView status={order.status} type={type} />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">{t('Order ID')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2">{order.id}</Typography>
            </TableCell>
          </TableRow>
          {order.unDomain && (
            <TableRow>
              <TableCell>
                <Typography variant="body2" color="textSecondary">{t('Unstoppable Domain')}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                  {order.unDomain}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">{t('Address')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {addressPart ? <BlockchainLink type="address" value={addressPart} currency={order.currency} charsLimit={25} /> : ''}
              </Typography>
            </TableCell>
          </TableRow>
          {memoTagPart && (
            <TableRow>
              <TableCell>
                <Typography variant="body2" color="textSecondary">{t('memo.label')}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                  {memoTagPart}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">{t('Transaction')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {order.blockchainTxId ? <BlockchainLink type="tx" value={order.blockchainTxId} currency={order.currency} charsLimit={25} /> : ''}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">{t('Paid ')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" translate="no">{order.paymentAmount} {order.paymentCurrency}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">{t('Amount')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" translate="no">{order.amount} <CurrencyNameMapper currency={order.currency} /></Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
