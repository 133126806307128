import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const isURL = (value: any) => {
  if (!value) return;
  var pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+!@#$%^&*()_;:-]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(value);
};

export default function FrameDemo() {
  const [src, setSrc] = useState('');

  return (
    <Grid container direction="column" style={{ width: '90%', margin: '10px auto' }}>
      <Grid item style={{ height: 80 }}>
        <TextField
          label="Frame source"
          fullWidth
          defaultValue={src}
          onChange={(e) => setSrc(e.target.value)}
          variant="outlined"
          margin="normal"
        />
      </Grid>
      <Grid item>
        {isURL(src) && <iframe style={{ width: '100%', height: 'calc(100vh - 100px)', border: '1px solid #ccc' }} src={src} sandbox="allow-forms allow-modals allow-popups allow-scripts allow-same-origin" allow="camera *"/>}
      </Grid>
    </Grid>
  )
}
