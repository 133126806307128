import React, { useState } from 'react'
import GdprPopup from './popup'
import { isSf } from '@/helpers/constants';

export default function GdprWrapper(props: any) {
  const [userSelectedGdprParam, setUserSelectedGdprParam] = useState(false)
  appendThirdpartyScripts()
  const isUTA = window.location.pathname.includes('/uta/')
  return (
    <div>
      {!isUTA && !isSf && (
        <GdprPopup setUserSelectedGdprParam={setUserSelectedGdprParam} />
      )}
      {props.children}
    </div>
  )
}

const canWeUseThirdPatyCookies = () => {
  let gdprParamAllowUse = false
  const gdprJSON = window.localStorage.getItem('cookiesAccepted')
  if (gdprJSON) {
    const gdprObj = JSON.parse(gdprJSON)

    gdprParamAllowUse = gdprObj.expired > new Date().getTime()
    gdprParamAllowUse = gdprParamAllowUse && gdprObj.selectedParam === 'All'
  }

  return gdprParamAllowUse
}

function appendThirdpartyScripts() {
  if (process.env.REACT_APP_WIDGET_ENV === 'prod') {
    /* const gtm = document.createElement('script')
    gtm.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-N45BGB2L');`
    const fr = document.createElement('iframe')
    fr.src = 'https://www.googletagmanager.com/ns.html?id=GTM-N45BGB2L'
    fr.height = '0'
    fr.width = '0'
    fr.style.display = 'none'
    fr.style.visibility = 'hidden'
    const ns = document.createElement('noscript')
    ns.appendChild(fr)
    document.head.appendChild(gtm)
    document.body.prepend(ns) */
  }
}
