import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { MenuItem, Select } from '@material-ui/core'

import { ISettlementBalance } from '@/types/settlements'

import { IRowBalance } from './RowBalance'
import DesktopSubaccountBalancesList from './Subaccount/DesktopSubaccountBalancesList'
import DesktopBalancesList from './DesktopBalancesList'
import Loader from '../../../components/Loader'
import { useSettlementBalancesList } from '../../../api/private/account'
import useGlobalStyles from '../../../hooks/useGlobalStyles'

interface IResetFormHandle {
  resetForm: () => void
}

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
  balanceTitle: {
    marginBottom: 0,
    paddingTop: 0,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
  select: {
    'height': '35px',
    '& .MuiFilledInput-input': {
      padding: '7px 32px 7px 9px',
    },
  },
}))

function Balances() {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  const { t } = useTranslation()

  const formRef = useRef<IResetFormHandle | null>(null)
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(
    'Utorg UAB (Lithuania)'
  )

  const [{ items: settlementBalances }, loading, loadBalances] =
    useSettlementBalancesList()

  const { user } = useSelector((state: any) => state.account)
  const history = useHistory()

  useEffect(() => {
    if (user.id) {
      if (!user?.authorities.includes('ROLE_MERCHANT')) {
        history.push('/account/info')
      } else {
        loadBalances()
      }
    }
  }, [user])

  const isUABLegalEntity = settlementBalances.some(
    (settlementBalance: ISettlementBalance) =>
      settlementBalance.legalEntity === 'Utorg UAB (Lithuania)'
  )
  const isCVLegalEntity = settlementBalances.some(
    (settlementBalance: ISettlementBalance) =>
      settlementBalance.legalEntity === 'Chain-Valley'
  )

  const filterSettlementBalancesByLegalEntity = (
    settlements: ISettlementBalance[]
  ) => {
    if (!user?.isMaster) {
      return settlements
    }

    if (!isUABLegalEntity || !isCVLegalEntity) {
      return settlements
    }

    return settlements.filter(
      (settlementBalance: ISettlementBalance) =>
        settlementBalance.legalEntity === selectedLegalEntity
    )
  }

  const filteredSettlementBalancesByLegalEntity =
    filterSettlementBalancesByLegalEntity(settlementBalances)

  let sortedByCurrencySettlementBalances: IRowBalance[] = []

  if (filteredSettlementBalancesByLegalEntity.length > 0) {
    sortedByCurrencySettlementBalances =
      filteredSettlementBalancesByLegalEntity.reduce(
        (acc: IRowBalance[], settlementBalance: ISettlementBalance) => {
          const containsCurrency = acc.find(
            (item) => item?.currency === settlementBalance.currency
          )

          if (containsCurrency) {
            containsCurrency.sids.push(settlementBalance)
          } else {
            acc.push({
              currency: settlementBalance.currency,
              total: 0,
              locked: 0,
              available: 0,
              sids: [settlementBalance],
            })
          }

          return acc
        },
        []
      )
  }

  const handleChangeLegalEntity = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    formRef.current?.resetForm()
    setSelectedLegalEntity(event.target.value as string)
  }

  const hasSelect = user?.isMaster && isUABLegalEntity && isCVLegalEntity

  return (
    <>
      <Loader loading={loading}>
        {!hasSelect && (
          <Typography
            variant="h4"
            className={clsx(globalClasses.title, classes.title)}
          >
            {t('Balance')}
          </Typography>
        )}
        {hasSelect && (
          <div className={classes.titleWrapper}>
            <Typography
              variant="h4"
              className={clsx(globalClasses.title, classes.balanceTitle)}
            >
              {t('Balance')}
            </Typography>
            <Select
              id="legal-entity-select"
              variant="filled"
              value={selectedLegalEntity}
              className={classes.select}
              onChange={handleChangeLegalEntity}
            >
              <MenuItem value={'Utorg UAB (Lithuania)'}>UAB</MenuItem>
              <MenuItem value={'Chain-Valley'}>Chainvalley</MenuItem>
            </Select>
          </div>
        )}

        {sortedByCurrencySettlementBalances.length > 0 && user?.isMaster && (
          <DesktopBalancesList
            ref={formRef}
            loadBalances={loadBalances}
            balances={sortedByCurrencySettlementBalances}
          />
        )}

        {settlementBalances.length > 0 && !user?.isMaster && (
          <DesktopSubaccountBalancesList
            loadBalances={loadBalances}
            balances={settlementBalances}
          />
        )}

        {settlementBalances.length === 0 && (
          <Typography variant="body1" align="center">
            {t('Balance is empty')}
          </Typography>
        )}
      </Loader>
    </>
  )
}

export default Balances
