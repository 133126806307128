import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

export interface IAutocompleteOption {
  value: string
  label: string
  name?: string
  icon?: string
}

interface IProps {
  options: IAutocompleteOption[]
  label: string
  onChange: (value: IAutocompleteOption | null) => void
  value?: string
  disabled?: boolean
  required?: boolean
}

const AutocompleteInput = ({
  options,
  label,
  onChange,
  value,
  disabled,
  required,
}: IProps) => {
  let selected: IAutocompleteOption | undefined
  if (value) {
    selected = options.find((o) => o.value === value)
  }

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option: IAutocompleteOption) => option.label}
      onChange={(e, v) => onChange(v)}
      disabled={disabled}
      value={value}
      renderInput={(params: any) => {
        const props = { ...params }
        if (!!value && !!selected) {
          props.InputLabelProps.shrink = true
          if (!props.inputProps.value) {
            props.inputProps.value = selected.label
          }
        }
        return (
          <TextField
            {...props}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            fullWidth
            required={required !== undefined ? required : true}
            variant="filled"
          />
        )
      }}
    />
  )
}
export default AutocompleteInput
