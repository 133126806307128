import React from 'react'

import clsx from 'clsx'

import { useTranslation } from 'react-i18next'

import makeStyles from '@material-ui/core/styles/makeStyles'
import LoginLayout from '../Login'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

import { MIRROR } from '@/helpers/constants'
import { getSupportEmail } from '@/helpers/mirrorHelper'
import StopIcon from '@/components/icons/stop.svg'
import IconWarning from '@/icons/warning.svg'
import useGlobalStyles from '@/hooks/useGlobalStyles'

export const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    marginBottom: theme.spacing(4),
    background: `url("${StopIcon}") no-repeat center`,
    height: 80,
  },
  iconWarning: {
    marginBottom: theme.spacing(4),
    background: `url("${IconWarning}") no-repeat center`,
    height: 80,
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  titleNotSupported: {
    marginBottom: theme.spacing(3),
    fontSize: '24px',
  },
  text: {
    marginBottom: theme.spacing(5),
  },
  button: {
    margin: '0 auto',
  },
  buttonBack: {
    marginBottom: theme.spacing(1),
  },
}))

export default function CloudflareLimit() {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { t } = useTranslation()
  return (
    <LoginLayout>
      <div className={classes.icon} />
      <Typography className={classes.title} variant="h6" align="center">
        {t('Request limit exceeded')}
      </Typography>
      <Typography
        className={classes.text}
        variant="body2"
        align="center"
        dangerouslySetInnerHTML={{ __html: t('cloudflareRequestLimit') }}
      />
      <Link
        className={clsx(globalClasses.button, 'text')}
        component={Button}
        href={getSupportEmail(MIRROR)}
      >
        {t('Contact support')}
      </Link>
    </LoginLayout>
  )
}
