import { IOrder } from '@/api/order'
import { OrderExtendedType } from '@/api/private/orders'
import { defineNFTFlow } from './nftHelpers/nftFlow'

export function getOrderAmount(order: IOrder, apmAmount: number | undefined) {
  const isNFTFlow = defineNFTFlow(order.currency)
  if (order.type === OrderExtendedType.WALLET_TO_FIAT) {
    return `${order.amount} ${order.currency}`
  }

  if (isNFTFlow) {
    return `${(apmAmount || order.paymentAmount) + ' ' + order.paymentCurrency}`
  }

  if (order.sf && order.apm) {
    if (apmAmount) {
      return `${apmAmount + ' ' + order.paymentCurrency}`
    } else {
      return ' '
    }
  } else {
    if (order.paymentAmount || apmAmount) {
      return `${
        (apmAmount || order.paymentAmount) + ' ' + order.paymentCurrency
      }`
    }
    return ''
  }
}
