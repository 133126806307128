import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Field } from 'react-final-form';
import { Checkbox } from 'final-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 0,
    '& .MuiFormHelperText-root': {
      position: 'static',
      // marginLeft: theme.spacing(2),
      // marginTop: -theme.spacing(1),
    },
    '&.error .MuiCheckbox-root': {
      color: theme.palette.error.main
    }
  }
}));

interface ICBoxProps {
  label?: any;
  className?: string;
  meta?: any;
  name: string;
  inputRef?: any;
  setAction?: any;
  disabled?: boolean
}

const CBoxBase = ({ label, className, meta, setAction, disabled, ...rest }: ICBoxProps) => {
  const classes = useStyles();
  return (
    <FormControl className={clsx(classes.root, { error: meta && meta.touched && meta.invalid })}>
      <FormControlLabel
        className={className}
        control={<Checkbox {...rest} meta={meta} action={setAction} />}
        label={label}
        disabled={disabled}
      />
      {meta && meta.touched && meta.invalid && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}

interface ICheckboxProps {
  label?: any;
  name: string;
  validate?: any;
  className?: any;
  inputRef?: any;
  inputProps?: any;
  setAction?: any;
  disabled?: boolean
}

const CheckboxField = (props: ICheckboxProps) => (
  <Field
    {...props}
    // @ts-ignore
    component={CBoxBase}
    type="checkbox"
    validate={props.validate}
  />
);

export default CheckboxField;