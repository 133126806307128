import React, { useEffect, FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

import Loader from '@/components/Loader'
import countries from '@/helpers/country'
import { KycStatus } from '@/api/kyc'
import { useKycDetails } from '@/api/private/kyc'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import {
  getPartnerExternalId,
  getPartnerSid,
  isKycPassed,
} from '@/helpers/kycHelper'

import ProfileSettings from './Settings'
import { useMerchantSettings } from '@/api/merchant'

const Profile: FC = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { user } = useSelector((state: any) => state.account)
  const ui = useSelector((state: any) => state.common.ui)
  const [, , loadUi] = useMerchantSettings()

  const [{ data: kyc }, loading, load] = useKycDetails()
  const countryList = countries.getNames(language.split('-')[0])
  const isTerminalUser =
    user && user.authorities && user.authorities.includes('ROLE_TERMINAL')
  const isMerchant =
    user && user.authorities && user.authorities.includes('ROLE_MERCHANT')
  const externalId = getPartnerExternalId()
  const sid = getPartnerSid()

  useEffect(() => {
    if (!isTerminalUser && !user.kycStatus) {
      load()
    }
  }, [])

  useEffect(() => {
    if (sid && externalId) {
      loadUi({ sid })
    }
  }, [externalId, sid])

  const kycStatus = user.kycStatus || kyc.status
  const kycUserPassed = isKycPassed(kycStatus)

  const merchantRedirect = ui?.global?.kyc_redirect
  const merchantName = ui?.global?.merchant_name
  const hasRedirectButton = Boolean(sid && externalId && merchantRedirect)

  return (
    <Loader loading={loading}>
      <div className={classes.root}>
        <div className="wrapper">
          <div>
            <Typography variant="h4" className={globalClasses.title}>
              {t('Profile info')}
            </Typography>
            <Table className={classes.table}>
              <TableBody>
                {user.legal ? (
                  <>
                    <TableRow>
                      <TableCell className={classes.title}>
                        <Typography variant="body2">
                          {t('Merchant name')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" component="div">
                          {user.legal.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.title}>
                        <Typography variant="body2">
                          {t('Merchant address')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" component="div">
                          {user.legal.address}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.title}>
                        <Typography variant="body2">{t('Reg.no')}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" component="div">
                          {user.legal.regNum}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell className={classes.title}>
                        <Typography variant="body2">
                          {t('labels.First name')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {user.firstName || 'N/A'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.title}>
                        <Typography variant="body2">
                          {t('labels.Last name')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {user.lastName || 'N/A'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell className={classes.title}>
                    <Typography variant="body2">{t('labels.Email')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{user.email}</Typography>
                  </TableCell>
                </TableRow>
                {user.phone && (
                  <TableRow>
                    <TableCell className={classes.title}>
                      <Typography variant="body2">
                        {t('labels.Phone')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{user.phone}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell className={classes.title}>
                    <Typography variant="body2">
                      {t('labels.country')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {user.country ? countryList[user.country] : 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                {!isTerminalUser && !isMerchant && (
                  <TableRow>
                    <TableCell className={classes.title}>
                      <Typography variant="body2">
                        {t('Verification status')}
                      </Typography>
                      {hasRedirectButton && (
                        <div>
                          <Link
                            href={merchantRedirect}
                            style={{ textDecoration: 'none' }}
                            component="a"
                          >
                            <Button color="primary" variant="contained">
                              {t('Back to')} {merchantName}
                            </Button>
                          </Link>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Typography variant="body2" component="div">
                          {!kycUserPassed && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t(`kycPanel.notCompletedStatus`),
                              }}
                            />
                          )}
                          {kycUserPassed && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t(`kycStatusData.${kycStatus}`),
                              }}
                            />
                          )}
                        </Typography>

                        {!kycUserPassed && (
                          <Link
                            to="/account/kyc"
                            style={{ textDecoration: 'none' }}
                            component={NavLink}
                          >
                            <Button color="primary" variant="contained">
                              {t('continue')}
                            </Button>
                          </Link>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        <div className="wrapper">
          <ProfileSettings user={user} />
        </div>
      </div>
    </Loader>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    'display': 'flex',
    '& .wrapper': {
      'width': '50%',
      '& > div': {
        width: 364,
      },
    },
    [theme.breakpoints.down('md')]: {
      'flexDirection': 'column',
      '& .wrapper': {
        'width': '100%',
        '& > div': {
          flex: '0 0 100% !important',
          width: '100% !important',
        },
      },
    },
  },
  table: {
    '& tr:last-child': {
      '& button': {
        marginTop: theme.spacing(1),
      },
      '& td:first-child': {
        verticalAlign: 'top',
      },
      '& td': {
        borderBottom: 'none !important',
      },
    },
  },
  title: {
    'paddingLeft': 0,
    'whiteSpace': 'nowrap',
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
}))

export default Profile
