import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import merge from 'lodash/merge';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { CardForm, VerificationForm } from '../Exchange/forms';
import AuthForm from './forms/AuthForm';
import Loader from '../../components/Loader';
import { emptyOrder, IOrder } from '../../api/order';
import { KycStatus } from '../../api/kyc';
import { useCurrencies } from '../../api/currency';
import { useMerchantSettings, initialUI } from '../../api/merchant';
import useFormStyles from '../Exchange/forms/useFormStyles';
import plural from '../../helpers/plural';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { ILangType } from '../../locales/';
import { MIRROR } from '@/helpers/constants';
import { getSupportEmail } from '@/helpers/mirrorHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1000px',
    margin: '0 auto',

    '& > div': {
      width: '100%',
    }
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: 4,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.12)',
    '& .currentProgress': {
      background: theme.palette.primary.main,
      height: 4,
      transition: 'width 500ms ease 0s'
    }
  },
  returnLink: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    '& a': {
      display: 'inline-flex',
      alignItemns: 'center',
      '& svg': {
        height: 15,
        marginTop: 1,
        marginRight: -5
      }
    }
  },
  resultTitle: {
    marginTop: -45,
    color: 'rgba(0, 0, 0, 0.38)'
  },
  resultText: {
    marginTop: 46,
    color: 'rgba(0,0,0,0.6)'
  },
  resultDetails: {
    marginTop: 16,
    marginBottom: 16,
    color: 'rgba(0,0,0,0.87)'
  },
  resultKyc: {
    background: '#fefaed',
    margin: '33px -40px',
    padding: '26px 40px'
  },
  merchantLogo: {
    position: 'absolute',
    left: 40,
    top: 20,
    height: 42
  }
}));

const steps = ['transaction', 'card'];

export default function DirectChange() {
  let { step } = useParams<any>()
  if (!step) {
    step = steps[0];
  }
  const { t, i18n: { language } } = useTranslation();
  const history = useHistory();
  let sid = '';
  let wallet = '';
  if (history.location.state) {
    // @ts-ignore
    sid = history.location.state.sid;
    // @ts-ignore
    wallet = history.location.state.wallet;
  }
  if (step === steps[0] && !sid.length) {
    history.push('/account');
  }
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [address, setAddress] = useState(wallet || '');
  const [order, setOrder] = useState<IOrder>({ ...emptyOrder, address, paymentAmount: 100, paymentCurrency: 'EUR', currency: 'BTC' });
  const [email, setEmail] = useState('');
  const [ui, setUi] = useState(initialUI);
  const [unavailable, setUnavailable] = useState(false);
  const [redirectIn, setRedirectIn] = useState(10);
  const [customer, setCustomer] = useState({ kycStatus: null, kycFlow: null });
  const [{ data: currencies }, currenciesLoading, loadCurrencies] = useCurrencies();
  const [{ data: uiServer }, uiLoading, loadUi] = useMerchantSettings();
  const lang = language.split('-')[0] as ILangType;
  const { global: { logo } } = ui;

  const formClasses = useFormStyles({});
  const resultSteps = ['success', 'error', 'interrupted'];

  const nextStep = (s: string) => history.push(`/cbc/exchange/${s}${window.location.search}`, history.location.state);


  useEffect(() => {
    if (step !== steps[0]) {
      nextStep(steps[0]);
    }
  }, []);

  useEffect(() => {
    setUi(merge(initialUI, uiServer));
  }, [uiServer]);

  useEffect(() => {
    if (sid.length) {
      loadCurrencies({ sid });
      loadUi({ sid });
    }
  }, [sid]);

  useEffect(() => {
    if (order && step && resultSteps.includes(step) && (order.successUrl || order.failUrl)) {
      if (redirectIn <= 0) {
        if (step === 'success') {
          if (order.successUrl) {
            window.location.assign(order.successUrl);
          }
        } else if (order.failUrl) {
          window.location.assign(order.failUrl);
        }
      } else {
        setTimeout(() => setRedirectIn(redirectIn - 1), 1000);
      }
    }
  }, [step, redirectIn, order]);

  let ActiveForm;
  let progressWidth = '33%';
  switch (step) {
    case 'card':
      ActiveForm = CardForm;
      progressWidth = '66%';
      break;
    case 'verification':
      ActiveForm = VerificationForm;
      progressWidth = '90%';
      break;
    default:
      ActiveForm = AuthForm;
  }

  if (resultSteps.includes(step)) {
    progressWidth = '100%'
  }

  const noneKYC = customer.kycFlow === 'NONE' || order.paymentCurrency === 'UAH';

  if (unavailable) {
    return (
      <div className={classes.root}>
        <Typography variant="body1" align="center">
          <p>{t('serviceUnavailable')}</p>
          <Link
            className={clsx(formClasses.send, 'wide')}
            component={Button}
            href={getSupportEmail(MIRROR)}
          >
            {t('Inform administrator')}
          </Link>
        </Typography>
      </div>
    );
  }

  return (
    <Loader loading={uiLoading || currenciesLoading}>
      <div className={classes.root}>
        <div className={classes.progress}>
          <div className="currentProgress" style={{ width: progressWidth }} />
        </div>
        {step !== 'verification' && logo && logo.length > 0 && (
          <img src={logo} className={classes.merchantLogo} alt="" />
        )}
        {resultSteps.includes(step)
          ? (
            <div>
              <div className={classes.resultText}>
                <Typography variant="body2" align="center" component="div" className={classes.resultTitle}>
                  {t('Order')} #{order.id}
                </Typography>
                {step !== 'interrupted' && (
                  <Typography variant="body2" align="center" component="div">
                    {t(`paymentResult.${step}Title`, order)}
                  </Typography>
                )}
                <Typography
                  variant={step === 'success' ? 'h4' : 'body2'}
                  component="div"
                  align="center"
                  className={classes.resultDetails}
                  translate="no"
                  dangerouslySetInnerHTML={{
                    __html: t(`paymentResult.${step}${step === 'success'
                      && (!ui.form.authorization.fields.currency.show || order.currency === order.paymentCurrency) ? '3' : ''}Details`,
                      {
                        ...order,
                        currency: 'CBC'
                      })
                  }}
                />
                {step !== 'interrupted' && ui.form.authorization.fields.walletAddress.show && (order.address || address) && (
                  <Typography variant="body2" align="center" component="div">
                    {t(`paymentResult.${step}2Details`, {
                      ...order,
                      byKyc: t(`paymentResult.byKyc${customer.kycStatus === KycStatus.APPROVED || noneKYC ? 'Approved' : 'Default'}`)
                    })}
                  </Typography>
                )}
                {step === 'success' && !noneKYC && (
                  <Typography variant="body2" align="center" component="div" className={classes.resultKyc}>
                    <p>{t('verificationStatus')} <span dangerouslySetInnerHTML={{ __html: t(`kycStatusData.${customer.kycStatus}`) }} /></p>
                    <p>{t('paymentResult.updatesWillSentToEmail')}</p>
                  </Typography>
                )}
                {step === 'success' && order.successUrl && (
                  <div>
                    <Typography variant="body2" align="center">
                      <p>{t('Redirect to merchant in')} {redirectIn} {t(plural(redirectIn, ['secPlural1', 'secPlural2', 'secPlural5']))}.</p>
                      <Button
                        className={globalClasses.button}
                        href={order.successUrl}
                        variant="contained"
                        color="primary"
                      >
                        {t('Done')}
                      </Button>
                    </Typography>
                  </div>
                )}
                {step !== 'success' && order.failUrl && (
                  <div>
                    <Typography variant="body2" align="center">
                      <p>{t('Redirect to merchant in')} {redirectIn} {t(plural(redirectIn, ['secPlural1', 'secPlural2', 'secPlural5']))}.</p>
                      <Button
                        className={globalClasses.button}
                        href={order.failUrl}
                        variant="contained"
                        color="primary"
                      >
                        {t('Done')}
                      </Button>
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ActiveForm
              order={order}
              setStep={nextStep}
              setEmail={setEmail}
              email={email}
              t={t}
              setCustomer={setCustomer}
              customer={customer}
              ui={ui}
              language={lang}
              currencies={currencies}
              currenciesLoading={currenciesLoading}
              address={address}
              setAddress={setAddress}
              sid={sid}
              setOrder={(o) => setOrder ? setOrder(o) : null}
              setUnavailable={setUnavailable}
              fromOrder
              setProcessingError={() => null}
            />
          )}
      </div>
    </Loader>
  );
}
