import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import { Input } from '../../../components/Form'
import { useAuth, useCodeResend } from '../../../api/customer'
import { IOrder, OrderStatus } from '../../../api/order'
import {
  composeValidators,
  lengthRequired,
  required,
} from '../../../validators'
import plural from '../../../helpers/plural'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import ServerMessage from '../../../components/UI/ServerMessage'
import { qaAttr } from '../../../helpers/qaHelpers'
import captchaRequest from '@/helpers/captcha';
import { trackOnrampLogin } from '@/helpers/analytics'

interface IProps {
  order: IOrder
  email: string
  t: any
  setCustomer: any
  setStep: any
  customer: any
  formClasses: any
  saveCustomerWallet: boolean
  extTimestamp?: number
  extPublicKey?: string
  extSignature?: string
  extAlg?: string
}

export default function ConfirmationCodeForm({
  order,
  email,
  t,
  setCustomer,
  setStep,
  customer,
  formClasses,
  saveCustomerWallet,
  extTimestamp,
  extPublicKey,
  extSignature,
  extAlg,
}: IProps) {
  const globalClasses = useGlobalStyles()
  const [isNotFirstResend, setIsNotFirstResend] = useState(false)
  const [resendCodeTimeout, setResendCodeTimeout] = useState(40)
  const [serverError, setServerError] = useState('')

  const [auth] = useAuth((res: any) => {
    moveNextStep(res)
  })

  const moveNextStep = (res: any) => {
    if (res.success) {
      trackOnrampLogin('add_email_otp_success', order, 'onramp_login')
    }
    if (!res.success) {
      setServerError('authError')
      return
    }
    setServerError('')
    setCustomer({ ...customer, ...res.data })
    if (
      ![OrderStatus.NEW, OrderStatus.ACCEPTED, OrderStatus.PROCESSING].includes(
        order.status
      )
    ) {
      return setStep(order.status === OrderStatus.ERROR ? 'error' : 'success')
    }
    setStep('card')
  }

  useEffect(() => {
    trackOnrampLogin('view_email_otp_screen', order, 'onramp_login')
  }, [])

  const [resendCode] = useCodeResend((res: any) => {
    if (res.success) {
      setResendCodeTimeout(40)
    }
  })

  useEffect(() => {
    let timeout: any
    if (resendCodeTimeout > 0) {
      timeout = setTimeout(
        () => setResendCodeTimeout(resendCodeTimeout - 1),
        1000
      )
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [resendCodeTimeout])

  return (
    <Form
      onSubmit={async (values) => {
        const req = {
          email,
          code: values.code,
          id: order.id,
          termsAccepted: true,
          privacyPolicyAccepted: true,
        }
        const walletReq =
          saveCustomerWallet && extTimestamp && extPublicKey && extSignature
            ? {
                timestamp: extTimestamp,
                publicKey: extPublicKey,
                signature: extSignature,
                alg: extAlg,
              }
            : {}
        auth({ ...req, ...walletReq })
      }}
      initialValues={{ code: '' }}
      render={({ handleSubmit }) => (
        <div>
          <Typography variant="h4" className={globalClasses.title}>
            {t('Verify Your Email')}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            className={globalClasses.subtitle}
          >
            {t('verifyCodeDescription')} <strong>{email}</strong>
          </Typography>
          {serverError.length > 0 && (
            <ServerMessage type="error" text={serverError} />
          )}
          <div>
            <Input
              name="code"
              label={t('labels.Authorization code')}
              validate={composeValidators(required, (v: any) =>
                lengthRequired(v, 6)
              )}
              inputProps={{ inputMode: 'numeric', ...qaAttr('f') }}
              type="number"
              fullWidth
              autoFocus
            />
            <OnChange name="code">
              {(val: string) => {
                if (val.length === 6) {
                  handleSubmit()
                }
              }}
            </OnChange>
          </div>
          <div className={globalClasses.hint} translate="no">
            {resendCodeTimeout > 0 ? (
              <p className={globalClasses.resendTimeout}>
                {isNotFirstResend && <div>{t('codeBeenResent')}</div>}
                {t('Resend confirmation code in')} {resendCodeTimeout}{' '}
                {t(
                  plural(resendCodeTimeout, [
                    'secPlural1',
                    'secPlural2',
                    'secPlural5',
                  ])
                )}
                .
              </p>
            ) : (
              <p>
                {t('notReceivedCode')}&nbsp;
                <Link
                  onClick={() => {
                    if (!isNotFirstResend) {
                      setIsNotFirstResend(true)
                    }
                    captchaRequest('/api/widget/v1/customer/code', resendCode,{ email, orderId: order.id })
                  }}
                  className={formClasses.link}
                >
                  {t('Resend code')}
                </Link>
              </p>
            )}
          </div>
        </div>
      )}
    />
  )
}
