import React, { useState, useEffect, FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Form } from 'react-final-form'
import { OnChange, OnBlur } from 'react-final-form-listeners'

import Button from '@material-ui/core/Button'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import Link from '@material-ui/core/Link'
import makeStyles from '@material-ui/core/styles/makeStyles'

import ServerMessage from '@/components/UI/ServerMessage'
import { Input } from '@/components/Form'
import { isEmail, required, composeValidators } from '@/validators'
import { useCustomerLogin } from '@/api/private/auth'
import captchaRequest from '@/helpers/captcha'
import useEmailSuggest from '@/hooks/useEmailSuggest'
import useQuery from '@/hooks/useQuery'
import globalConfig from '@/config/global.json'

import LoginConfirmation from './Confirmation'
import SignUp from './SignUp'

import NoUserIcon from './icons/noUser.svg'
import { setPartnerExternalId, setPartnerSid } from '@/helpers/kycHelper'
import { useHistory } from 'react-router'
import { useCustomerBind } from '@/api/customer'
import { getRequestError } from '@/helpers/error'
import { getMirrorName, isChainValley } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

export enum LoginType {
  EMAIL = 'email',
  PHONE = 'phone',
}

export type TNextStep = 'OTP' | 'PASSWORD'

const LoginPage: FC = () => {
  const classes = useLoginStyles()
  const history = useHistory()

  const [next, setNext] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState<undefined | string>()
  const [emailSuggestion, checkEmail] = useEmailSuggest()
  const { t } = useTranslation()
  const [query] = useQuery({})
  const { user } = useSelector((state: any) => state.account)
  const { bindCustomer } = useCustomerBind({
    sid: query.sid,
    externalId: query.externalId,
  })

  const [startLogin, loginStarting] = useCustomerLogin((res: any) => {
    if (res.success) {
      if (isChainValley) {
        if (res.data.next === 'REGISTER') {
          setError('somethingsWentWrong')
        } else {
          setError(undefined)
          setNext(res.data.next)
        }
      } else {
        setError(undefined)
        setNext(res.data.next)
      }
    }
  })

  useEffect(() => {
    document.body.classList.add('auth')

    return () => {
      document.body.classList.remove('auth')
    }
  }, [])

  useEffect(() => {
    const { sid, externalId } = query
    if (sid && externalId) {
      setPartnerSid(sid)
      setPartnerExternalId(externalId)
    }
  }, [query.sid, query.externalId])

  const logged = user && Object.keys(user).length > 0 && !user.restricted

  useEffect(() => {
    if (logged) {
      bindCustomer()
        .then((bind) => {
          if (bind) {
            history.push('/account/kyc')
          } else {
            history.push('/account/info')
          }
        })
        .catch((error) => {
          setError(getRequestError({ error }))
        })
    }
  }, [logged])

  if (next === 'REGISTER') {
    return (
      <SignUp
        sid={query.sid}
        externalId={query.externalId}
        email={email}
        setEmail={setEmail}
        classes={classes}
        goBack={() => setNext('')}
      />
    )
  }

  if (next) {
    return (
      <LoginConfirmation
        sid={query.sid}
        externalId={query.externalId}
        email={email}
        classes={classes}
        setConfirm={setNext}
        field={next as TNextStep}
        goBack={setNext}
      />
    )
  }

  return (
    <Form
      initialValues={{ login: email }}
      onSubmit={(values) => {
        setEmail(values.login.trim())

        captchaRequest('/api/private/v1/auth/login', startLogin, values, () =>
          setError('captchaError')
        )
      }}
      mutators={{
        setEmail: (args, state, { changeValue }) =>
          changeValue(state, 'login', () => args[0]),
      }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h4">{t('Sign in')}</Typography>
          {query.from && query.from === 'restore' && (
            <Typography
              variant="body2"
              component="div"
              className={clsx(classes.success, classes.formTitle, 'hint')}
            >
              {t('passwordResetSuccess')}
            </Typography>
          )}
          <Typography
            variant="body2"
            component="div"
            className={classes.formTitle}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t('authFormTitle', getMirrorName(MIRROR)),
              }}
            />
          </Typography>
          {error && <ServerMessage type="error" text={error} />}
          <div style={{ position: 'relative' }}>
            <Input
              name="login"
              label={t('labels.Email')}
              validate={composeValidators(required, isEmail)}
              type="text"
              fullWidth
              required={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                spellCheck: false,
              }}
            />
            {emailSuggestion.length > 0 && (
              <FormHelperText variant="filled">
                {t('Do you mean')}{' '}
                <Link
                  onClick={() => form.mutators.setEmail(emailSuggestion)}
                  className={classes.link}
                >
                  {emailSuggestion}
                </Link>
                ?
              </FormHelperText>
            )}
            <OnChange name="login">
              {() => {
                if (globalConfig.useEmailSuggest) {
                  checkEmail(form.getFieldState('login'))
                }
              }}
            </OnChange>
            <OnBlur name="login">
              {() => {
                const login = form.getFieldState('login')
                form.change(
                  'login',
                  login && login.value ? login.value.trim() : ''
                )

                if (globalConfig.useEmailSuggest) {
                  checkEmail(form.getFieldState('login'))
                }
              }}
            </OnBlur>
          </div>
          <div>
            <Button
              disabled={loginStarting}
              type="submit"
              className={classes.button}
              variant="contained"
              color="primary"
            >
              {t('Proceed')}
            </Button>
          </div>
        </form>
      )}
    />
  )
}

export const useLoginStyles = makeStyles((theme) => ({
  root: {},
  form: {},
  title: {
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  stepBack: {
    'position': 'absolute',
    'left': -16,
    'top': -59,
    'color': theme.palette.text.primary,
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  login: {
    marginBottom: theme.spacing(2),
    fontSize: '18px',
    textAlign: 'center',
  },
  noUser: {
    paddingLeft: 60,
    background: `url("${NoUserIcon}") no-repeat left 4px`,
    position: 'relative',
  },
  hint: {
    fontSize: 14,
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(2),
  },
  resendTimeout: {},
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  formTitle: {
    'marginTop': 24,
    'lineHeight': '150%',
    'marginBottom': 48,
    '&.hint': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
      marginBottom: 22,
    },
  },
  button: {
    'height': 62,
    'width': '100%',
    'fontSize': 14,
    'fontWeight': 500,
    'letterSpacing': 1.25,
    'boxShadow': 'none',
    '&.text': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '&.Mui-disabled': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
  },
  checkBox: {
    marginTop: -9,
    marginBottom: 11,
    color: 'rgba(0,0,0,0.6)',
  },
  checkBoxText: {
    whiteSpace: 'normal',
  },
  errCheckBox: {
    '& .MuiCheckbox-root': {
      color: theme.palette.error.main,
    },
  },
  termsErr: {
    marginTop: -9,
    marginBottom: 8,
    color: theme.palette.error.main,
  },
  link: {
    cursor: 'pointer',
  },
}))

export default LoginPage
