import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import './i18n';
import configureStore from './store';
import GdprWrapper from './components/Layout/GDPR';

const store = configureStore();

function renderApp() {
  ReactDOM.render(<GdprWrapper><Provider store={store}><App /></Provider></GdprWrapper>, document.getElementById('root'));
}
try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    // tracesSampleRate: 1.0,
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0.2,
  })
} catch (error) {
  console.error(error)
}

renderApp();
