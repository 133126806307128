import * as Sentry from '@sentry/react'

import { AxiosResponse } from 'axios'

import { IOrder } from '@/api/order'
import { MIRROR } from '@/helpers/constants'
import { getLegalEntity } from '@/helpers/mirrorHelper'
import { ICustomer } from '@/types/customer'
import { initPaymentPayload, PaymentSource } from '@/api/payment'
import { IApplePayPayload } from '@/views/components/MobilePay/MobilePay.types'

interface IApplePayPaymentAuthorizedEvent {
  payment: IApplePayPayment
}
interface IApplePayValidateMerchantEvent extends Event {
  validationURL: string
}

interface IApplePayPayment {
  token: IApplePayPaymentToken
  billingContact?: IApplePayPaymentContact
  shippingContact?: IApplePayPaymentContact
}

interface IApplePayPaymentToken {
  paymentData: any
  paymentMethod: IApplePayPaymentMethod
  transactionIdentifier: string
}

interface IApplePayPaymentContact {
  emailAddress?: string
  phoneNumber?: string
  givenName?: string
  familyName?: string
  addressLines?: string[]
  locality?: string
  administrativeArea?: string
  postalCode?: string
  countryCode?: string
}

interface IApplePayPaymentMethod {
  displayName?: string
  network?: string
  type: string
}

interface IUseApplePay {
  order: IOrder
  customer: ICustomer
  initPayment: (data: initPaymentPayload) => Promise<AxiosResponse>
}

export default function useApplePay({
  order,
  customer,
  initPayment,
}: IUseApplePay) {
  const request = {
    countryCode: customer.country,
    currencyCode: String(order.paymentCurrency),
    total: {
      label: getLegalEntity(MIRROR),
      amount: String(order.finalPaymentAmount || order.paymentAmount),
    },
    supportedNetworks: ['masterCard', 'visa'],
    merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
  }

  const applePay = (
    setPaymentError: (error: string) => void,
    onMobilePayment: ({ paymentId, token }: IApplePayPayload) => Promise<void>
  ) => {
    let mobilePaymentId = ''

    try {
      const session = new window.ApplePaySession(3, request)

      session.begin()

      session.oncancel = () => {
        session.abort()
      }

      session.onvalidatemerchant = (event: IApplePayValidateMerchantEvent) => {
        const validationURL = event.validationURL

        initPayment({
          source: PaymentSource.APM,
          orderId: order.id,
          apm: {
            id: 'applepay',
            inputs: { sessionLink: validationURL },
          },
        })
          .then((paymentInitResponse: Record<string, any>) => {
            const validatedMerchant = paymentInitResponse?.data?.data?.url
            mobilePaymentId = paymentInitResponse?.data?.data?.id

            if (validatedMerchant) {
              session.completeMerchantValidation(JSON.parse(validatedMerchant))
            } else {
              setPaymentError('somethingWentWrong')
              session.abort()
            }
          })
          .catch(() => {
            setPaymentError('somethingWentWrong')
            session.abort()
          })
      }

      session.onpaymentauthorized = (
        event: IApplePayPaymentAuthorizedEvent
      ) => {
        const token = event.payment.token.paymentData

        onMobilePayment({
          paymentId: mobilePaymentId,
          token: JSON.stringify(token),
        })
          .then((res: any) => {
            if (res?.data?.success) {
              if (res?.data?.data?.nextStep.toUpperCase() === 'SUCCESS') {
                session.completePayment(ApplePaySession.STATUS_SUCCESS)
              } else {
                session.completePayment(ApplePaySession.STATUS_FAILURE)
              }
            } else {
              session.completePayment(ApplePaySession.STATUS_FAILURE)
            }
          })
          .catch((e) => {
            if (process.env.REACT_APP_SENTRY_URL) {
              Sentry.captureException(e, {
                tags: { section: 'APPLE PAY' },
              })
            }
            session.completePayment(ApplePaySession.STATUS_FAILURE)
          })
      }
    } catch (e) {
      setPaymentError('somethingWentWrong')
      console.log(e)
    }
  }

  return applePay
}
