import Api from './index';
import useFetchApi from '../hooks/useFetch';
import { useEffect } from "react";
import { updateCurrencies } from "../store/actions/appData";
import { currencyMapper } from "../helpers/currency";
import { useDispatch } from "react-redux";

export function useCurrencies() {
  const dispatch = useDispatch()

  const fetchCurrencies = useFetchApi(
    async (...data: any) => Api.post('/settings/order/currency', ...data),
    { data: [] },
    undefined,
    true
  );

  const [{ data: currencies }, currenciesLoading] = fetchCurrencies

  useEffect(() => {
    dispatch(updateCurrencies({
      data: currencies,
      loading: currenciesLoading,
      map: currencyMapper(currencies)
    }))
  }, [currencies, currenciesLoading]);

  return fetchCurrencies
}
