import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import thunk from 'redux-thunk'

import common from './reducers/common'
import account from './reducers/account'
import appData from './reducers/appData'
import routing from './reducers/routing'
import { WIIDGET_ENV } from '@/helpers/constants'

const rootReducer = combineReducers({
  appData,
  common,
  account,
  routing,
})

export type RootState = ReturnType<typeof rootReducer>

export default function configureStore() {
  //@ts-ignore
  const composeEnhancers = WIIDGET_ENV === 'dev' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
}
