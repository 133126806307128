import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {Document, Page} from 'react-pdf';
import Typography from '@material-ui/core/Typography';
import {Upload} from '../../../../components/Form';
import {IdPhotoType, PhotoTypes} from '../VerificationForm';
import useGlobalStyles from '../../../../hooks/useGlobalStyles';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Camera from './Camera';
import ServerMessage from '../../../../components/UI/ServerMessage';
import PassportNotExpiredIcon from '../icons/passportNotExpired.svg';
import PassportNotCropedIcon from '../icons/passportNotCroped.svg';
import PassportReadableIcon from '../icons/passportReadable.svg';
import IdNotExpiredIcon from '../icons/idNotExpired.svg';
import IdNotCropedIcon from '../icons/idNotCropped.svg';
import IdReadableIcon from '../icons/idReadable.svg';
import IdBackNotExpiredIcon from '../icons/idBackNotExpired.svg';
import IdBackNotCropedIcon from '../icons/idBackNotCropped.svg';
import IdBackReadableIcon from '../icons/idBackReadable.svg';
import SelfiePassportNoHatIcon from '../icons/selfiePassportNoHat.svg';
import SelfiePassportNotCroppedIcon from '../icons/selfiePassportNotCropped.svg';
import SelfieIdNoHatIcon from '../icons/selfieIdNoHat.svg';
import SelfieIdNotCroppedIcon from '../icons/selfieIdNotCropped.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.profile': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div': {
        width: 463
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        '& > div': {
          width: '100%'
        }
      }
    }
  },
  makePhoto: {
    margin: '28px -40px 0',
    padding: '28px 40px 0',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    position: 'relative',
    '& .or': {
      background: '#fff',
      textAlign: 'center',
      color: 'rgba(0,0,0,0.12)',
      width: 44,
      position: 'absolute',
      top: -10,
      left: '50%',
      marginLeft: -22
    }
  },
  imageWrapper: {
    position: 'relative',
    marginBottom: theme.spacing(6)
  },
  image: {
    width: '100%',
    borderRadius: 8
  },
  deleteImage: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    '&:hover': {
      opacity: 1
    },
    '& .delBtn': {
      backgroundColor: theme.palette.error.main,
      color: '#fff'
    }
  },
  correction: {
    color: theme.palette.warning.main
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
    '& .descItem': {
      paddingTop: 85,
      width: 150,
      marginRight: theme.spacing(2),
      background: 'no-repeat center top',
      [theme.breakpoints.down('xs')]: {
        width: 130,
        backgroundSize: 'contain'
      },
      '&:last-child': {
        marginRight: 0
      },
      '&.id-passportNotExpired': {
        backgroundImage: `url("${PassportNotExpiredIcon}")`
      },
      '&.id-passportNotCropped': {
        backgroundImage: `url("${PassportNotCropedIcon}")`
      },
      '&.id-passportReadable': {
        backgroundImage: `url("${PassportReadableIcon}")`
      },
      '&.backside-idNotExpired, &.backside-driverLicenseNotExpired': {
        backgroundImage: `url("${IdBackNotExpiredIcon}")`
      },
      '&.backside-idNotCropped, &.backside-driverLicenseNotCropped': {
        backgroundImage: `url("${IdBackNotCropedIcon}")`
      },
      '&.backside-idReadable, &.backside-driverLicenseReadable': {
        backgroundImage: `url("${IdBackReadableIcon}")`
      },
      '&.id-idNotExpired, &.id-driverLicenseNotExpired': {
        backgroundImage: `url("${IdNotExpiredIcon}")`
      },
      '&.id-idNotCropped, &.id-driverLicenseNotCropped': {
        backgroundImage: `url("${IdNotCropedIcon}")`
      },
      '&.id-idReadable, &.id-driverLicenseReadable': {
        backgroundImage: `url("${IdReadableIcon}")`
      },
      '&.selfie-passportNotCropped': {
        backgroundImage: `url("${SelfiePassportNotCroppedIcon}")`
      },
      '&.selfie-passportNoHat': {
        backgroundImage: `url("${SelfiePassportNoHatIcon}")`
      },
      '&.selfie-idNotCropped, &.selfie-driverLicenseNotCropped': {
        backgroundImage: `url("${SelfieIdNotCroppedIcon}")`
      },
      '&.selfie-idNoHat, &.selfie-driverLicenseNoHat': {
        backgroundImage: `url("${SelfieIdNoHatIcon}")`
      },
    }
  }
}));

interface IProps {
  title: any;
  description?: any;
  onLoad: any;
  type: PhotoTypes;
  stepBack?: any;
  photo: string;
  save?: any;
  correction?: string;
  template?: 'profile';
  error?: string;
  idPhotoType?: IdPhotoType;
}

export default function VerificationDocument({
  title,
  description,
  type,
  onLoad,
  stepBack,
  photo,
  save,
  correction,
  template,
  error,
  idPhotoType
}: IProps) {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const [camera, setCamera] = useState(false);
  const [docError, setDocError] = useState(false);

  function getStepNumber() {
    switch (type) {
      case PhotoTypes.ID:
        return 1;
      case PhotoTypes.Backside:
        return 2;
      case PhotoTypes.Selfie:
        return idPhotoType === IdPhotoType.Passport ? 2 : 3;
    }
  }

  return (
    <div>
      <Camera open={camera} onClose={setCamera} type={type} onShoot={onLoad} />
      <div className={clsx(classes.root, template)}>
        <div>
          <div className={globalClasses.title}>
            {stepBack && (
              <IconButton onClick={stepBack} className="stepBack">
                <ArrowRightAltIcon />
              </IconButton>
            )}
            <Typography variant="h4" component="span">{title}</Typography>
            {stepBack && (
              <span className="subtitle">&nbsp;&nbsp;{getStepNumber()}/{idPhotoType === IdPhotoType.Passport ? 2 : 3}</span>
            )}
          </div>
          {correction && (
            <Typography variant="body2" className={classes.correction} component="div">
              {t('Correction')}: {correction}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body2"
              component="div"
              className={globalClasses.subtitle}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {type !== PhotoTypes.Residence &&
            (
              <div>
              {type === PhotoTypes.Selfie
                ? (
                  <div className={classes.description}>
                    <div className={`descItem ${type}-${idPhotoType}NoHat`}>
                      <Typography variant="body2" component="div" align="center" style={{ whiteSpace: 'nowrap' }}>
                        {t('No hats or sunglasses')}
                      </Typography>
                    </div>
                    <div className={`descItem ${type}-${idPhotoType}NotCropped`}>
                      <Typography variant="body2" component="div" align="center">{t('Not cropped')}</Typography>
                    </div>
                  </div>
                ) : (
                  <div className={classes.description}>
                    {type !== PhotoTypes.Backside && (
                      <div className={`descItem ${type}-${idPhotoType}NotExpired`}>
                        <Typography variant="body2" component="div" align="center">{t('Not expired')}</Typography>
                      </div>
                    )}
                    <div className={`descItem ${type}-${idPhotoType}NotCropped`}>
                      <Typography variant="body2" component="div" align="center">{t('Not cropped')}</Typography>
                    </div>
                    <div className={`descItem ${type}-${idPhotoType}Readable`}>
                      <Typography variant="body2" component="div" align="center">{t('Readable')}</Typography>
                    </div>
                  </div>
              )}
              </div>
            )
          }
        </div>
        <div>
          <div>
            {docError && <ServerMessage type="error" text="kycDocumentWrongFormat" />}
            {!docError && photo.length > 0
              ? (
                <div className={classes.imageWrapper}>
                  {photo.includes('application/pdf')
                    ? (
                      <Document file={photo} className={classes.image} onLoadError={() => setDocError(true)}>
                        <Page pageNumber={1} width={280} />
                      </Document>
                    ) : <img src={photo} alt="image" className={classes.image} />}
                  <div className={classes.deleteImage}>
                    <IconButton onClick={() => onLoad('')} size="medium" className="delBtn">
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <Upload
                  type={type}
                  idType={idPhotoType}
                  onLoad={(v) => {
                    if (v.data !== null) {
                      onLoad(v.data);
                      setDocError(false);
                    }
                  }}
                  input={{
                    accept: 'image/*, application/pdf',
                    multiple: false,
                    name: type
                  }}
                />
              )}
          </div>
          {(docError || !photo.length)
            ? (
              <div className={template !== 'profile' ? classes.makePhoto : undefined}>
                {template !== 'profile' && <Typography variant="body2" className="or">{t('Or')}</Typography>}
                <div>
                  <Button
                    onClick={() => setCamera(true)}
                    variant={template === 'profile' ? 'text' : 'contained'}
                    type="button"
                    className={globalClasses.button}
                    color="primary"
                    startIcon={<PhotoCamera />}
                  >
                    {t('Make photo')}
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                {error && error.length > 0 && <ServerMessage type="error" text={error} />}
                {save && (
                  <Button
                    className={globalClasses.button}
                    variant="contained"
                    color="primary"
                    onClick={save}
                  >
                    {t(type === PhotoTypes.Selfie ? 'Finish verification' : 'Save and proceed')}
                  </Button>
                )}
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
