import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { PrivateApi, usePrivateFetchApi } from '../index';
import { updateCurrencies } from "../../store/actions/appData";
import { currencyMapper } from "../../helpers/currency";

export function useCurrencies() {
  const dispatch = useDispatch()

  const privateFetchCurrency = usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/settings/currency', ...data),
    { data: [] }
  );

  const [{ data: currencies }, currenciesLoading] = privateFetchCurrency

  useEffect(() => {
    dispatch(updateCurrencies({
      data: currencies,
      loading: currenciesLoading,
      map: currencyMapper(currencies)
    }))
  }, [currencies, currenciesLoading])

  return privateFetchCurrency
}
