import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APMFlow, IAPM } from '../../api/settings';
import useFormStyles from '../../views/Exchange/forms/useFormStyles';
import Loader from '../Loader';
import { getSupportEmail } from '@/helpers/mirrorHelper';
import { MIRROR } from '@/helpers/constants';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '600px',
    border: 'none'
  }
})

interface IProps {
  paymentData: any;
  classes?: any;
  apm?: IAPM;
}

export default function PaymentFrame({ paymentData, classes, apm }: IProps) {
  const formRef = useRef(null);
  const localClasses = useStyles();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const formClasses = useFormStyles();

  useEffect(() => {
    if (paymentData?.id) {
      window.localStorage.setItem('paymentId', paymentData.id)
    }
  }, [paymentData?.id])

  useEffect(() => {
    if (apm?.flow === APMFlow.REDIRECT) {
      window.location.assign(paymentData.url);
    }
  }, [paymentData.url, apm?.flow])

  useEffect(() => {
    if (formRef.current) {
      // @ts-ignore
      formRef.current.submit();
    }
  }, [formRef.current])

  if (!paymentData.id) {
    return <Loader loading height={600} />;
  }

  if (!paymentData.url) {
    return (
      <Typography variant="body1" align="center">
        <p>{t('providerUnavailableMessage')}</p>
        <Link className={clsx(formClasses.send, 'wide')} component={Button} href={getSupportEmail(MIRROR)}>
          {t('Inform administrator')}
        </Link>
      </Typography>
    )
  }

  const paymentUrlParts = paymentData.url.split(' ');
  const paymentRequestType = ['GET', 'POST'].includes(paymentUrlParts[0]) ? paymentUrlParts[0] : 'GET';
  const paymentUrl = paymentUrlParts[paymentUrlParts.length - 1];
  let paymentRequest: any = {};
  if (paymentRequestType === 'POST') {
    paymentRequest = queryString.parse(paymentUrl.split('?')[1]);
  }

  return (
    <div>
      <Loader
        loading={loading}
        height={apm?.flow === APMFlow.REDIRECT ? 0 : 660}
      />
      {apm?.flow !== APMFlow.REDIRECT && (
        <div style={{ display: loading ? 'none' : 'block' }}>
          {paymentRequestType === 'GET' ? (
            <iframe
              id="payment"
              src={paymentUrl}
              title="Card form"
              className={classes ? classes.frame : localClasses.root}
              onLoad={() => setLoading(false)}
            />
          ) : (
            <form action={paymentUrl.split('?')[0]} method="POST" ref={formRef}>
              {Object.keys(paymentRequest).map((k: string) => (
                <input type="hidden" name={k} value={paymentRequest[k]} key={k} />
              ))}
            </form>
          )}
        </div>
      )}
    </div>
  )
}
