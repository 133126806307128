import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

export default function useQuery(initialValues: any) {
  const { location } = window;

  const query = queryString.parse(location.search) as any;
  const history = useHistory();

  const setQuery = Object.keys(initialValues).reduce((result: any, key: string) => {
    // eslint-disable-next-line
    result[key] = function (val: string) {
      if (val) {
        query[key] = val;
      } else {
        delete query[key];
      }
      if (key !== 'page') {
        delete query.page;
      }
      const q = new URLSearchParams(query);
      history.push(`${location.pathname}?${q}`);
    };

    return result;
  }, {});

  return [query, setQuery];
}