export function getBrowserDataFor(name?: string) {
  let response = {}
  switch (name) {
    case 'pay':
      response = getBrowserDataForPay()
      break
    default:
      return response
  }

  return response
}

function getBrowserDataForPay() {
  return {
    acceptHeader: 'text/html,application/xhtml+xml',
    userAgent: window.navigator.userAgent,
    colorDepth: window.screen.colorDepth,
    language: window.navigator.language,
    timeZone: '5',
    javaEnabled: navigator.javaEnabled().toString(),
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  }
}

export const isSafariBrowser = () => {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1
  )
}
