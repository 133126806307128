import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '-32px -40px 0',
    display: 'flex',
    alignItems: 'flex-start',
    height: 46,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      margin: '-32px -20px 0'
    }
  },
  item: {
    height: 46,
    width: 220,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    borderBottom: '2px solid transparent',
    transition: 'all 300ms ease 0s',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    '&.active': {
      color: theme.palette.primary.main,
      borderBottomColor: theme.palette.primary.main
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
      letterSpacing: 1.25,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: 9,
        letterSpacing: 0
      }
    }
  }
}));

interface IProps {
  open: boolean;
  closeMenu: any;
  hasCards: boolean;
}

interface IMenuItem {
  label: string;
  link: string;
  matching?: string[];
}

export default function MainMenu({ open, closeMenu, hasCards }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useSelector((state: any) => state.account);

  // ZHPW-1159 TMP hide balance page for b2c
  let pages: IMenuItem[] = [
    { label: t('Profile info'), link: '/account/info', matching: ['/account/kyc'] },
    { label: t('Personal orders'), link: '/account/orders', matching: ['/account/orders/create'] },
  ];

  if (user.authorities && user.authorities.includes('ROLE_MERCHANT')) {
    pages = [
      {
        label: t('Profile info'),
        link: '/account/info',
        matching: ['/account/kyc'],
    }]
    if (user.ccwEnabled) {
      pages.push({ label: t('Balance'), link: '/account/wallets' })
    }
    pages.push(
      { label: t('Clients orders'), link: '/account/merchant-orders' }
      // WW-408 Hide personal orders and referral program tabs
      /* { label: t('Personal orders'), link: '/account/orders', matching: ['/account/orders/create'] },
    { label: t('Referral program'), link: '/account/referral' } */
    )
    if (user.ccwEnabled) {
      pages.push({
        label: t('settlements.title'),
        link: '/account/settlements',
      })
    }
  }

  // if (hasCards) {
  //   pages.push({ label: t('Saved cards'), link: '/account/cards' });
  // }

  return (
    <nav className={classes.root}>
      {pages.map((p) => (p.link.length > 0) && (
        <ListItem
          button
          component={NavLink}
          to={p.link}
          onClick={closeMenu}
          className={clsx(classes.item, { 'active': p.link === pathname || (p.matching && p.matching.includes(pathname)) })}
          key={p.link}
        >
          <ListItemText primary={p.label} />
        </ListItem>
      ))}
    </nav>
  );
}