import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useKycDocumentsRequest } from '../../api/private/kyc';
import { KycStatus } from '../../api/kyc';
import Employment from './Employment';
import Income from './Income';
import ProofOfFundsFinish from './Finished';
import ServerMessage from '../../components/UI/ServerMessage';
import useGlobalStyles from '../../hooks/useGlobalStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'flex-start',
    '& .pof-formTitle': {
      marginBottom: theme.spacing(2)
    },
    '& .pof-button': {
      marginTop: theme.spacing(6)
    }
  },
}));

export enum EmploymentType {
  EMPLOYED = 'EMPLOYED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  UNEMPLOYED = 'UNEMPLOYED'
}

interface IProps {
  setCustomer(customer: any): void;
  customer: any;
  setStep(step: string): void;
  paymentResult?: string;
}

export default function ProofOfFunds({ setCustomer, customer, setStep, paymentResult }: IProps) {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const [employee, setEmployee] = useState<EmploymentType | undefined>();
  // FIXME TMP disable employment type due backend improvements
  const [employmentDetails, setEmploymentDetails] = useState<any>(true);
  const [finish, setFinish] = useState(false);
  const [error, setError] = useState(false);
  const [uploadDocs, docsUploading] = useKycDocumentsRequest((res: any) => {
    if (res.success) {
      setFinish(true);
      setCustomer({ ...customer, kycStatus: KycStatus.PENDING });
      setStep(paymentResult || 'success');
    } else {
      setError(true);
    }
  })

  if (finish) {
    return <ProofOfFundsFinish className={classes.root}/>;
  }

  return (
    <div className={classes.root}>
      <div className={globalClasses.title}>
        <Typography variant="h4" component="span">{t('Proof of funds')}</Typography>
        {/* FIXME TMP disable employment type due backend improvements */}
        {/*<span className="subtitle">&nbsp;&nbsp;{employmentDetails ? 2 : 1}/2</span>*/}
      </div>
      {error && <ServerMessage type="error" text={t('somethingWentWrong')} />}
      {employmentDetails
        ? <Income onSave={uploadDocs} saving={docsUploading} />
        : <Employment type={employee} setType={setEmployee} setData={setEmploymentDetails} />
      }
    </div>
  )
}
