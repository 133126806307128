import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form } from 'react-final-form'
import { OnChange, OnBlur } from 'react-final-form-listeners'
import { Trans } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import Link from '@material-ui/core/Link'
import { InputAdornment } from '@material-ui/core'
import MetaMaskOnboarding from '@metamask/onboarding'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'

import { IExchangeFormProps } from '../../Exchange'
import useFormStyles from '../../Exchange/forms/useFormStyles'
import { Checkbox, Currency, Input } from '../../../components/Form'
import ConfirmationCodeForm from '../../Exchange/forms/CodeForm'
import FormChangeConfirm from '../../Exchange/forms/auth/FormChangeConfirm'
import KycRejected from '../../Exchange/forms/KycRejected'
import {
  composeValidators,
  isEmail,
  isWalletAddress,
  required,
  isNewXRPAddress,
} from '../../../validators'
import { useAcceptOrder, OrderStatus } from '../../../api/order'
import { useCodeResend, useCustomerWallet } from '../../../api/customer'
import { OrderExtendedType } from '../../../api/private/orders'
import { useConvert, useInit } from '../../../api/merchant/order'
import Loader from '../../../components/Loader'

import useGlobalStyles from '../../../hooks/useGlobalStyles'
import ServerMessage, {
  TServerMessageType,
} from '../../../components/UI/ServerMessage'
import TXLimitError from '../../../components/TxLimitsError'
import ConfirmModal from '../../../components/ConfirmModal'
import Whisper from '../../../components/Whisper'
import NFTPreview from '../../../components/NFTPreview'
import useEmailSuggest from '../../../hooks/useEmailSuggest'
import {
  setExchangeRate,
  setOrderDataRdx,
} from '../../../store/actions/appData'
import globalConfig from '../../../config/global.json'
import defaults from '../../../config/defaults.json'
import { INTERNAL_LINK_TARGET_BLANK } from '../../../helpers/internalTarget'
import { ICurrency } from '../../../types/currency'
import { currencyOptionMapper } from '../../../helpers/currency'
import { splitAddress } from '../../../helpers/address'
import { useLedger } from '../../../hooks/wallets/ledger'
import { getNftHexId } from '../../../helpers/nftHelpers'
import captchaRequest from '@/helpers/captcha'
import { useUDomain } from '@/hooks/useUDomain'
import UDIcon from '@/icons/ud.svg'
import ResidenceRestricted from '@/views/Account/Profile/ResidenceRestricted'
import { getPrivacyPolicyLink, getTermsOfUseLink } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

interface IProps extends IExchangeFormProps {
  currencies: any[]
  currenciesLoading: boolean
  setUnavailable: any
  fromOrder?: boolean
  referralId?: string
  fromCurrency?: string
  setFromCurrency?: any
  toCurrency?: string
  setToCurrency?: any
  externalId?: string
  amount?: string
  paymentAmount?: string
  lockCrypto?: boolean
  lockEmail?: boolean
  formChanging: boolean
  setFormChanging(v: boolean): void
  extTimestamp?: number
  extPublicKey?: string
  extSignature?: string
  extAlg?: string
  nftId?: string
  apm?: string
}

const unavailableErrors = ['SERVICE_UNAVAILABLE_ON_THIS_SITE', 'UNAUTHORIZED']

export default function AuthForm({
  address,
  setStep,
  setEmail,
  email,
  order,
  t,
  setCustomer,
  customer,
  setAddress,
  ui,
  language,
  currencies,
  currenciesLoading,
  sid,
  setOrder,
  setUnavailable,
  fromOrder,
  setCreateNewOrder,
  referralId,
  fromCurrency,
  setFromCurrency,
  toCurrency,
  setToCurrency,
  externalId,
  amount,
  paymentAmount,
  lockCrypto,
  lockEmail,
  formChanging,
  setFormChanging,
  setProcessingError,
  extTimestamp,
  extPublicKey,
  extSignature,
  extAlg,
  nftId,
  apm,
}: IProps) {
  const {
    form: {
      authorization: { fields },
      buttonName,
      buttonColor,
      borderRadius,
    },
    global: {
      palette: { border },
    },
  } = ui
  let setAmount: any
  let setPaymentAmount: any
  let setDisableEmailChanging = (v1?: any, v2?: any, v3?: any) => {
    return void 0
  }
  const onboardingRef = useRef<MetaMaskOnboarding>(null)

  const history = useHistory()
  const formClasses = useFormStyles({ buttonColor, borderRadius, border })
  const globalClasses = useGlobalStyles()
  const [confirm, setConfirm] = useState(false)
  const [converting, setConverting] = useState(false)
  const [lockAddress, setLockAddress] = useState(!fromOrder && !!order.address)
  const [confirmAddressChange, setConfirmAddressChange] = useState(false)
  const [emailSuggestion, checkEmail] = useEmailSuggest()
  const [customerVerified, setCustomerVerified] = useState(false)
  const [saveCustomerWallet, setSaveCustomerWallet] = useState(false)
  const [hideTerms, setHideTerms] = useState(false)

  const [dataForDelayedInit, setDataForDelayedInit] = useState<any>(null)
  const [nftUnavailable, setNftUnavailable] = useState(Boolean(nftId))
  const [nftMaxAmount, setNftMaxAmount] = useState<number | undefined>(
    undefined
  )

  const [initial, setInitial] = useState<any>(() => {
    const initialData: Record<string, any> = {
      ...order,
      email: email || order.acceptedByEmail,
      terms: false,
      addressAccept: lockAddress,
      address: /*fromOrder ? '' :*/ order.address,
      saveCustomerWallet,
      disableEmailChanging:
        !!order.acceptedByEmail ||
        formChanging ||
        lockEmail ||
        customerVerified,
    }

    const [addressPart, memoTagPart] = splitAddress(order.address)
    if (memoTagPart) {
      initialData.address = addressPart
      initialData.memoTag = memoTagPart
    }

    return initialData
  })

  const dispatch = useDispatch()

  const calculateExchangeRate = (amount: number, paymentAmount: number) => {
    if (amount === 0 || paymentAmount === 0) {
      dispatch(setExchangeRate(0))
    } else {
      dispatch(setExchangeRate(Number((paymentAmount / amount).toFixed(2))))
    }
  }

  const [convertFiat] = useConvert(sid, (res: any, ...rest: any[]) => {
    if (res.success && typeof setPaymentAmount === 'function') {
      setPaymentAmount(res.data)
    } else if (!res.success && unavailableErrors.includes(res.error.type)) {
      setUnavailable(true)
    }
  })

  const [convertCrypto] = useConvert(sid, (res: any) => {
    if (res.success && typeof setAmount === 'function') {
      setAmount(res.data)
    } else if (!res.success && unavailableErrors.includes(res.error.type)) {
      setUnavailable(true)
    }
  })
  const [initialConvertCrypto] = useConvert(sid, (res: any) => {
    if (res.success && typeof setAmount === 'function') {
      setInitial({ ...initial, amount: res.data })
    } else if (!res.success && unavailableErrors.includes(res.error.type)) {
      setUnavailable(true)
    }
    setConverting(false)
  })
  const [currencyUnavailable, setCurrencyUnavailable] = useState(false)
  const [walletCreds, setWalletCreds] = useState({
    timestamp: 0,
    publicKey: '',
    signature: '',
    alg: 'ED25519',
  })
  const cryptoAmountTimeout = useRef<any>(null)
  const fiatAmountTimeout = useRef<any>(null)
  const convertRequestTimeout = useRef<any>(null)

  const {
    accept,
    accepting,
    limitError,
    errorType: serverErrorType,
    error: serverError,
    kycRejected,
    countryNotSupported,
  } = useAcceptOrder({
    setOrder: (o) => {
      dispatch(setOrderDataRdx(o))
      setOrder?.(o)
    },
    setCustomer: (c) => (setCustomer ? setCustomer(c) : null),
    setConfirm,
    order,
    customer,
    setProcessingError,
    navigateOnSuccess: customerVerified
      ? (status, id) =>
        history.push(
          `/order/${id}/${status === OrderStatus.VERIFICATION ? 'verification' : 'card'
          }${window.location.search}`
        )
      : undefined,
  })

  const [init, initing] = useInit(async (res: any) => {
    if (res.success) {
      if (setOrder) setOrder(res.data)

      captchaRequest('/api/widget/v1/order/accept', accept, {
        email: res.data.acceptedByEmail,
        id: res.data.id,
        currency: res.data.paymentCurrency,
        ...walletCreds,
      })
    }
  })

  const [checkCustomer, checkCustomerLoading] = useCustomerWallet(
    (res: any) => {
      if (res.success) {
        setCustomerVerified(true)
        if (res.data.termsAccepted) {
          setHideTerms(true)
        }
        if (!email && res.data.email) {
          setEmail(res.data.email)
          setDisableEmailChanging(true)
        }
      }
    }
  )

  const [resendCode] = useCodeResend(() => {
    setConfirm(true)
  })

  const {
    isValidUDomain,
    checkIsValidUDomain,
    resolveAddressFromUD,
    uDomainResolveError,
    uDomainSupportError,
  } = useUDomain()

  // resolve address from UD
  useEffect(() => {
    if (isValidUDomain && toCurrency) {
      resolveAddressFromUD(toCurrency)
    }
  }, [isValidUDomain, toCurrency])

  function getCryptoAmount(data: any, initial = false) {
    clearTimeout(cryptoAmountTimeout.current)
    clearTimeout(fiatAmountTimeout.current)
    clearTimeout(convertRequestTimeout.current)
    if (initial) {
      initialConvertCrypto(data)
    } else {
      convertCrypto(data)
    }
    cryptoAmountTimeout.current = setTimeout(() => getCryptoAmount(data), 30000)
  }

  function getFiatAmount(data: any) {
    if (!fiatAmountTimeout.current) {
      setTimeout(() => {
        getFiatAmount(data)
      }, 100)
    }
    clearTimeout(fiatAmountTimeout.current)
    clearTimeout(cryptoAmountTimeout.current)
    clearTimeout(convertRequestTimeout.current)
    convertFiat(data)
    fiatAmountTimeout.current = setTimeout(() => getFiatAmount(data), 30000)
  }

  const [isOnLedger, ledgerCurrencies, requestLedgerAccount] = useLedger()

  useEffect(() => {
    walletCreds.publicKey &&
      walletCreds.signature &&
      walletCreds.timestamp &&
      checkCustomer({ ...walletCreds })
  }, [walletCreds])

  useEffect(() => {
    let timestampReq = 0
    let publicKeyReq = ''
    let signatureReq = ''

    if (extTimestamp && extPublicKey && extSignature) {
      timestampReq = extTimestamp
      publicKeyReq = extPublicKey && extPublicKey.replace(/ /g, '') //Hack for Android React Native
      signatureReq = extSignature && extSignature.replace(/ /g, '') //Hack for Android React Native
    }

    setWalletCreds({
      timestamp: timestampReq,
      publicKey: publicKeyReq,
      signature: signatureReq,
      alg: extAlg || 'ED25519',
    })
  }, [extTimestamp, extPublicKey, extSignature, extAlg])

  useEffect(() => {
    if (!currenciesLoading && currencies.length) {
      let fiatCurrency = currencies.find(
        (c: any) => c.currency === defaults.currency.from
      )
      if (fromCurrency) {
        fiatCurrency =
          currencies.find(
            (c: any) =>
              c.currency === fromCurrency && c.enabled && c.type === 'FIAT'
          ) || fiatCurrency
      }

      let cryptoCurrency = currencies.find((c: any) => {
        return c.currency === (toCurrency ?? defaults.currency.to) && c.enabled
      })

      let toCurrencyParsed = toCurrency

      if (!cryptoCurrency) {
        cryptoCurrency = currencies.find(
          (c: ICurrency) => c.type === 'CRYPTO' && c.enabled
        )
        toCurrencyParsed = cryptoCurrency?.currency
        if (setToCurrency && toCurrencyParsed) {
          setToCurrency(toCurrencyParsed)
        }
      }

      if (toCurrencyParsed) {
        cryptoCurrency =
          currencies.find(
            (c: any) =>
              c.currency === toCurrencyParsed &&
              c.enabled &&
              c.type === 'CRYPTO'
          ) || cryptoCurrency
      }

      if (
        (fromCurrency && fiatCurrency.currency !== fromCurrency) ||
        (toCurrencyParsed && cryptoCurrency.currency !== toCurrencyParsed)
      ) {
        setCurrencyUnavailable(true)
      }

      if (!fromOrder) {
        const initvals = {
          ...initial,
          currency: cryptoCurrency.currency,
          paymentCurrency: fiatCurrency.currency,
        }
        if (paymentAmount && !amount) {
          initvals.paymentAmount = parseFloat(paymentAmount)
        } else {
          initvals.amount = amount
            ? parseFloat(amount)
            : cryptoCurrency.depositMin
          if (typeof setAmount === 'function') {
            setAmount(initvals.amount)
          }
        }
        setInitial({ ...initvals })
        if (initvals.paymentAmount) {
          getCryptoAmount({
            fromCurrency: initvals.paymentCurrency,
            toCurrency: initvals.currency,
            paymentAmount: initvals.paymentAmount,
            type: 'DEPOSIT',
          })
        } else {
          getFiatAmount({
            fromCurrency: initvals.paymentCurrency,
            toCurrency: initvals.currency,
            amount: initvals.amount,
            type: 'DEPOSIT',
          })
        }
      }
    }
  }, [currencies, currenciesLoading, fromOrder])

  // convert amount if create from existing order
  useEffect(() => {
    if (fromOrder && order.id) {
      setConverting(true)
      getCryptoAmount(
        {
          fromCurrency: order.paymentCurrency,
          toCurrency: order.currency,
          paymentAmount: order.paymentAmount,
          type: 'DEPOSIT',
        },
        true
      )
    }
  }, [fromOrder, order])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    onboardingRef.current = new MetaMaskOnboarding()

    return () => {
      clearTimeout(cryptoAmountTimeout.current)
      clearTimeout(fiatAmountTimeout.current)
      clearTimeout(convertRequestTimeout.current)
    }
  }, [])

  if (kycRejected) {
    return <KycRejected classes={formClasses} t={t} order={order} type="kyc" />
  }

  if (countryNotSupported) {
    return <ResidenceRestricted country={countryNotSupported} order={order} />
  }

  if (confirm) {
    return (
      <ConfirmationCodeForm
        order={order}
        email={email || order.acceptedByEmail || ''}
        t={t}
        setCustomer={setCustomer}
        setStep={setStep}
        customer={customer}
        formClasses={formClasses}
        saveCustomerWallet={saveCustomerWallet}
        extTimestamp={walletCreds.timestamp}
        extPublicKey={walletCreds.publicKey}
        extSignature={walletCreds.signature}
        extAlg={walletCreds.alg}
      />
    )
  }

  const onSubmit = (values: any) => {
    if (order.status !== 'NEW') {
      return captchaRequest('/api/widget/v1/customer/code', resendCode, {
        email,
        id: order.id,
      })
    }
    const currency = (
      values.currency
        ? currencies.find((c) => c.currency === values.currency)
        : {}
    ) as ICurrency
    setEmail(values.email)
    setSaveCustomerWallet(Boolean(values.saveCustomerWallet))

    const dataForInit: Record<string, any> = {
      email: values.email,
      type: OrderExtendedType.FIAT_TO_CRYPTO,
      currency: values.currency,
      paymentCurrency: values.paymentCurrency,
      amount: values.amount,
      externalId: externalId || sid + '-' + Date.now(),
      address: values && values.address?.trim(),
      ref: referralId,
      apm,
    }

    if (nftId && currency.nftContract) {
      dataForInit.nft = {
        id: getNftHexId(nftId, true),
      }
    }

    if (
      currency.allowTag &&
      !values.memoTag &&
      !isNewXRPAddress(values.address)
    ) {
      setDataForDelayedInit(dataForInit)
      return
    }

    if (
      currency.allowTag &&
      values.memoTag &&
      !isNewXRPAddress(values.address)
    ) {
      const { address } = dataForInit
      dataForInit.address = `${address}:${values.memoTag}`
    }

    return captchaRequest(
      '/api/merchant/v1/order/init',
      init,
      dataForInit,
      undefined,
      {
        'X-AUTH-SID': sid,
        'X-AUTH-NONCE': Date.now(),
      }
    )
  }

  return (
    <div>
      <ConfirmModal
        isShown={Boolean(dataForDelayedInit)}
        onConfirm={() => {
          captchaRequest(
            '/api/merchant/v1/order/init',
            init,
            dataForDelayedInit,
            undefined,
            {
              'X-AUTH-SID': sid,
              'X-AUTH-NONCE': Date.now(),
            }
          )
        }}
        onCancel={() => {
          setDataForDelayedInit(null)
        }}
        title={t('memo.confirmTitle')}
        text={t('memo.confirmText')}
        caption={t('memo.confirmCaption')}
        confirmBtnText={t('Confirm')}
        returnBtnText={t('Return')}
      />
      <Form
        onSubmit={onSubmit}
        mutators={{
          setAmount: (args, state, tools) => {
            const newAmount = args[0]
            tools.changeValue(state, 'amount', () => newAmount)
            const { paymentAmount } = state.formState.values as any

            if (!newAmount || !paymentAmount) {
              calculateExchangeRate(0, paymentAmount)
            } else {
              calculateExchangeRate(newAmount, paymentAmount)
            }

            state.fields.amount?.blur()
          },
          setPaymentAmount: (args, state, tools) => {
            const newPaymentAmount = args[0]
            const { amount } = state.formState.values as any

            if (!amount || !newPaymentAmount) {
              calculateExchangeRate(0, newPaymentAmount)
            } else {
              calculateExchangeRate(amount, newPaymentAmount)
            }

            tools.changeValue(state, 'paymentAmount', () => newPaymentAmount)
            state.fields.paymentAmount?.blur()
          },
          setEmail: (args, state, { changeValue }) => {
            changeValue(state, 'email', () => args[0])
          },
          setAddress: (args, state, { changeValue }) => {
            setAddress(args.length ? args[0] : '')
            changeValue(state, 'address', () => args[0])
            state.fields.address?.blur()
          },
          setAddressAccept: (args, state, { changeValue }) => {
            changeValue(state, 'addressAccept', () => true)
          },
          setFromCurrencyForm: (args, state, { changeValue }) => {
            setFromCurrency(args.length ? args[0] : '')
            changeValue(state, 'fromCurrency', () => true)
          },
          setToCurrencyForm: (args, state, { changeValue }) => {
            setToCurrency(args.length ? args[0] : '')
            changeValue(state, 'toCurrency', () => true)
          },
          setDisableEmailChanging: (args, state, { changeValue }) => {
            changeValue(state, 'disableEmailChanging', () => args[0])
          },
        }}
        initialValues={initial}
        render={({ handleSubmit, submitting, values, form }) => {
          const currency = (
            values.currency
              ? currencies.find((c) => c.currency === values.currency)
              : {}
          ) as ICurrency
          const paymentCurrency = values.paymentCurrency
            ? currencies.find((c) => c.currency === values.paymentCurrency)
            : {}
          const setFromCurrencyForm = form.mutators.setFromCurrencyForm
          const setToCurrencyForm = form.mutators.setToCurrencyForm
          setAmount = form.mutators.setAmount
          setPaymentAmount = form.mutators.setPaymentAmount
          setEmail = form.mutators.setEmail
          // setAddress = form.mutators.setAddress
          setDisableEmailChanging = form.mutators.setDisableEmailChanging

          return (
            <Loader
              loading={
                checkCustomerLoading ||
                accepting ||
                initing ||
                currenciesLoading ||
                !paymentCurrency.currency ||
                !currency.currency
              }
            >
              {confirmAddressChange && (
                <FormChangeConfirm
                  order={order}
                  onConfirm={() => {
                    setLockAddress(false)
                    setConfirmAddressChange(false)
                  }}
                  onCancel={() => {
                    form.mutators.setAddressAccept(true)
                    setConfirmAddressChange(false)
                  }}
                />
              )}
              <form className={formClasses.root} onSubmit={handleSubmit}>
                {limitError.error.length > 0 ? (
                  <TXLimitError error={limitError} failUrl={order.failUrl} />
                ) : (
                  <div>
                    {serverError.length > 0 && (
                      <ServerMessage
                        type={serverErrorType as TServerMessageType}
                        text={serverError}
                      />
                    )}
                    {currencyUnavailable && (
                      <ServerMessage
                        type="alert"
                        text="currencyUnavailableMessage"
                        textOptions={{ currency: fromCurrency }}
                      />
                    )}
                    <div className={formClasses.row}>
                      <Currency
                        inputName="paymentAmount"
                        selectName="paymentCurrency"
                        currencies={currencies
                          .filter(
                            (c: ICurrency) =>
                              c.enabled &&
                              (order.type ===
                                OrderExtendedType.CRYPTO_TO_WALLET ||
                                c.type === 'FIAT')
                          )
                          .map(currencyOptionMapper)}
                        label={
                          fields &&
                          fields.paymentCurrency.title[language].length
                            ? fields.paymentCurrency.title[language]
                            : t('labels.Sell')
                        }
                        currenciesLoading={currenciesLoading}
                        inputEnabled={
                          !fromOrder && !formChanging && !converting
                        }
                        max={paymentCurrency?.depositMax}
                        locked={fromOrder || (formChanging && converting)}
                        className={
                          values.paymentAmount &&
                          values.paymentAmount.toString().length > 10
                            ? 'text-small'
                            : ''
                        }
                        onInput={(e: any) => {
                          if (
                            e.target.toString().includes('HTMLSelectElement')
                          ) {
                            return
                          }
                          const val = e.target.value
                          clearTimeout(convertRequestTimeout.current)
                          convertRequestTimeout.current = setTimeout(() => {
                            if (!val || parseFloat(val) === 0) {
                              return form.mutators.setAmount('')
                            }
                            getCryptoAmount({
                              fromCurrency: values.paymentCurrency,
                              toCurrency: values.currency,
                              paymentAmount: val,
                              type: 'DEPOSIT',
                            })
                          }, 800)
                        }}
                      />
                      <OnChange name="paymentCurrency">
                        {(currency: string) => {
                          setFromCurrencyForm(currency)
                          // getExchangeRate({
                          //   fromCurrency: currency,
                          //   toCurrency: values.currency,
                          //   amount: 1,
                          //   type: 'DEPOSIT',
                          // })
                          if (!values.amount) return
                          getFiatAmount({
                            fromCurrency: currency,
                            toCurrency: values.currency,
                            amount: values.amount,
                            type: 'DEPOSIT',
                          })
                        }}
                      </OnChange>
                      <Currency
                        inputName="amount"
                        selectName="currency"
                        currencies={currencies
                          .filter((c: ICurrency) => {
                            if (!nftId && c.nftContract) {
                              return false
                            }

                            return (
                              c.type === 'CRYPTO' &&
                              c.enabled &&
                              (!isOnLedger ||
                                c.currency === 'BTC' ||
                                ledgerCurrencies.find(
                                  (lc) =>
                                    lc.ticker === c.currency &&
                                    lc.family.toLowerCase() ===
                                      c.chain?.toLowerCase()
                                ))
                            )
                          })
                          .map(currencyOptionMapper)}
                        label={
                          fields && fields.currency.title[language].length
                            ? fields.currency.title[language]
                            : t('labels.Buy')
                        }
                        currenciesLoading={currenciesLoading}
                        min={currency?.depositMin}
                        max={nftMaxAmount || currency?.depositMax}
                        inputEnabled={
                          !fromOrder && !formChanging && !converting
                        }
                        locked={
                          lockCrypto ||
                          fromOrder ||
                          (formChanging && converting)
                        }
                        className={
                          values.amount && values.amount.toString().length > 10
                            ? 'text-small'
                            : ''
                        }
                        onInput={(e: any) => {
                          if (
                            e.target.toString().includes('HTMLSelectElement')
                          ) {
                            return
                          }
                          const val = e.target.value
                          clearTimeout(convertRequestTimeout.current)
                          convertRequestTimeout.current = setTimeout(() => {
                            if (!val || parseFloat(val) === 0) {
                              return form.mutators.setPaymentAmount('')
                            }
                            getFiatAmount({
                              fromCurrency: values.paymentCurrency,
                              toCurrency: values.currency,
                              amount: val,
                              type: 'DEPOSIT',
                            })
                          }, 800)
                        }}
                      />
                      <OnChange name="currency">
                        {(currency: string) => {
                          setToCurrencyForm(currency)
                          setTimeout(() => {
                            // getExchangeRate({
                            //   fromCurrency: values.paymentCurrency,
                            //   toCurrency: currency,
                            //   amount: 1,
                            //   type: 'DEPOSIT',
                            // })

                            const cryptoObject = currencies.find((curr) => {
                              return curr.currency === currency
                            })

                            const cryptoAmount =
                              cryptoObject?.depositMin ?? values.amount
                            setAmount(cryptoAmount)

                            getFiatAmount({
                              fromCurrency: values.paymentCurrency,
                              toCurrency: currency,
                              amount: cryptoAmount,
                              type: 'DEPOSIT',
                            })
                          })
                        }}
                      </OnChange>
                    </div>
                    <div>
                      {currency.nftContract && nftId && !lockAddress && (
                        <Button
                          className={formClasses.connectWalletBtn}
                          color="primary"
                          size="small"
                          onClick={() => {
                            const onboarding = onboardingRef.current
                            if (!onboarding) {
                              return
                            }

                            if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
                              onboarding.startOnboarding()
                              return
                            }

                            //@ts-ignore
                            window.ethereum
                              .request({ method: 'eth_requestAccounts' })
                              .then((accounts: string[]) => {
                                const [account] = accounts
                                form.mutators.setAddress(account)
                              })

                            onboarding.stopOnboarding()
                          }}
                        >
                          <AccountBalanceWalletIcon />
                          &nbsp;
                          {MetaMaskOnboarding.isMetaMaskInstalled()
                            ? t('labels.connectWallet')
                            : t('labels.createWallet')}
                        </Button>
                      )}

                      <Input
                        name="address"
                        type="text"
                        label={
                          fields && fields.walletAddress.title[language].length
                            ? fields.walletAddress.title[language]
                            : t('labels.Wallet address')
                        }
                        fullWidth
                        disabled={lockAddress || formChanging}
                        validate={composeValidators(required, (v: string) =>
                          isWalletAddress(
                            v,
                            currency.addressValidator as string
                          )
                        )}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={UDIcon} width={20} />
                            </InputAdornment>
                          ),
                        }}
                        errorText={uDomainSupportError || uDomainResolveError}
                      />
                      <OnChange name="address">
                        {(v: string) => {
                          checkIsValidUDomain(v)
                          setAddress(v)
                        }}
                      </OnChange>
                    </div>
                    {isOnLedger && (
                      <div
                        style={{
                          paddingBottom: 32,
                          marginTop: -24,
                          position: 'relative',
                          textAlign: 'right',
                        }}
                      >
                        <Link
                          color="primary"
                          style={{
                            textTransform: 'uppercase',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            requestLedgerAccount(
                              form.mutators.setAddress,
                              currency.chain?.toLowerCase()
                            )
                          }
                        >
                          {t('Request Ledger account')}
                        </Link>
                      </div>
                    )}
                    {currency?.allowTag && !isNewXRPAddress(values.address) && (
                      <div>
                        <Input
                          name="memoTag"
                          type="memoTag"
                          label={t('memo.label')}
                          fullWidth
                          // disabled={values.disableEmailChanging}
                          inputProps={{
                            spellCheck: false,
                          }}
                        />
                        <Whisper
                          text={t('memo.whatIsText')}
                          title={t('memo.whatIsLabel')}
                        >
                          {t('memo.whatIsLabel')}
                        </Whisper>
                      </div>
                    )}
                    <div style={{ position: 'relative' }}>
                      <Input
                        name="email"
                        type="email"
                        label={t('labels.Email')}
                        fullWidth
                        validate={composeValidators(required, isEmail)}
                        disabled={values.disableEmailChanging}
                        autoComplete="new-password"
                        inputProps={{
                          spellCheck: false,
                        }}
                        InputProps={{
                          endAdornment: values.disableEmailChanging && (
                            <InputAdornment position="end">
                              <div
                                className={formClasses.emailChangeBtn}
                                onClick={() => {
                                  setCustomerVerified(false)
                                  setDisableEmailChanging(false)
                                }}
                              >
                                {t('Change')}
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {!values.disableEmailChanging &&
                        emailSuggestion.length > 0 && (
                          <FormHelperText variant="filled">
                            {t('Do you mean')}{' '}
                            <Link
                              onClick={() =>
                                form.mutators.setEmail(emailSuggestion)
                              }
                              className={formClasses.link}
                            >
                              {emailSuggestion}
                            </Link>
                            ?
                          </FormHelperText>
                        )}
                      <OnChange name="email">
                        {() => {
                          if (globalConfig.useEmailSuggest) {
                            checkEmail(form.getFieldState('email'))
                          }
                        }}
                      </OnChange>
                      <OnBlur name="email">
                        {() => {
                          if (globalConfig.useEmailSuggest) {
                            checkEmail(form.getFieldState('email'))
                          }
                        }}
                      </OnBlur>
                    </div>

                    {nftId && currency.nftContract && (
                      <NFTPreview
                        id={nftId}
                        contract={currency.nftContract}
                        onLoad={(data) => {
                          const maxAmount = data.maxSupply - data.totalSupply

                          setNftMaxAmount(maxAmount)
                          setNftUnavailable(data.isSoldOut)
                          form.mutators.setAmount(
                            Math.min(values.amount, maxAmount)
                          )
                        }}
                      />
                    )}

                    <div>
                      {(fromOrder || !!order.address) && (
                        <div className={globalClasses.checkBox}>
                          <Checkbox
                            label={
                              <Typography variant="body2" component="span">
                                {fromOrder ? (
                                  <>{t('agreeToSendToWalletAddress')} </>
                                ) : (
                                  t('agreeToSendToWallet')
                                )}
                              </Typography>
                            }
                            name="addressAccept"
                            className={globalClasses.cbAddressAccept}
                          />
                          <OnChange name="addressAccept">
                            {(val: boolean) => {
                              if (fromOrder) {
                                setFormChanging(true)
                                setTimeout(() => {
                                  setFormChanging(false)
                                  setCreateNewOrder(!val)
                                }, 500)
                              } else if (!val) {
                                setConfirmAddressChange(true)
                              } else {
                                setLockAddress(true)
                              }
                            }}
                          </OnChange>
                        </div>
                      )}
                      {!hideTerms && (
                        <div className={globalClasses.checkBox}>
                          <Checkbox
                            label={
                              <Typography
                                variant="body2"
                                component="span"
                                className={globalClasses.checkBoxText}
                              >
                                <Trans i18nKey="acceptTermsAndPolicy">
                                  I read and accept{' '}
                                  <Link
                                    href={getTermsOfUseLink(MIRROR)}
                                    target={INTERNAL_LINK_TARGET_BLANK}
                                  >
                                    Terms of Use
                                  </Link>{' '}
                                  and{' '}
                                  <Link
                                    href={getPrivacyPolicyLink(MIRROR)}
                                    target={INTERNAL_LINK_TARGET_BLANK}
                                  >
                                    Privacy Policy
                                  </Link>
                                </Trans>
                              </Typography>
                            }
                            name="terms"
                          />
                        </div>
                      )}
                      {!customerVerified &&
                        extTimestamp !== 0 &&
                        extPublicKey !== '' &&
                        extSignature !== '' && (
                          <div className={globalClasses.checkBox}>
                            <Checkbox
                              label={
                                <Typography
                                  variant="body2"
                                  component="span"
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  {t(
                                    'Use this email for other orders via Utorg'
                                  )}
                                </Typography>
                              }
                              name="saveCustomerWallet"
                            />
                          </div>
                        )}
                    </div>

                    <div className={globalClasses.payButtonWrapper}>
                      <Button
                        className={globalClasses.button}
                        disabled={
                          formChanging ||
                          submitting ||
                          !values.address ||
                          nftUnavailable ||
                          !values.terms
                        }
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {buttonName[language] || t('Next')}
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            </Loader>
          )
        }}
      />
    </div>
  )
}
