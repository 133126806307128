import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    [theme.breakpoints.down('xs')]: {
      position: 'sticky',
      top: 0,
    },
  },
  banner: {
    display: 'flex',
    alignItems: 'stretch',
    borderRadius: '6px',
    backgroundColor: '#D6EBFF',
    color: '#2F66E3',
    margin: '8px',
    lineHeight: '1.5em',
  },
  bannerContent: {
    'color': 'inherit',
    'textDecoration': 'none',
    'display': 'flex',
    'flex': 1,
    'justifyContent': 'center',
    'alignItems': 'center',
    'padding': '10px 0 10px 48px',
    'minHeight': '68px',
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
    '&active': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      textAlign: 'left',
    },
  },
  closeWrapper: {
    paddingRight: '2px',
    display: 'grid',
    placeContent: 'center',
  },
  close: {
    color: 'rgba(14,85,252,0.5)',
  },
}))

interface IPromoBannerProps {
  href: string
  onClose(): void
}

const PromoBanner: FC<IPromoBannerProps> = ({ children, href, onClose }) => {
  const classes = useStyles()

  const { uiLoaded } = useSelector((state: any) => state.common)

  return (
    <div className={classes.wrapper}>
      <div aria-label="promo banner" className={classes.banner}>
        <a
          id="utp-promo-content"
          target="_blank"
          rel="noreferrer"
          href={href}
          className={classes.bannerContent}
        >
          {!uiLoaded && <CircularProgress />}
          {uiLoaded && children}
        </a>
        <div className={classes.closeWrapper}>
          <IconButton
            id="close-utp-promo-banner"
            aria-label="close"
            onClick={onClose}
            className={classes.close}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default PromoBanner
