import React, { useRef, useEffect } from 'react';
import { I3DsForm } from '@/types/payment';

interface IProps {
  form: I3DsForm;
  className: any;
}

export default function ThreeDS({ form, className }: IProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const hasForm = form.method !== 'GET'

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [formRef.current])

  return (
    <div>
      <iframe src={form.action} name="three-ds" className={className} />
      {hasForm && (
        <form
          action={form.action}
          method={form.method}
          ref={formRef}
          target="three-ds"
          style={{ height: 0 }}
        />
      )}
    </div>
  );
}
