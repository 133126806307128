import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
}))

interface IProps {
  setBillingData: any
  classes: any
  cityError?: string
  setCityError: any
  zipcodeError?: string
  setZipcodeError: any
  streetAddressError?: string
  setStreetAddressError: any
}

export default function BillingFields({
  setBillingData,
  classes,
  cityError,
  setCityError,
  setStreetAddressError,
  setZipcodeError,
  streetAddressError,
  zipcodeError,
}: IProps) {
  const localClasses = useStyles()
  const { t } = useTranslation()

  return (
    <div className={localClasses.root}>
      <div className={classes.blockTitle}>{t('Billing address')}</div>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          name="city"
          variant="filled"
          label={t('labels.city')}
          onChange={(e) => {
            const city = e.target.value
            setBillingData({ city })
            if (!city || !city.length) {
              setCityError(t('validation.Required field'))
            } else {
              setCityError(undefined)
            }
          }}
          error={!!cityError}
          helperText={cityError}
          id="billing-city"
          autoFocus
        />
        <TextField
          className={classes.field}
          name="zip"
          variant="filled"
          label={t('labels.zip')}
          onChange={(e) => {
            const zipCode = e.target.value
            setBillingData({ zipCode })
            if (!zipCode || !zipCode.length) {
              setZipcodeError(t('validation.Required field'))
            } else {
              setZipcodeError(undefined)
            }
          }}
          error={!!zipcodeError}
          helperText={zipcodeError}
          id="billing-zipcode"
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          name="streetAddress"
          variant="filled"
          label={t('labels.address')}
          onChange={(e) => {
            const address = e.target.value
            setBillingData({ address })
            if (!address || !address.length) {
              setStreetAddressError(t('validation.Required field'))
            } else {
              setStreetAddressError(undefined)
            }
          }}
          error={!!streetAddressError}
          helperText={streetAddressError}
          id="billing-address"
        />
      </div>
    </div>
  )
}
