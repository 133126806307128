import React, { useRef } from 'react'

import { useHistory } from 'react-router'

import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'

import { PaymentSource } from '@/api/payment'
import GooglePayIcon from '@/icons/googlePay.svg'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import { trackClickGoogleOrApplePay } from '@/helpers/analytics'
import useGooglePay from '@/hooks/useGooglePay'

import GPayButton from './GPayButton'
import { IGooglePayButtonProps, IParsedGatewayConfig } from '../MobilePay.types'

const useStyles = makeStyles((theme) => ({
  googlePayWrapper: {
    paddingBottom: theme.spacing(1.25),
  },
  googlePay: {
    'background': `url(${GooglePayIcon}) no-repeat center #000`,
    'color': '#fff',
    '&.processing': {
      backgroundImage: 'none',
    },
    '&:disabled, &:hover': {
      backgroundColor: '#000',
    },
    '&:disabled': {
      opacity: 0.75,
    },
  },
  progressWrapper: {
    display: 'grid',
    placeContent: 'center',
    height: '100%',
  },
}))

export default function GooglePay(props: IGooglePayButtonProps) {
  const {
    order,
    billing,
    customer,
    isPayByCard,
    saveBilling,
    checkingKyc,
    kycRequired,
    paymentData,
    initPayment,
    googlePayStarted,
    requestInProgress,
    onGooglePayPayment,
    setGooglePayStarted,
    validateCountryAndTerms,
    validateBillingAddress,
  } = props

  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const history = useHistory()

  const gPayWrapperRef = useRef<HTMLDivElement>(null)
  const historyUnblockRef = useRef<null | (() => void)>(null)

  const { isGoogleModalOpen, initGPayButton } = useGooglePay()

  let parsedGatewayConfig: undefined | IParsedGatewayConfig

  if (paymentData?.url) {
    parsedGatewayConfig = JSON.parse(paymentData.url)
  }

  const handleInitGPayButton = () => {
    historyUnblockRef.current = history.block()
    trackClickGoogleOrApplePay('click_google_pay', order, 'google pay')
    return initGPayButton(
      order,
      customer,
      (token: string, cardholderName?: string) => {
        onGooglePayPayment(token, cardholderName)
        historyUnblockRef.current && historyUnblockRef.current()
      },
      {
        gateway: parsedGatewayConfig?.gateway ?? '',
        gatewayMerchantId: parsedGatewayConfig?.merchantId ?? '',
      }
    )
  }

  const handleInitPayment = () => {
    if (!validateBillingAddress(validateCountryAndTerms(true, true)[0])) return
    if (!validateCountryAndTerms(true, true)[0]) return

    setGooglePayStarted()

    if (billing) {
      saveBilling()
    } else if (!kycRequired) {
      initPayment({
        source: PaymentSource.APM,
        orderId: order.id,
        apm: { id: 'googlepay', inputs: {} },
      })
    }
  }

  return (
    <div ref={gPayWrapperRef} className={classes.googlePayWrapper}>
      {isPayByCard && googlePayStarted && (
        <GPayButton
          classes={classes}
          globalClasses={globalClasses}
          disabled={Boolean(
            isGoogleModalOpen || requestInProgress || checkingKyc
          )}
          initGPayButton={handleInitGPayButton}
        />
      )}

      {!isPayByCard && (
        <Button
          id="init-google-pay-button"
          onClick={handleInitPayment}
          className={clsx(globalClasses.button, classes.googlePay)}
          variant="contained"
          disabled={requestInProgress}
        />
      )}
    </div>
  )
}
