import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EmploymentItem from './EmploymentItem';
import { EmploymentType } from './index';
import useGlobalStyles from '../../hooks/useGlobalStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  description: {
    marginBottom: theme.spacing(3)
  }
}))

interface IProps {
  type?: EmploymentType;
  setType(type: EmploymentType): void;
  setData(data: any): void;
}

export default function Employment({ type, setType, setData }: IProps) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Typography
        variant="body2"
        component="div"
        className={classes.description}
      >
        {t('proofOfFundsDescription')}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        className="pof-formTitle"
      >
        {t('Please specify the type of employment.')}
      </Typography>
      <Form
        onSubmit={setData}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            {Object.values(EmploymentType).map((t) => (
              <EmploymentItem
                type={t}
                active={t === type}
                setType={setType}
                values={values}
                key={t}
              />
            ))}
            <Button
              className={clsx(globalClasses.button, 'pof-button', 'customButton')}
              variant="contained"
              color="primary"
              type="submit"
            >
              {t('Proceed')}
            </Button>
          </form>
        )}
      />
    </div>
  )
}
