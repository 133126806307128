import Api from './index'
import useFetchApi from '../hooks/useFetch'

export function useCountries() {
  return useFetchApi(
    async (...data: any) => Api.post('/settings/country', ...data),
    { data: [] }
  )
}

export const useStatesList = () => {
  return useFetchApi(
    async (...data: any) => {
      return Api.post('/settings/state', ...data)
    },
    { data: [] },
    undefined,
    true
  )
}

export interface IAPMField {
  id: string
  validation: string
  localizedName?: string
  name?: string
  defaultValue?: string | null
}

interface IAPMFee {
  deposit: number
  depositFixed: number
  depositFixedCurrency: string
}

export enum APMFlow {
  IFRAME = 'IFRAME',
  REDIRECT = 'REDIRECT',
}

export interface IAPM {
  id: string
  name: string
  logo: string
  fee: IAPMFee
  flow?: APMFlow
  fields?: IAPMField[]
}

export function useAPMs(loading = false) {
  return useFetchApi(
    async (...data: any) => Api.post('/settings/apm', ...data),
    { data: [] },
    undefined,
    loading
  )
}

export function useUDSupported() {
  return useFetchApi(
    async (...data: any) => Api.post('/settings/domain/supported', ...data),
    { data: {} }
  )
}

export function useUDResolve() {
  return useFetchApi(
    async (...data: any) => Api.post('/settings/domain/resolve', ...data),
    { data: {} }
  )
}
