import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import { Form } from 'react-final-form'

import { OnChange } from 'react-final-form-listeners'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { Input } from '@/components/Form'
import { composeValidators, lengthRequired, required } from '@/validators'
import { useAccountFormStyles } from '@/hooks/useAccountFormStyles'
import { setLogIn, setLogInMethod } from '@/store/actions/account'
import {
  useCustomerLogin,
  useOTPAuth,
  usePasswordAuth,
} from '@/api/private/auth'
import { useCustomerBind } from '@/api/customer'
import plural from '@/helpers/plural'
import { getMaskedEmail } from '@/helpers/mask'
import { TNextStep } from './index'
import captchaRequest from '@/helpers/captcha'
import ServerMessage from '@/components/UI/ServerMessage'
import { getRequestError } from '@/helpers/error'

interface IProps {
  email: string
  classes: any
  field: TNextStep
  setConfirm: any
  goBack: any
  externalId?: string
  sid?: string
}

export default function LoginConfirmation({
  classes,
  email,
  field,
  setConfirm,
  goBack,
  sid,
  externalId,
}: IProps) {
  const history = useHistory()
  const formClasses = useAccountFormStyles()
  const [isNotFirstResend, setIsNotFirstResend] = useState(false)
  const [resendCodeTimeout, setResendCodeTimeout] = useState(40)
  const [authError, setAuthError] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { bindCustomer } = useCustomerBind({ sid, externalId })

  const navigateToKyc = () => {
    history.replace('/account/kyc')
  }

  const [getCode] = useCustomerLogin(async (res: any) => {
    if (res.success) {
      let isBind = false
      try {
        isBind = await bindCustomer()
      } catch (error) {
        setAuthError(getRequestError({ error }))
        return
      }
      setResendCodeTimeout(40)
      if (isBind) {
        setTimeout(navigateToKyc)
      }
    }
  })
  const [OTPAuth] = useOTPAuth(async (res: any) => {
    if (res.success) {
      let isBind = false
      try {
        isBind = await bindCustomer()
      } catch (error) {
        setAuthError(getRequestError({ error }))
        return
      }
      dispatch(setLogIn(res.data))
      dispatch(setLogInMethod('email'))
      if (isBind) {
        setTimeout(navigateToKyc)
      }
    } else if (res.error.type === 'ACCESS_DENIED') {
      setAuthError('authError')
    }
  })
  const [passwordAuth] = usePasswordAuth(async (res: any) => {
    if (res.success) {
      let isBind = false
      try {
        isBind = await bindCustomer()
      } catch (error) {
        setAuthError(getRequestError({ error }))
        return
      }

      if (res.data.next && res.data.next === 'OTP') {
        setConfirm(res.data.next)
        setAuthError('')
      } else {
        dispatch(setLogIn(res.data))
        dispatch(setLogInMethod('email'))
        if (isBind) {
          setTimeout(navigateToKyc)
        }
      }
    } else if (res.error.type === 'ACCESS_DENIED') {
      setAuthError('authError')
    } else if (res.error.type === 'INVALID_PASSWORD') {
      setAuthError('validation.incorrectPassword')
    }
  })

  useEffect(() => {
    if (field === 'OTP' && resendCodeTimeout > 0) {
      setTimeout(() => setResendCodeTimeout(resendCodeTimeout - 1), 1000)
    }
  }, [resendCodeTimeout, field])

  function resendCode() {
    if (!isNotFirstResend) {
      setIsNotFirstResend(true)
    }
    if (password.length) {
      setResendCodeTimeout(40)
      return captchaRequest(
        '/api/private/v1/auth/password',
        passwordAuth,
        { email, password },
        () => {
          setResendCodeTimeout(0)
          setIsNotFirstResend(false)
          setAuthError('captchaError')
        }
      )
    }
    captchaRequest(
      '/api/private/v1/auth/login',
      getCode,
      { login: email },
      () => {
        setIsNotFirstResend(false)
        setAuthError('captchaError')
      }
    )
  }

  return (
    <Form
      initialValues={{
        [field === 'OTP' ? 'code' : 'password']: '',
        email: getMaskedEmail(email),
      }}
      onSubmit={(values) => {
        const data = {
          email,
          [field === 'OTP' ? 'code' : 'password']:
            values[field === 'OTP' ? 'code' : 'password'],
        }
        if (field === 'OTP') {
          captchaRequest('/api/private/v1/auth/otp', OTPAuth, data, () =>
            setAuthError('captchaError')
          )
        } else {
          setPassword(values.password)
          captchaRequest(
            '/api/private/v1/auth/password',
            passwordAuth,
            data,
            () => setAuthError('captchaError')
          )
        }
      }}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          {field === 'PASSWORD' ? (
            <div>
              <Typography
                variant="h4"
                className={classes.title}
                component="div"
              >
                <IconButton
                  onClick={() => goBack('')}
                  className={classes.stepBack}
                >
                  <ArrowRightAltIcon />
                </IconButton>
                {t('labels.Password')}
              </Typography>
              {authError.length > 0 && (
                <ServerMessage type="error" text={authError} />
              )}
              <div>
                <Input
                  name="email"
                  type="text"
                  label={t('labels.Email')}
                  fullWidth
                  disabled
                  required={false}
                />
              </div>
              <div>
                <Input
                  name="password"
                  type="password"
                  label={t('labels.Password')}
                  fullWidth
                  validate={required}
                  autoComplete="new-password"
                  autoFocus
                />
              </div>
              <div>
                <Button
                  disabled={invalid}
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                >
                  {t('Next')}
                </Button>
              </div>
              <div className={classes.hint}>
                <Link
                  component={NavLink}
                  to="/account/restore"
                  className={formClasses.link}
                >
                  {t('Forgot password')}
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <Typography
                variant="h4"
                className={classes.title}
                component="div"
              >
                <IconButton
                  onClick={() => goBack(password.length ? 'PASSWORD' : '')}
                  className={classes.stepBack}
                >
                  <ArrowRightAltIcon />
                </IconButton>
                {t('labels.Authorization code')}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                className={classes.formTitle}
              >
                {t('authCodeDescription')} {t('email')} <strong>{email}</strong>
              </Typography>
              {authError.length > 0 && (
                <ServerMessage type="error" text={authError} />
              )}
              <div>
                <Input
                  name="email"
                  type="hidden"
                  label=""
                  fullWidth
                  disabled
                  required={false}
                />
              </div>
              <div>
                <Input
                  name="code"
                  label={t('labels.Authorization code')}
                  validate={composeValidators(required, (v: any) =>
                    lengthRequired(v, 6)
                  )}
                  type="number"
                  fullWidth
                  autoFocus
                />
                <OnChange name="code">
                  {(val: string) => {
                    if (val.length === 6) {
                      handleSubmit()
                    }
                  }}
                </OnChange>
              </div>
              <div className={classes.hint} translate="no">
                {resendCodeTimeout > 0 ? (
                  <p className={classes.resendTimeout}>
                    {isNotFirstResend && <div>{t('codeBeenResent')}</div>}
                    {t('Resend confirmation code in')} {resendCodeTimeout}{' '}
                    {t(
                      plural(resendCodeTimeout, [
                        'secPlural1',
                        'secPlural2',
                        'secPlural5',
                      ])
                    )}
                    .
                  </p>
                ) : (
                  <p>
                    {t('notReceivedCode')}&nbsp;
                    <Link onClick={resendCode} className={formClasses.link}>
                      {t('Resend code')}
                    </Link>
                  </p>
                )}
              </div>
            </div>
          )}
        </form>
      )}
    />
  )
}
