import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import clsx from 'clsx'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Checkbox } from '@material-ui/core'

import { isSelectedSid } from '@/helpers/settlements'
import { ICheckedSidRequest, ISettlementBalance } from '@/types/settlements'
import CustomBadge from '@/components/CustomBadge'
import { useMasterBalanceRowStyles } from '@/hooks/useMasterBalanceRowStyles'

import SubrowBalance from './SubrowBalance'

export interface IRowBalance {
  available: number
  total: number
  currency: string
  locked: number
  sids: ISettlementBalance[]
}

interface ISortedFormDataByAvailableSids {
  currency: string
  sids: ICheckedSidRequest[]
}

interface IRow {
  row: IRowBalance
  setPercentage: (
    percentageField: string,
    amountField: string,
    amount: number | string,
    availableBalance: number | string
  ) => void
  setAmount: (
    amountField: string,
    percentageField: string,
    amount: number | string,
    availableBalance: number
  ) => void
  setChangePercentage: (
    currency: string,
    percentageField: string,
    amountField: string,
    availableBalance: number,
    alg: string,
    sid?: string
  ) => void
  setCustomerId: (customerIdField: string, id: number) => void
  setChecked: (checkedField: string, value: boolean) => void
  setAvailableBalance: (availableBalanceField: string, amount: number) => void
  sortedFormDataByAvailableSids: ISortedFormDataByAvailableSids[]
}

const RowBalance = forwardRef(function RowBalance(
  {
    row,
    setPercentage,
    setAmount,
    setChangePercentage,
    setAvailableBalance,
    setChecked,
    setCustomerId,
    sortedFormDataByAvailableSids,
  }: IRow,
  forwardedRef
) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedSids, setSelectedSids] = useState<string[]>([])
  const [requestTotalAmount, setRequestTotalAmount] = useState(0)

  const formCurrencyBalance = sortedFormDataByAvailableSids.find(
    (item) => item?.currency === row.currency
  )

  const classes = useMasterBalanceRowStyles()

  useEffect(() => {
    row.sids.map((settlement) => {
      const containsSid = selectedSids.find((item) => item === settlement.sid)

      const settlementSidFieldPrefix = `${row.currency}.${settlement.sid}`

      setCustomerId(
        `${settlementSidFieldPrefix}.customerId`,
        settlement.customerId
      )
      setAvailableBalance(
        `${settlementSidFieldPrefix}.availableBalance`,
        Number((settlement.balance - settlement.locked).toFixed(2))
      )

      if (containsSid) {
        setChecked(`${settlementSidFieldPrefix}.checked`, true)
      } else {
        setChecked(`${settlementSidFieldPrefix}.checked`, false)
      }
      return
    })
  }, [selectedSids])

  useEffect(() => {
    row?.sids.map((settlement) => {
      setPercentage(
        `${settlement.currency}.${settlement.sid}.req`,
        `${settlement.currency}.${settlement.sid}.reqAmount`,
        0,
        Number((settlement.balance - settlement.locked).toFixed(2))
      )
      return
    })
  }, [row?.sids])

  useEffect(() => {
    if (formCurrencyBalance && formCurrencyBalance?.sids.length > 0) {
      const acc = formCurrencyBalance.sids.reduce(
        (acc: number, sid) => acc + Number(sid.amount.toFixed(2)),
        0
      )
      setRequestTotalAmount(acc)
    } else {
      setRequestTotalAmount(0)
    }
  }, [formCurrencyBalance])

  const fillAvailableAmount = () => {
    row.sids.map((settlement: ISettlementBalance) => {
      const availableBalance = (settlement.balance - settlement.locked).toFixed(
        2
      )
      setPercentage(
        `${row.currency}.${settlement.sid}.req`,
        `${row.currency}.${settlement.sid}.reqAmount`,
        availableBalance,
        availableBalance
      )
      return
    })
  }

  const totalBalance = row.sids.reduce(
    (acc: number, sid) => acc + Number(sid.balance.toFixed(2)),
    0
  )

  const lockedBalance = row.sids.reduce(
    (acc: number, sid) => acc + Number(sid.locked.toFixed(2)),
    0
  )

  const availableBalance = totalBalance - lockedBalance
  const sidsLength = row.sids.length

  const handleSelectAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    rows: ISettlementBalance[]
  ) => {
    if (event.target.checked) {
      const newSelected = rows.map((subrow) => subrow.sid)
      setSelectedSids(newSelected)
      return
    }
    setSelectedSids([])
  }

  const handleSelect = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedSids.indexOf(name)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSids, name)
    } else if (selectedIndex === 0) {
      newSelected = selectedSids.slice(1)
    } else if (selectedIndex === selectedSids.length - 1) {
      newSelected = selectedSids.slice(0, -1)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSids.slice(0, selectedIndex),
        selectedSids.slice(selectedIndex + 1)
      )
    }
    setSelectedSids(newSelected)
  }

  useImperativeHandle(
    forwardedRef,
    () => ({
      resetRow: () => {
        row.sids.forEach((settlement) => {
          const settlementSidFieldPrefix = `${row.currency}.${settlement.sid}`
          setChecked(`${settlementSidFieldPrefix}.checked`, false)
          setPercentage(
            `${settlementSidFieldPrefix}.req`,
            `${settlementSidFieldPrefix}.reqAmount`,
            0,
            0
          )
        })
        setSelectedSids([])
        setIsExpanded(false)
      },
    }),
    [row.sids, selectedSids, isExpanded]
  )

  return (
    <>
      <TableRow
        className={clsx(
          classes.root,
          sidsLength > 0 &&
            selectedSids.length === sidsLength &&
            classes.selectedRow
        )}
      >
        <TableCell
          className={classes.arrowCell}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <KeyboardArrowUpIcon className={classes.icon} />
          ) : (
            <KeyboardArrowDownIcon className={classes.icon} />
          )}
        </TableCell>
        <TableCell className={classes.checkboxCell}>
          <Checkbox
            className={classes.checkbox}
            disabled={availableBalance === 0}
            indeterminate={
              selectedSids.length > 0 && selectedSids.length < sidsLength
            }
            checked={sidsLength > 0 && selectedSids.length === sidsLength}
            onChange={(e) => {
              setIsExpanded(true)
              handleSelectAll(e, row.sids)
            }}
            inputProps={{ 'aria-label': 'select all sids' }}
          />
          <CustomBadge content={row.sids.length}>
            <Typography
              variant="body2"
              component="span"
              className={classes.totalCell}
            >
              {row.currency}
            </Typography>
          </CustomBadge>
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="body2"
            component="span"
            className={clsx(
              classes.totalCell,
              availableBalance > 0 ? '' : classes.disabled
            )}
          >
            {totalBalance.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell align="right" className={classes.lockedCell}>
          <Typography
            variant="body2"
            component="span"
            className={availableBalance > 0 ? '' : classes.disabled}
          >
            {lockedBalance > 0 ? lockedBalance.toFixed(2) : '—'}
          </Typography>
        </TableCell>
        <TableCell align="right" className={classes.availableCell}>
          <Typography
            variant="body2"
            component="span"
            onClick={() => {
              if (availableBalance > 0) {
                fillAvailableAmount()
              }
              return null
            }}
            className={clsx(
              classes.totalCell,
              availableBalance === 0 ? classes.disabled : classes.available
            )}
          >
            {availableBalance.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell align="center" className={classes.percentageCell}>
          <Typography variant="body2" component="span"></Typography>
        </TableCell>
        <TableCell className={classes.amountCell}>
          <Typography
            variant="body2"
            component="span"
            className={clsx(
              classes.totalReqAmount,
              requestTotalAmount > 0 && classes.totalCell
            )}
          >
            {requestTotalAmount.toFixed(2)}
          </Typography>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <>
          {row.sids.map((subrow, index) => (
            <SubrowBalance
              index={index}
              key={subrow.sid}
              handleSelect={handleSelect}
              subrow={subrow}
              isSidSelected={isSelectedSid(subrow.sid, selectedSids)}
              setPercentage={setPercentage}
              setAmount={setAmount}
              setChangePercentage={setChangePercentage}
            />
          ))}
        </>
      )}
    </>
  )
})

export default RowBalance
