import React from 'react'

import clsx from 'clsx'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { Checkbox } from '@material-ui/core'

import { Input } from '@/components/Form'
import { ISettlementBalance } from '@/types/settlements'
import { composeValidators, availableValue, onlyNumber } from '@/validators'
import { formatTextInput } from '@/helpers/input'
import { useMasterBalanceRowStyles } from '@/hooks/useMasterBalanceRowStyles'

import PercentageArrows from './PercentageArrows'

interface ISubrowBalance {
  subrow: ISettlementBalance
  index: number
  handleSelect: (event: React.MouseEvent<unknown>, name: string) => void
  isSidSelected: boolean
  setPercentage: (
    percentageField: string,
    amountField: string,
    amount: number | string,
    availableBalance: number | string
  ) => void
  setAmount: (
    amountField: string,
    percentageField: string,
    amount: number | string,
    availableBalance: number
  ) => void
  setChangePercentage: (
    currency: string,
    percentageField: string,
    amountField: string,
    availableBalance: number,
    alg: string,
    sid?: string
  ) => void
}

function SubrowBalance({
  subrow,
  index,
  handleSelect,
  isSidSelected,
  setPercentage,
  setAmount,
  setChangePercentage,
}: ISubrowBalance) {
  const classes = useMasterBalanceRowStyles()

  const availableSubrowBalance = subrow.balance - subrow.locked
  const labelId = `sid-checkbox-${index}`
  const sidFieldPrefix = `${subrow.currency}.${subrow.sid}`
  const isZeroBalance = availableSubrowBalance === 0

  const changePercentageWrapper = (alg: string) => {
    setChangePercentage(
      `${subrow.currency}`,
      `${sidFieldPrefix}.req`,
      `${sidFieldPrefix}.reqAmount`,
      Number(availableSubrowBalance.toFixed(2)),
      alg,
      `${subrow.sid}`
    )
  }

  return (
    <TableRow
      className={clsx(classes.subrow, isSidSelected && classes.selectedRow)}
      key={subrow.sid}
    >
      <TableCell className={classes.emptyCell}>
        <Input name={`${sidFieldPrefix}.customerId`} type="hidden" />
      </TableCell>
      <TableCell className={classes.checkboxCell}>
        <Checkbox
          disabled={isZeroBalance}
          checked={isSidSelected}
          onClick={(event) => handleSelect(event, subrow.sid)}
          inputProps={{ 'aria-labelledby': labelId }}
        />
        <Typography variant="body2" component="span">
          {subrow.sid}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        className={!isZeroBalance ? '' : classes.disabled}
      >
        <Typography variant="body2" component="span">
          {subrow.balance.toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell align="right" className={classes.lockedCell}>
        <Typography
          variant="body2"
          component="span"
          className={!isZeroBalance ? '' : classes.disabled}
        >
          {subrow.locked > 0 ? subrow.locked.toFixed(2) : '—'}
        </Typography>
      </TableCell>
      <TableCell align="right" className={classes.availableCell}>
        <Typography
          variant="body2"
          onClick={() => {
            if (!isZeroBalance) {
              setPercentage(
                `${sidFieldPrefix}.req`,
                `${sidFieldPrefix}.reqAmount`,
                availableSubrowBalance.toFixed(2),
                Number(availableSubrowBalance.toFixed(2))
              )
            }
            return null
          }}
          component="span"
          className={!isZeroBalance ? classes.available : classes.disabled}
        >
          {availableSubrowBalance.toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell align="center" className={classes.percentageCell}>
        {!isZeroBalance && (
          <Input
            name={`${sidFieldPrefix}.req`}
            type="number"
            className={clsx(
              classes.field,
              classes.inputPercentage,
              isSidSelected && classes.checked
            )}
            validate={composeValidators(onlyNumber, (v: any) =>
              availableValue(v, 100, 0)
            )}
            variant="outlined"
            format={(value: string | number | undefined) =>
              formatTextInput(value)
            }
            InputProps={{
              endAdornment: (
                <PercentageArrows
                  handleUp={() => changePercentageWrapper('increment')}
                  handleDown={() => changePercentageWrapper('decrement')}
                />
              ),
            }}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
              const inputValue = event.target.value

              setAmount(
                `${sidFieldPrefix}.reqAmount`,
                `${sidFieldPrefix}.req`,
                inputValue,
                Number(availableSubrowBalance.toFixed(2))
              )
            }}
          />
        )}
      </TableCell>
      <TableCell className={classes.amountCell}>
        {!isZeroBalance && (
          <>
            <Input
              variant="outlined"
              className={clsx(
                classes.field,
                classes.inputAmount,
                isSidSelected && classes.checked
              )}
              validate={composeValidators(onlyNumber, (v: any) =>
                availableValue(v, Number(availableSubrowBalance.toFixed(2)), 0)
              )}
              format={(value: string | number | undefined) =>
                formatTextInput(value)
              }
              inputProps={{ maxLength: 10 }}
              name={`${sidFieldPrefix}.reqAmount`}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                const inputValue = event.target.value

                setPercentage(
                  `${sidFieldPrefix}.req`,
                  `${sidFieldPrefix}.reqAmount`,
                  Number(inputValue),
                  Number(availableSubrowBalance.toFixed(2))
                )
              }}
            />
          </>
        )}
      </TableCell>
    </TableRow>
  )
}
export default SubrowBalance
