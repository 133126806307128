import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

interface IProps {
  onChange: any;
  deley?: number;
  fieldError?: boolean;
  label: string
}

export default function DebounceInput({
  onChange,
  deley = 500,
  fieldError,
  label,
}: IProps) {
  const [serchFieldValue, setSearchFieldValue] = useState('');

  const changeSearchField = (value: string) => {
    setSearchFieldValue(value);
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      onChange(serchFieldValue);
    }, deley);

    return () => clearTimeout(handler);
  }, [serchFieldValue])

  return (
    <TextField
      error={fieldError}
      variant='outlined'
      label={label}
      helperText={fieldError ? 'Incorrect entry' : ''}
      onChange={(e: any) => {
        changeSearchField(e.target.value);
      }}
    />
  )
}