import * as Sentry from '@sentry/react'

export const updateSentryContext = (location: any) => {
  const { orderId, userId, merchantId } = getTransactionData(location)
  Sentry.configureScope((scope) => {
    const savedUser = scope.getUser()
    if (savedUser?.id && userId && savedUser?.id !== userId) {
      Sentry.captureMessage('user will be overwrite')
    }

    if (userId && savedUser?.id !== userId) {
      userId && scope.setUser({ id: userId })
    }

    orderId && scope.setTag('OrderID', orderId)

    merchantId && scope.setTag('MerchantId', merchantId)
  })
}

const getTransactionData = (location: any) => {
  const orderId =
    location.pathname.indexOf('order') > -1
      ? location.pathname.split('/')[2]
      : ''
  const token = new URLSearchParams(location.search).get('token')
  const pId = new URLSearchParams(location.search).get('pid')
  const tokenDecoded = token && atob(token)
  const userId = tokenDecoded?.split('-')[0]

  return {
    orderId: orderId,
    userId: !isNaN(Number(userId)) ? userId : '',
    merchantId: !isNaN(Number(pId)) ? pId : '',
  }
}

export function addTagSentry(key: string, value: string | number) {
  return Sentry.configureScope((scope) => {
    scope.setTag(key, value)
  })
}
