import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

import { AuthForm } from './forms'
import PaymentForm from './forms/Pay'
import DirectAuthForm from '../Direct/forms/AuthForm'
import Loader from '../../components/Loader'
import ServerMessage from '../../components/UI/ServerMessage'
import { IOrder, OrderProcessingError } from '../../api/order'
import { IMerchantSettings } from '../../api/merchant'
import { useCustomerInfo } from '../../api/customer'
import useFormStyles from './forms/useFormStyles'
import useQuery from '../../hooks/useQuery'
import { ILangType } from '../../locales/'
import { ICurrency } from '../../types/currency'
import Payout from '../Payout'
import { OrderExtendedType } from '@/api/private/orders'
import { getSupportEmail } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

export interface IExchangeFormProps {
  order: IOrder
  setStep: any
  t: any
  setEmail?: any
  setCustomer?(data: any): void
  email: string
  customer?: any
  loadOrder?(data: any): void
  ui: IMerchantSettings
  language: ILangType
  address?: string
  setAddress?: any
  setOrder?(order: IOrder): void
  setCreateNewOrder?: any
  sid?: string
  setPaymentError?: any
  setProcessingError(error: OrderProcessingError): void
}

const steps = ['transaction', 'card']
const defaultMerchantSid =
  process.env.REACT_APP_DEFAULT_MERCHANT_SID || 'LnYK5Qgh8e'

interface IProps {
  createNew?: boolean
  setCreateNew: any
  order: IOrder
  loadOrder: any
  orderError?: any
  ui: any
  setOrder: any
  setCustomer: any
  customer: any
  setProcessingError(error: OrderProcessingError): void
  currencies: ICurrency[]
  currenciesLoading: boolean
}

export default function Exchange({
  createNew,
  setCreateNew,
  order,
  loadOrder,
  orderError,
  ui,
  setOrder,
  customer,
  setCustomer,
  setProcessingError,
  currencies,
  currenciesLoading,
}: IProps) {
  const { id, step = steps[0] } = useParams<any>()

  const [{ token, timestamp, publicKey, signature, alg, email: extEmail }] =
    useQuery({})
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [email, setEmail] = useState(extEmail || '')
  const [address, setAddress] = useState('')
  const [unavailable, setUnavailable] = useState(false)
  const [customerLoaded, setCustomerLoaded] = useState(false)
  const [serverError, setServerError] = useState('')
  // address change
  const [confirmFormChange, setConfirmFormChange] = useState(false)
  const [formChanging, setFormChanging] = useState(false)
  const isPayout = order?.type === OrderExtendedType.WALLET_TO_FIAT

  const [{ data: customerInfo }, customerLoading, loadCustomer] =
    useCustomerInfo()
  const lang = language.split('-')[0] as ILangType

  const formClasses = useFormStyles({ buttonColor: ui.form.buttonColor })

  const nextStep = (s: string) => {
    history.replace(`/order/${id}/${s}${window.location.search}`)
  }

  const parsedTimestamp = () => {
    const parsed =
      typeof timestamp === 'string' ? parseInt(timestamp) : undefined
    return parsed ? (!isNaN(parsed) ? parsed : undefined) : parsed
  }

  useEffect(() => {
    if (step !== steps[0]) {
      loadCustomer()
    }
  }, [])

  useEffect(() => {
    if (!customerInfo.notLoaded) {
      setCustomer({ ...customer, ...customerInfo })
      setCustomerLoaded(true)
    }
  }, [customerInfo])

  useEffect(() => {
    if (customerLoaded && order.id) {
      if (
        step !== steps[0] &&
        (!customerInfo.id || order.acceptedById !== customerInfo.id)
      ) {
        nextStep(steps[0])
      }
    }
  }, [customerLoaded, order])

  useEffect(() => {
    if (orderError && orderError.type) {
      if (orderError.type === 'ORDER_ALREADY_PROCESSED') {
        return setProcessingError(OrderProcessingError.ORDER_ALREADY_PROCESSED)
      }
      setServerError('somethingWentWrong')
    }
  }, [orderError])

  useEffect(() => {
    setEmail(extEmail || '')
  }, [extEmail])

  useEffect(() => {
    order?.acceptedByEmail && setEmail(order.acceptedByEmail)
  }, [order?.acceptedByEmail])

  useEffect(() => {
    if (order.unDomain) {
      setAddress(order.unDomain)
    } else {
      setAddress(order.address)
    }
  }, [order.address, order.unDomain])

  if (unavailable) {
    return (
      <div className={classes.root}>
        <Typography variant="body1" align="center">
          <p>{t('serviceUnavailable')}</p>
          <Link
            className={clsx(formClasses.send, 'wide')}
            component={Button}
            href={getSupportEmail(MIRROR)}
          >
            {t('Inform administrator')}
          </Link>
        </Typography>
      </div>
    )
  }

  const ActiveForm = createNew ? DirectAuthForm : AuthForm
  const progressWidth = '33%'

  const merchantName = order.legal?.name || ''

  if (step === 'transaction' || !!createNew) {
    return (
      <Loader loading={customerLoading || currenciesLoading}>
        <div className={classes.root}>
          <div className={classes.progress}>
            <div className="currentProgress" style={{ width: progressWidth }} />
          </div>
          {serverError.length > 0 ? (
            <ServerMessage type="error" text={serverError} />
          ) : (
            <ActiveForm
              order={order}
              setStep={nextStep}
              setEmail={setEmail}
              email={email}
              t={t}
              setCustomer={setCustomer}
              customer={customer}
              loadOrder={loadOrder}
              ui={ui}
              language={lang}
              currencies={currencies}
              currenciesLoading={currenciesLoading}
              address={address}
              setAddress={setAddress}
              setOrder={setOrder}
              sid={defaultMerchantSid}
              setUnavailable={setUnavailable}
              fromOrder
              authToken={token}
              formChanging={formChanging}
              setFormChanging={setFormChanging}
              setProcessingError={setProcessingError}
              extTimestamp={parsedTimestamp()}
              extPublicKey={publicKey}
              extSignature={signature}
              extAlg={alg}
            />
          )}
        </div>
      </Loader>
    )
  }

  return (
    <>
      {!isPayout && (
        <PaymentForm
          order={order}
          ui={ui}
          orderError={orderError}
          customer={customer}
          setCustomer={setCustomer}
          confirmFormChange={confirmFormChange}
          setConfirmFormChange={setConfirmFormChange}
          setCreateNewOrder={setCreateNew}
          withToken={!!token}
          setProcessingError={setProcessingError}
          currencies={currencies}
        />
      )}
      {isPayout && (
        <Payout
          currencies={currencies}
          loadOrder={loadOrder}
          token={token}
          setProcessingError={setProcessingError}
        />
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },

  wrapper: {
    'height': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'maxWidth': '1000px',
    'margin': '0 auto',

    '& > div': {
      width: '100%',
    },
  },
  progress: {
    'position': 'absolute',
    'left': 0,
    'top': 0,
    'height': 4,
    'width': '100%',
    'background': 'rgba(0, 0, 0, 0.12)',
    '& .currentProgress': {
      background: theme.palette.primary.main,
      height: 4,
      transition: 'width 500ms ease 0s',
    },
  },
  returnLink: {
    'marginTop': theme.spacing(2),
    'textAlign': 'center',
    '& a': {
      'display': 'inline-flex',
      'alignItemns': 'center',
      '& svg': {
        height: 15,
        marginTop: 1,
        marginRight: -5,
      },
    },
  },
  resultTitle: {
    marginBottom: theme.spacing(2),
  },
  resultText: {
    marginTop: theme.spacing(4),
    color: 'rgba(0,0,0,0.6)',
  },
  resultDetails: {
    marginTop: 16,
    marginBottom: 16,
    color: 'rgba(0,0,0,0.87)',
  },
  resultKyc: {
    background: '#fefaed',
    margin: '33px -40px',
    padding: '26px 40px',
  },
  merchantLogo: {
    position: 'absolute',
    left: 40,
    top: 20,
    height: 42,
  },
}))
