import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import {
  useSetPassword,
  useOTPDisable,
  useChangePassword,
  useOTPDisableConfirm,
  useSetPasswordConfirm,
  useOTPEnable,
  useOTPEnableConfirm
} from '../../../api/private/auth';
import { setLogIn } from '../../../store/actions/account';
import SettingsItem from './settings/Item';
import OTPForm from './settings/OTPForm';
import PasswordForm from './settings/PasswordForm';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    }
  }
}));

interface IProps {
  user: any;
}

export default function ProfileSettings({ user }: IProps) {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [otpForm, setOtpForm] = useState(false);
  const [passwordForm, setPasswordForm] = useState(false);
  const [passwordSetConfirm, setPasswordSetConfirm] = useState(false);
  const [otpConfirm, setOtpConfirm] = useState(false);
  const [passwordErr, setPasswordErr] = useState<string | undefined>();
  const [passwordVal, setPasswordVal] = useState('');
  const [resendCodeTimeout, setResendCodeTimeout] = useState(0);
  const [passChanged, setPassChanged] = useState('');
  const [otpChanged, setOtpChanged] = useState('');
  const [disableOTP, otpDisabling] = useOTPDisable((res: any) => {
    if (res.success) {
      setOtpConfirm(true);
      setResendCodeTimeout(40);
      setOtpChanged('');
    }
  })
  const [confirmOTPDisable, otpDisableConfirming] = useOTPDisableConfirm((res: any) => {
    if (res.success) {
      setOtpConfirm(false);
      setOtpForm(false);
      setOtpChanged('otpDisabled');
      dispatch(setLogIn({ ...user, otpEnabled: false }));
    }
  })
  const [enableOTP, otpEnabling] = useOTPEnable((res: any) => {
    if (res.success) {
      setOtpConfirm(true);
      setResendCodeTimeout(40);
      setOtpChanged('');
    }
  })
  const [confirmOTPEnable, otpEnableConfirming] = useOTPEnableConfirm((res: any) => {
    if (res.success) {
      setOtpConfirm(false);
      setOtpForm(false);
      setOtpChanged('otpEnabled');
      dispatch(setLogIn({ ...user, otpEnabled: true }));
    }
  })
  const [setPassword, passwordSetting] = useSetPassword((res: any) => {
    if (res.success) {
      setPasswordForm(false);
      setPasswordSetConfirm(true);
      setResendCodeTimeout(40);
      setPassChanged('');
    }
  });
  const [confirmSetPassword, passwordSetConfirming] = useSetPasswordConfirm((res: any) => {
      if (res.success) {
        setPasswordForm(false)
        setPasswordSetConfirm(false)
        setPassChanged('passwordEnabled')
        setTimeout(() => dispatch(setLogIn({})), 2000)
      }
    }
  )
  const [changePassword, passwordChanging] = useChangePassword((res: any) => {
    if (res.success) {
      setPasswordErr(undefined);
      setPasswordForm(false);
      setPassChanged('passwordChanged');
    } else {
      setPasswordErr(res.error?.details?.find((d: any) => d.newPassword?.includes('equals to old password'))
        ? 'validation.samePassword' : 'validation.incorrectPassword'
      );
    }
  });

  useEffect(() => {
    if (resendCodeTimeout > 0) {
      setTimeout(() => setResendCodeTimeout(resendCodeTimeout - 1), 1000)
    }
  }, [resendCodeTimeout]);

  const openOtp = () => {
    if (otpForm) return;
    setOtpForm(true);
    setOtpChanged('');
    if (!user.otpEnabled) {
      enableOTP({});
    }
  };

  const closeOtp = () => {
    setOtpConfirm(false);
    setOtpForm(false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={globalClasses.title}>{t('Settings')}</Typography>
      <Table className={globalClasses.tableSimple}>
        <TableBody>
          <SettingsItem
            title={t('One-time password').toString()}
            enabled={user.otpEnabled}
            onOpen={user.passwordEnabled ? openOtp : undefined}
            changed={otpChanged}
            open={otpForm}
          />
          {otpForm && (
            <OTPForm
              enabled={user.otpEnabled}
              isConfirm={otpConfirm}
              isDisabling={otpDisabling}
              disable={disableOTP}
              enable={enableOTP}
              confirmDisable={confirmOTPDisable}
              confirmEnable={confirmOTPEnable}
              sending={otpDisableConfirming || otpEnableConfirming}
              onCancel={closeOtp}
              resendTimeout={resendCodeTimeout}
            />
          )}
          <SettingsItem
            title={t('Password')}
            enabled={user.passwordEnabled}
            changed={passChanged}
            open={passwordForm || passwordSetConfirm}
            onOpen={() => {
              setPassChanged('');
              setPasswordForm(true);
            }}
          />
          {(passwordForm || passwordSetConfirm) && (
            <PasswordForm
              enabled={user.passwordEnabled}
              isConfirm={passwordSetConfirm}
              confirmEnable={confirmSetPassword}
              sending={passwordSetConfirming || passwordSetting || passwordChanging}
              onCancel={() => {
                setPasswordSetConfirm(false);
                setPasswordForm(false);
              }}
              resendTimeout={resendCodeTimeout}
              passwordVal={passwordVal}
              email={user.email}
              setPassword={setPassword}
              changePassword={changePassword}
              setVal={setPasswordVal}
              error={passwordErr}
            />
          )}
        </TableBody>
      </Table>
    </div>
  );
}
