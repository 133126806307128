import update from 'immutability-helper'
import merge from 'lodash/merge'
import { initialUI } from '../../api/merchant'

const initialState = {
  maintenance: false,
  ui: initialUI,
  uiLoaded: false,
  cloudFlareLimit: false,
  customerNotSupported: false,
}

export default function common(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_MAINTENANCE':
      return update(state, { maintenance: { $set: action.maintenance } })
    case 'SET_NOT_SUPPORTED':
      return update(state, {
        customerNotSupported: { $set: action.customerNotSupported },
      })
    case 'SET_UI':
      return update(state, {
        ui: {
          $set: merge(initialUI, action.ui),
        },
        uiLoaded: { $set: true },
      })
    case 'RATE_LIMIT_EXCEEDED':
      return update(state, { cloudFlareLimit: { $set: true } })
    default:
      return state
  }
}
