import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 20,
    left: '50%',
    padding: '12px 12px',
    zIndex: 1000,
    width: 300,
    minHeight: 50,
    backgroundColor: '#202020',
    borderRadius: 8,
    marginLeft: '-150px',
  },
  header: {},
  body: {
    'fontSize': 14,
    'color': '#fff',
    'marginBottom': 14,
    'letterSpacing': 0.25,
    'textAlign': 'center',

    '& a': {
      color: '#fff',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    height: 35,
  },
  select: {
    'color': '#fff',
    'backgroundColor': 'rgba(255, 255, 255, 0.1)',
    'width': 160,
    'textAlign': 'center',
    'outline': 'none',

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  menuItem: {
    'paddingLeft': 30,

    '&.Mui-selected:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: 10,
      backgroundImage:
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='17'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.99993 11.3001L3.19993 8.50006L2.2666 9.4334L5.99993 13.1667L13.9999 5.16673L13.0666 4.2334L5.99993 11.3001Z' fill='black' fill-opacity='0.87'/></svg>\")",
      width: 16,
      height: 17,
    },
  },
  button: {
    'backgroundColor': '#fff',
    'height': 25,
    'width': 140,
    'fontSize': 14,
    'borderRadius': 3,
    'outline': 'none',
    'textTransform': 'capitalize',

    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}))

const isGdprParamSelected = () => {
  let gdprParamIsValid = false
  const gdprJSON = window.localStorage.getItem('cookiesAccepted')
  if (gdprJSON) {
    const gdprObj = JSON.parse(gdprJSON)

    gdprParamIsValid = gdprObj.expired > new Date().getTime()
  }

  return gdprParamIsValid
}

export default function GdprPopup(props: any) {
  const classes = useStyles()
  const [userSelection, setUserSelection] = useState('All')
  const { t } = useTranslation()

  const gdprParamIsSelected = isGdprParamSelected()

  const onChange = (event: any) => {
    const value = event.target.value
    setUserSelection(value)
  }

  const onClick = () => {
    const gdprObj = JSON.stringify({
      expired: new Date().getTime() + 1000 * 60 * 60 * 24 * 90,
      selectedParam: userSelection,
    })

    window.localStorage.setItem('cookiesAccepted', gdprObj)
    props.setUserSelectedGdprParam(true)
  }

  return !gdprParamIsSelected ? (
    <div className={classes.root}>
      <div className={classes.header}></div>
      <div className={classes.body}>
        {t('cookiesAcceptance.We use cookies')}
      </div>
      <div className={classes.footer}>
        {/*    <Select
            className={classes.select}
            value={userSelection}
            onChange={onChange}
            variant='outlined'
          >
            <MenuItem className={classes.menuItem} value='All' key={1}>{t('cookiesAcceptance.All cookies')}</MenuItem>
            <MenuItem className={classes.menuItem} value='Only necessary' key={2}>{t('cookiesAcceptance.Only strictly necessary')}</MenuItem>
          </Select> */}
        <Button className={classes.button} onClick={onClick}>
          {t('cookiesAcceptance.Acknowledged')}
        </Button>
      </div>
    </div>
  ) : null
}
