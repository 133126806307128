import React from 'react'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core'

import { IOrder } from '@/api/order'
import KycRejected from '@/views/Exchange/forms/KycRejected'
import useFormStyles from '@/views/Exchange/forms/useFormStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  progress: {
    'position': 'absolute',
    'left': 0,
    'top': 0,
    'height': 4,
    'width': '100%',
    'display': 'flex',
    '& .step': {
      'background': 'rgba(0, 0, 0, 0.12)',
      'marginRight': 2,
      'flex': '1 1 auto',
      'transition': 'background 300ms ease-in 0s',
      '&:last-child': {
        marginRight: 0,
      },
      '&.active': {
        background: theme.palette.primary.main,
      },
    },
  },
}))

interface IComplianceScreenProps {
  order: IOrder
}

function ComplianceScreen({ order }: IComplianceScreenProps) {
  const classes = useStyles()
  const formClasses = useFormStyles()

  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.progress}>
        <div className="step active" />
        <div className="step" />
        <div className="step" />
      </div>
      <KycRejected classes={formClasses} t={t} order={order} type="kyc" />
    </div>
  )
}
export default ComplianceScreen
