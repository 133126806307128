import React from 'react';
import format from 'date-fns/format'

interface IDateViewProps {
  value: Date | number;
  timeless?: boolean;
}

export default function DateView({ value, timeless }: IDateViewProps) {
  let date = '';
  try {
    date = format(value, `dd.MM.yyyy${!timeless ? ' HH:mm:ss' : ''}`);
  } catch (e) {
  }
  return <>{date}</>
}
