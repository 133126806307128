import { useState } from 'react';
import MailCheck from '../library/MailCheck';

export default function useEmailSuggest(): [string, (fieldState: any) => void] {
  const [suggestion, setSuggestion] = useState('');
  function suggest(fieldState: any) {
    if (!fieldState || !fieldState.value || !fieldState.value.length || (fieldState.touched && !fieldState.valid)) {
      return setSuggestion('');
    }
    if (fieldState.value.includes('@')) {
      MailCheck.run({
        email: fieldState.value,
        suggested: (suggestion: any) => {
          setSuggestion(suggestion.full);
        },
        empty: () => {
          setSuggestion('');
        }
      })
    } else {
      setSuggestion('');
    }
  }

  return [suggestion, suggest];
}
