import React from 'react';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import Typography from '@material-ui/core/Typography';
import Loader from '../../../components/Loader';
import OrdersFilter from '../Orders/OrdersFilter';
import ReferralList from './ReferralList';
import ReferralMobileList from './ReferralMobileList';
import { useReferralBonusList } from '../../../api/private/account';
import useFilter from '../../../hooks/useFilter';
import useGlobalStyles from '../../../hooks/useGlobalStyles';

const emptyFilter = {
  dateFrom: startOfDay(new Date('2020-02-01')).valueOf(),
  dateTo: endOfDay(new Date()).valueOf(),
  currency: null,
};

export default function ReferralProgramPage() {
  const globalClasses = useGlobalStyles();
  const [{ items, full, error }, loading, load] = useReferralBonusList();
  const [setFilter, filter] = useFilter('referral', emptyFilter);
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h4" className={globalClasses.title}>{t('Referral program')}</Typography>
      <OrdersFilter
        filter={filter}
        onChange={setFilter}
        onSubmit={() => load({ filter })}
        onClear={() => {
          setFilter(emptyFilter);
          load({ filter: emptyFilter });
        }}
        type="referral"
        load={load}
      />
      <div className={globalClasses.desktopShow}>
        <ReferralList items={items} loading={loading} />
      </div>
      <div className={globalClasses.mobileShow}>
        <ReferralMobileList items={items} loading={loading} />
      </div>
      <Loader loading={loading} height={items.length > 0 ? 100 : undefined} />
      {!loading && !error && !full && <Waypoint onEnter={() => load({ filter, cursor: items.length, limit: 30 })} />}
    </div>
  );
}
