import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IOrder } from '../../../api/order';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DateView from '../../../components/DateView';
import OrderStatusView from '../../../components/Order/Status';
import OrderTypeView from '../../../components/Order/Type';
import OrderDetails from './Details';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import CurrencyNameMapper from "../../../components/CurrencyNameMapper/CurrencyNameMapper";

interface IProps {
  orders: IOrder[];
  loading?: boolean;
  type?: 'merchant' | 'client';
}

export default function TXMobileList({ orders, loading, type }: IProps) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const [details, setDetails] = useState({} as any);

  if (loading && !orders?.length) return null;

  if (!orders?.length) {
    return <Typography variant="body1" align="center">{t('Order history is empty')}</Typography>;
  }

  return (
    <div>
      {details.id
        ? <OrderDetails order={details} onClose={() => setDetails({} as any)} type={type} />
        : (
          <Table className={globalClasses.tableSimple}>
            <TableBody>
              {orders.map((i) => (
                <TableRow key={i.id} onClick={() => setDetails(i)}>
                  <TableCell>
                    <Typography variant="body2" component="div">
                      <OrderTypeView type={i.type} />
                    </Typography>
                    <Typography variant="body2" component="div">
                      <DateView value={i.createdAt} />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" component="div" className={globalClasses.nowrap} translate="no">
                      <strong>{i.paymentAmount} {i.paymentCurrency}</strong>
                    </Typography>
                    <Typography variant="body2" component="div" color="textSecondary" className={globalClasses.nowrap} translate="no">
                      {i.amount} <CurrencyNameMapper currency={i.currency} />
                    </Typography>
                    <Typography variant="body2" component="div">
                      <OrderStatusView status={i.status} type={type} />
                    </Typography>
                    <Typography variant="body2" component="div" className={globalClasses.nowrap} translate="no">
                      {i.apmData?.lastId}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      }
    </div>
  );
}
