import { useEffect, useState, useRef } from 'react'
import { useCustomerInfo } from '../../api/customer'
import { kycPassedStatuses, KycStatus } from '../../api/kyc'
import { IOrder } from '../../api/order'
import { useKycFlow } from '../../api/private/kyc'
import { IAddressAndTermsProps } from '../../views/components/AddressAndTerms'

export interface IPreVerifyCheckProps extends IAddressAndTermsProps {
  order: IOrder
  setCustomer(customer: any): void
  setStep(step: string): void
}

export default function usePreVerify(
  order: IOrder,
  customer: any,
  setCustomer: any,
  apm?: string
): [any, () => void] {
  const [
    {
      data: { required, flow },
    },
    checkingKyc,
    checkKyc,
  ] = useKycFlow()
  const [{ data: customerInfo }, , loadCustomerInfo] = useCustomerInfo()
  const [waitingKycUpdate, setWaitingKycUpdate] = useState(
    [KycStatus.WAITING_FOR_APPROVAL, KycStatus.PENDING].includes(
      customer.kycStatus
    )
  )
  const kycCheckTimeout = useRef<any>(null)

  function checkCustomerInfo() {
    loadCustomerInfo()
    kycCheckTimeout.current = setTimeout(checkCustomerInfo, 5000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(kycCheckTimeout.current)
    }
  }, [])

  useEffect(() => {
    setData()
  }, [order.id, customer.country, apm])

  const setData = () => {
    if (order.id && customer.country && apm && !order.sf) {
      checkKyc({ orderId: order.id, apmId: apm })
    }
  }

  useEffect(() => {
    setWaitingKycUpdate(
      [KycStatus.WAITING_FOR_APPROVAL, KycStatus.PENDING].includes(
        customer.kycStatus
      )
    )
  }, [customer.kycStatus])

  useEffect(() => {
    if (waitingKycUpdate) {
      checkCustomerInfo()
    }
  }, [waitingKycUpdate])

  useEffect(() => {
    if (
      kycPassedStatuses.includes(customerInfo.kycStatus) ||
      customerInfo.kycStatus === KycStatus.CORRECTION_REQUESTED
    ) {
      clearTimeout(kycCheckTimeout.current)
      checkKyc({ orderId: order.id, apmId: apm })
      setCustomer({ ...customer, kycStatus: customerInfo.kycStatus })
    }
  }, [customerInfo.kycStatus])

  return [[checkingKyc ? false : required, checkingKyc, waitingKycUpdate, flow], setData]
}
