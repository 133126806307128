import React, { FC, useState, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { IOrder } from '../../../../api/order'
import {
  useCustomerPhoneConfirm,
  useCustomerUsBilling,
} from '../../../../api/customer'
import { ICustomer } from '../../../../types/customer'

import PayUSSecurityStep from './PayUSSecurityStep'
import CodePhoneForm from '../CodePhoneForm'
import { trackUsFlowInfo } from '@/helpers/analytics'

interface IPayUsFlowProps {
  order: IOrder
  customer: ICustomer
  setCustomer: Dispatch<SetStateAction<ICustomer>>
}

const PayUSFlow: FC<IPayUsFlowProps> = ({ order, customer, setCustomer }) => {
  const { t } = useTranslation()
  const [phone, setPhone] = useState<string | undefined>(customer.phone)
  const [ssn, setSsn] = useState<string | undefined>(customer.ssn)

  const [confirmError, setConfirmError] = useState('')

  const [saveUsBilling] = useCustomerUsBilling()

  const [confirmPhone] = useCustomerPhoneConfirm()

  if (phone && ssn) {
    return (
      <CodePhoneForm
        phone={phone}
        serverError={confirmError}
        onSubmit={async (code) => {
          try {
            await confirmPhone({ code })
            trackUsFlowInfo(
              'add_phone_otp_success',
              order,
              customer?.country,
              customer?.state,
              'onramp_personal_info'
            )
            setCustomer((prevValues) => {
              return { ...prevValues, phone, ssn }
            })
          } catch (err) {
            setConfirmError(t('wrongCode'))
            console.error(err)
          }
        }}
        onResend={() => {
          saveUsBilling({ phone, ssn })
        }}
      />
    )
  }

  return (
    <PayUSSecurityStep
      order={order}
      customer={customer}
      onProceed={async ({ ssn, phone }) => {
        await saveUsBilling({ ssn, phone })
        setPhone(phone)
        setSsn(ssn)
      }}
    />
  )
}

export default PayUSFlow
