import React from 'react'

import clsx from 'clsx'

import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

import { MIRROR } from '@/helpers/constants'
import { getSupportEmail } from '@/helpers/mirrorHelper'
import useGlobalStyles from '@/hooks/useGlobalStyles'

import LoginLayout from '../Login'
import { useStyles } from '../CloudflareLimit/Cloudflare'

export default function Maintenance() {
  const { t } = useTranslation()
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  return (
    <LoginLayout>
      <div className={classes.icon} />
      <Typography className={classes.title} variant="h6" align="center">
        {t('Service temporary unavailable')}
      </Typography>
      <Typography className={classes.text} variant="body2" align="center">
        {t('tryAgain')}
      </Typography>
      <Link
        className={clsx(globalClasses.button, 'text')}
        component={Button}
        href={getSupportEmail(MIRROR)}
      >
        {t('Contact support')}
      </Link>
    </LoginLayout>
  )
}
