import * as Sentry from '@sentry/react';

export const setLogIn = (data: any) => {
  if (process.env.REACT_APP_SENTRY_URL) {
    let userData = {};
    if (data && Object.keys(data).length) {
      userData = {
        id: data.id
      }
    }
    Sentry.setUser(userData);
  }
  return { type: 'USER_LOGIN_STATE', data }
};

export const setBlockedUser = (unblock?: boolean) => ({ type: 'USER_BLOCKED', unblock });

export const setLogInMethod = (data: any) => ({ type: 'USER_LOGIN_METHOD', data });

export const setRegistrationMethod = (data: any) => ({ type: 'USER_REGISTRATION_METHOD', data });

export const setUserKyc = (data: any) => ({ type: 'USER_KYC_DATA', data });
