import makeStyles from '@material-ui/core/styles/makeStyles';

export const useAccountFormStyles = makeStyles((theme) => ({
  root: {
    width: '300px'
  },
  row: {
    marginBottom: theme.spacing(2),
    '& .hint': {
      textAlign: 'center'
    },
    '& .error': {
      color: theme.palette.error.main,
      fontSize: 12,
      margin: 0
    }
  },
  button: {
    width: '100%',
    height: '40px',
  },
  link: {
    cursor: 'pointer'
  }
}));
