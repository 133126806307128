import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TextCopy from '../TextCopy';
import { useAccountStyles } from '../../hooks/useAccountStyles';

interface IProps {
  chars: number;
  text?: string;
  copy?: boolean;
}

export default function ShortString({ chars, text, copy }: IProps) {
  const classes = useAccountStyles();
  if (!text) return null;

  if (text.length <= chars) return (
    <div>
      {text}
      {copy && <TextCopy text={text} />}
    </div>
  );

  return (
    <Tooltip title={text} placement="top-end">
      <span className={classes.tooltiped}>
        {text.substring(0, chars)}...
        {copy && <TextCopy text={text} />}
      </span>
    </Tooltip>
  );
}
