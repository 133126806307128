import React, { useEffect, useState, FC } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { Input } from '@/components/Form'
import {
  composeValidators,
  isEmail,
  lengthRequired,
  required,
} from '@/validators'
import { useAccountFormStyles } from '@/hooks/useAccountFormStyles'
import { useCustomerLogin, useSignUp } from '@/api/private/auth'
import plural from '@/helpers/plural'
import Link from '@material-ui/core/Link'
import ServerMessage from '@/components/UI/ServerMessage'
import { setLogIn, setRegistrationMethod } from '@/store/actions/account'
import captchaRequest from '@/helpers/captcha'
import { getRequestError } from '@/helpers/error'
import { useCustomerBind } from '@/api/customer'

interface ISignUpProps {
  email: string
  setEmail: any
  classes: any
  goBack: any
  externalId?: string
  sid?: string
}

const SignUp: FC<ISignUpProps> = ({
  classes,
  email,
  setEmail,
  goBack,
  sid,
  externalId,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formClasses = useAccountFormStyles()
  const [resendCodeTimeout, setResendCodeTimeout] = useState(40)
  const [serverError, setServerError] = useState('')
  const { bindCustomer } = useCustomerBind({ sid, externalId })

  const [getCode] = useCustomerLogin((res: any) => {
    if (res.success) {
      setResendCodeTimeout(40)
    }
  })

  const [send, sending] = useSignUp(async (res: any) => {
    if (res.success) {
      let isBinded = false
      try {
        isBinded = await bindCustomer()
      } catch (error) {
        setServerError(getRequestError({ error }))
        return
      }
      if (isBinded) {
        setTimeout(() => {
          history.replace('/account/kyc')
        })
      }
      dispatch(setLogIn(res.data))
      return dispatch(setRegistrationMethod('email'))
    }
    setServerError('authError')
  })
  const initValues = {
    email,
  }

  useEffect(() => {
    if (resendCodeTimeout > 0) {
      setTimeout(() => setResendCodeTimeout(resendCodeTimeout - 1), 1000)
    }
  }, [resendCodeTimeout])

  function resendCode() {
    captchaRequest(
      '/api/private/v1/auth/login',
      getCode,
      { login: email },
      () => setServerError('captchaError')
    )
  }

  return (
    <Form
      initialValues={initValues}
      onSubmit={(values) => {
        if (sending) {
          return
        }
        setEmail(values.email)
        captchaRequest('/api/private/v1/auth/register', send, values, () =>
          setServerError('captchaError')
        )
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.noUser}>
            <IconButton onClick={goBack} className={classes.stepBack}>
              <ArrowRightAltIcon />
            </IconButton>
            <Typography variant="body2">
              <p
                dangerouslySetInnerHTML={{
                  __html: t('userNotFound', {
                    loginType: t('loginTypeEmail'),
                    login: values.email,
                  }),
                }}
              />
            </Typography>
          </div>
          <Typography variant="h4" className={classes.title}>
            {t('createAnAccount')}
          </Typography>
          {serverError.length > 0 && (
            <ServerMessage type="error" text={serverError} />
          )}
          {email && (
            <div>
              <Input
                name="email"
                label=""
                validate={composeValidators(required, isEmail)}
                type="hidden"
                fullWidth
                disabled
                required={false}
              />
            </div>
          )}
          <div>
            <Input
              name="code"
              label={t('labels.Authorization code')}
              validate={composeValidators(required, (v: any) =>
                lengthRequired(v, 6)
              )}
              type="number"
              fullWidth
              autoFocus
            />
            <OnChange name="code">
              {(val: string) => {
                if (val.length === 6) {
                  handleSubmit()
                }
              }}
            </OnChange>
          </div>
          <div className={classes.hint}>
            <p>
              {t('Authorisation code was sent to your')} {t('email')}
            </p>
            {resendCodeTimeout > 0 ? (
              <p className={classes.resendTimeout} translate="no">
                {t('Resend confirmation code in')} {resendCodeTimeout}{' '}
                {t(
                  plural(resendCodeTimeout, [
                    'secPlural1',
                    'secPlural2',
                    'secPlural5',
                  ])
                )}
                .
              </p>
            ) : (
              <p>
                {t('notReceivedCode')}&nbsp;
                <Link onClick={resendCode} className={formClasses.link}>
                  {t('Resend code')}
                </Link>
              </p>
            )}
          </div>
        </form>
      )}
    />
  )
}

export default SignUp
