import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DateView from '../../../components/DateView';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import { IReferralListProps } from './ReferralList';

export default function ReferralMobileList({ items, loading }: IReferralListProps) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  if (loading && !items.length) return null;

  if (!items.length) {
    return <Typography variant="body1" align="center">{t('Referral history is empty')}</Typography>;
  }

  return (
    <div>
      <Table className={globalClasses.tableSimple}>
        <TableBody>
          {items.map((i) => (
            <TableRow key={i.id}>
              <TableCell>
                <Typography variant="body2" component="div">
                  {t('Order')} #{i.orderId}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" component="div">
                  <DateView value={i.createdAt} />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" component="div" className={globalClasses.nowrap}>
                  <strong>{i.amount} {i.currency}</strong>
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
