import makeStyles from '@material-ui/core/styles/makeStyles'

import error from '@/icons/error-icon.svg'

export const useNewsletterStyles = makeStyles((theme) => ({
  root: {},
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '0 16px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(375)]: {
      display: 'block',
    },
  },
  title: {
    color: '#0F1318',
    textAlign: 'center',
    fontSize: '40px',
    fontWeight: 650,
    lineHeight: '120%',
    letterSpacing: '-1px',
    margin: '0px 0px 12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      letterSpacing: '-0.75px',
    },
  },
  subtitle: {
    color: '#5A5D6A',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 450,
    lineHeight: '150%',
    letterSpacing: '-0.08px',
    margin: '0px 0px 48px',
  },
  field: {
    'width': '343px',
    [theme.breakpoints.down(375)]: {
      width: '100%',
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '12px',
      padding: '14.5px 14px',
    },
    'paddingBottom': '0px',
    '& .MuiOutlinedInput-root': {
      'height': 48,
      'borderRadius': '12px',
      'backgroundColor': '#FFFFFF',
      '& fieldset': {
        borderColor: '#ADADB9',
      },
      '&:hover fieldset': {
        borderColor: '#ADADB9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ADADB9',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: '3px solid #E7293E',
      },
    },
    '& .MuiFormHelperText-root': {
      backgroundImage: `url(${error})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left 3px',
      paddingLeft: '20px',
      top: 49,
      fontWeight: 450,
      lineHeight: '150%',
      marginLeft: '0px',
      marginRight: '0px',
      fontSize: '14px',
    },
  },
  fieldWrapper: {
    marginBottom: '28px',
  },
  button: {
    'width': '343px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'minHeight': '52px',
    'borderRadius': '16px',
    'backgroundColor': '#0C0D0F',
    'color': '#FFFFFF',
    'fontSize': '16px',
    'fontWeight': 600,
    'lineHeight': '150%',
    'letterSpacing': '0.08px',
    'textTransform': 'capitalize',
    'opacity': '1',
    'transition': 'all 0.3s linear 0s',
    '&:hover': {
      backgroundColor: '#0C0D0F',
      opacity: 0.8,
      transition: 'all 0.3s linear 0s',
    },
    '&:disabled': {
      backgroundColor: '#0C0D0F',
      opacity: 0.42,
      color: '#FFFFFF',
      transition: 'all 0.3s linear 0s',
    },
    [theme.breakpoints.down(375)]: {
      width: '100%',
    },
  },
  imageWrapper: {
    textAlign: 'center',
  },
  image: {
    marginBottom: '24px',
  },
}))
