import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Input, Select, Upload, IUploadedFile } from '../../components/Form';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { required } from '../../validators';
import { DocumentType } from '../../api/private/kyc';
import ImageIcon from '../../icons/image.svg';
import { PhotoTypes } from '../Exchange/forms/VerificationForm';

const useStyles = makeStyles((theme) => ({
  root: {},
  documents: {
    marginTop: theme.spacing(4)
  },
  document: {
    display: 'flex',
    height: 37,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0
    }
  },
  docInfo: {
    display: 'flex',
    height: 37,
    alignItems: 'center',
  },
  preview: {
    width: 37,
    height: 37,
    marginRight: theme.spacing(2),
    border: '1px solid ' + theme.palette.divider,
    borderRadius: 4,
    background: `url(${ImageIcon}) no-repeat center`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      maxHeight: 35,
      maxWidth: 35,
      width: 'auto',
      height: 'auto',
      borderRadius: 4
    }
  },
  loader: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
    color: '#fff',
    flex: '0 0 37px',
    marginRight: 0
  },
  remove: {
    padding: 6,
    '&.abort': {
      color: theme.palette.error.main
    }
  }
}))

enum MonthlyIncome {
  FROM_10000 = 'FROM_10000',
  FROM_5000_TO_10000 = 'FROM_5000_TO_10000',
  BELOW_5000 = 'BELOW_5000'
}

interface IProps {
  onSave(data: any): void;
  saving: boolean;
}

export default function Income({ onSave, saving }: IProps) {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<IUploadedFile[]>([]);
  const [loadingDoc, setLoadingDoc] = useState<IUploadedFile | undefined>();

  useEffect(() => {
    if (loadingDoc) {
      const docs = [...documents];
      const i = docs.findIndex((d) => d.name === loadingDoc.name && d.type === loadingDoc.type);
      if (i !== -1) {
        docs[i] = loadingDoc;
      } else if (!loadingDoc.data) {
        docs.push(loadingDoc);
      }
      setDocuments(docs);
    }
  }, [loadingDoc])

  return (
    <Form
      onSubmit={() => {
        if (documents[0]?.data) {
          onSave({ documents: { [DocumentType.PROOF_OF_FUND]: String(documents[0].data).split(',')[1] } });
        }
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Typography
            variant="body2"
            component="div"
            className="pof-formTitle"
          >
            {t('Please select your monthly income')}
          </Typography>
          <Select
            name="monthlyIncome"
            label={t('Monthly income')}
            options={Object.values(MonthlyIncome).map((v) => ({ value: v, label: t(v) }))}
            validate={required}
            useEmptyOption
          />
          {values.monthlyIncome === MonthlyIncome.FROM_10000 && (
            <Input
              name="expectMonthlyTurnover"
              label={t('Expect monthly turnover')}
              validate={required}
              fullWidth
            />
          )}
          <Typography
            variant="body2"
            component="div"
            className="pof-formTitle"
          >
            {t('proofOfFundsClarifications')}
          </Typography>
          <Input
            name="comment"
            label={t('Comment')}
            validate={required}
            fullWidth
          />
          <Typography
            variant="body2"
            component="div"
            className="pof-formTitle"
          >
            {t('proofOfFundsDocumentsText')}
          </Typography>
          {documents.length > 0 && (
            <div className={classes.documents}>
              {documents.map((d, i) => (
                <div className={classes.document} key={i}>
                  <div className={classes.docInfo}>
                    <div className={classes.preview}>
                      {!d.data
                        ? <div className={clsx(classes.loader, classes.preview)}><CircularProgress color="inherit" size={20} /></div>
                        : !d.type.includes('pdf') && <img src={d.data.toString()} alt={d.name} />
                      }
                    </div>
                    <div>
                      <Typography variant="body2" component="div" color={d.data ? 'textPrimary' : 'textSecondary'}>
                        {d.name}
                      </Typography>
                      <Typography variant="body2" component="div" color="textSecondary">
                        {d.size}
                      </Typography>
                    </div>
                  </div>
                  <IconButton
                    onClick={() => {
                      const docs = [...documents];
                      docs.splice(i, 1);
                      setDocuments(docs);
                    }}
                    className={clsx(classes.remove, { abort: !d.data })}
                  >
                    {d.data ? <DeleteForeverIcon /> : <CancelRoundedIcon color="inherit" />}
                  </IconButton>
                </div>
              ))}
            </div>
          )}
          <Upload
            onLoad={setLoadingDoc}
            input={{
              accept: 'image/*, application/pdf',
              multiple: false,
              name: 'documents'
            }}
            type={PhotoTypes.ProofOfFunds}
            className={classes.documents}
          />
          <Button
            className={clsx(globalClasses.button, 'pof-button')}
            variant="contained"
            color="primary"
            type="submit"
            disabled={saving}
          >
            {saving ? <CircularProgress size={22} color="inherit" /> : t('Finish verification')}
          </Button>
        </form>
      )}
    />
  )
}
