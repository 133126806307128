import { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'
import { AxiosRequestConfig, AxiosPromise } from 'axios'

import {
  setMaintenance,
  setCloudflareLimitError,
  setCustomerNotSupported,
} from '../store/actions/common'
import { setBlockedUser, setLogIn } from '../store/actions/account'
import { clearAuthToken, getAuthToken, setAuthToken } from '../api'
import isCFError from '../helpers/limitError'
import createReport from '../sentryReporter'

export default function useUpdateApi<ReturnType = void>(
  remote: any,
  callback: any,
  sentryIgnoreErrors: string[] = []
): [(...args: any) => Promise<ReturnType>, boolean] {
  const [updating, setUpdating] = useState(false)
  const dispatch = useDispatch()
  const requestedOrder = useRef(null)

  const req = async (data: any, options?: any) => {
    if (
      data.orderId &&
      requestedOrder.current !== undefined &&
      requestedOrder.current === data.orderId
    )
      return
    requestedOrder.current = data.orderId
    setUpdating(true)
    const opts = options
      ? { ...options, headers: { ...options.headers, ...getAuthToken() } }
      : { headers: { ...getAuthToken() } }
    try {
      const res = await remote(data || {}, opts)

      if (res.headers && res.headers['x-auth-token']) {
        if (res.headers['x-auth-token'] === '-') {
          clearAuthToken()
          dispatch(setLogIn({}))
        } else {
          setAuthToken(res.headers['x-auth-token'])
        }
      }

      callback?.(res.data)
      dispatch(setBlockedUser(true))
      setUpdating(false)
      requestedOrder.current = null
      return res
    } catch (e) {
      if (e.response) {
        if (
          !e.response.data?.error &&
          e.response.status.toString().startsWith('5')
        ) {
          dispatch(setMaintenance(true))
        }
        if (
          e.response?.data?.error?.type === 'CUSTOMER_NOT_SUPPORTED' ||
          e.response?.data?.error?.type === 'ACCOUNT_HAS_RESTRICTIONS'
        ) {
          dispatch(setCustomerNotSupported(true))
        }
        if (e.response.status.toString() === '429' && isCFError(e.response)) {
          dispatch(setCloudflareLimitError())
        }
        if (e.response.data?.error) {
          if (
            process.env.REACT_APP_SENTRY_URL &&
            !sentryIgnoreErrors.includes(e.response.data.error.type)
          ) {
            const report = createReport('useUpdateApi', e)
            report && Sentry.captureException(report?.error, report?.data)
          }
          // if (e.response.data.error.type === 'ACCESS_DENIED') {
          //   dispatch(setBlockedUser());
          // }
          if (e.response.data.error.type === 'UNAUTHORIZED') {
            clearAuthToken()
            dispatch(setLogIn({}))
          }
          callback?.(e.response.data)
        }
      }
      setUpdating(false)
      requestedOrder.current = null
    }
  }

  return [req, updating]
}

export function useUnauthorizedUpdateApi(
  remote: any,
  callback: any
): [(...args: any) => void, boolean] {
  const [updating, setUpdating] = useState(false)
  const dispatch = useDispatch()

  const req = async (data: any, options?: any) => {
    setUpdating(true)
    try {
      const res = await remote(data || {}, options)

      callback(res.data)
      dispatch(setBlockedUser(true))
    } catch (e) {
      if (e.response) {
        if (e.response.status.toString().startsWith('5')) {
          dispatch(setMaintenance(true))
        }
        if (e.response.status.toString() === '429' && isCFError(e.response)) {
          dispatch(setCloudflareLimitError())
        }
        if (e.response.data && e.response.data.error) {
          callback(e.response.data)
        }
      }
    }

    setUpdating(false)
  }

  return [req, updating]
}

type RemoteRequestType<DataType, ReturnType> = (
  data?: DataType,
  configs?: AxiosRequestConfig
) => AxiosPromise<ReturnType>

interface IUseUpdateApiPromise<DataType, ReturnType> {
  remote: RemoteRequestType<DataType, ReturnType>
  sentryIgnoreErrors?: string[]
}

export const useUpdateApiPromise = <
  DataType extends Record<string, any>,
  ReturnType
>({
  remote,
  sentryIgnoreErrors = [],
}: IUseUpdateApiPromise<DataType, ReturnType>) => {
  const [updating, setUpdating] = useState(false)
  const dispatch = useDispatch()

  const req = async (
    data: DataType = {} as Record<any, any>,
    options?: AxiosRequestConfig
  ) => {
    setUpdating(true)
    const opts = options
      ? { ...options, headers: { ...options.headers, ...getAuthToken() } }
      : { headers: { ...getAuthToken() } }
    try {
      const res = await remote(data || {}, opts)

      if (res.headers && res.headers['x-auth-token']) {
        if (res.headers['x-auth-token'] === '-') {
          clearAuthToken()
          dispatch(setLogIn({}))
        } else {
          setAuthToken(res.headers['x-auth-token'])
        }
      }

      dispatch(setBlockedUser(true))
      setUpdating(false)
      return res
    } catch (err) {
      if (err.response) {
        if (
          !err.response.data?.error &&
          err.response.status.toString().startsWith('5')
        ) {
          dispatch(setMaintenance(true))
        }
        if (
          err.response.status.toString() === '429' &&
          isCFError(err.response)
        ) {
          dispatch(setCloudflareLimitError())
        }
        if (err.response.data?.error) {
          if (
            process.env.REACT_APP_SENTRY_URL &&
            !sentryIgnoreErrors.includes(err.response.data.error.type)
          ) {
            const report = createReport('useUpdateApi', err)
            report && Sentry.captureException(report?.error, report?.data)
          }

          if (err.response.data.error.type === 'UNAUTHORIZED') {
            clearAuthToken()
            dispatch(setLogIn({}))
          }
        }
      }
      setUpdating(false)
      throw err
    }
  }

  return [req, updating] as [typeof req, boolean]
}
