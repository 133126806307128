import React from 'react'

import { useTranslation } from 'react-i18next'

import { IAPMField } from '@/api/settings'
import { Input } from '@/components/Form'
import { composeValidators, required, validateByRegex } from '@/validators'

import usePaymentFormStyles from '../usePaymentFormStyles'

interface IApmFieldsProps {
  fields: IAPMField[]
  isFormFilled: boolean
}

function ApmFields({ fields, isFormFilled = false }: IApmFieldsProps) {
  const { t } = useTranslation()

  const paymentFormClasses = usePaymentFormStyles()

  if (isFormFilled) return null

  return (
    <div>
      <div className={paymentFormClasses.blockTitle}>
        {t('Payment Information')}
      </div>

      {fields.map((field) => {
        return (
          <Input
            key={field.id}
            name={field.id}
            label={field.localizedName || t(field.id)}
            validate={composeValidators(required, (v: string) =>
              field.validation
                ? validateByRegex(v, field.validation)
                : undefined
            )}
            fullWidth
          />
        )
      })}
    </div>
  )
}

export default ApmFields
