import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'

import BlockIcon from '@material-ui/icons/Block'
import makeStyles from '@material-ui/core/styles/makeStyles'

const NftSoldOut: FC = () => {
  const { t } = useTranslation()
  const classes = useNFTSoldOutStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <BlockIcon className={classes.icon} fontSize="large" color="error" />
        <Typography className={classes.caption} variant="h2" color="error">
          {t('labels.soldOut')}
        </Typography>
      </div>
    </div>
  )
}

const useNFTSoldOutStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: 'rgb(255, 255, 255, 0.7)',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: '40%',
    height: '40%',
  },
  caption: {
    fontWeight: 800,
  },
}))

export default NftSoldOut
