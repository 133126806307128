import makeStyles from '@material-ui/core/styles/makeStyles';

const useConfirmModalStyles = makeStyles((theme) => ({
  backdrop: {
    background: 'rgba(0, 0, 0, 0.6)',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  modal: {
    width: '65%',
    maxWidth: '560px',
    backgroundColor: 'white',
    boxShadow: '0 4px 34px rgba(0, 0, 0, 0.25)',
    padding: '30px 18px',
    borderRadius: '5px',
    textAlign: 'center',
  },
  hidden: {
    display: 'none',
  },
  verticalMargin: {
    margin: '16px 0',
  },
  caption: {
    color: '#8B959E',
    display: 'block',
    margin: '1rem 0',
  },
  btnPanel: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  alertIcon: {
    height: '40px',
    width: '40px'
  }
}));


export default useConfirmModalStyles