import makeStyles from '@material-ui/core/styles/makeStyles'

export const useSubaccountBalanceRowStyles = makeStyles((theme) => ({
  cellTotal: {
    fontWeight: 600,
  },
  cellCheckbox: {
    paddingLeft: 0,
  },
  cellPercentage: {
    width: '150px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  cellAmount: {
    width: '174px',
    paddingLeft: 0,
  },
  disabled: {
    opacity: '0.5',
  },
  field: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    'paddingBottom': '0px',
    'verticalAlign': 'bottom',
    'borderRadius': '8px',
    'background': 'rgba(125, 147, 168, 0.08)',
    '& .MuiOutlinedInput-input': {
      padding: '6px 2px 6px 8px',
      height: '36px',
      boxSizing: 'border-box',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(125, 147, 168, 0.08)',
    },
    '& .MuiInputBase-input': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiFormHelperText-root': {
      top: 37,
      left: '-11px',
      whiteSpace: 'nowrap',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '6px',
    },
  },
  available: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  inputAmount: {
    width: '166px',
  },
  inputPercentage: {
    width: '84px',
  },
  selectedRow: {
    backgroundColor: '#edf4fb',
  },
  checked: {
    'backgroundColor': '#FFF',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.12)',
    },
    '& .MuiInputBase-input': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  checkbox: {
    margin: '0px',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#707070',
    cursor: 'pointer',
  },
  row: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
}))
