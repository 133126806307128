import { IOrder } from '@/api/order'
import { VIEW_FOR_APMS, isSf } from './constants'

export function getLayoutApmView(step: string | undefined, order?: IOrder) {
  if (
    (isSf &&
      order?.apm &&
      order?.apm !== 'CARD' &&
      (step === 'card' || step === 'transaction')) ||
    (order?.sf &&
      order?.apm &&
      VIEW_FOR_APMS.includes(order?.apm) &&
      step === 'card')
  ) {
    return true
  }
  return false
}
