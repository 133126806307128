import React, { FC } from 'react'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'

import AlertIcon from '../../icons/alert.svg';

import useStyles from './ConfirmModalStyles'
import useGlobalStyles from "../../hooks/useGlobalStyles";

interface IConfirmModalProps {
  isShown: boolean;
  title?: string;
  text?: string;
  caption?: string;
  onCancel(): void;
  onConfirm(): void;
  confirmBtnText?: string;
  returnBtnText?: string;
}

const ConfirmModal: FC<IConfirmModalProps> = ({
  isShown,
  title,
  text,
  caption,
  onConfirm,
  onCancel,
  confirmBtnText,
  returnBtnText,
}) => {

  const globalClasses = useGlobalStyles();
  const styles = useStyles()

  return (
    <div
      className={clsx(styles.backdrop, {
        [styles.hidden]: !isShown
      })}
    >
      <div className={styles.modal}>
        <img className={styles.alertIcon} src={AlertIcon} alt="info" />
        {title && (
          <h2 className={styles.verticalMargin}>{title}</h2>
        )}
        {text && <p className={styles.verticalMargin}>{text}</p>}
        {caption && (
          <p
            className={clsx(
              styles.caption,
              styles.verticalMargin
            )}
          >
            {caption}
          </p>
        )}
        <div className={styles.btnPanel}>
          <Button
            className={globalClasses.button}
            type="button"
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            {confirmBtnText}
          </Button>

          <Button
            className={globalClasses.button}
            type="button"
            variant="contained"
            onClick={onCancel}
          >
            {returnBtnText}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal
