export default {
  root: {
    position: 'absolute',
    left: 0,
    top: 62,
    lineHeight: 1,
    '&.Mui-disabled': {
      display: 'none',
    },
  }
};
