import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ErrorIcon from '@material-ui/icons/Error'
import { fade } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import Header from './Header'
import MainMenu from './Menu'
import LoginPage from '../../../views/Account/Login'
import Loader from '../../Loader'
import EmailSetForm from '../../../views/Account/Login/EmailSetForm'
import ResidenceRestricted from '../../../views/Account/Profile/ResidenceRestricted'
import LoginLayout from '../Login'
import {
  getAppleTouchIcon,
  getDescription,
  getFavicon,
  getKycAmlPolicyLink,
  getPrivacyPolicyLink,
  getTermsOfUseLink,
  getTitle,
  isChainValley,
} from '@/helpers/mirrorHelper'
import Panel from '../../Panel/Panel'
import ServerMessage from '../../UI/ServerMessage'
import { useCustomerInfo } from '../../../api/customer'
import { useCustomerLogout } from '../../../api/private/auth'
import { setLogIn } from '../../../store/actions/account'
import { useCountries } from '../../../api/settings'
import { useAccountFormStyles } from '../../../hooks/useAccountFormStyles'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import { useAcceptTerms } from '../../../api/private/account'
import { useKycDetails } from '../../../api/private/kyc'
import { KycStatus } from '../../../api/kyc'
import { useCardsList } from '../../../api/private/payment'
import i18n from '../../../i18n'
import LanguageSelect from '../../LanguageSelect'
import { INTERNAL_LINK_TARGET_BLANK } from '../../../helpers/internalTarget'
import appstore from '@/components/icons/appstore.png'
import googleplay from '@/components/icons/googleplay.png'
import { MIRROR } from '@/helpers/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    padding: '0 0 100px',
    boxSizing: 'border-box',
    position: 'relative',
    fontSize: 12,
    background: '#f2f2f2',
  },
  wrap: {
    minHeight: 'calc(100vh - 270px)',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  content: {
    padding: `${theme.spacing(2)}px 5px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  bottom: {
    width: 1142,
    margin: '16px auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  kycPanel: {
    'background': fade('#F2994A', 0.1),
    'margin': '0 -40px',
    'padding': '24px 73px',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    [theme.breakpoints.down('xs')]: {
      margin: '0 -20px',
      padding: '24px 20px',
    },
    '& .data': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1,
    },
    '& .icon': {
      'marginRight': theme.spacing(2),
      'color': '#F2994A',
      '& svg': {
        height: 20,
      },
    },
  },
  kycPanelEmpty: {
    height: theme.spacing(4),
  },
  updButton: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
}))

interface IProps {
  children: any
}

interface IContentProps extends IProps {
  menuOpen: boolean
  closeMenu: any
  logged: boolean
  user: any
  restrictedCountry: any
  countries: any[]
  logout: any
  url: string
  kyc: any
  hasCards: boolean
  hideLogo?: boolean
}

const reCaptcha3Key =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  '6LeIDKgZAAAAABpUZ9JyPtgHEf_OwylbsKDcrPBR'

function Content({
  children,
  menuOpen,
  closeMenu,
  logged,
  user,
  logout,
  kyc,
  hasCards,
  hideLogo,
}: IContentProps) {
  const accFormClasses = useAccountFormStyles()
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const isMerchant =
    user && user.authorities && user.authorities.includes('ROLE_MERCHANT')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [acceptTerms] = useAcceptTerms((res: any) => {
    if (res.success) {
      dispatch(setLogIn({ ...user, termsAccepted: true }))
    }
  })
  if (logged) {
    if (!user.termsAccepted) {
      return (
        <Panel hideLogo={hideLogo}>
          <Typography variant="body1" align="center" component="div">
            <p>
              {t('acceptTermsText')}{' '}
              <Link href={getTermsOfUseLink(MIRROR)} target="_blank">
                {t('terms of use')}
              </Link>
            </p>
            <p>
              <Button
                className={globalClasses.button}
                color="primary"
                variant="contained"
                onClick={() =>
                  acceptTerms({
                    termsAccepted: true,
                  })
                }
              >
                {t('Accept terms')}
              </Button>
            </p>
            <p>
              <Link onClick={logout} className={accFormClasses.link}>
                {t('Return to log in page')}
              </Link>
            </p>
          </Typography>
        </Panel>
      )
    }

    // TODO ZHPW-1080
    // if (!user.proofOfFunds) {
    //   return <ProofOfFunds logout={logout} />;
    // }

    const corrections: any[] = []
    if (kyc && kyc.corrections) {
      kyc.corrections.forEach((c: any) => {
        if (!c.resolved && !corrections.includes(t(c.type))) {
          corrections.push(t(c.type))
        }
      })
    }

    const kycStatus =
      kyc && kyc.status ? kyc.status : user.kycStatus || KycStatus.NONE
    const isKycContent = window.location.href.includes('account/kyc')

    return (
      <Panel width={1142} hideLogo={hideLogo}>
        <MainMenu open={menuOpen} closeMenu={closeMenu} hasCards={hasCards} />
        {![
          KycStatus.TRUSTED,
          KycStatus.APPROVED,
          KycStatus.REVIEWED,
          KycStatus.WAITING_FOR_APPROVAL,
        ].includes(kycStatus) &&
          !isKycContent &&
          !isMerchant && (
            <div className={classes.kycPanel}>
              <div className="data">
                <div className="icon">
                  <ErrorIcon />
                </div>
                <div>
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    {t(`kycPanel.notCompleted`)}
                  </Typography>
                  {/* {corrections.length > 0 && (
                  <Typography variant="body2">
                    {corrections.join(', ')}
                  </Typography>
                )} */}
                  {(kycStatus === KycStatus.NONE ||
                    kycStatus === KycStatus.PARTIAL) && (
                    <Typography variant="body2">
                      {t('kycPanel.accessAll')}
                    </Typography>
                  )}
                </div>
                <Link
                  to="/account/kyc"
                  style={{ textDecoration: 'none', marginLeft: '20px' }}
                  component={NavLink}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.updButton}
                  >
                    {t('continue')}
                  </Button>
                </Link>
              </div>
            </div>
          )}
        {isKycContent && <div className={classes.kycPanelEmpty}></div>}
        <div className={classes.content}>{children}</div>
      </Panel>
    )
  }
  return <LoginPage />
}

export default function AccountLayout({ children }: IProps) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [, loading, load] = useCustomerInfo(setLogIn)
  const [{ data: countries }, loadingCountries, loadCountries] = useCountries()
  const [{ data: cards }, loadingCards, loadCards] = useCardsList()
  const [, loadingKyc, loadKyc] = useKycDetails()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const dispatch = useDispatch()
  const [logout, logoutProcess] = useCustomerLogout((res: any) => {
    if (res.success) {
      dispatch(setLogIn({}))
    }
  })
  const history = useHistory()
  const match = useRouteMatch()
  const { url } = match
  const { user, userLoaded, kyc, isUserBlocked } = useSelector(
    (state: any) => state.account
  )

  useEffect(() => {
    // @ts-ignore
    if (!window.grecaptcha) {
      const s = document.createElement('script')
      s.src = `https://www.google.com/recaptcha/api.js?render=${reCaptcha3Key}`
      s.onload = function () {
        setRecaptchaLoaded(true)
      }
      s.onerror = function () {
        setRecaptchaLoaded(true)
      }
      s.onabort = function () {
        setRecaptchaLoaded(true)
      }
      document.body.appendChild(s)
    } else {
      setRecaptchaLoaded(true)
    }
    load()
  }, [])

  useEffect(() => {
    if (user.id) {
      loadCountries()
      loadCards()
    }
    if (user.id && !user.kycStatus) {
      loadKyc()
    }
  }, [user.id])

  const logged = user && Object.keys(user).length > 0 && !user.restricted

  useEffect(() => {
    if (!isUserBlocked) {
      if (logged && url === '/account/login') {
        history.push('/account/info')
      }
      if (userLoaded && !loading && !logged) {
        history.push('/account/login')
      }
    }
  }, [logged, url, loading, userLoaded, isUserBlocked])

  if (isUserBlocked) {
    return (
      <LoginLayout>
        <ServerMessage type="error" text="Authorization blocked" />
      </LoginLayout>
    )
  }

  const uCountry =
    user && user.country
      ? countries.find((c: any) => c.code === user.country)
      : undefined
  const restrictedCountry = uCountry && !uCountry.supported && uCountry.name

  if (logged && (!user.email || !user.country)) {
    return <EmailSetForm user={user} countries={countries} />
  }

  if (restrictedCountry) {
    return (
      <LoginLayout>
        <ResidenceRestricted logout={logout} />
      </LoginLayout>
    )
  }

  return (
    <Loader
      loading={
        !userLoaded ||
        loading ||
        logoutProcess ||
        !recaptchaLoaded ||
        loadingCountries ||
        loadingCards
      }
    >
      <Helmet>
        <link rel="icon" href={getFavicon(MIRROR)} />
        <link rel="apple-touch-icon" href={getAppleTouchIcon(MIRROR)} />
        <title>{getTitle(MIRROR)}</title>
      </Helmet>
      <div className={classes.root}>
        {logged && (
          <Header
            loggedIn={
              user.email &&
              user.country &&
              user.termsAccepted &&
              !restrictedCountry
            }
            logout={logout}
            email={user.email}
          />
        )}
        <div className={classes.wrap}>
          <Content
            menuOpen={showMenu}
            closeMenu={() => setShowMenu(false)}
            logged={logged}
            user={user}
            restrictedCountry={restrictedCountry}
            countries={countries}
            logout={logout}
            url={url}
            kyc={kyc}
            hasCards={cards.length > 0}
            hideLogo
          >
            {children}
          </Content>
        </div>
        <div>
          <div className={clsx(globalClasses.bottom, classes.bottom)}>
            <List component="nav" className={globalClasses.menu}>
              <ListItem className={globalClasses.bottomMenuLink}>
                <Link
                  href={getPrivacyPolicyLink(MIRROR)}
                  target={INTERNAL_LINK_TARGET_BLANK}
                >
                  {t('Privacy policy')}
                </Link>
              </ListItem>
              <ListItem className={globalClasses.bottomMenuLink}>
                <Link
                  href={getTermsOfUseLink(MIRROR)}
                  target={INTERNAL_LINK_TARGET_BLANK}
                >
                  {t('Terms of use')}
                </Link>
              </ListItem>
              <ListItem className={globalClasses.bottomMenuLink}>
                <Link
                  href={getKycAmlPolicyLink(MIRROR)}
                  target={INTERNAL_LINK_TARGET_BLANK}
                >
                  {t('KYC / AML policy')}
                </Link>
              </ListItem>
            </List>
            <LanguageSelect />
          </div>
          {!isChainValley && (
            <div>
              <div className={globalClasses.promoButtons}>
                <Link
                  href="https://cutt.ly/appstorewidget"
                  target={INTERNAL_LINK_TARGET_BLANK}
                >
                  <img
                    className={globalClasses.promoBtn}
                    src={appstore}
                    width={160}
                    height={46}
                  />
                </Link>
                <Link
                  href="https://cutt.ly/6wbxnrJT"
                  target={INTERNAL_LINK_TARGET_BLANK}
                >
                  <img
                    className={globalClasses.promoBtn}
                    src={googleplay}
                    width={160}
                    height={46}
                  />
                </Link>
              </div>
              <div className={globalClasses.promoWrapper}>
                <div className={globalClasses.promoText}>
                  {t('downloadApp')}
                </div>
              </div>
            </div>
          )}
        </div>
        {!isChainValley && (
          <div className={globalClasses.paymentSystems}>
            <Link
              href="https://utorg.pro/about/#pci-dss"
              target="_blank"
              className="pci-link"
            >
              &nbsp;
            </Link>
          </div>
        )}
        <div className={globalClasses.footer}>
          <div>
            <Typography
              variant="body2"
              component="div"
              className="small"
              dangerouslySetInnerHTML={{ __html: t(getDescription(MIRROR)) }}
            />
          </div>
          <Typography className="recap">
            This site is protected by reCAPTCHA
            <br />
            and the Google&nbsp;
            <Link href="https://policies.google.com/privacy" target="_blank">
              Privacy Policy
            </Link>{' '}
            and&nbsp;
            <Link href="https://policies.google.com/terms" target="_blank">
              Terms of Service
            </Link>{' '}
            apply.
          </Typography>
        </div>
      </div>
    </Loader>
  )
}
