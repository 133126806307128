import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../Layout';
import AccountLayout from '../Layout/Account';
import LoginLayout from '../Layout/Login';

interface IRouteProps {
  component: any;
  exact?: boolean;
  path: string;
  ignoreUi?: boolean;
  step?: string;
}

export default function RouteWithLayout(props: IRouteProps) {
  const { component: Component, ignoreUi, step, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout ignoreUi={ignoreUi} step={step}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
}

export const AccountRoute = ({ component: Component, ...rest }: IRouteProps) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <AccountLayout>
        <Component {...matchProps} />
      </AccountLayout>
    )}
  />
);

export const LoginRoute = ({ component: Component, ...rest }: IRouteProps) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <LoginLayout>
        <Component {...matchProps} />
      </LoginLayout>
    )}
  />
);
