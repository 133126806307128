import { PrivateApi, usePrivateFetchApi } from '../index';
import useUpdateApi from '../../hooks/useUpdate';
import useFingerprintedRequest from '../../hooks/useFingerprint';
import { getBrowserDataFor } from '../../helpers/browserData';
import { IPayResponse } from '@/types/payment';

export function usePrivatePaymentInit(orderId: string, cb: any): [(data: any) => void, boolean] {
  const fingerprintedRequest = useFingerprintedRequest(orderId);
  const [send, sending] = useUpdateApi(
    async (...data: any) => PrivateApi.post('/payment/init', ...data),
    cb
  );
  return [(data: any) => fingerprintedRequest(send, data), sending];
}

export function usePrivatePaymentPayNew(cb: (res: IPayResponse) => void) {
  const browserData = getBrowserDataFor('pay');
  return useUpdateApi(
    async (data: any, ...other: any) => PrivateApi.post('/payment/pay/new', {...data, browserData}, ...other),
    cb
  );
}

export function usePrivatePaymentPay(cb: (res: IPayResponse) => void) {
  const browserData = getBrowserDataFor('pay');
  return useUpdateApi(
    async (data: any, ...other: any) => PrivateApi.post('/payment/pay', {...data, browserData}, ...other),
    cb
  );
}

export function usePrivatePaymentMobile(cb: (res: IPayResponse) => void) {
  const browserData = getBrowserDataFor('pay');
  return useUpdateApi(
    async (data: any, ...other: any) => PrivateApi.post('/payment/pay/token', {...data, browserData}, ...other),
    cb
  );
}

export function useCardsList() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/card/list', ...data),
    { data: [] }
  )
}

export function useCardDelete(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/card/delete', ...data),
    cb
  )
}

export function useThreeDSGet(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/payment/3ds', ...data),
    cb
  )
}
