import { InputAdornment } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cookies from 'react-cookies';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {Currency, Input, MaskedInput} from '../../../components/Form';
import {composeValidators, isCardNumber, isWalletAddress, required} from '../../../validators';
import {OrderType, useOrderConvert} from '../../../api/private/orders';
import useFormStyles from '../../Exchange/forms/useFormStyles';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/Loader';
import ServerMessage from '../../../components/UI/ServerMessage';
import {KycStatus} from '../../../api/kyc';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import {ICurrency} from "../../../types/currency";
import {currencyOptionMapper} from "../../../helpers/currency";
import { useUDomain } from '@/hooks/useUDomain';
import UDIcon from '@/icons/ud.svg';

interface IProps {
  type: string;
  currencies: any[];
  currenciesLoading: boolean;
  init: any;
  initing: boolean;
  formErrors?: any;
  setFormErrors: any;
  balances: any[];
  balancesLoading: boolean;
  kycStatus: KycStatus;
  error: string;
  currencyByCountry: string;
}

const useStyles = makeStyles({
  root: {},
  available: {
    marginTop: -30,
    marginLeft: 10
  }
});

export default function OrderForm({
  type,
  currencies,
  currenciesLoading,
  balances,
  balancesLoading,
  init,
  initing,
  formErrors,
  setFormErrors,
  kycStatus,
  error,
  currencyByCountry
}: IProps) {
  const formClasses = useFormStyles({});
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const isWithdrawal = type === OrderType.WITHDRAWAL;
  const [initial, setInitial] = useState({
    currency: '',
    paymentCurrency: '',
    paymentAmount: '',
    amount: ''
  });
  const [toCurrency, setToCurrency] = useState<string | undefined>();
  let setAmount: any;
  let setPaymentAmount: any;
  const [convertAmount, convertingAmount] = useOrderConvert((res: any) => {
    if (res.success && typeof (setAmount) === 'function') {
      setAmount(res.data)
    }
  });
  const [convertPaymentAmount, convertingPaymentAmount] = useOrderConvert((res: any) => {
    if (res.success && typeof (setPaymentAmount) === 'function') {
      setPaymentAmount(res.data)
    }
  });
  let convertRequestTimeout: any;

  const balanceList: any = {};
  balances.forEach((b: any) => {
    balanceList[b.currency] = b.available;
  });

  const {
    isValidUDomain, checkIsValidUDomain, resolveAddressFromUD, uDomainResolveError, uDomainSupportError,
  } = useUDomain();

  // resolve address from UD
  useEffect(() => {
    if (isValidUDomain && toCurrency) {
      resolveAddressFromUD(toCurrency);
    }
  }, [isValidUDomain, toCurrency])

  useEffect(() => {
    if (!currenciesLoading && currencies.length && !balancesLoading) {
      const fiatCurrencies = currencies.filter((c: any) => c.enabled && c.type === 'FIAT');
      const cryptoCurrencies = currencies.filter((c: any) => c.enabled && c.type === 'CRYPTO');
      let selectedForWd = 'BTC';
      if (type === OrderType.WITHDRAWAL) {
        const activeCurrencies = currencies.filter((c: any) => c.enabled && (balanceList[c.currency] > 0 || c.type === 'CRYPTO'));
        selectedForWd = activeCurrencies.filter((c: any) => balanceList[c.currency] > 0)[0] ? activeCurrencies.filter((c: any) => balanceList[c.currency] > 0)[0].currency : 'BTC';
      }
      let paymentCurrency = 'BTC';
      if (type === OrderType.DEPOSIT) {
        paymentCurrency = fiatCurrencies.find((c: any) => c.currency === currencyByCountry) ? currencyByCountry : 'EUR';
      }
      const initvals = {
        ...initial,
        currency: type === OrderType.DEPOSIT ? cryptoCurrencies[0].currency : selectedForWd,
        paymentCurrency
      };
      setInitial(initvals);
      setToCurrency(paymentCurrency);
    }
  }, [currencies, currenciesLoading, balancesLoading, type]);

  const wdCurrOptions = currencies
    .filter((c: any) => c.enabled).map((c: any) => c.currency);

  const paymentConvert = (value: any, values: any) => {
    if (formErrors) {
      const errs = { ...formErrors };
      delete errs.amount;
      delete errs.paymentAmount;
      setFormErrors(errs);
    }
    clearTimeout(convertRequestTimeout);
    convertRequestTimeout = setTimeout(() => {
      if (!value || parseFloat(value) === 0) {
        return setPaymentAmount('');
      }
      let currs = {
        fromCurrency: values.paymentCurrency,
        toCurrency: values.currency
      };
      if (isWithdrawal) {
        currs = {
          fromCurrency: values.currency,
          toCurrency: values.paymentCurrency
        }
      }
      convertPaymentAmount({
        ...currs,
        type,
        amount: parseFloat(value) || 0
      });
    }, 800);
  };

  const amountConvert = (value: any, values: any) => {
    if (formErrors) {
      const errs = { ...formErrors };
      delete errs.paymentAmount;
      delete errs.amount;
      setFormErrors(errs);
    }
    clearTimeout(convertRequestTimeout);
    convertRequestTimeout = setTimeout(() => {
      if (!value || parseFloat(value) === 0) {
        return setAmount('');
      }
      let currs = {
        fromCurrency: values.paymentCurrency,
        toCurrency: values.currency
      };
      if (isWithdrawal) {
        currs = {
          fromCurrency: values.currency,
          toCurrency: values.paymentCurrency
        }
      }
      convertAmount({
        ...currs,
        type,
        paymentAmount: parseFloat(value) || 0
      });
    }, 800);
  };

  const disabled = isWithdrawal ? kycStatus !== KycStatus.APPROVED : kycStatus === KycStatus.REJECTED;
  // const changeDisabled = currenciesLoading || convertingAmount || convertingPaymentAmount;

  return (
    <Loader loading={currenciesLoading || balancesLoading}>
      <Form
        onSubmit={(values) => {
          // @ts-ignore
          const address = isWithdrawal ? values.address || values.cardNumber : undefined;
          const data: any = {
            type,
            paymentCurrency: values.paymentCurrency,
            paymentAmount: parseFloat(values.paymentAmount),
            currency: values.currency,
            amount: parseFloat(values.amount),
            ref: cookies.load('r')
          };
          if (address) {
            data.address = address.replace(/\s/g, '')
          }
          init(data);
        }}
        initialValues={initial}
        mutators={{
          setAmount: (args, state, tools) => {
            tools.changeValue(state, 'amount', () => args[0]);
          },
          setPaymentAmount: (args, state, tools) => {
            tools.changeValue(state, 'paymentAmount', () => args[0]);
          },
          setPaymentCurrency: (args, state, { changeValue }) => {
            changeValue(state, 'paymentCurrency', () => args[0]);
          }
        }}
        validate={() => formErrors}
        render={({ handleSubmit, invalid, values, form, errors }) => {
          const currency = (values.currency ? currencies.find((c) => c.currency === values.currency) : {}) as ICurrency;
          const paymentCurrency = values.paymentCurrency ? currencies.find((c) => c.currency === values.paymentCurrency) : {};
          // TMP disabling fiat withdrawals
          const isFiatWithdrawal = false; // isWithdrawal && paymentCurrency.type === 'FIAT';
          setAmount = form.mutators.setAmount;
          setPaymentAmount = form.mutators.setPaymentAmount;
          const availableWdAmount = balanceList[currency.currency] || 0;
          const amountState = form.getFieldState('amount');
          return (
            <form className={formClasses.root} onSubmit={handleSubmit}>
              <div>
                {isWithdrawal
                  ? (
                    <div className={formClasses.row}>
                      <div>
                        <Currency
                          inputName="amount"
                          selectName="currency"
                          currencies={currencies.filter((c: ICurrency) => c.enabled && (balanceList[c.currency] > 0)).map(currencyOptionMapper)}
                          label={t('labels.Sell')}
                          styles={{}}
                          currenciesLoading={currenciesLoading}
                          inputEnabled={!currenciesLoading}
                          onInput={(e: any) => {
                            if (e.target.toString().includes('HTMLSelectElement')) {
                              return;
                            }
                            paymentConvert(e.target.value || 0, values)
                          }}
                          min={currency.withdrawalMin}
                          max={currency.withdrawalMax}
                          available={availableWdAmount}
                          className={values.amount && values.amount.toString().length > 10 ? 'text-small' : ''}
                        />
                        {(!amountState || amountState.valid || !amountState.touched) && (
                          <Typography variant="body2" component="div" className={classes.available} translate="no">
                            <Link
                              onClick={() => {
                                form.mutators.setAmount(availableWdAmount);
                                paymentConvert(availableWdAmount, values)
                              }}
                              component="button"
                            >
                              {t('Available')} {availableWdAmount} {currency.currency}
                            </Link>
                          </Typography>
                        )}
                      </div>
                      <Currency
                        inputName="paymentAmount"
                        selectName="paymentCurrency"
                        // currencies={currencies.filter((c: any) => c.enabled).map((c: any) => c.currency)} FIXME tmp disabled all currencies
                        currencies={currencies.filter(
                          (c: ICurrency) => c.enabled && c.type === 'CRYPTO'
                            // currency.type === 'CRYPTO' && currency.currency !== 'BTC'
                            // ? c === currency.currency
                            // : c === 'BTC' || c === currency.currency || (currency.currency ==='BTC' && (c === 'UAH' || c === 'RUB'))
                        ).map(currencyOptionMapper)}
                        label={t('labels.Buy')}
                        styles={{}}
                        currenciesLoading={currenciesLoading}
                        inputEnabled={!currenciesLoading}
                        onInput={(e: any) => {
                          if (e.target.toString().includes('HTMLSelectElement')) {
                            return;
                          }
                          amountConvert(e.target.value || 0, values)
                        }}
                        max={paymentCurrency.withdrawalMax}
                        min={paymentCurrency.withdrawalMin}
                        className={values.paymentAmount && values.paymentAmount.toString().length > 10 ? 'text-small' : ''}
                      />
                    </div>
                  ) : (
                    <div className={formClasses.row}>
                      <Currency
                        inputName="paymentAmount"
                        selectName="paymentCurrency"
                        currencies={currencies.filter((c: ICurrency) => c.type === 'FIAT' && c.enabled).map(currencyOptionMapper)}
                        label={t('labels.Sell')}
                        styles={{}}
                        currenciesLoading={currenciesLoading}
                        inputEnabled={!currenciesLoading}
                        onInput={(e: any) => {
                          if (e.target.toString().includes('HTMLSelectElement')) {
                            return;
                          }
                          amountConvert(e.target.value || 0, values)
                        }}
                        max={paymentCurrency.depositMax}
                        className={values.paymentAmount && values.paymentAmount.toString().length > 10 ? 'text-small' : ''}
                      />
                      <Currency
                        inputName="amount"
                        selectName="currency"
                        currencies={currencies.filter((c: ICurrency) => c.type === 'CRYPTO' && c.enabled).map(currencyOptionMapper)}
                        label={t('labels.Buy')}
                        styles={{}}
                        currenciesLoading={currenciesLoading}
                        inputEnabled={!currenciesLoading}
                        onInput={(e: any) => {
                          if (e.target.toString().includes('HTMLSelectElement')) {
                            return;
                          }
                          paymentConvert(e.target.value || 0, values)
                        }}
                        min={currency.depositMin}
                        max={currency.depositMax}
                        className={values.amount && values.amount.toString().length > 10 ? 'text-small' : ''}
                      />
                    </div>
                  )}
                <OnChange name="currency">
                  {(value: any) => {
                    const paymentCurr = values.paymentCurrency;
                    // if (isWithdrawal) {
                    //   const isCrypto = currencies.find((c: any) => c.currency === value && c.type === 'CRYPTO');
                    //   const wdCurrs = wdCurrOptions.filter((c) => c === value || (!isCrypto && c === 'BTC'));
                    //   if (!!isCrypto) {
                    //     form.mutators.setPaymentCurrency(value);
                    //     form.mutators.setAmount(0);
                    //     form.mutators.setPaymentAmount(0);
                    //     return;
                    //   } else if (wdCurrs.length === 1) {
                    //     form.mutators.setPaymentCurrency(wdCurrs[0]);
                    //     paymentCurr = wdCurrs[0];
                    //   } else {
                    //     form.mutators.setPaymentCurrency(value);
                    //     paymentCurr = value;
                    //   }
                    // }
                    if (values.paymentAmount && parseFloat(values.paymentAmount) > 0) {
                      clearTimeout(convertRequestTimeout);
                      const currs = {
                        fromCurrency: isWithdrawal ? value : paymentCurr,
                        toCurrency: isWithdrawal ? paymentCurr : value
                      };
                      convertAmount({
                        ...currs,
                        type,
                        paymentAmount: parseFloat(values.paymentAmount)
                      });
                    }
                    if (values.amount && values.amount.length) {
                      form.blur('amount');
                    }
                  }}
                </OnChange>
                <OnChange name="paymentCurrency">
                  {(value: any) => {
                    if (values.amount && parseFloat(values.amount) > 0) {
                      clearTimeout(convertRequestTimeout);
                      let currs = {
                        fromCurrency: value,
                        toCurrency: values.currency
                      };
                      if (isWithdrawal) {
                        currs = {
                          fromCurrency: values.currency,
                          toCurrency: value
                        }
                      }
                      convertPaymentAmount({
                        ...currs,
                        type,
                        amount: parseFloat(values.amount)
                      });
                    }
                    if (values.paymentAmount && values.paymentAmount.length) {
                      form.blur('paymentAmount');
                    }
                  }}
                </OnChange>
                {isWithdrawal && (
                  <div>
                    {isFiatWithdrawal
                      ? (
                        <div>
                          <MaskedInput
                            name="cardNumber"
                            type="tel"
                            label={t('labels.Card number')}
                            fullWidth
                            validate={composeValidators(required, isCardNumber)}
                            placeholder="4000 0000 0000 0000"
                          />
                          <OnChange name="cardNumber">
                            {() => setFormErrors({ ...formErrors, cardNumber: undefined })}
                          </OnChange>
                        </div>
                      ) : (
                        <div>
                          <Input
                            name="address"
                            type="text"
                            label={t('labels.Wallet address')}
                            fullWidth
                            validate={composeValidators(required, (v: any) => {
                              return isWalletAddress(v, currency.addressValidator as string)
                            })}
                            InputProps={{ endAdornment: <InputAdornment position="end"><img src={UDIcon} width={20} /></InputAdornment> }}
                            errorText={uDomainSupportError || uDomainResolveError}
                          />
                          <OnChange name="address">
                            {(v: string) => {
                              checkIsValidUDomain(v);
                              setFormErrors({ ...formErrors, address: undefined })
                            }}
                          </OnChange>
                        </div>
                      )
                    }
                  </div>
                )}
                {error.length > 0 && <ServerMessage type="error" text={error} isAccount />}
                <div>
                  <Button
                    className={globalClasses.button}
                    disabled={initing || disabled}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {t('Next')}
                  </Button>
                </div>
              </div>
            </form>
          )
        }}
      />
    </Loader>
  );
}
