import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import ErrorIcon from '@material-ui/icons/Error'
import Typography from '@material-ui/core/Typography'
import useGlobalStyles from '../../hooks/useGlobalStyles'
import { trackErrorInput } from '@/helpers/analytics'

export type TServerMessageType = 'success' | 'error' | 'alert'

interface IProps {
  type: TServerMessageType
  text?: string
  textOptions?: object
  isAccount?: boolean
  align?: 'v-top'
  children?: any | any[]
}

export default function ServerMessage({
  type,
  text,
  isAccount,
  align,
  children,
  textOptions,
}: IProps) {
  const classes = useGlobalStyles()
  const { t } = useTranslation()

  useEffect(() => {
    if (text === 'validation.country of residence') {
      trackErrorInput('input_error', t('labels.country of residence'))
    }
  }, [text])

  return (
    <div
      className={clsx(
        classes.serverMessage,
        type,
        align,
        isAccount ? 'acc' : ''
      )}
    >
      <div className="icon">
        <ErrorIcon />
      </div>
      {text ? (
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: t(text, textOptions) }}
        />
      ) : (
        children
      )}
    </div>
  )
}
