import googlePayConfig from '@/config/googlePay.json'
import { IOrder } from '@/api/order'
import { MIRROR, WIIDGET_ENV } from '@/helpers/constants'
import { ICustomer } from '@/types/customer'
import { IAPM } from '@/api/settings'

import { getLegalEntity } from './mirrorHelper'

export type IGateway = {
  gateway: string
  gatewayMerchantId: string
}

function getIsGooglePayEnabledForCustomer(customerId: string) {
  return (
    !googlePayConfig.enabledCustomers[
      WIIDGET_ENV as keyof typeof googlePayConfig.enabledCustomers
    ] ||
    googlePayConfig.enabledCustomers[
      WIIDGET_ENV as keyof typeof googlePayConfig.enabledCustomers
    ].includes(customerId)
  )
}

export const getHasGooglePay = (
  isGooglePayDataInited: boolean,
  isGooglePayLoading: boolean,
  isApmShown: boolean,
  apms: IAPM[],
  customerId: number
) => {
  const isGooglePayApmEnabled =
    apms.length > 0 && apms.find((apm) => apm.id === 'googlepay')

  return (
    isGooglePayDataInited &&
    !isGooglePayLoading &&
    isApmShown &&
    !!isGooglePayApmEnabled &&
    getIsGooglePayEnabledForCustomer(String(customerId))
  )
}

const IS_DEV = WIIDGET_ENV !== 'prod'

const VERSION = {
  apiVersion: 2,
  apiVersionMinor: 0,
}

export const ALLOWED_CARD_NETWORKS = IS_DEV
  ? ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
  : ['MASTERCARD', 'VISA']
const ALLOWED_CARD_AUTH_METHODS = ['PAN_ONLY', 'CRYPTOGRAM_3DS']

const BASE_CARD_PAYMENT_METHOD = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: ALLOWED_CARD_AUTH_METHODS,
    allowedCardNetworks: ALLOWED_CARD_NETWORKS,
    billingAddressRequired: true,
    billingAddressParameters: {
      format: 'FULL',
    },
  },
}

const getCardPaymentMethod = (gateway: IGateway) => {
  return Object.assign({}, BASE_CARD_PAYMENT_METHOD, {
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: gateway,
    },
  })
}

export const getGoogleIsReadyToPayRequest = () => {
  return Object.assign({}, VERSION, {
    allowedPaymentMethods: [BASE_CARD_PAYMENT_METHOD],
  })
}

export const getGooglePaymentDataRequest = (
  order: IOrder,
  customer: ICustomer,
  gateway: IGateway
) => {
  const transaction = {
    transactionInfo: {
      countryCode: customer.country,
      currencyCode: String(order.paymentCurrency),
      totalPriceStatus: 'FINAL',
      totalPrice: String(order.finalPaymentAmount || order.paymentAmount),
    },
  }
  const cardPaymentMethod = getCardPaymentMethod(gateway)
  const paymentMethodData = {
    allowedPaymentMethods: [cardPaymentMethod],
    merchantInfo: {
      merchantId: gateway.gatewayMerchantId,
      merchantName: getLegalEntity(MIRROR),
    },
  }
  return Object.assign({}, VERSION, paymentMethodData, transaction)
}
