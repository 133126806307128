import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import { IOrder } from '@/api/order'
import CurrencyNameMapper from '@/components/CurrencyNameMapper/CurrencyNameMapper'

const useStyles = makeStyles({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 12,
  },
  buttonIcon: {
    width: 18,
    height: 18,
  }
})

interface IProps {
  order: IOrder
  styles: any
}

export function SuccessPayout({ styles, order }: IProps) {
  const { t } = useTranslation()
  const skrillEmail = window.localStorage.getItem('skrillEmail')
  const classes = useStyles()
  const [detailsOpen, setDetailsOpen] = useState(false)
  const ButtonIcon = detailsOpen ? ExpandLess : ExpandMore

  return (
    <div>
      <Typography
        variant="h5"
        align="center"
        component="div"
        color="textPrimary"
      >
        {t('payout.successText')}
      </Typography>
      <div className={clsx(styles.delim, 'payout')} />
      <div className={styles.formGroup}>
        <div
          onClick={() => setDetailsOpen(!detailsOpen)}
          className={clsx(styles.blockTitle, classes.button)}
        >
          {t('payout.detailsTitle')}
          <ButtonIcon className={classes.buttonIcon} />
        </div>
        {detailsOpen && (
          <div>
            {skrillEmail && (
              <div className={clsx(styles.orderDetailsRow, 'payout')}>
                <Typography
                  component="div"
                  variant="body2"
                  color="textSecondary"
                  className="dTitle"
                >
                  {t('payout.skrillEmailDetails')}
                </Typography>
                <Typography component="div" variant="body2" align="right">
                  {skrillEmail}
                </Typography>
              </div>
            )}
            <div className={clsx(styles.orderDetailsRow, 'payout')}>
              <Typography
                component="div"
                variant="body2"
                color="textSecondary"
                className="dTitle"
              >
                {t('payout.paymentAmount')}
              </Typography>
              <Typography
                component="div"
                variant="body2"
                align="right"
                translate="no"
              >
                {order.paymentAmount} <CurrencyNameMapper currency={String(order.paymentCurrency)} />
              </Typography>
            </div>
            <div className={clsx(styles.orderDetailsRow, 'payout')}>
              <Typography
                component="div"
                variant="body2"
                color="textSecondary"
                className="dTitle"
              >
                {t('payout.amount')}
              </Typography>
              <Typography
                component="div"
                variant="body2"
                align="right"
                translate="no"
              >
                {order.amount} <CurrencyNameMapper currency={order.currency} />
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
