import React from 'react'
import { useTranslation } from 'react-i18next'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Layout from '../Layout'
import { OrderProcessingError } from '../../api/order'
import { useLoginStyles } from '../../views/Account/Login'

const useStyles = makeStyles((theme) => ({
  root: {},
  image: {
    width: 240,
    height: 215,
    margin: `0 auto ${theme.spacing(3)}px`,
    display: 'block',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(6),
  },
}))

interface IProps {
  error: OrderProcessingError
  url?: string
}

export default function ProcessingError({ error, url }: IProps) {
  const { t } = useTranslation()
  const localClasses = useStyles()
  const classes = useLoginStyles()

  return (
    <Layout>
      <div className={localClasses.root}>
        <img
          src="/img/processingError.png"
          className={localClasses.image}
          alt=""
        />
        <Typography variant="h6" align="center" className={localClasses.title}>
          {t(`orderProcessingError.${error}.title`)}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={localClasses.description}
          dangerouslySetInnerHTML={{
            __html: t(`orderProcessingError.${error}.description`),
          }}
        />
        {url && (
          <Button
            href={url}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {t('failBack')}
          </Button>
        )}
      </div>
    </Layout>
  )
}
