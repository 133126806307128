import { IOrder } from '@/api/order'

export function addGtagEvent(
  event: string,
  eventProperties: string,
  category: string
) {
  const s = document.createElement('script')
  s.type = 'text/javascript'
  s.textContent = `dataLayer.push({
    'event': 'autoEvent', 
    'eventCategory': '${category}',
    'eventAction': '${event}',
    'params': ${eventProperties}});`
  document.body.appendChild(s)
}

export function trackViewCheckoutScreen(
  event: string,
  order: IOrder,
  country: string | undefined,
  screen: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    country: country,
    screen_name: screen,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(
    event,
    JSON.stringify(properties),
    'onramp_payment_method_selection'
  )
}

export function trackAddPaymentMethod(
  event: string,
  order: IOrder,
  payment: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    payment_type: payment,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(
    event,
    JSON.stringify(properties),
    'onramp_payment_method_selection'
  )
}

export function trackClickPay(
  event: string,
  order: IOrder,
  payment: string,
  screen: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    payment_type: payment,
    screen_name: screen,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(
    event,
    JSON.stringify(properties),
    'onramp_payment_method_selection'
  )
}

export function trackClickGoogleOrApplePay(
  event: string,
  order: IOrder,
  payment: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    payment_type: payment,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(
    event,
    JSON.stringify(properties),
    'onramp_payment_method_selection'
  )
}

export function trackErrorScreen(
  event: string,
  order: IOrder | undefined,
  error: string,
  payment: string
) {
  const properties = {
    error_message: error,
    order_id: order?.id,
    user_id: order?.acceptedById,
    screen_name: `Payment method ${payment}`,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), 'error')
}

export function trackErrorInput(event: string, error: string) {
  const properties = {
    error_message: error,
    screen_name: 'Account information',
    transaction_type: 'b2b',
    event_type: 'fe',
  }
  addGtagEvent(event, JSON.stringify(properties), 'error')
}

export function trackTakeVerification(
  event: string,
  order: IOrder,
  payment: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    payment_type: payment,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), 'onramp_payment_confirmation')
}

export function trackKycEvent(
  event: string,
  order: IOrder,
  level: string,
  payment?: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    kyc_level: level,
    payment_type: payment,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), 'onramp_processing')
}

export function trackPaymentError(
  event: string,
  order: IOrder | undefined,
  payment: string | undefined,
  screen: string,
  error: string,
  level: number | undefined
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    error_message: error,
    kyc_level: level,
    payment_type: payment,
    screen_name: screen,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), 'payment_error')
}

export function trackPaymentResult(
  event: string,
  order: IOrder | undefined,
  payment: string | undefined,
  level: number | undefined
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    kyc_level: level,
    payment_type: payment,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), 'onramp_processing')
}

export function trackOnrampLogin(
  event: string,
  order: IOrder | undefined,
  category: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), category)
}

export function trackAddCor(
  event: string,
  order: IOrder | undefined,
  country: string | undefined,
  category: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    country: country,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), category)
}

export function trackUsFlowInfo(
  event: string,
  order: IOrder | undefined,
  country: string | undefined,
  state: string | undefined,
  category: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    country: country,
    state: state,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), category)
}

export function trackConfirmLogin(
  event: string,
  order: IOrder | undefined,
  page: string,
  category: string
) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.paymentAmount,
    crypto_amount: order?.amount,
    user_id: order?.acceptedById,
    fiat_name: order?.paymentCurrency,
    crypto_name: order?.currency,
    page_name: page,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), category)
}

export function trackPayout(event: string, order: IOrder | undefined) {
  const properties = {
    order_id: order?.id,
    fiat_amount: order?.amount,
    crypto_amount: order?.paymentAmount,
    user_id: order?.acceptedById,
    fiat_name: order?.currency,
    crypto_name: order?.paymentCurrency,
    payment_type: order?.apm,
    merchant_id: order?.mId,
    transaction_type: 'b2b',
    event_type: 'fe',
    flow_type: order?.sf ? 'simplified' : 'ordinary',
  }
  addGtagEvent(event, JSON.stringify(properties), 'offramp_order_init')
}
