import React, { useState } from 'react'

import { Form } from 'react-final-form'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, Chip } from '@material-ui/core'

import { useSettlementInit } from '@/api/private/account'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import {
  ICheckedSubaccountRequest,
  ISettlementBalance,
} from '@/types/settlements'
import line from '@/icons/line.svg'
import { checkAvailableRequests } from '@/helpers/settlements'

import SubaccountBalanceRow from './SubaccountBalanceRow'
import SubaccountSettlementConfirmation from './SubaccountSettlementConfirmation'
import SettlementRequestResult from '../SettlementRequestResult'
import {
  setAmount,
  setPercentage,
  setChangePercentage,
} from '@/helpers/settlementFormMutators'

const useStyles = makeStyles((theme) => ({
  button: {
    height: 36,
    maxWidth: '210px',
  },
  cellAmount: {
    paddingLeft: 0,
  },
  headerForm: {
    display: 'grid',
    gridTemplateColumns: '1fr 210px',
    columnGap: '16px',
    marginBottom: theme.spacing(4),
  },
  selectedBalances: {
    fontSize: '0.875rem',
    lineHeight: 1.71,
    letterSpacing: '0.01em',
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
  },
  selectedItems: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    paddingTop: '6px',
  },
  formChip: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
}))

interface IDesktopSubaccountBalancesList {
  balances: ISettlementBalance[]
  loadBalances: () => void
}

export default function DesktopSubaccountBalancesList({
  balances,
  loadBalances,
}: IDesktopSubaccountBalancesList) {
  const { t } = useTranslation()

  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [isOpenResultModal, setIsOpenResultModal] = useState(false)
  const [formData, setFormData] = useState<ICheckedSubaccountRequest[] | []>([])
  const [createdSettlement, setCreatedSettlement] = useState({ id: '' })

  const [create, creating] = useSettlementInit((res: any) => {
    if (res.success) {
      setCreatedSettlement(res.data)
    } else {
      setCreatedSettlement(res)
    }

    setIsOpenResultModal(true)
    setIsOpenConfirmModal(false)
  })

  const closeResultModal = () => {
    if (createdSettlement?.id) {
      loadBalances()
    }
    setIsOpenResultModal(false)
  }

  return (
    <div>
      <Form
        onSubmit={(values) => {
          const checkedRequests: ICheckedSubaccountRequest[] | [] = []
          checkAvailableRequests(values, checkedRequests)

          if (checkedRequests.length > 0) {
            setFormData(checkedRequests)
          }

          setIsOpenConfirmModal(true)
        }}
        mutators={{
          setPercentage,
          setChangePercentage,
          setAmount,
        }}
        render={({ handleSubmit, form, values, invalid }) => {
          const setAmount = form.mutators.setAmount
          const setChangePercentage = form.mutators.setChangePercentage
          const setPercentage = form.mutators.setPercentage

          const checkedRequests: ICheckedSubaccountRequest[] = []

          if (Object.keys(values).length > 0) {
            checkAvailableRequests(values, checkedRequests)
          }

          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.headerForm}>
                <div className={classes.selectedItems}>
                  <>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.selectedBalances}
                      dangerouslySetInnerHTML={{
                        __html: t('settlements.selectedBalances', {
                          amount: checkedRequests.length,
                        }),
                      }}
                    />
                    {checkedRequests.length > 0 && (
                      <img src={line} height={24} />
                    )}
                  </>
                  <div className={classes.formChip}>
                    {checkedRequests.length > 0 &&
                      checkedRequests.map((item) => (
                        <Chip
                          size="small"
                          key={item.currency}
                          label={`${item.amount.toFixed(2)} ${item.currency}`}
                        />
                      ))}
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    className={clsx(globalClasses.button, classes.button)}
                    color="primary"
                    type="submit"
                    disabled={checkedRequests.length === 0 || invalid}
                  >
                    {t('settlements.requestSettlement')}
                  </Button>
                </div>
              </div>
              <Table className={globalClasses.tableSimple}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" component="span">
                        {t('Currency')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" component="span">
                        {t('Total')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" component="span">
                        {t('Locked')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" component="span">
                        {t('Available')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" component="span">
                        {t('settlements.req')}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cellAmount}>
                      <Typography variant="body2" component="span">
                        {t('settlements.reqAmount')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balances.map((balance) => (
                    <SubaccountBalanceRow
                      key={balance.currency}
                      row={balance}
                      values={values}
                      setPercentage={setPercentage}
                      setAmount={setAmount}
                      setChangePercentage={setChangePercentage}
                    />
                  ))}
                </TableBody>
              </Table>
            </form>
          )
        }}
      />
      {formData.length > 0 && (
        <SubaccountSettlementConfirmation
          create={create}
          creating={creating}
          formData={formData}
          setIsOpenModal={setIsOpenConfirmModal}
          isOpenModal={isOpenConfirmModal}
        />
      )}
      <SettlementRequestResult
        isOpen={isOpenResultModal}
        settlement={createdSettlement}
        closeModal={() => closeResultModal()}
      />
    </div>
  )
}
