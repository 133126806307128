import * as Sentry from '@sentry/react'

import applePayConfig from '@/config/applePay.json'
import { WIIDGET_ENV } from '@/helpers/constants'
import { IAPM } from '@/api/settings'

import { isInIframe } from './iframe'

function getIsApplePayEnabledForCustomer(customerId: string) {
  return (
    !applePayConfig.enabledCustomers[
      WIIDGET_ENV as keyof typeof applePayConfig.enabledCustomers
    ] ||
    applePayConfig.enabledCustomers[
      WIIDGET_ENV as keyof typeof applePayConfig.enabledCustomers
    ].includes(customerId)
  )
}

function loadApplePayScript() {
  if (!document.getElementById('apple-pay-widget')) {
    const script = document.createElement('script')
    script.async = true
    script.id = 'apple-pay-widget'
    script.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'
    document.head.appendChild(script)
  }
}

export const getHasApplePay = (
  isApmShown: boolean,
  apms: IAPM[],
  customerId: number
) => {
  const isApplePayApmEnabled =
    apms.length > 0 && apms.find((apm) => apm.id === 'applepay')

  let hasApplePay =
    !isInIframe(true) &&
    isApmShown &&
    !!window.ApplePaySession &&
    !!isApplePayApmEnabled &&
    getIsApplePayEnabledForCustomer(String(customerId))

  if (hasApplePay) {
    try {
      loadApplePayScript()
      hasApplePay = window.ApplePaySession.canMakePayments()
    } catch (e) {
      Sentry.captureException(e, { tags: { section: 'APPLE PAY' } })
      console.log(e)
      hasApplePay = false
    }
  }

  return Boolean(hasApplePay)
}
