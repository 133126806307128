export const postMessage = (data: any, handlerName: string) => {
  const JSONData = JSON.stringify(data)
  // Android bridge
  // @ts-ignore
  if (window && window.Android && window.Android[handlerName]) {
    // @ts-ignore
    window?.Android[handlerName](JSONData)
    return
  }

  // iOS bridge
  // @ts-ignore
  if (window.webkit?.messageHandlers[handlerName]) {
    // @ts-ignore
    window.webkit?.messageHandlers[handlerName].postMessage(JSONData)
    return
  }

  // React Native webview bridge
  // @ts-ignore
  if (window?.ReactNativeWebView) {
    // @ts-ignore
    window?.ReactNativeWebView.postMessage(JSONData)
    return
  }

  // IFrame
  window.postMessage(JSONData, '*')
}

export default postMessage
