import React, { useState, useMemo, Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { usePopupSelectStyles } from './ResidenceStyles'
import ResidenceDialog from './ResidenceDialog'

import { IAutocompleteOption } from '../../../components/Form/AutocompleteField'
import { IOrder } from '@/api/order'

interface IProps {
  name: string
  fieldId: string
  checked: IAutocompleteOption | null
  setChecked: Dispatch<SetStateAction<IAutocompleteOption | null>>
  options: IAutocompleteOption[]
  title: string
  searchTitle: string
  placeholder: string
  classes: any
  detected?: string
  setCode: any
  errorText?: string
  order?: IOrder
  checkedCountry?: IAutocompleteOption | null
  code?: string
}

const inputProps = {
  readOnly: true,
  endAdornment: (
    <InputAdornment position="end">
      <ExpandMoreIcon />
    </InputAdornment>
  ),
}

export default function ResidenceField({
  checked,
  setChecked,
  name,
  fieldId,
  title,
  searchTitle,
  options,
  detected,
  setCode,
  classes,
  errorText,
  placeholder,
  order,
  checkedCountry,
  code,
}: IProps) {
  const localClasses = usePopupSelectStyles()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const detectedOption = useMemo(() => {
    return options.find((it) => it.value === detected)
  }, [detected, options])

  const closeModal = () => {
    setIsOpenModal(false)
  }

  const openModal = () => {
    setIsOpenModal(true)
  }

  return (
    <div className={localClasses.root}>
      <ResidenceDialog
        order={order}
        title={searchTitle}
        classes={classes}
        checked={checked}
        setChecked={setChecked}
        options={options}
        open={isOpenModal}
        setCode={setCode}
        detected={detectedOption}
        closeModal={closeModal}
        checkedCountry={checkedCountry}
        code={code}
      />
      <div className={classes.blockTitle}>{title}</div>
      <div className={classes.row}>
        <TextField
          // placeholder={placeholder}
          value={(checked?.label || checked?.name) ?? ''}
          className={clsx(classes.field, localClasses.countryField, 'select')}
          name={name}
          variant="filled"
          label={placeholder}
          onClick={openModal}
          InputLabelProps={{ shrink: Boolean(checked) }}
          InputProps={inputProps}
          error={Boolean(errorText)}
          helperText={errorText}
          id={fieldId}
        />
      </div>
    </div>
  )
}
