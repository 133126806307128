import React from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'

import { getLayoutApmView } from '@/helpers/getLayoutApmView'
import { IOrder } from '@/api/order'
import usePaymentFormStyles from '@/views/components/usePaymentFormStyles'
import { getOrderAmount } from '@/helpers/getOrderAmount'
import { isChainValley } from '@/helpers/mirrorHelper'

import CCWChainValley from './CCWChainValley'
import { IApmPanel } from './Panel'

const useStyles = makeStyles(() => ({
  orderInfoWrapper: {
    display: 'flex',
  },
}))

interface IOrderInfo {
  order: IOrder
  step: string
  showOrderAmount: boolean
  apm?: IApmPanel
}

function OrderInfo({ order, step, showOrderAmount, apm }: IOrderInfo) {
  const { t } = useTranslation()

  const showApmView = getLayoutApmView(step, order)

  const paymentFormStyles = usePaymentFormStyles()
  const classes = useStyles()

  if (showApmView && isChainValley) {
    return (
      <CCWChainValley
        showOrderAmount={showOrderAmount}
        order={order}
        apm={apm}
      />
    )
  }

  return (
    <div className={classes.orderInfoWrapper}>
      <div
        className={clsx(
          !showApmView
            ? paymentFormStyles.orderInfo
            : paymentFormStyles.orderInfoCenter
        )}
      >
        <Typography variant="body2" className="id" component="div">
          {t('Exchange order')} #{order.id}
        </Typography>
        {showOrderAmount && (
          <div className="amount" translate="no">
            {getOrderAmount(order, apm?.apmAmount)}
          </div>
        )}
      </div>
    </div>
  )
}
export default OrderInfo
