import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DateView from '../../../components/DateView';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import useGlobalStyles from '../../../hooks/useGlobalStyles';

export interface IReferralBonus {
  amount: number;
  createdAt: number;
  currency: string;
  id: number;
  orderId: number;
  updatedAt: number;
}

export interface IReferralListProps {
  items: IReferralBonus[];
  loading?: boolean;
}

export default function ReferralList({ items, loading }: IReferralListProps) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  if (loading && !items.length) return null;

  if (!items.length) {
    return <Typography variant="body1" align="center">{t('Referral history is empty')}</Typography>;
  }

  return (
    <div>
      <Table className={globalClasses.tableSimple}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2" component="span">{t('Order ID')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="span">{t('Date')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="span">{t('Currency')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" component="span">{t('Bonus amount')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((i) => (
            <TableRow key={i.id}>
              <TableCell>
                <Typography variant="body2" component="span" color="textSecondary">{i.orderId}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" component="div">
                  <DateView value={i.createdAt} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" component="div">
                  {i.currency}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" component="div">{i.amount}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
