import update from 'immutability-helper';
import { clearAuthToken } from '../../api';

const initial = {
  user: {},
  userLoaded: false,
  logInMethod: null,
  registrationMethod: null,
  kyc: {},
  isUserBlocked: false
};

export default function account(state = initial, action: any) {
  const { data } = action;
  switch (action.type) {
    case 'USER_LOGIN_STATE':
      if (data && Object.keys(data).length) {
        return update(state, {
          user: { $set: data },
          userLoaded: { $set: true }
        });
      }
      clearAuthToken();
      return update(state, {
        user: { $set: {} },
        userLoaded: { $set: true }
      });
    case 'USER_LOGIN_METHOD':
      return update(state, { logInMethod: { $set: data } });
    case 'USER_REGISTRATION_METHOD':
      return update(state, { registrationMethod: { $set: data } });
    case 'USER_KYC_DATA':
      return update(state, { kyc: { $set: data } });
    case 'USER_BLOCKED':
      return update(state, { isUserBlocked: { $set: !action.unblock } })
    default:
      return state;
  }
}
