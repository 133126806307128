import React from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {OrderExtendedType} from '../../api/private/orders';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap'
  },
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  }
}));

interface IProps {
  type?: OrderExtendedType;
}

export default function OrderTypeView({ type }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  let title = 'Withdrawal';
  let className = classes.warning;

  switch (type) {
    case OrderExtendedType.CRYPTO_TO_WALLET:
    case OrderExtendedType.FIAT_TO_CRYPTO:
      title = 'Deposit';
      className = classes.success;
      break;
    case OrderExtendedType.WALLET_TO_FIAT:
      title = 'Payouts';
      className = classes.success;
      break;
    case OrderExtendedType.INVOICE:
      title = 'Invoice';
      className = classes.success;
      break;
  }

  return (
    <span className={clsx(classes.root, className)}>{t(title)}</span>
  );
}
