import axios from 'axios';
import { useUnauthorizedUpdateApi } from '../hooks/useUpdate';

const Api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL || 'http://localhost:4530/api'}/merchant/v1`,
});

export function useDemoInit(cb: any) {
  return useUnauthorizedUpdateApi(
    async (auth: string, data: any) => Api.post(
      '/order/init',
      data,
      {
        headers: {
          'X-AUTH-SID': auth
        }
      }
    ),
    cb
  )
}
