import React from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useGlobalStyles from '@/hooks/useGlobalStyles'
import DateView from '@/components/DateView'
import { ISettlementOperations } from '@/types/settlements'

import SettlementDetailsRow, {
  ISettlementRequest,
} from './SettlementDetailsRow'
import SettlementStatusView from '../SettlementStatusView'

export const useStyles = makeStyles((theme) => ({
  root: {},
  modal: {
    width: '460px',
    overflowY: 'scroll',
    padding: '26px 24px',
    background: '#FFF',
    borderRadius: 8,
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down(530)]: {
      width: '100%',
    },
  },
  button: {
    'width': '100%',
    'maxWidth': '100%',
    'margin': '0px',
    'height': '52px',
    'backgroundColor': 'rgba(125, 147, 168, 0.08)',
    'color': 'rgba(0, 0, 0, 1)',
    '&:hover': {
      backgroundColor: 'rgba(125, 147, 168, 0.12)',
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    marginBottom: theme.spacing(1),
  },
  subtitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    gap: '8px',
  },
  actions: {
    padding: '0px',
    display: 'block',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.5,
    letterSpacing: '0.01em',
    [theme.breakpoints.down(405)]: {
      fontSize: '1rem',
    },
  },
  id: {
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.02em',
    whiteSpace: 'nowrap',
  },
  content: {
    'padding': '0px',
    '&:first-child': {
      padding: '0px',
    },
    'marginBottom': theme.spacing(5),
  },
}))

export interface IRequestInfo {
  amount: number
  currency: string
  customerId: string
}

interface ISettlementDetails {
  setIsOpenModal: (open: boolean) => void
  isOpenModal: boolean
  settlement: any
}

function SettlementDetails({
  setIsOpenModal,
  isOpenModal,
  settlement,
}: ISettlementDetails) {
  const { t } = useTranslation()

  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const parsedSettlementOperations: IRequestInfo[] = []

  if (settlement?.operations) {
    Object.entries(settlement.operations as ISettlementOperations).map(
      ([customerId, requestInfo]) => {
        Object.entries(requestInfo).map(([currency, amount]) => {
          parsedSettlementOperations.push({ customerId, currency, amount })
          return { customerId, currency, amount }
        })
        return
      }
    )
  }

  let sortedSettlementByCurrency: ISettlementRequest[] = []

  if (parsedSettlementOperations.length > 0) {
    sortedSettlementByCurrency = parsedSettlementOperations.reduce(
      (acc: ISettlementRequest[], requestInfo) => {
        const containsСurrency = acc.find(
          (item) => item?.currency === requestInfo.currency
        )

        if (containsСurrency) {
          containsСurrency.sids.push(requestInfo)
        } else {
          acc.push({
            currency: requestInfo.currency,
            sids: [requestInfo],
          })
        }

        return acc
      },
      []
    )
  }

  return (
    <Dialog open={isOpenModal}>
      <div className={classes.modal}>
        <DialogContent className={classes.content}>
          <div className={classes.titleWrapper}>
            <Typography variant="h6" align="left" className={classes.title}>
              {t('settlements.titleDetails')}
            </Typography>
            <Typography component="h1" variant="body2" className={classes.id}>
              ID #{settlement.id}
            </Typography>
          </div>

          <div className={classes.subtitleWrapper}>
            <Typography variant="body2" component="div">
              <SettlementStatusView status={settlement.status} />
            </Typography>
            <DateView value={settlement.createdAt} />
          </div>

          <div>
            <Table className={globalClasses.tableSimple}>
              <TableBody>
                {sortedSettlementByCurrency.map((row) => (
                  <SettlementDetailsRow key={row.currency} row={row} />
                ))}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.actions}>
          <Button
            className={clsx(globalClasses.button, classes.button)}
            color="primary"
            variant="contained"
            onClick={() => setIsOpenModal(false)}
            id="close-settlement-details"
          >
            {t('settlements.close')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
export default SettlementDetails
