import React from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core'

import useGlobalStyles from '@/hooks/useGlobalStyles'
import { useSettlementConfirmationStyles } from '@/hooks/useSettlementConfirmationStyles'

import SettlementConfirmationRow from './SettlementConfirmationRow'
import { ICheckedSidRequest } from '@/types/settlements'
import { checkAvailableSidRequests } from '@/helpers/settlements'

interface ICheckedRequestInfo {
  customerId: number
  reqAmount: number
  currency: string
  checked: boolean
}

interface IRequestInfo {
  reqAmount: number
  req: number
  customerId: number
  checked: boolean
  availableBalance: number
}

type IRequestForInit = Record<string, Record<string, number>>

type IFormData = Record<string, Record<string, IRequestInfo>>

interface ISettlementRequestConfirmation {
  setIsOpenModal: (open: boolean) => void
  isOpenModal: boolean
  formData: IFormData
  create: (data: any) => void
  creating: boolean
}

function SettlementConfirmation({
  setIsOpenModal,
  isOpenModal,
  formData,
  create,
  creating,
}: ISettlementRequestConfirmation) {
  const { t } = useTranslation()
  const { user } = useSelector((state: any) => state.account)

  const classes = useSettlementConfirmationStyles()
  const globalClasses = useGlobalStyles()

  const parsedDataForConfirmation = Object.entries(formData).map(
    ([currency, requestInfo]) => {
      const checkedSidRequests: ICheckedSidRequest[] = []

      checkAvailableSidRequests(requestInfo, checkedSidRequests)

      return {
        currency,
        sids: checkedSidRequests,
      }
    }
  )

  const checkedRequestForInit: ICheckedRequestInfo[] = []

  Object.entries(formData).forEach(([currency, requestInfo]) => {
    Object.entries(requestInfo).forEach(([sid, info]) => {
      const { customerId, reqAmount, checked } = info
      if (info?.checked && info?.reqAmount > 0) {
        checkedRequestForInit.push({
          customerId: customerId,
          reqAmount: reqAmount,
          currency: currency,
          checked: checked,
        })
      }
    })
  })

  let sortedRequestsByID = []

  if (checkedRequestForInit.length > 0) {
    sortedRequestsByID = checkedRequestForInit.reduce((acc: any[], request) => {
      const containsID = acc.find(
        (item) => item?.customerId === request.customerId
      )

      const { currency, reqAmount, customerId } = request

      if (containsID) {
        containsID.currencies.push({
          currency: currency,
          amount: reqAmount,
        })
      } else {
        acc.push({
          customerId: customerId,
          currencies: [{ currency: currency, amount: reqAmount }],
        })
      }

      return acc
    }, [])
  }
  let dataForInit: IRequestForInit

  if (sortedRequestsByID.length > 0) {
    dataForInit = Object.fromEntries(
      sortedRequestsByID.map((item) => {
        const { customerId, currencies } = item

        return [
          customerId,
          Object.fromEntries(
            currencies.map((currency: { currency: string; amount: number }) => [
              currency.currency,
              currency.amount,
            ])
          ),
        ]
      })
    )
  }

  const createSettlement = (values: IRequestForInit) => {
    if (Object.keys(values).length) {
      create({
        settlementsData: values,
        customerId: user.id,
      })
    }
  }

  return (
    <Dialog open={isOpenModal && parsedDataForConfirmation.length > 0}>
      <div className={classes.modal}>
        <DialogContent className={classes.content}>
          <Typography variant="h6" align="left" className={classes.title}>
            {t('settlements.confirmTitle')}
          </Typography>

          <Typography variant="body1" align="left" className={classes.subtitle}>
            {t('settlements.confirmSubtitle')}
          </Typography>

          <div>
            <Table className={globalClasses.tableSimple}>
              <TableBody>
                {parsedDataForConfirmation
                  .filter((item) => item.sids.length > 0)
                  .map((row) => (
                    <SettlementConfirmationRow key={row.currency} row={row} />
                  ))}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.actions}>
          <div className={classes.actionsButtons}>
            <Button
              className={clsx(
                globalClasses.button,
                classes.button,
                classes.cancelButton
              )}
              color="primary"
              variant="contained"
              disabled={creating}
              onClick={() => setIsOpenModal(false)}
              id="return-settlement"
            >
              {t('settlements.return')}
            </Button>
            <Button
              className={clsx(globalClasses.button, classes.button)}
              color="primary"
              variant="contained"
              disabled={creating}
              id="confirm-settlement"
              onClick={() => createSettlement(dataForInit)}
            >
              {t('settlements.confirm')}
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}
export default SettlementConfirmation
