import React, { FC, useEffect, useState } from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'
import OpenInNew from '@material-ui/icons/OpenInNew'

import Loader from '../../components/Loader'
import { getNftImageById, INftData } from '../../helpers/nftHelpers'

import NFTSoldOut from './NFTSoldOut'
import { INftPreviewProps } from './NFTPreview.types'

const NftPreview: FC<INftPreviewProps> = ({ id, contract, onLoad }) => {
  const styles = useNFTPreviewStyles()

  const [loading, setIsLoading] = useState(true)
  const [nft, setNft] = useState<INftData | null>(null)

  useEffect(() => {
    getNftImageById(id, contract)
      .then((data) => {
        onLoad?.(data)
        setNft(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch(console.error)
  }, [])

  return (
    <Loader loading={loading} className={styles.loader}>
      {nft?.imageSrc && (
        <figure className={styles.figure}>
          {nft.isSoldOut && <NFTSoldOut />}

          <img className={styles.image} src={nft.imageSrc} alt={nft.title} />
          <a
            className={styles.externalUrl}
            href={nft.externalUrl}
            target="_blank"
            rel="noreferrer"
          >
            <figcaption className={styles.figcaption}>
              <b>{nft.title}</b>

              <OpenInNew />
            </figcaption>
          </a>
        </figure>
      )}
    </Loader>
  )
}

const useNFTPreviewStyles = makeStyles(() => ({
  figure: {
    margin: 0,
    display: 'grid',
    gridTemplateRows: '1fr',
    position: 'relative',
    width: '100%',
    marginBottom: '32px',
    borderRadius: '8px',
    overflow: 'hidden',
    gridTemplateAreas: 'figure',
  },
  loader: {
    minHeight: '150px',
  },
  image: {
    width: '100%',
    gridArea: 'figure',
  },
  figcaption: {
    minHeight: '40px',
    padding: '0 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: 'white',
  },
  externalUrl: {
    'gridArea': 'figure',
    'alignSelf': 'end',
    'display': 'block',
    'color': 'white',
    'textDecoration': 'none',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '&:visited': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}))

export default NftPreview
