import { isJson } from "../validators";

export default function createReport(methodName: string, e: any) {
  switch (methodName) {
    case 'useUpdateApi':
      return createApiReport('UPDATE API', e)
    case 'useFetchApi':
      return createApiReport('FETCH API', e)
    default:
      return { error: e, data: {} }
  }
}

function createApiReport(methodName: string, e: any) {
  let errorStatus = `status ${e.response.status}`;

  if (e.response.data.error.type === 'CONSTRAINT_VIOLATION') {
    errorStatus = 'CONSTRAINT_VIOLATION';
  } 
  else if (e.response.data.error.type === 'CARD_NOT_SUPPORTED') {
    errorStatus = 'CARD_NOT_SUPPORTED';
  } 
  else if (e.response.data.error.type === 'CARD_PROCESSING_ERROR') {
    errorStatus = 'CARD_PROCESSING_ERROR';
  }

  return {
    error: new Error(
      `${methodName} ERROR ${errorStatus} url ${e.response.config.url}`
    ),
    data: {
      extra: {
        response: e.response.data,
        request: filteredRequestData(e.response.config)
      },
      tags: {
        component: "api",
        url: e.response.config.url,
      }
    } 
  }
}

function filteredRequestData(requestData: any) {
  if (isJson(requestData.data)) {
    const parsedData = JSON.parse(requestData.data);
    delete parsedData.securityCode;
    parsedData.number = parsedData?.number ? parsedData.number.replace(parsedData.number.substr(6,parsedData.number.length-(4+6)), parsedData.number.substr(6,parsedData.number.length-(4+6)).replace(/./g,"*")) : 'card number not available';
    requestData.data = JSON.stringify(parsedData);
  }
  return requestData;
}