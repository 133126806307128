export const setMaintenance = (maintenance: boolean) => ({
  type: 'SET_MAINTENANCE',
  maintenance,
})

export const setCloudflareLimitError = () => ({ type: 'RATE_LIMIT_EXCEEDED' })

export const setCustomerNotSupported = (customerNotSupported: boolean) => ({
  type: 'SET_NOT_SUPPORTED',
  customerNotSupported,
})