import React, { useState, useEffect, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import merge from 'lodash/merge'

import { initialUI, useMerchantSettings } from '@/api/merchant'
import { useOrder, emptyOrder, OrderProcessingError } from '@/api/order'
import useQuery from '../../hooks/useQuery'
import Exchange from '../Exchange'
import Pay from './Pay'
import Layout from '../../components/Layout'
import Loader from '../../components/Loader'
import ProcessingError from '../../components/Order/ProcessingError'
import { setOrderDataRdx } from '@/store/actions/appData'
import { useCurrencyByIp } from '@/hooks/useCurrencyByIp'
import { USA_CODE } from '@/helpers/usFlowHelper'
import CurrencyRestricted from '../Account/Profile/CurrencyRestricted'
import { useCurrencies } from '@/api/currency'
import { OrderExtendedType } from '@/api/private/orders'
import Payout from '@/views/Payout'
import { countryByApm } from '@/helpers/countryByApm'
import CountryWithoutIP from '@/components/CountryWithoutIP'
import { getResidenceFromStorage } from '@/helpers/getResidenceFromStorage'
import Menu from '@/components/Layout/Order/Menu'
import { isChainValley } from '@/helpers/mirrorHelper'
import { getLayoutApmView } from '@/helpers/getLayoutApmView'
import { updateIntercomCustomer } from '@/helpers/updateIntercomCustomer'

const Order: FC = () => {
  const dispatch = useDispatch()
  const { id, step } = useParams<any>()
  const history = useHistory()
  const [{ token: authToken }] = useQuery({})
  const [ui, setUi] = useState(initialUI)
  const [createNewOrder, setCreateNewOrder] = useState(false)
  // address change
  const [confirmFormChange, setConfirmFormChange] = useState(false)
  const [{ data: order, error }, loading, load] = useOrder()
  const [{ data: uiServer }, uiLoading, loadUi] = useMerchantSettings()
  const [orderData, setOrderData] = useState<any>({ ...emptyOrder })
  const [customer, setCustomer] = useState({
    kycStatus: null,
    kycFlow: null,
  } as any)
  const { uiLoaded } = useSelector((state: any) => state.common)
  const [{ data: currencies }, currenciesLoading, loadCurrencies] =
    useCurrencies()
  const [showPromo, setShowPromo] = useState(false)
  const [processingError, setProcessingError] = useState<
    OrderProcessingError | undefined
  >()

  const [unavailableCurrency, setUnavailableCurrency] = useState(false)
  const { ipCountry } = useCurrencyByIp(true)
  const storageResidence = getResidenceFromStorage()
  const [isSaveCountry, setIsSaveCountry] = useState(false)

  const showApmView = getLayoutApmView(step, order)

  useEffect(() => {
    if (ipCountry !== USA_CODE || !currencies.length) {
      return
    }

    if (
      order.currency &&
      !currencies.find((c: any) => c.currency === order.currency)
    ) {
      setUnavailableCurrency(true)
    }
  }, [currencies, order.currency])

  useEffect(() => {
    load({ id })
    updateIntercomCustomer({ Order: String(id) })
    loadUi({ orderId: id })
  }, [id])

  useEffect(() => {
    setUi(merge(initialUI, uiServer))
  }, [uiServer])

  useEffect(() => {
    setOrderData(order)
    dispatch(setOrderDataRdx(order))
  }, [order])

  useEffect(() => {
    if (order.mId) {
      addGtag(order.mId)
    }
  }, [order.mId])

  useEffect(() => {
    if (ipCountry.length) {
      window.localStorage.removeItem('residence')
      loadCurrencies({ orderId: id, country: ipCountry })
    }
  }, [id, ipCountry])

  useEffect(() => {
    if (order.sf && !ipCountry.length) {
      loadCurrencies({ orderId: id, country: countryByApm(order.apm) })
    }
  }, [id, ipCountry, order?.sf])

  useEffect(() => {
    if (isSaveCountry && !order?.sf && !ipCountry.length) {
      if (storageResidence) {
        loadCurrencies({
          orderId: id,
          country: storageResidence?.code,
        })
        return
      }
    }
    if (storageResidence && !order?.sf && order?.id && !ipCountry.length) {
      if (storageResidence?.id === Number(id)) {
        loadCurrencies({
          orderId: id,
          country: storageResidence?.code,
        })
      } else {
        window.localStorage.removeItem('residence')
      }
    }
  }, [id, isSaveCountry, order?.sf, order.id, ipCountry])

  useEffect(() => {
    if (createNewOrder) {
      history.push('/exchange/transaction', {
        sourceUrl: `${window.location.pathname}${window.location.search}`,
        sourceOrder: JSON.stringify(order),
      })
    }
  }, [createNewOrder, order])

  const isPayout =
    order.type === OrderExtendedType.WALLET_TO_FIAT &&
    !unavailableCurrency &&
    !!authToken

  const isPayoutLayout =
    order.type === OrderExtendedType.WALLET_TO_FIAT && !unavailableCurrency

  const isPayFlow =
    !isPayout &&
    !!authToken &&
    !createNewOrder &&
    !!orderData?.address &&
    !unavailableCurrency

  const isExchangeFlow = !isPayout && !isPayFlow && !unavailableCurrency

  if (processingError) {
    return <ProcessingError error={processingError} url={order.failUrl} />
  }

  function addGtag(mId: any) {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.textContent = `
    gtag('event', 'screen_view', {
      'mId': '${mId}',
    });`
    document.body.appendChild(s)
  }

  if (order.id && !order.sf && !ipCountry.length && !storageResidence) {
    return (
      <Layout
        showPromo={showPromo}
        closePromo={() => setShowPromo(false)}
        step={step}
        isPayout={isPayoutLayout}
      >
        <CountryWithoutIP order={order} setIsSaveCountry={setIsSaveCountry} />
      </Layout>
    )
  }

  return (
    <Layout
      showPromo={showPromo}
      closePromo={() => setShowPromo(false)}
      step={step}
      order={order}
      isPayout={isPayoutLayout}
    >
      <Loader loading={loading || uiLoading || !uiLoaded}>
        {unavailableCurrency && (
          <CurrencyRestricted
            fallBackUrl={order.failUrl}
            currency={order.currency}
            country={ipCountry}
          />
        )}
        {isPayout && (
          <Payout
            token={authToken}
            currencies={currencies}
            loadOrder={load}
            setProcessingError={setProcessingError}
          />
        )}
        {isPayFlow && (
          <Pay
            token={authToken}
            createNewOrder={(v: boolean) => {
              setCreateNewOrder(v)
              setConfirmFormChange(false)
            }}
            order={orderData}
            setOrder={setOrderData}
            loadOrder={load}
            ui={ui}
            orderError={error}
            confirmFormChange={confirmFormChange}
            setConfirmFormChange={setConfirmFormChange}
            setProcessingError={setProcessingError}
            currencies={currencies}
          />
        )}
        {isExchangeFlow && (
          <Exchange
            currencies={currencies}
            currenciesLoading={currenciesLoading}
            createNew={createNewOrder}
            setCreateNew={setCreateNewOrder}
            order={orderData}
            loadOrder={load}
            orderError={error}
            ui={ui}
            setOrder={setOrderData}
            customer={customer}
            setCustomer={setCustomer}
            setProcessingError={setProcessingError}
          />
        )}
        {isChainValley && showApmView && <Menu isCCWChainValley />}
      </Loader>
    </Layout>
  )
}

export default Order
