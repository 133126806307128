import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormProps } from 'react-final-form'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'

import useGlobalStyles from '../../../../hooks/useGlobalStyles'
import usePaymentFormStyles from '../../../components/usePaymentFormStyles'
import MaskedInput from '../../../../components/Form/MaskedInput'
import OrderDetails from '../../../components/Details'
import { IOrder } from '../../../../api/order'
import { isPhoneNumber, isSsnInValid } from '../../../../validators'
import { ICustomer } from '../../../../types/customer'
import { trackUsFlowInfo } from '@/helpers/analytics'

const isDev = process.env.REACT_APP_WIDGET_ENV !== 'prod'

interface IPayUSSecurityForm {
  socialSecurityNumber?: string
  phoneNumber?: string
}

interface IOnProceedProps {
  ssn: string
  phone: string
}

interface IPayUSSecurityStep {
  order: IOrder
  customer: ICustomer
  onProceed(values: IOnProceedProps): Promise<any>
}

const PayUSSecurityStep: FC<IPayUSSecurityStep> = ({
  order,
  customer,
  onProceed,
}) => {
  const { t } = useTranslation()

  const paymentFormClasses = usePaymentFormStyles()
  const globalClasses = useGlobalStyles()

  const onSubmit: FormProps<IPayUSSecurityForm>['onSubmit'] = async (
    values
  ) => {
    try {
      await onProceed({
        ssn: values.socialSecurityNumber as string,
        phone: values.phoneNumber as string,
      })
    } catch (err) {
      const error: any = err
      if (error.isAxiosError) {
        const errorType = err.response?.data?.error?.type

        if (errorType === 'DUPLICATED_SSN') {
          return {
            socialSecurityNumber: t('validation.duplicatedSsn'),
          }
        }
        if (errorType === 'DUPLICATED_PHONE') {
          return {
            phoneNumber: t('validation.duplicatePhone'),
          }
        }

        const errorDetails = err.response?.data?.error?.details
        if (Array.isArray(errorDetails)) {
          let hasSsnError = false
          let hasPhoneError = false
          errorDetails.forEach((error: Partial<IOnProceedProps>) => {
            if (error.ssn) {
              hasSsnError = true
            }
            if (error.phone) {
              hasPhoneError = true
            }
          })
          const submitError: Partial<IPayUSSecurityForm> = {}
          if (hasSsnError) {
            submitError.socialSecurityNumber = t('validation.invalidSsnFormat')
          }
          if (hasPhoneError) {
            submitError.phoneNumber = t('validation.Invalid phone number')
          }
          if (submitError.socialSecurityNumber || submitError.phoneNumber) {
            return submitError
          }
        }
      } else {
        console.error(error)
        return {
          socialSecurityNumber: t('somethingWentWrong'),
        }
      }
    }
  }

  return (
    <Form<IPayUSSecurityForm>
      initialValues={{
        socialSecurityNumber: customer.ssn,
        phoneNumber: customer.phone,
      }}
      onSubmit={onSubmit}
      validate={(values) => {
        const error: Partial<Record<keyof IPayUSSecurityForm, string>> = {}

        if (!values.socialSecurityNumber) {
          error.socialSecurityNumber = t('validation.Required field')
        }

        if (!values.phoneNumber) {
          error.phoneNumber = t('validation.Required field')
        }

        if (error.socialSecurityNumber || error.phoneNumber) {
          return error
        }

        const phoneValidation = isPhoneNumber(
          values.phoneNumber ?? '',
          isDev ? undefined : 'US'
        )

        if (phoneValidation) {
          error.phoneNumber = phoneValidation
        }

        const invalidSSN = isSsnInValid(values.socialSecurityNumber ?? '')
        if (invalidSSN) {
          error.socialSecurityNumber = invalidSSN
        }
        if (values.socialSecurityNumber && !invalidSSN) {
          trackUsFlowInfo(
            'add_social_number',
            order,
            customer?.country,
            customer?.state,
            'onramp_personal_info'
          )
        }
        if (values.phoneNumber && !phoneValidation) {
          trackUsFlowInfo(
            'add_phone_number',
            order,
            customer?.country,
            customer?.state,
            'onramp_personal_info'
          )
        }

        return error
      }}
      render={({ handleSubmit }) => {
        return (
          <form
            noValidate
            onSubmit={handleSubmit}
            className={globalClasses.payButtonWrapper}
          >
            <MaskedInput
              fullWidth
              label={t('labels.enterSecurityNumber')}
              customMask="999-99-9999"
              placeholder="XXX-XX-XXXX"
              name="socialSecurityNumber"
              type="text"
            />
            <MaskedInput
              fullWidth
              customMask={
                isDev ? '+99999999999999999999999' : '+1 (999) 999-99-99'
              }
              placeholder="+1 (XXX) XXX-XX-XX"
              label={t('labels.specifyPhone')}
              name="phoneNumber"
              type="text"
            />
            <OrderDetails order={order} styles={paymentFormClasses} />
            <Button
              className={clsx('customButton', globalClasses.button)}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('Proceed')}
            </Button>
          </form>
        )
      }}
    />
  )
}

export default PayUSSecurityStep
