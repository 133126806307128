import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cookies from 'react-cookies'
import { Helmet } from 'react-helmet'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import {
  getAppleTouchIcon,
  getDescription,
  getFavicon,
  getTitle,
  isChainValley,
} from '@/helpers/mirrorHelper'
import appstore from '@/components/icons/appstore.png'
import googleplay from '@/components/icons/googleplay.png'
import { MIRROR } from '@/helpers/constants'
import { getLayoutApmView } from '@/helpers/getLayoutApmView'
import { IOrder } from '@/api/order'

import Menu from './Order/Menu'
import Panel from '../Panel/Panel'
import Loader from '../Loader'
import useGlobalStyles from '../../hooks/useGlobalStyles'
import UAPromoBanner from '../../components/Promo/UAPromoBanner'
import referrals from '../../config/referrals.json'
import { INTERNAL_LINK_TARGET_BLANK } from '../../helpers/internalTarget'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    paddingBottom: 120,
    paddingTop: 44,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    // minWidth: 375,
    [theme.breakpoints.down('xs')]: {
      'paddingTop': 0,
      'alignItems': 'flex-start',
      '& > div': {
        width: '100%',
      },
    },
  },
  bcLogo: {
    'marginTop': theme.spacing(2),
    'display': 'block',
    'width': 112,
    'height': 24,
    'opacity': 0.75,
    'transition': 'opacity 300ms ease 0s',
    '&:hover': {
      opacity: 1,
    },
  },
  content: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
  },
}))

interface ILayoutProps {
  children: any
  showPromo?: boolean
  closePromo?(): void
  ignoreUi?: boolean
  step?: string
  paymentCurrency?: string
  currency?: string
  hideRightSideInfo?: boolean
  isPayout?: boolean
  order?: IOrder
}

const reCaptcha3Key =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  '6LeIDKgZAAAAABpUZ9JyPtgHEf_OwylbsKDcrPBR'

export default function Layout({
  children,
  ignoreUi,
  showPromo,
  order,
  closePromo,
  step,
  paymentCurrency,
  currency,
  hideRightSideInfo,
  isPayout,
}: ILayoutProps) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { t } = useTranslation()
  const {
    ui: {
      global: { favicon },
    },
  } = useSelector((state: any) => state.common)

  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false)

  const showApmView = getLayoutApmView(step, order)
  const isCCWChainValley = isChainValley && showApmView

  // @ts-ignore
  const referral = referrals[cookies.load('r')]

  useEffect(() => {
    // @ts-ignore
    if (!window.grecaptcha) {
      const s = document.createElement('script')
      s.src = `https://www.google.com/recaptcha/api.js?render=${reCaptcha3Key}`
      s.onload = function () {
        setRecaptchaLoaded(true)
      }
      s.onerror = function () {
        setRecaptchaLoaded(true)
      }
      s.onabort = function () {
        setRecaptchaLoaded(true)
      }
      document.body.appendChild(s)
    } else {
      setRecaptchaLoaded(true)
    }
  }, [])

  return (
    <div className={classes.root}>
      {showPromo && <UAPromoBanner onClose={closePromo} />}
      <Helmet>
        <link
          rel="icon"
          href={favicon && favicon.length ? favicon : getFavicon(MIRROR)}
        />
        <link rel="apple-touch-icon" href={getAppleTouchIcon(MIRROR)} />
        <title>{getTitle(MIRROR)}</title>
      </Helmet>
      <div className={classes.content}>
        <Panel
          step={step}
          paymentCurrency={paymentCurrency}
          currency={currency}
          hideRightSideInfo={hideRightSideInfo}
          isPayout={isPayout}
        >
          <Loader loading={!recaptchaLoaded}>{children}</Loader>
        </Panel>
        <div>
          {!isCCWChainValley && <Menu />}
          {!isChainValley && (
            <div>
              <div className={globalClasses.promoButtons}>
                <Link
                  href="https://cutt.ly/appstorewidget"
                  target={INTERNAL_LINK_TARGET_BLANK}
                >
                  <img
                    className={globalClasses.promoBtn}
                    src={appstore}
                    width={160}
                    height={46}
                  />
                </Link>
                <Link
                  href="https://cutt.ly/6wbxnrJT"
                  target={INTERNAL_LINK_TARGET_BLANK}
                >
                  <img
                    className={globalClasses.promoBtn}
                    src={googleplay}
                    width={160}
                    height={46}
                  />
                </Link>
              </div>
              <div className={globalClasses.promoWrapper}>
                <div className={globalClasses.promoText}>
                  {t('downloadApp')}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={globalClasses.footer}>
        <div className="description">
          <Typography
            variant="body2"
            component="div"
            className="small"
            dangerouslySetInnerHTML={{ __html: t(getDescription(MIRROR)) }}
          />
          {referral && (
            <a
              href={referral.url}
              className={classes.bcLogo}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={referral.image}
                title={referral.title}
                alt={referral.alt}
                width="112"
                height="24"
              />
            </a>
          )}
        </div>
        <Typography className="recap">
          This site is protected by reCAPTCHA
          <br />
          and the Google&nbsp;
          <Link href="https://policies.google.com/privacy" target="_blank">
            Privacy Policy
          </Link>{' '}
          and&nbsp;
          <Link href="https://policies.google.com/terms" target="_blank">
            Terms of Service
          </Link>{' '}
          apply.
        </Typography>
      </div>
    </div>
  )
}
