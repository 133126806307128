import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Loader from '../../../../components/Loader';
import { OrderType } from '../../../../api/private/orders';
import { KycStatus } from '../../../../api/kyc';
import useGlobalStyles from '../../../../hooks/useGlobalStyles';

export default function OrderResult() {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.account);
  const { type, result } = useParams();
  const orderType = type ? type.toUpperCase() as OrderType : OrderType.DEPOSIT;
  const [order, setOrder] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    if (window.localStorage.getItem('lo')) {
      // @ts-ignore
      const o = JSON.parse(window.localStorage.getItem('lo'));
      if (!o.id) {
        window.localStorage.removeItem('lo');
        history.push(`/account/orders/create/${orderType.toLowerCase()}`);
      } else {
        setOrder(o);
      }
    } else {
      history.push(`/account/orders/create/${orderType.toLowerCase()}`)
    }

    return () => {
      window.localStorage.removeItem('lo');
    }
  }, [])
  return (
    <div className={globalClasses.halfContent}>
      <Typography variant="h6" color={result === 'error' ? 'error' : 'primary'} component="div">
        {t(`paymentResult.${result}Title`, order)}
      </Typography>
      <Typography variant="body1" component="div">
        <p
          dangerouslySetInnerHTML={{
            __html: t(`paymentResult.${result}${type === OrderType.WITHDRAWAL ? 'Withdrawal' : ''}Details`, order)
          }}
        />
        {type === OrderType.DEPOSIT && (
          <p dangerouslySetInnerHTML={{ __html: t(`paymentResult.${result}CabinetDepositDetails`) }} />
        )}
        {type === OrderType.WITHDRAWAL && (
          <p dangerouslySetInnerHTML={{
            __html: t(`paymentResult.${result}2Details`, {
              address: order.address,
              byKyc: t(`paymentResult.byKyc${user.kycStatus === KycStatus.APPROVED ? 'Approved' : 'Default'}`)
            })}} />
        )}
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          window.localStorage.removeItem('lo');
          window.location.assign(`/account/orders/create/${orderType.toLowerCase()}`)
        }}
        color="primary"
        className={globalClasses.button}
      >
        {t(`Make another ${orderType}`)}
      </Button>
    </div>
  );
}
