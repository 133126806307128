import blueGrey from '@material-ui/core/colors/blueGrey';
import blue from '@material-ui/core/colors/blue';
import {IPalette} from '../api/merchant';

const white = '#FFFFFF';
const black = '#000000';

function getHEX(c: string) {
  if (c.startsWith('#')) return c;
  return `#${c}`;
}

export default function createPalette(overrides: IPalette) {
  return {
    black,
    white,
    primary: {
      contrastText: (overrides.primary && overrides.primary.contrastText && overrides.primary.contrastText.length > 0) ? getHEX(overrides.primary.contrastText) : white,
      dark: (overrides.primary && overrides.primary.dark && overrides.primary.dark.length > 0) ? getHEX(overrides.primary.dark) : blue[900],
      main: (overrides.primary && overrides.primary.main && overrides.primary.main.length > 0) ? getHEX(overrides.primary.main) : '#2F66E3',
      light: (overrides.primary && overrides.primary.light && overrides.primary.light.length > 0) ? getHEX(overrides.primary.light) : blue[100]
    },
    secondary: {
      contrastText: (overrides.secondary && overrides.secondary.contrastText && overrides.secondary.contrastText.length > 0) ? getHEX(overrides.secondary.contrastText) : white,
      dark: (overrides.secondary && overrides.secondary.dark && overrides.secondary.dark.length > 0) ? getHEX(overrides.secondary.dark) : blueGrey[900],
      main: (overrides.secondary && overrides.secondary.main && overrides.secondary.main.length > 0) ? getHEX(overrides.secondary.main) : blueGrey[500],
      light: (overrides.secondary && overrides.secondary.light && overrides.secondary.light.length > 0) ? getHEX(overrides.secondary.light) : blueGrey[100]
    },
    text: {
      main: 'rgba(0,0,0,0.87)',
      primary: (overrides.text && overrides.text.primary && overrides.text.primary.length > 0) ? getHEX(overrides.text.primary) : blueGrey[900],
      secondary: (overrides.text && overrides.text.secondary && overrides.text.secondary.length > 0) ? getHEX(overrides.text.secondary) : 'rgba(0,0,0,0.6)',
      link: (overrides.text && overrides.text.link && overrides.text.link.length > 0) ? getHEX(overrides.text.link) : blue[600]
    },
    icon: (overrides.icon && overrides.icon.length > 0) ? getHEX(overrides.icon) : blueGrey[600],
    divider: 'rgba(0,0,0,0.12)'
  };
}
