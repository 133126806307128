import React, { useRef, useEffect } from 'react'
import { IFingerprintForm } from '@/types/payment'

interface IProps {
  form: IFingerprintForm
  checkStatus(): void
}

export default function Fingerprint({ form: { methodPayload, methodUrl }, checkStatus }: IProps) {
  const formRef = useRef<HTMLFormElement | null>(null)
  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
      checkStatus()
    }
  }, [formRef])
  return (
    <div style={{ display: 'none' }}>
      <iframe name="fingerprint" />
      <form target="fingerprint" method="post" action={methodUrl} ref={formRef}>
        <input type="hidden" name="threeDSMethodData" value={methodPayload} />
      </form>
    </div>
  )
}
