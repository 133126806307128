import Api from './index';
import useFetchApi from '../hooks/useFetch';

interface IPaletteColor {
  contrastText?: string;
  dark: string;
  main: string;
  light: string;
}

interface ITextColor {
  primary: string;
  secondary: string;
  link: string;
}

export interface IPalette {
  primary: IPaletteColor;
  secondary: IPaletteColor;
  text: ITextColor;
  border?: string;
  icon: string;
}

export interface ILocales {
  en: string;
  ru: string;
  fr: string;
  pt: string;
  pl: string
}

export interface IMenuLink {
  url?: string | null;
  text?: ILocales;
}

export interface IFooterMenu {
  terms?: IMenuLink;
  privacy?: IMenuLink;
}

interface IGlobalSettings {
  logo: string | null;
  favicon: string | null;
  palette: IPalette;
  footer: {
    show: boolean;
    copyright?: string | null;
    menu?: IFooterMenu;
    text?: ILocales;
  };
  chatID?: string | null;
  locale?: {
    langChangeDisable: boolean;
    defaultLang?: string;
  }
}

interface IFormField {
  show: boolean;
  title: ILocales;
  disable?: boolean;
}

interface IFormFields {
  [key: string]: IFormField;
}

interface IFormStep {
  title: ILocales;
  infoText?: ILocales;
  fields?: IFormFields;
  stepCurrencyTitle?: ILocales;
  stepEmailTitle?: ILocales;
  useInternalCard?: boolean;
}

export interface IButtonColor {
  base: string;
  hover: string;
}

export interface IBorderRadius {
  button: string;
  input: string;
}

export interface IMerchantSettings {
  global: IGlobalSettings;
  form: {
    title: ILocales;
    buttonName: ILocales;
    buttonColor?: IButtonColor;
    borderRadius?: IBorderRadius;
    authorization: IFormStep;
    personalInfo: IFormStep;
    verification: IFormStep;
    card: IFormStep;
  }
}

const TRANSLATIONS_BY_LOCALES = {
  en: '',
  ru: '',
  fr: '',
  pt: '',
  pl: '',
}

export const initialUI = {
  global: {
    logo: "",
    favicon: "",
    palette: {
      primary: {
        contrastText: "",
        dark: "",
        main: "",
        light: ""
      },
      secondary: {
        dark: "",
        main: "",
        light: ""
      },
      text: {
        primary: "",
        secondary: "",
        link: ""
      },
      icon: "",
      border: ""
    },
    footer: {
      show: true,
      copyright: "",
      menu: {
        terms: {
          url: "",
          text: TRANSLATIONS_BY_LOCALES,
        },
        privacy: {
          url: "",
          text: TRANSLATIONS_BY_LOCALES,
        }
      },
      text: TRANSLATIONS_BY_LOCALES,
    },
    locale: {
      langChangeDisable: false
    },
    chatID: ""
  },
  form: {
    title: TRANSLATIONS_BY_LOCALES,
    buttonName: TRANSLATIONS_BY_LOCALES,
    buttonColor: {
      base: "",
      hover: "",
    },
    borderRadius: {
      button: "",
      input: ""
    },
    authorization: {
      title: TRANSLATIONS_BY_LOCALES,
      stepCurrencyTitle: TRANSLATIONS_BY_LOCALES,
      stepEmailTitle: TRANSLATIONS_BY_LOCALES,
      fields: {
        currency: {
          show: true,
          title: TRANSLATIONS_BY_LOCALES,
        },
        paymentCurrency: {
          show: true,
          title: TRANSLATIONS_BY_LOCALES,
        },
        walletAddress: {
          show: true,
          title: TRANSLATIONS_BY_LOCALES,
        },
        email: {
          show: true,
          disable: false,
          title: TRANSLATIONS_BY_LOCALES,
        }
      }
    },
    verification: {
      title: TRANSLATIONS_BY_LOCALES,
      infoText: TRANSLATIONS_BY_LOCALES,
    },
    personalInfo: {
      title: TRANSLATIONS_BY_LOCALES,
    },
    card: {
      title: TRANSLATIONS_BY_LOCALES,
      useInternalCard: false
    }
  }
};

const setUI = (ui: any) => ({ type: 'SET_UI', ui });

export function useMerchantSettings() {
  return useFetchApi(
    async (...data: any) => Api.post('/settings/order/ui', ...data),
    { data: initialUI },
    setUI
  )
}
