export const getMaskedEmail = (email: string) => {
  const parts = email.split('@');
  const n = parts[0].split('');
  let res = n[0];
  for (let k = 1, l = n.length; k < l; k++) {
    res += '*';
  }
  return res + `@${parts[1]}`;
};

export const getMaskedPhone = (phone: string) => `${phone.substring(0, 5)}*****${phone.substring(10, 12)}`;

export const getMaskedCard = (card: string) => {
  const l = card.length;
  return `${card.substring(0, 4)}********${card.substring(l - 4, l)}`;
};
