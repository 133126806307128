import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import TwitterIcon from '@material-ui/icons/Twitter'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useGlobalStyles from '../../hooks/useGlobalStyles'

const useStyles = makeStyles(() => ({
  twitterButton: {
    borderColor: 'currentcolor',
    color: '#1DA1F2',
  },
}))

interface IButtonCheckTwitterProps {
  href: string
}

const ButtonCheckTwitter: FC<IButtonCheckTwitterProps> = ({ href }) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { t } = useTranslation()

  return (
    <Button
      className={clsx(globalClasses.button, classes.twitterButton)}
      variant="outlined"
      component="a"
      target="_blank"
      rel="nofollow"
      href={href}
      startIcon={<TwitterIcon />}
    >
      {t('twitterLinkPromo')}
    </Button>
  )
}

export default ButtonCheckTwitter
