import React from 'react'

import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { SettlementStatus } from '@/types/settlements'

const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'nowrap',
    padding: '4px 12px',
    letterSpacing: '0.02em',
    borderRadius: '8px',
    lineHeight: 1.5,
  },
  success: {
    backgroundColor: 'rgba(102, 187, 106, 0.15)',
    color: '#3e7241',
  },
  warning: {
    backgroundColor: 'rgba(251, 188, 4, 0.15)',
    color: '#9a7902',
  },
  error: {
    backgroundColor: 'rgba(255, 74, 68, 0.15)',
    color: '#9c3639',
  },
}))

interface ISettlementStatusView {
  status: SettlementStatus
}

function SettlementStatusView({ status }: ISettlementStatusView) {
  const classes = useStyles()

  let title = 'Requested'
  let className = classes.warning

  switch (status) {
    case SettlementStatus.SETTLED:
      title = 'Settled'
      className = classes.success
      break
    case SettlementStatus.REJECTED:
      title = 'Rejected'
      className = classes.error
      break
    case SettlementStatus.REQUESTED:
      break
  }

  return <span className={clsx(classes.root, className)}>{title}</span>
}

export default SettlementStatusView
