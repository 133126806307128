import React from 'react'
import clsx from 'clsx'

import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

import { Input, Select } from './index'
import useFormStyles from '../../views/Exchange/forms/useFormStyles'
import {
  composeValidators,
  mustBeNumber,
  intervalValue,
  required,
  minValue,
  maxValue,
  notEnoughBalance,
} from '../../validators'

import { ISelectOption } from '../../types/select'

interface IProps {
  inputName: string
  selectName: string
  currencies: ISelectOption[]
  label: any
  styles?: any
  currenciesLoading: boolean
  locked?: boolean
  inputEnabled?: boolean
  min?: number
  max?: number
  available?: number
  onInput?: any
  onBlur?: any
  isCBC?: boolean
  className?: any
}

export default function CurrencyField({
  inputName,
  selectName,
  currencies,
  label,
  styles = {},
  currenciesLoading,
  locked,
  inputEnabled,
  onInput,
  min,
  max,
  available,
  isCBC,
  className,
  onBlur,
}: IProps) {
  const formClasses = useFormStyles(styles)
  return (
    <Input
      name={inputName}
      type="text"
      label={label}
      fullWidth
      className={clsx(formClasses.currencyInput, className)}
      // @ts-ignore
      validate={composeValidators(
        required,
        mustBeNumber,
        available !== undefined
          ? (v: any) => notEnoughBalance(v, available)
          : () => undefined,
        min !== undefined && max !== undefined
          ? (v: any) => intervalValue(v, min, max)
          : min !== undefined
          ? (v: any) => minValue(v, min)
          : max !== undefined
          ? (v: any) => maxValue(v, max)
          : () => undefined
      )}
      disabled={!inputEnabled}
      onInput={onInput}
      onBlur={onBlur}
      inputProps={{ inputMode: 'numeric', lang: 'en' }}
      InputProps={{
        endAdornment: (
          <Select
            name={selectName}
            label=""
            loading={currenciesLoading}
            options={currencies}
            className={formClasses.currencySelect}
            disabled={locked || currencies.length < 2}
            native={false}
            translate="no"
            renderOption={(option) => {
              if (!option) {
                return <MenuItem />
              }
              return (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}&nbsp;
                  {option.caption && (
                    <Typography
                      display="block"
                      color="textSecondary"
                      variant="caption"
                    >
                      {option.caption}
                    </Typography>
                  )}
                </MenuItem>
              )
            }}
          />
        ),
      }}
    />
  )
}
