import { MerchantApi } from '../index';
import { useUnauthorizedUpdateApi } from '../../hooks/useUpdate';

export function useConvert(sid = '', cb: any) {
  return useUnauthorizedUpdateApi(
    async (data: any) => MerchantApi.post('/tools/convert', data, {
      headers: {
        'X-AUTH-SID': sid,
        'X-AUTH-NONCE': Date.now()
      },
    }),
    cb
  )
}

export function useInit(cb: any) {
  return useUnauthorizedUpdateApi(
    async (...data: any) => MerchantApi.post('/order/init', ...data),
    cb
  )
}
