import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUDomain } from '@/validators';
import { useUDSupported, useUDResolve } from '@/api/settings';

interface UseUDomain {
  isValidUDomain: boolean;
  checkIsValidUDomain: (v: string) => void;
  resolveAddressFromUD: (currencyId: string) => void;
  uDomainSupportError: string | undefined;
  uDomainResolveError: string | undefined;
}

export function useUDomain(): UseUDomain {
  const [isValidUDomain, setIsValidUDomain] = useState(false);
  const [currency, setCurrency] = useState('')
  const [uDomain, setUDomain] = useState('');
  const [{ data: supported, error: supportError }, checkingSupported, checkSupported] = useUDSupported();
  const [{ error: resolveError }, resolving, resolve] = useUDResolve();
  const { t } = useTranslation();

  function checkIsValidUDomain(v: string) {
    const valid = isUDomain(v);
    setIsValidUDomain(valid);
    if (valid) {
      setUDomain(v);
    }
  }

  useEffect(() => {
    if (uDomain.length && supported && currency.length) {
      resolve({ domain: uDomain, currency });
    }
  }, [uDomain, supported, currency])

  useEffect(() => {
    if (isValidUDomain && uDomain.length) {
      checkSupported({ domain: uDomain });
    }
  }, [isValidUDomain, uDomain]);

  return {
    isValidUDomain,
    checkIsValidUDomain,
    resolveAddressFromUD: setCurrency,
    uDomainSupportError: isValidUDomain && (!supported || Object.keys(supportError).length > 0) ? t('unDomain.supportError') : undefined,
    uDomainResolveError: isValidUDomain && Object.keys(resolveError).length > 0 ? t('unDomain.resolveError') : undefined,
  }
}
