import Api from './index';
import useUpdateApi from '../hooks/useUpdate';
import useFetchApi from '../hooks/useFetch';

export enum KycStatus {
  NONE = 'NONE',
  PARTIAL = 'PARTIAL',
  NEW = 'NEW',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  REVIEWED = 'REVIEWED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CORRECTION_REQUESTED = 'CORRECTION_REQUESTED',
  TRUSTED = 'TRUSTED'
}

export const kycPassedStatuses: KycStatus[] = [
  KycStatus.TRUSTED,
  KycStatus.APPROVED,
  KycStatus.REVIEWED
]

export interface IKycRequest {
  firstName: string;
  lastName: string;
  birthDate: Date;
  address: string;
  residence?: string;
  zip: string;
  country: string;
  city: string;
  orderId?: string;
}

export function useKycRequest(cb: any) {
  return useUpdateApi(
    async (...data: any) => Api.post('/kyc/init', ...data),
    cb
  );
}

export function useKycSimpleRequest(cb: any) {
  return useUpdateApi(
    async (...data: any) => Api.post('/kyc/simple', ...data),
    cb
  );
}

export function useDocumentsRequest(cb: any) {
  return useUpdateApi(
    async (...data: any) => Api.post('/kyc/documents', ...data),
    cb
  );
}
