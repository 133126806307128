import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import PromoBanner from './PromoBanner'

interface IUaPromoBannerProps {
  onClose?(): void
}

const UaPromoBanner: FC<IUaPromoBannerProps> = ({ onClose = () => void 0 }) => {
  return (
    <PromoBanner href="https://utorg.pro/blogs/uabonus/" onClose={onClose}>
      <span>
        <img
          style={{ display: 'inline-block', height: '0.8em' }}
          src="/img/flags/icon-UA.svg"
          alt="ua flag"
        />
        &nbsp;&nbsp;
        <Trans i18nKey="uaPromo" />
      </span>
    </PromoBanner>
  )
}

export default UaPromoBanner
