import clsx from 'clsx';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Panel from '../../components/Panel/Panel';
import useGlobalStyles from '../../hooks/useGlobalStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    margin: '0 auto 24px',
    width: 84
  }
}))

interface IProps {
  className: any;
}

export default function ProofOfFundsFinish({ className }: IProps) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  return (
    <Panel className={className}>
      <div className={classes.icon}>
        <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.7764 68.7236C-0.258796 53.6884 -0.258796 29.3116 14.7764 14.2764C29.8116 -0.758795 54.1884 -0.758795 69.2236 14.2764C84.2588 29.3116 84.2588 53.6884 69.2236 68.7236C54.1884 83.7588 29.8116 83.7588 14.7764 68.7236ZM12.6551 12.1551C-3.55169 28.3618 -3.55169 54.6382 12.6551 70.8449C28.8618 87.0517 55.1382 87.0517 71.3449 70.8449C87.5517 54.6382 87.5517 28.3618 71.3449 12.1551C55.1382 -4.05169 28.8618 -4.05169 12.6551 12.1551ZM24.814 41.9479L36.5533 52.1549L59.4791 29.2291L61.6004 31.3504L37.6854 55.2654L36.6962 56.2545L35.6405 55.3367L22.8456 44.2118L24.814 41.9479Z" fill="#27AE60"/>
        </svg>
      </div>
      <Typography variant="h5" component="div" align="center" className={globalClasses.title}>
        {t('Your documents have been uploaded')}
      </Typography>
      <Typography variant="body2" component="div" align="center">
        {t('proofOfFundsFinishText')}
      </Typography>
      <Button
        className={clsx(globalClasses.button, 'pof-button')}
        variant="contained"
        color="primary"
        type="submit"
      >
        {t('Got it')}
      </Button>
    </Panel>
  )
}
