export default function (palette: any) {
  return {
    root: {
      color: 'rgba(0,0,0,0.6)',
      marginTop: -9,
      marginBottom: -9
    },
    colorSecondary: {
      '&.Mui-checked': {
        color: palette.primary.main
      }
    }
  };
}