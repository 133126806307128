import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Form} from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {Input} from '../../../../components/Form';
import {IOrder} from '../../../../api/order';
import {OrderType, useWithdrawalCodeResend} from '../../../../api/private/orders';
import {PaymentSource} from '../../../../api/payment';
import {getMaskedCard, getMaskedEmail} from '../../../../helpers/mask';
import {composeValidators, lengthRequired, required} from '../../../../validators';
import plural from '../../../../helpers/plural';
import Link from '@material-ui/core/Link';
import ServerMessage from '../../../../components/UI/ServerMessage';
import useGlobalStyles from '../../../../hooks/useGlobalStyles';
import useFormStyles from '../../../Exchange/forms/useFormStyles';
import pcidssUsers from '../../../../config/pcidss.json';
import CurrencyNameMapper from "../../../../components/CurrencyNameMapper/CurrencyNameMapper";

interface IProps {
  order: IOrder;
  accept: any;
  accepting: boolean;
  currencies: any[];
  type: OrderType;
  acceptError: string;
  billing: boolean;
  saveBilling: any;
  savingBilling: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    marginBottom: theme.spacing(4)
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > *:first-child': {
      width: 120
    }
  },
  wdConfirm: {
    marginBottom: theme.spacing(1)
  },
  link: {
    cursor: 'pointer'
  },
  codeInput: {
    maxWidth: 200
  },
}));

export default function OrderInfo({ order, accept, accepting, currencies, type, acceptError, billing, saveBilling, savingBilling }: IProps) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const formClasses = useFormStyles({});
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.account);
  const [resendCodeTimeout, setResendCodeTimeout] = useState(40);
  const isWithdrawal = type === OrderType.WITHDRAWAL;
  const isFiatWithdrawal = isWithdrawal && currencies.find((c: any) => c.currency === order.paymentCurrency)
    && currencies.find((c: any) => c.currency === order.paymentCurrency).type === 'FIAT';
  const [resendCode] = useWithdrawalCodeResend((res: any) => {
    if (res.success) {
      setResendCodeTimeout(40);
    }
  });

  useEffect(() => {
    if (resendCodeTimeout > 0) {
      setTimeout(() => setResendCodeTimeout(resendCodeTimeout - 1), 1000)
    }
  }, [resendCodeTimeout]);

  // @ts-ignore
  const isPCIDSS = user.email.includes('utform@') || pcidssUsers.users.includes(user.id);

  return (
    <div>
      <Table className={globalClasses.tableSimple}>
        <TableBody>
          <TableRow>
            <TableCell className="title">
              <Typography variant="body2" component="span">{t('labels.Sell')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="span" translate="no">

                {isWithdrawal ? <>{order.amount} {<CurrencyNameMapper currency={order.currency} />}</> : `${order.paymentAmount} ${order.paymentCurrency}`}
              </Typography>
            </TableCell>
          </TableRow>
        <TableRow>
          <TableCell className="title">
            <Typography variant="body2" component="span">{t('labels.Buy')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" component="span" translate="no">
              {/* {isWithdrawal ? `${order.paymentAmount} ${order.paymentCurrency}` : `${order.amount} ${order.currency}`} */}
              {isWithdrawal ? `${order.paymentAmount} ${order.paymentCurrency}` : <>{order.amount} {<CurrencyNameMapper currency={order.currency} />}</>}
            </Typography>
          </TableCell>
        </TableRow>
        {order.address && (
          <TableRow>
            <TableCell className="title">
              <Typography variant="body2" component="span">
                {t(isFiatWithdrawal ? 'labels.Card number' : 'labels.Wallet address')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" component="span">
                {isFiatWithdrawal ? getMaskedCard(order.address) : order.address}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        </TableBody>
      </Table>
      {acceptError.length > 0 && <ServerMessage type="error" text={acceptError} isAccount />}
      {isWithdrawal
        ? (
          <Form
            onSubmit={(values) => accept({ ...values, id: order.id })}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="body2" component="div" className={clsx(globalClasses.hint, classes.wdConfirm)}>
                  {t('txConfirmViaEmailText')}&nbsp;{getMaskedEmail(user.email)}
                </Typography>
                <div>
                  <Input
                    name="code"
                    type="number"
                    label={t('Verification code')}
                    validate={composeValidators(required, (v: any) => lengthRequired(v, 6))}
                    autoFocus
                    fullWidth
                  />
                </div>
                <div>
                  <Button
                    className={globalClasses.button}
                    disabled={accepting}
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    {t('Confirm')}
                  </Button>
                </div>
                <div>
                  <div className={globalClasses.hint} translate="no">
                    {resendCodeTimeout > 0
                      ? (
                        <p className={globalClasses.resendTimeout}>
                          {t('Resend confirmation code in')} {resendCodeTimeout} {t(plural(resendCodeTimeout, ['secPlural1', 'secPlural2', 'secPlural5']))}.
                        </p>
                      ) : (
                        <p>
                          {t('notReceivedCode')}&nbsp;
                          <Link
                            onClick={() => resendCode({ id: order.id })}
                            className={classes.link}
                          >
                            {t('Resend code')}
                          </Link>
                        </p>
                      )
                    }
                  </div>
                </div>
              </form>
            )}
          />
        ) : (
          <div>
            {billing
              ? (
                <Form
                  onSubmit={saveBilling}
                  initialValues={{
                    city: '',
                    zipCode: '',
                    address: '',
                    country: user.country
                  }}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} style={{ marginTop: 16 }}>
                      <Typography variant="body1" className={formClasses.stepTitle}>{t('Billing address')}</Typography>
                      <div className={formClasses.row}>
                        <Input name="city" label={t('labels.city')} validate={required} />
                        <Input name="zipCode" label={t('labels.zip')} validate={required} />
                      </div>
                      <div className={formClasses.row}>
                        <Input name="address" label={t('labels.address')} validate={required} />
                      </div>
                      <Input name="country" label="" type="hidden" />
                      <Button
                        className={globalClasses.button}
                        disabled={accepting || savingBilling}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t('Confirm')}
                      </Button>
                    </form>
                  )}
                />
              ) : (
                <Button
                  className={globalClasses.button}
                  disabled={accepting}
                  onClick={() => accept({
                    source: isPCIDSS ? PaymentSource.CardInternal : PaymentSource.Card,
                    orderId: order.id
                  })}
                  variant="contained"
                  color="primary"
                >
                  {t('Confirm')}
                </Button>
              )}
          </div>
        )}
    </div>
  );
}
