import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

const UTA_TX_URL = 'utorg://tx'
const UTA_KYC_URL = 'utorg://kyc'

export function useUTARedirect() {
  function redirectToApp(appURL: string, redirectURL: string) {
    try {
      window.location.href = appURL
    } catch (e) {
      // Handle any potential errors here
    } finally {
      // setTimeout(() => {
      //   window.location.href = redirectURL
      // }, 200)
    }
  }

  useEffect(() => {
    const href = window.location.href
    const params = new URLSearchParams(window.location.search)
    const order = params.get('uo')

    if (isMobile) {
      if (order) {
        const appUrl = `${UTA_TX_URL}/${order}`
        redirectToApp(appUrl, href.replace('?uo=' + order, ''))
      } else if (href.includes('/account/info?source=email')) {
        redirectToApp(UTA_KYC_URL, href.replace('?source=email', ''))
      }
    }
  }, [])
}
