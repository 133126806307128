import { getHasGooglePay } from '@/helpers/googlePay'
import { IAPM } from '@/api/settings'

import useGooglePay from './useGooglePay'

function useHasGooglePay(
  isApmShown: boolean,
  apms: IAPM[],
  customerId: number
) {
  const { isGooglePayInited, isGooglePayLoading } = useGooglePay()

  const hasGooglePay = getHasGooglePay(
    isGooglePayInited,
    isGooglePayLoading,
    isApmShown,
    apms,
    customerId
  )

  return Boolean(hasGooglePay)
}

export default useHasGooglePay
