export const formatTextInput = (value: string | number | undefined) => {
  if (
    value &&
    typeof value === 'string' &&
    value.startsWith('0') &&
    isFinite(Number(value[1]))
  ) {
    return value.slice(1)
  }

  return value ?? ''
}
