import React from 'react'
import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import RouteWithLayout, { AccountRoute, LoginRoute } from './components/Route'
import Terms from './views/Info/Terms'
import Privacy from './views/Info/Privacy'
import KycAmlPolicy from './views/Info/KYC'
import PaymentResult from './views/Exchange/PaymentResult'
import DirectChange from './views/Direct'
import RedirectToDirectFlow from './views/Direct/RedirectPage'
import RedirectToReferralFlow from './views/ReferralExchange/ReferralRedirect'
import ReferralProgramPage from './views/Account/Refferral'
import Order from './views/Order'
import OrderResult from './views/Account/Orders/Create/Result'
import TestSentry from './test/TestSentry'
import { updateSentryContext } from './helpers/sentryParams'
import PaymentStatus from './views/Exchange/PaymentStatus'

import DemoInitPage from './views/Demo'
import FrameDemo from './views/Demo/Frame'

import { UTAMobilePay } from '@/views/UTAPayments'

// FIXME temporary demo for CBC
import DirectCBCChange from './views/DirectCBC'
import RedirectToDirectCBCFlow from './views/DirectCBC/RedirectPage'

// Account
import Orders from './views/Account/Orders'
import Profile from './views/Account/Profile'
import KYCPage from './views/Account/KYC'
import MerchantOrders from './views/Account/Orders/MerchantOrders'
import OrderCreate from './views/Account/Orders/OrderCreate'
import PasswordRestore from './views/Account/Login/PasswordRestore'
import PasswordReset from './views/Account/Login/PasswordReset'
import LoginPage from './views/Account/Login'
import CardsPage from './views/Account/Cards'
import Settlements from './views/Account/Settlements'
import Balances from './views/Account/Balances'

// Newsletter
import Newsletter from './views/Newsletter/Newsletter'

export default function Routes() {
  const location = useLocation()

  updateSentryContext(location)

  return (
    <Switch>
      <Redirect to="/account/login" from="/" exact />
      <Route component={Order} path="/order/:id/:step" exact />
      <Route component={DirectChange} path="/exchange/:step?" exact />
      <RouteWithLayout
        component={RedirectToDirectFlow}
        path="/direct/:sid/:wallet?"
        exact
      />
      <RouteWithLayout
        component={RedirectToReferralFlow}
        path="/partner/:referralId/:fromCurrency/:toCurrency"
        exact
      />
      <RouteWithLayout component={Terms} path="/terms" exact ignoreUi />
      <RouteWithLayout component={Privacy} path="/privacy" exact ignoreUi />
      <RouteWithLayout
        component={KycAmlPolicy}
        path="/kyc-aml-policy"
        exact
        ignoreUi
      />
      <Route component={PaymentResult} path="/payment-success/:status" exact />
      <Route component={PaymentResult} path="/payment-pending" exact />
      <Route component={PaymentStatus} path="/status" exact />

      <Route component={DemoInitPage} path="/demo" exact />
      <Route component={FrameDemo} path="/demo/iframe" exact />

      <Redirect to="/account/login" from="/account" exact />
      <Redirect to="/account/login" from="/account/password" exact />
      <Redirect to="/account/login" from="/account/password/reset" exact />
      <LoginRoute component={LoginPage} path="/account/login" exact />
      <LoginRoute component={PasswordRestore} path="/account/restore" exact />
      <LoginRoute
        component={PasswordReset}
        path="/account/password/reset/:token"
        exact
      />
      <AccountRoute component={Orders} path="/account/orders" exact />
      <AccountRoute component={Settlements} path="/account/settlements" exact />
      <AccountRoute
        component={MerchantOrders}
        path="/account/merchant-orders"
        exact
      />
      <AccountRoute component={Profile} path="/account/info" exact />
      <AccountRoute component={KYCPage} path="/account/kyc" exact />
      <AccountRoute
        component={OrderCreate}
        path="/account/orders/create/:type/:step?"
        exact
      />
      <AccountRoute
        component={OrderResult}
        path="/account/orders/finish/:type/:result"
        exact
      />
      <AccountRoute component={Balances} path="/account/wallets" exact />
      <AccountRoute component={CardsPage} path="/account/cards" exact />
      <AccountRoute
        component={ReferralProgramPage}
        path="/account/referral"
        exact
      />

      <RouteWithLayout
        component={RedirectToDirectCBCFlow}
        path="/cbc/direct/:sid/:wallet"
        exact
      />
      <RouteWithLayout
        component={DirectCBCChange}
        path="/cbc/exchange/:step?"
        exact
      />
      {process.env.NODE_ENV !== 'production' && (
        <Route component={TestSentry} path="/test-sentry" exact />
      )}

      {/* Utorg App payments */}
      <Route component={UTAMobilePay} path="/uta/mobile-pay" exact />

      <Route component={Newsletter} path="/newsletter-opt-in" exact />
      <Route component={Newsletter} path="/newsletter-opt-out" exact />
    </Switch>
  )
}
