import React from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useGlobalStyles from '@/hooks/useGlobalStyles'
import { useSettlementConfirmationStyles } from '@/hooks/useSettlementConfirmationStyles'
import {
  ICheckedSubaccountRequest,
  ISettlementRequestType,
} from '@/types/settlements'

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down(515)]: {
      marginBottom: theme.spacing(8),
    },
  },
}))

interface ISettlementRequestConfirmation {
  setIsOpenModal: (open: boolean) => void
  isOpenModal: boolean
  formData: ICheckedSubaccountRequest[]
  create: (data: ISettlementRequestType) => void
  creating: boolean
}

function SubaccountSettlementConfirmation({
  setIsOpenModal,
  isOpenModal,
  formData,
  create,
  creating,
}: ISettlementRequestConfirmation) {
  const { t } = useTranslation()
  const { user } = useSelector((state: any) => state.account)

  const classes = useSettlementConfirmationStyles()
  const globalClasses = useGlobalStyles()
  const localClasses = useStyles()

  const createSettlement = (requestItems: ICheckedSubaccountRequest[]) => {
    create({
      settlementsData: {
        [user.id]: Object.fromEntries(
          requestItems.map((request) => {
            return [request.currency, request.amount]
          })
        ),
      },
      customerId: user.id,
    })
  }

  return (
    <Dialog open={isOpenModal}>
      <div className={classes.modal}>
        <DialogContent className={clsx(classes.content, localClasses.content)}>
          <Typography variant="h6" align="left" className={classes.title}>
            {t('settlements.confirmTitle')}
          </Typography>
          <Typography variant="body1" align="left" className={classes.subtitle}>
            {t('settlements.confirmSubtitle')}
          </Typography>
          <div>
            <Table className={globalClasses.tableSimple}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.totalCell}
                    >
                      {t('Currency')}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.totalCell}
                    >
                      {t('settlements.reqAmount')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.map((request) => (
                  <TableRow key={request.currency}>
                    <TableCell>
                      <Typography variant="body2" component="span">
                        {request.currency}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" component="span">
                        {request.amount.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.actions}>
          <div className={classes.actionsButtons}>
            <Button
              className={clsx(
                globalClasses.button,
                classes.button,
                classes.cancelButton
              )}
              color="primary"
              variant="contained"
              disabled={creating}
              onClick={() => setIsOpenModal(false)}
              id="subaccount-return-settlement"
            >
              {t('settlements.return')}
            </Button>
            <Button
              className={clsx(globalClasses.button, classes.button)}
              color="primary"
              variant="contained"
              disabled={creating}
              id="subaccount-confirm-settlement"
              onClick={() => createSettlement(formData)}
            >
              {!creating ? (
                t('settlements.confirm')
              ) : (
                <CircularProgress className={classes.loader} size={18} />
              )}
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}
export default SubaccountSettlementConfirmation
