import { ICustomer } from '@/types/customer'
import update from 'immutability-helper'

const initial = {
  order: {},
  customer: {} as ICustomer,
  apm: {
    selected: '',
    apmAmount: 0,
  },
  currencies: {
    loading: false,
    data: [],
    map: {},
  },
  logo: '',
  exchangeRate: 0,
  ui: {},
}

export default function appData(state = initial, action: any) {
  const { data } = action
  switch (action.type) {
    case 'SET_EXCHANGE_RATE':
      return update(state, { exchangeRate: { $set: data || 0 } })
    case 'SET_ORDER_DATA':
      return update(state, { order: { $set: data } })
    case 'SET_CUSTOMER':
      return update(state, { customer: { $set: data } })
    case 'SET_APM_DATA':
      return update(state, { apm: { $set: data } })
    case 'UPDATE_CURRENCIES':
      return update(state, { currencies: { $merge: data } })
    case 'SET_LOGO':
      return update(state, { logo: { $set: data } })
    default:
      return state
  }
}
