import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';
import PaymentSystems from '../icons/paySystems.svg';
import FlagRU from '../icons/flagRu.svg';
import FlagUS from '../icons/flagUS.svg';
import FlagFR from '../icons/flagFR.svg';
import FlagPT from '../icons/flagPT.svg';
import FlagPL from '../icons/flagPL.svg'

interface IGlobalStylesProps {
  isChainValley?: boolean
}

const useGlobalStyles = makeStyles((theme) => ({
  root: {},
  button: {
    height: 62,
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25,
    boxShadow: 'none',
    [theme.breakpoints.down(350)]: {
      height: 58,
    },
    '&.text': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
    '&.small': {
      height: 48,
      fontSize: 12,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        letterSpacing: 0
      }
    },
    '&.Mui-disabled, &.customButton.Mui-disabled': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main
    },
    '&:disabled': {
      color: '#fff',
      opacity: 0.5
    },
    '&.hasMarginBottom': {
      marginBottom: theme.spacing(4)
    }
  },
  payButtonWrapper: {
    paddingTop: theme.spacing(4)
  },
  checkBox: {
    marginBottom: 12,
    color: 'rgba(0,0,0,0.6)',
    '&:last-child': {
      marginBottom: 0
    }
  },
  checkBoxText: {
    whiteSpace: 'normal',
  },
  cbAddressAccept: {
    marginRight: '0 !important',
    '& > span > span': {
      letterSpacing: '-0.04em',
      fontSize: 14
    }
  },
  errCheckBox: {
    '& .MuiCheckbox-root': {
      color: theme.palette.error.main
    }
  },
  title: {
    marginBottom: theme.spacing(3),
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1)
    },
    '& .subtitle': {
      color: 'rgba(0,0,0,0.38)',
      fontSize: 20
    },
    '& .stepBack': {
      position: 'absolute',
      left: -16,
      top: -59,
      color: theme.palette.text.primary,
      '& svg': {
        transform: 'rotate(180deg)'
      }
    }
  },
  formTitle: {
    marginTop: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(6),
  },
  hint: {
    fontSize: 14,
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(2)
  },
  resendTimeout: {
  },
  widgetTitle: {
    margin: '0 -40px 28px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '0 40px 21px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& .exchangeRate': {
      transition: 'opacity 250ms ease-out 0s',
      opacity: 1,
      '&.convertingExchangeRate': {
        opacity: 0.3
      }
    }
  },
  serverMessage: {
    margin: `0 -40px ${theme.spacing(2)}px`,
    padding: '18px 40px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
    color: 'rgba(0, 0, 0, 0.6)',
    '& .icon': {
      marginRight: theme.spacing(2),
      '& svg': {
        height: 20
      }
    },
    '&.error': {
      background: fade('#EB5757', 0.1),
      '& .icon': {
        color: '#EB5757'
      }
    },
    '&.alert': {
      background: fade('#F2C94C', 0.1),
      '& .icon': {
        color: '#F2C94C'
      }
    },
    '&.success': {
      background: fade('#27AE60', 0.1),
      '& .icon': {
        color: '#27AE60'
      }
    },
    '&.acc': {
      marginLeft: 0,
      marginRight: 0
    },
    '&.v-top': {
      alignItems: 'flex-start'
    }
  },
  tableSimple: {
    '& tr:last-child': {
      '& td': {
        borderBottom: 'none !important'
      }
    },
    '& td:first-child': {
      paddingLeft: 0,
    },
    '& td:last-child': {
      paddingRight: 0,
    },
    '& .switcher': {
      paddingLeft: 0,
      width: 20
    },
    '& th': {
      '&:first-child': {
        paddingLeft: 0
      },
      '&:last-child': {
        paddingRight: 0
      },
      '& .MuiTypography-root': {
        fontWeight: 600
      },
    },
    '& .title': {
      paddingLeft: 0,
      whiteSpace: 'nowrap',
      maxWidth: '50%',
      '& .MuiTypography-root': {
        fontWeight: 600
      }
    },
    '& .form': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      '& td': {
        paddingLeft: 0
      }
    }
  },
  halfContent: {
    maxWidth: 460
  },
  scrollX: {
    // [theme.breakpoints.down('sm')]: {
    //   overflowX: 'auto'
    // }
  },
  desktopShow: {
    [theme.breakpoints.down(1142)]: {
      display: 'none'
    }
  },
  mobileShow: {
    display: 'none',
    [theme.breakpoints.down(1142)]: {
      display: 'block'
    }
  },
  xsShow: {
    display: 'none',
    [theme.breakpoints.down(420)]: {
      display: 'block'
    }
  },
  xsHide: {
    display: 'block',
    [theme.breakpoints.down(420)]: {
      display: 'none'
    }
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  paymentSystems: {
    margin: '20px auto 0',
    height: 25,
    width: 165,
    background: `url("${PaymentSystems}") no-repeat center`,
    opacity: 0.6,
    '& .pci-link': {
      display: 'block',
      height: 25,
      width: 50,
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  footer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: 100,
    color: 'rgba(0, 0, 0, 0.6)',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end',
    gap: '15px',
    justifyContent: 'space-between',
    '& > *, & .small': {
      fontSize: 12
    },
    '& .recap': {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > *, & .small': {
        textAlign: 'center'
      },
      '& .description': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
      }
    }
  },
  bottom: ({ isChainValley }: IGlobalStylesProps) => (
    isChainValley
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '18px',
          marginTop: theme.spacing(0),
          paddingTop: theme.spacing(3),
          gap: '16px',
          width: '100%',
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          },
          [theme.breakpoints.down(350)]: {
            marginBottom: '40px',
          },
        }
      : {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: '56px',
          marginTop: theme.spacing(2),
          width: 560,
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          },
          [theme.breakpoints.down(350)]: {
            marginBottom: '40px',
          },
        }),
  menu: {
    display: 'flex',
  },
  bottomMenuLink: ({ isChainValley }: IGlobalStylesProps) => ({
    'padding': '0 !important',
    'marginRight': theme.spacing(2),
    'fontSize': 12,
    'width': 'auto !important',
    '&:last-child': {
      marginRight: 0,
    },
    '& a': {
      color: isChainValley ? '#B2B9C8' : 'rgba(0, 0, 0, 0.6)',
    },
  }),
  lang: ({ isChainValley }: IGlobalStylesProps) => ({
    'width': isChainValley ? 'auto' : 120,
    'paddingBottom': '0 !important',
    '& .MuiInput-root': {
      'display': 'flex',
      'justifyContent': 'flex-end',
      'background': 'transparent',
      '&:before, &:after': {
        display: 'none',
      }
    },
    '& .MuiSelect-select': {
      'padding': '3px 18px 0 0',
      'textAlign': 'right',
      'fontSize': 12,
      'width': 'auto',
      '&:focus': {
        background: 'transparent',
      },
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 13px)',
      width: '0.8em',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  }),
  langSelectorWrapper: {
    display: 'flex'
  },
  langItem: {
    paddingRight: '16px',
    paddingLeft: '35px',
    minHeight: 'auto !important',
    background: 'no-repeat 10px 9px',
    '&.ru, &.ru-RU': {
      backgroundImage: `url("${FlagRU}")`
    },
    '&.en, &.en-US': {
      backgroundImage: `url("${FlagUS}")`
    },
    '&.fr, &.fr-FR': {
      backgroundImage: `url("${FlagFR}")`,
    },
    '&.pt, &.pt-PT': {
      backgroundImage: `url("${FlagPT}")`,
    },
    '&.pl, &.pl-PL': {
      backgroundImage: `url("${FlagPL}")`,
    },
  },
  delimeter: {
    width: 'calc(100%)',
    height: 1,
    backgroundColor: '#8B959F',
    opacity: 0.25,
    marginBottom: 25
  },
  promoButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    marginBottom: '24px',
    [theme.breakpoints.down(350)]: {
      gap: '10px',
    },
  },
  promoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 1.2,
    color: '#7F8AAC',
    marginBottom: '20px',
  },
  promoText: {
    textAlign: 'center',
    flex: '0 0 290px',
  },
  promoBtn: {
    [theme.breakpoints.down(350)]: {
      width: 150,
      height: 40,
    },
  },
}))

export default useGlobalStyles;
