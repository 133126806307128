import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export default {
  root: {},
  h4: {
    fontSize: 34,
    [breakpoints.down('xs')]: {
      fontSize: 26
    }
  },
  h5: {
    fontSize: 24,
    [breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  h6: {
    fontSize: 20,
    lineHeight: 1.2,
    [breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  body2: {
    [breakpoints.down(350)]: {
      fontSize: '0.84rem'
    }
  }
}