import React, { useEffect } from 'react'

import { useHistory } from 'react-router'

import { useSelector } from 'react-redux'

import { Waypoint } from 'react-waypoint'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { useSettlementsList } from '@/api/private/account'

import DesktopSettlementsList from './DesktopSettlementsList'
import MobileSettlementsList from './MobileSettlementsList'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import Loader from '../../../components/Loader'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
}))

function Settlements() {
  const { t } = useTranslation()

  const { user } = useSelector((state: any) => state.account)

  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  const history = useHistory()

  const [{ items: settlements, full, error }, loading, load] =
    useSettlementsList()

  useEffect(() => {
    if (user.id) {
      if (!user?.authorities.includes('ROLE_MERCHANT')) {
        history.push('/account/info')
      } else {
        load({ filter: { customerId: user.id }, cursor: 0 })
      }
    }
  }, [user])

  return (
    <>
      <Typography
        variant="h4"
        className={clsx(globalClasses.title, classes.title)}
      >
        {t('settlements.title')}
      </Typography>
      {settlements.length > 0 && (
        <DesktopSettlementsList settlements={settlements} />
      )}
      {settlements.length > 0 && (
        <MobileSettlementsList settlements={settlements} />
      )}
      {settlements.length === 0 && !loading && (
        <Typography variant="body1" align="center">
          {t('settlements.empty')}
        </Typography>
      )}
      <Loader
        loading={loading}
        height={settlements.length > 0 ? 100 : undefined}
      />
      {!loading && !error && !full && (
        <Waypoint
          onEnter={() =>
            load({
              filter: { customerId: user.id },
              cursor: settlements.length,
            })
          }
        />
      )}
    </>
  )
}
export default Settlements
