export default function isCFError(res: any) {
  return !res.data || !res.data.error || res.data.error.type === 'RATE_LIMIT_EXCEEDED';
}

export function isTxLimitError(type: string) {
  return type.includes('LIMIT_REACHED');
}

export function getLimitPeriod(type: string) {
  if (type.includes('MONTH')) {
    return 'MONTH'
  }
  if (type.includes('WEEK')) {
    return 'WEEK'
  }

  return 'DAY'
}
