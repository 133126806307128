import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { KycStatus } from '../../api/kyc';
import { IAPM } from '../../api/settings';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import APMVerifyIcon from '../../icons/apmVerify.svg';
import usePaymentFormStyles from './usePaymentFormStyles';
import usePreVerify, { IPreVerifyCheckProps } from '../../hooks/payment/usePreVerify';
import AlertIcon from '../../icons/alert.svg';
import { trackTakeVerification, trackKycEvent } from '@/helpers/analytics'

export const useApmStyles = makeStyles((theme) => ({
  root: {
    '&>.title': {
      marginBottom: 12,
      fontSize: 34,
      lineHeight: '39px'
    },
    '&>p>.subtitle': {
      marginBottom: 24,
      fontSize: 18
    }
  },
  verify: {
    background: `url("${APMVerifyIcon}") no-repeat center ${theme.spacing(3)}px`,
    backgroundColor: 'rgba(28, 84, 211, 0.05)',
    borderRadius: 12,
    padding: `${theme.spacing(3) + 74}px 42px ${theme.spacing(4)}px`,
    '& .title': {
      marginBottom: 10
      
    }
  },
  waitingForKyc: {
    position: 'relative',
    marginBottom: 32
  },
  description: {
    position: 'relative',
    width: 'calc(100% + 80px)',
    height: '100%',
    top: 0,
    left: -40,
    
    backgroundColor: 'rgba(242, 201, 76, 0.1)',
    
  },
  innerDescription: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 22,
    paddingBottom: 22,
    paddingRight: 32,
    paddingLeft: 74,
    position: 'relative',

    '& .title': {
      marginBottom: 8,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
      lineHeight: '24px'
    },
    '& .subtitle': {
      // marginBottom: 10
      color: 'rgba(0, 0, 0, 0.6)',
      lineHeight: '21px',
      fontSize: 14
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 24,
      left: 34,
      width: 24,
      height: 24,
      background: `url("${AlertIcon}") no-repeat center center`,
    }
  },
  statusDetails: {
    '& .dTitle': {
      fontSize: 16,
      color: 'rgba(0,0,0,0.6)',
      lineHeight: '18.75px'
    },
    '& .dValue': {
      fontSize: 16,
      color: 'rgba(0,0,0,0.87)',
      lineHeight: '18.75px'
    }
  },
  dividerWrapper: {
    position: 'relative',
    height: 1,
    marginTop: 32,
    marginBottom: 24
  },
  divider: {
    position: 'absolute',
    width: 'calc(100% + 80px)',
    top: 0,
    left: -40,
    height: 1,
    background: 'linear-gradient(70.07deg, #DFD5C2 5.97%, #F2D0E1 15.54%, #D1E1EF 26.43%, #D0F1E0 42.03%, #E1F0CE 66.61%, #EBCEE9 81.02%, #D6D7F3 92.6%)',
  },
  disabledButton: {
    color: '#fff !important',
    backgroundColor: '#2F66E3 !important',
    opacity: '0.5 !important'
  }
}));

interface IProps extends IPreVerifyCheckProps {
  apm?: IAPM;
  buttonDisabled: boolean;
  requestInProgress: boolean;
  validateTermsAccept(valid: boolean, focus: boolean): [boolean, boolean];
  validateBillingAddress(valid: boolean): boolean;
  apmAmount?: number;
  setFormData(data: any): void;
  kycRequired: boolean;
  waitingForKyc: boolean;
}

export default function KYCStatus(props: any/*IProps*/) {
  const {
    apm = {} as IAPM,
    setStep,
    customer,
    kycRequired,
    waitingForKyc,
    order
  } = props;

  const classes = useApmStyles();
  const paymentFormClasses = usePaymentFormStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (kycRequired && customer.kycStatus === KycStatus.CORRECTION_REQUESTED) {
      setStep('pre-verification');
    }
  }, [kycRequired, customer.kycStatus])

  useEffect(() => {
    if (waitingForKyc) {
      trackKycEvent('view_order_pending', order, customer?.level, apm?.name)
    }
  }, [waitingForKyc])

  return <div>
      {waitingForKyc &&   
        <div className={classes.root}>
          <Typography variant="h3" component="div" align="left" className="title">{t('kycWaitingStatus.Document verification')}</Typography>
          <p>
            <Typography variant="body1" component="span" align="left" className="subtitle">{t('kycWaitingStatus.We are verifying your documents')}</Typography>
          </p>
          <div className={classes.waitingForKyc}>
            <div className={classes.description}>
              <div className={classes.innerDescription}>
                <Typography variant="body1" component="div" align="left" className="title">{t('kycWaitingStatus.Please note')}</Typography>
                <Typography variant="body1" component="div" align="left" className="subtitle" dangerouslySetInnerHTML={{ __html: t('kycWaitingStatus.This is a one-time check') }} />
              </div>
            </div>
          </div>
          
          <div className={classes.statusDetails}>
            {/* <div className={paymentFormClasses.orderDetailsRow}>
              <Typography component="div" variant="body2" color="textSecondary" className="dTitle">
                {t('kycWaitingStatus.Reserved')}
              </Typography>
              <Typography component="div" variant="body2" align="right" className="dValue">{order.paymentAmount} {order.paymentCurrency}</Typography>
            </div> */}
            <div className={paymentFormClasses.orderDetailsRow}>
              <Typography component="div" variant="body2" color="textSecondary" className="dTitle">
                {t('kycWaitingStatus.Status')}
              </Typography>
              <Typography component="div" variant="body2" align="right" className="dValue">{t('kycWaitingStatus.Verification pending')}</Typography>
            </div>
            <div className={paymentFormClasses.orderDetailsRow}>
              <Typography component="div" variant="body2" color="textSecondary" className="dTitle">
                {t('kycWaitingStatus.Waiting time')}
              </Typography>
              <Typography component="div" variant="body2" align="right" className="dValue">~ 1 {t('kycWaitingStatus.min')}</Typography>
            </div>
          </div>
          <div className={paymentFormClasses.delim} />
          {props.children}
          <Button
            className={clsx(globalClasses.button, classes.disabledButton)}
            variant="contained"
            color="primary"
            disabled
            onClick={() => history.push('/account/info')}
          >
            {t('kycWaitingStatus.Processing')}
          </Button>   
        </div>
      }

      {kycRequired && !waitingForKyc &&
        <div className={classes.root}>
          <div className={classes.verify}>
            <Typography variant="h5" component="div" align="center" className="title">{t('One step left')}</Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              dangerouslySetInnerHTML={{ __html: t('apmVerificationRequired', { apm: apm.name }) }}
            />
          </div>
          <div className={clsx(paymentFormClasses.payButtonWrapper, 'kycRequired')}>
            <Button
            className={globalClasses.button}
            variant="contained"
            color="primary"
            onClick={() => {
                trackTakeVerification(
                  'click_take_verification',
                  order,
                  apm?.name
                )
              setStep('pre-verification')
            }} 
          >
            {t('Take the verification')}
          </Button>
          </div>
        </div>
      }
  </div>
}
