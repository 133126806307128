import React, { useEffect } from 'react'
import { parse } from 'query-string'

import useSafeCharge from '@/hooks/useSafeCharge'

export const UTAMobilePay = () => {
  const query = parse(window.location.search)
  const {
    googleToken,
    appleToken,
    orderId,
    customerId,
    email,
    country,
    currency: paymentCurrency,
    amount: paymentAmount,
    scToken,
  } = query

  const RNWebview = appleToken
    ? window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ReactNativeWebView
    : window.ReactNativeWebView

  const onComplete = (result: any) => {
    if (RNWebview) {
      RNWebview.postMessage(JSON.stringify({ paymentComplete: true, result }))
    }
  }
  const onError = () => {
    if (RNWebview) {
      RNWebview.postMessage(JSON.stringify({ paymentComplete: false }))
    }
  }
  const {
    isSCLoaded,
    initSC,
    sfc,
    callbacks: { googlePay },
  } = useSafeCharge(onError)

  useEffect(() => {
    if (scToken && isSCLoaded) {
      const [sessionToken, merchantId, merchantSiteId, env] = atob(String(scToken)).split(';')
      initSC(
        { sessionToken, merchantId, merchantSiteId, env },
        { id: customerId, email, country },
        { id: orderId, paymentAmount, paymentCurrency }
      )
    }
  }, [scToken, isSCLoaded])

  useEffect(() => {
    const token = googleToken || appleToken
    if (sfc && token) {
      googlePay(
        String(token),
        false,
        onComplete,
        appleToken ? 'ApplePay' : undefined
      )
    } else if (!token) {
      onError()
    }
  }, [sfc, googleToken, appleToken])

  return null
}
