import makeStyles from '@material-ui/core/styles/makeStyles';

const useWhisperStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: '32px',
    position: 'relative',
  },
  textWrapper: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    textDecoration: 'underline dotted',
    textUnderlineOffset: '0.25em',
    '&:hover': {
      color: '#2F66E3',
    },
  },
  window: {
    color: 'rgba(255, 255, 255, 0.74)',
    padding: '16px',
    display: 'none',
    position: 'absolute',
    bottom: 'calc(100% + 5px)',
    background: 'rgba(32, 32, 32, 0.92)',
    borderRadius: '6px',
    zIndex: 1,
  },
  showWindow: {
    display: 'block'
  },
  title: {
    color: 'white',
    paddingBottom: '16px',
    borderBottom: '1px solid rgba(163, 171, 188, 0.3)',
    marginBottom: '16px',
  }
}));


export default useWhisperStyles