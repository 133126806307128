import makeStyles from '@material-ui/core/styles/makeStyles'
import { fade } from '@material-ui/core'
import red from '@material-ui/core/colors/red'
import { IButtonColor, IBorderRadius } from '../../../api/merchant'
import BtcIcon from './icons/btc.svg'

interface IProps {
  buttonColor?: IButtonColor
  borderRadius?: IBorderRadius
  border?: string
}

const useFormStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
  },
  amountBottom: {
    margin: '-28px 0 8px 16px',
  },
  row: {
    'display': 'flex',
    // safari fix
    'minHeight': 102,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      // safari fix
      height: 204,
    },
    '& > *': {
      flex: '1 1 0px',
      marginRight: `${theme.spacing(2)}px !important`,
      marginLeft: 0,
      [theme.breakpoints.down('xs')]: {
        marginRight: '0 !important',
      },
    },
    '& > *:last-child': {
      marginRight: '0 !important',
      marginBottom: '0 !important',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: 64,
    },
  },
  currencyInput: {
    '& .MuiFormHelperText-root': {
      top: 66,
    },
    '& .MuiFilledInput-input': {
      fontSize: 24,
      paddingBottom: 7,
    },
    '& > .MuiFilledInput-underline.Mui-focused': {
      background: `linear-gradient(#F5F6F7 62px, ${theme.palette.primary.main} 2px) !important`,
    },
    '& > .MuiFilledInput-underline.Mui-error': {
      background: `linear-gradient(#F5F6F7 62px, ${red[600]} 2px) !important`,
    },
    '&.text-small input': {
      fontSize: 16,
    },
  },
  currencySelect: {
    'height': 30,
    'width': 90,
    'backgroundColor': 'transparent !important',
    'background': 'transparent !important',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent !important',
    },
    '&.Mui-focused, &.Mui-disabled': {
      background: 'transparent !important',
      backgroundImage: 'none !important',
      backgroundColor: 'transparent !important',
    },
    '& .MuiSelect-select': {
      padding: '0 8px',
      borderLeft: '1px solid rgba(0,0,0,0.12)',
    },
    '& .MuiFilledInput-input': {
      fontSize: 16,
      paddingBottom: 0,
    },
  },
  stepTitle: {
    'textTransform': 'uppercase',
    'marginBottom': theme.spacing(2),
    'color': theme.palette.primary.main,
    '&.disabled': {
      color: theme.palette.text.disabled,
    },
  },
  grouped: {
    'borderRadius': ({ borderRadius }: IProps) =>
      borderRadius && borderRadius.input.length
        ? `${borderRadius.input} !important`
        : '3px',
    'border': '1px solid transparent',
    'height': 38,
    'marginTop': 3,
    '&.focused': {
      borderColor: ({ border }: IProps) =>
        border && border.length ? `#${border}` : theme.palette.primary.main,
    },
    '& .MuiFormHelperText-root': {
      top: 36,
    },
  },
  group: {
    'display': 'flex',
    // safari fix
    'minHeight': 60,
    '& > *:first-child': {
      flex: '1 1 0px',
    },
    '& > *:last-child': {
      width: 80,
    },
    '& .MuiFormControl-root:first-child .MuiInput-root': {
      'borderTopRightRadius': '0 !important',
      'borderBottomRightRadius': '0 !important',
      '&.Mui-focused': {
        borderColor: 'transparent',
      },
    },
    '& .MuiFormControl-root:last-child .MuiInput-root': {
      'borderTopLeftRadius': '0 !important',
      'borderBottomLeftRadius': '0 !important',
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: '15px',
      },
      '&.Mui-focused': {
        borderColor: 'transparent',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.primary.light,
  },
  terms: {
    [theme.breakpoints.down('xs')]: {
      'display': 'block',
      '& .MuiTypography-root': {
        fontSize: '12px !important',
      },
    },
  },
  send: {
    'background': ({ buttonColor }: IProps) =>
      buttonColor && buttonColor.base.length
        ? `#${buttonColor.base}`
        : theme.palette.primary.main,
    'height': '50px !important',
    'minHeight': '50px !important',
    'maxHeight': '50px !important',
    'width': 230,
    'maxWidth': 230,
    'textAlign': 'center',
    'padding': '12px 0',
    'textTransform': 'uppercase',
    'textDecoration': 'none !important',
    'borderRadius': ({ borderRadius }: IProps) =>
      borderRadius && borderRadius.button.length ? borderRadius.button : 0,
    'boxShadow': 'none',
    'color': '#fff',
    '&:disabled': {
      background: ({ buttonColor }: IProps) =>
        fade(
          buttonColor && buttonColor.base.length
            ? `#${buttonColor.base}`
            : theme.palette.primary.main,
          0.3
        ),
      color: '#fff',
    },
    '&:hover': {
      background: ({ buttonColor }: IProps) =>
        buttonColor && buttonColor.hover.length
          ? `#${buttonColor.hover}`
          : theme.palette.primary.main,
    },
    '&.wide': {
      width: 300,
      maxWidth: 300,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      marginTop: `${theme.spacing(3)}px !important`,
    },
  },
  btcIcon: {
    background: `url("${BtcIcon}") no-repeat center`,
    width: 24,
    height: 24,
  },
  amount: {
    'marginBottom': theme.spacing(4),
    '& .label': {
      fontSize: 12,
      marginBottom: -1,
    },
  },
  link: {
    cursor: 'pointer',
  },
  emailChangeBtn: {
    cursor: 'pointer',
  },
  connectWalletBtn: {
    marginBottom: '5px',
  },
}))

export default useFormStyles
