import React, { useState } from 'react'

import { useLocation } from 'react-router-dom'

import { Form } from 'react-final-form'
import { OnBlur } from 'react-final-form-listeners'

import { useTranslation } from 'react-i18next'

import i18n from '@/i18n'

import clsx from 'clsx'

import { Button, makeStyles } from '@material-ui/core'
import { useNewsletterStyles } from '@/hooks/useNewsletterStyles'
import { Input } from '@/components/Form'
import { required, composeValidators, isEmail } from '@/validators'
import { WIIDGET_ENV } from '@/helpers/constants'
import smile from '@/icons/smile.svg'
import crying from '@/icons/crying.svg'
import { isChainValley } from '@/helpers/mirrorHelper'

interface IStylesProps {
  isOptIn: boolean
}

export const useStyles = makeStyles((theme) => ({
  root: {},
  shell: ({ isOptIn }: IStylesProps) => ({
    maxWidth: isOptIn ? '430px' : '565px',
  }),
  subtitle: ({ isOptIn }: IStylesProps) => ({
    maxWidth: isOptIn ? '340px' : '480px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '339px',
    },
  }),
  title: {
    marginBottom: '42px',
  },
}))

function Newsletter() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [submit, setSubmit] = useState(false)
  const location = useLocation()
  const isOptIn = location.pathname === '/newsletter-opt-in'
  const classes = useNewsletterStyles()
  const classesForm = useStyles({ isOptIn })

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {!submit && (
          <div className={clsx(classes.form, classesForm.shell)}>
            <h3 className={classes.title}>
              {isOptIn
                ? t('newsletter.subscribeTitle')
                : t('newsletter.unsubscribeTitle')}
            </h3>
            <p className={clsx(classes.subtitle, classesForm.subtitle)}>
              {isOptIn
                ? t('newsletter.subscribeConfirm')
                : t('newsletter.unsubscribeConfirm')}
            </p>
            <Form
              initialValues={{ email: email }}
              onSubmit={(values) => {
                setSubmit(true)
              }}
              render={({ handleSubmit, form, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <div className={classes.fieldWrapper}>
                    <Input
                      name="email"
                      validate={composeValidators(
                        required,
                        (v:any)=>isEmail(v,i18n.t('validation.invalidEmailNewsletter'))
                      )}
                      type="text"
                      placeholder={t('newsletter.enterEmail')}
                      className={classes.field}
                      variant="outlined"
                      required={false}
                      inputProps={{
                        spellCheck: false,
                      }}
                    />
                    <OnBlur name="email">
                      {() => {
                        const login = form.getFieldState('email')
                        form.change(
                          'email',
                          login && login.value ? login.value.trim() : ''
                        )
                      }}
                    </OnBlur>
                  </div>
                  <div>
                    <Button
                      disabled={invalid}
                      type="submit"
                      className={classes.button}
                      variant="contained"
                    >
                      {isOptIn
                        ? t('newsletter.subscribe')
                        : t('newsletter.unsubscribe')}
                    </Button>
                  </div>
                </form>
              )}
            />
          </div>
        )}
        {submit && (
          <div className={classes.form}>
            <div className={classes.imageWrapper}>
              <img
                className={classes.image}
                src={isOptIn ? smile : crying}
                alt="Icon"
                width={40}
                height={48}
              />
            </div>
            <h3 className={clsx(classes.title, classesForm.title)}>
              {isOptIn ? t('newsletter.thankYou') : t('newsletter.missYou')}
            </h3>
            <Button
              className={classes.button}
              variant="contained"
              href={
                isChainValley
                  ? 'https://chainvalley.pro'
                  : `https://${
                      !WIIDGET_ENV || WIIDGET_ENV === 'prod'
                        ? ''
                        : `landing-${WIIDGET_ENV}.`
                    }utorg.pro`
              }
            >
              {t('newsletter.homepage')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
export default Newsletter
