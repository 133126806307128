import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { IOrder } from '../../../../api/order';
import Loader from '../../../../components/Loader';
import SumSub from '../../../Exchange/forms/verification/SumSub';
import { OrderType } from '../../../../api/private/orders';
import { useKycFlow } from '../../../../api/private/kyc';

interface IProps {
  order: IOrder;
  type: OrderType;
  onFinish: any;
}

export default function OrderVerification({ type, onFinish, order }: IProps) {
  const { user } = useSelector((state: any) => state.account);
  const history = useHistory();
  const [{ data: { required } }, loadingFlow, loadFlow] = useKycFlow();

  useEffect(() => {
    if (order.id) {
      loadFlow({ orderId: order.id });
    }
  }, [order.id])

  useEffect(() => {
    if (required === false) {
      onFinish();
      history.push(`/account/orders/finish/${type.toLowerCase()}/success`)
    }
  }, [required])

  return (
    <Loader loading={loadingFlow}>
      <SumSub
        customer={user}
        // kycFlow={flow}
        setStep={() => {
          onFinish();
          history.push(`/account/orders/finish/${type.toLowerCase()}/success`)
        }}
      />
    </Loader>
  );
}
