import { fade } from '@material-ui/core';

export default function (palette: any) {
  return {
    root: {
      // background: '#F5F6F7',
      // padding: '3px 5px 1px',
      // borderRadius: '3px',
      // border: `1px solid transparent`,
      // color: '#000',
      // minHeight: 38,
      // '&.Mui-focused': {
      //   borderColor: palette.border && palette.border.length ? `#${palette.border}` : palette.primary.main,
      // },
      // '&.Mui-disabled': {
      //   background: fade(palette.primary.light, 0.3),
      // },
      // '&:after': {
      //   display: 'none',
      // },
      // '&:before': {
      //   display: 'none',
      // },
      // '& input::placeholder': {
      //   opacity: '0.42 !important'
      // },
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        }
      }
    },
  };
}