import React, { useEffect } from 'react'

import clsx from 'clsx'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { Checkbox, Input } from '@/components/Form'
import { ISettlementBalance } from '@/types/settlements'
import { availableValue, composeValidators, onlyNumber } from '@/validators'
import { formatTextInput } from '@/helpers/input'
import { useSubaccountBalanceRowStyles } from '@/hooks/useSubaccountBalanceRowStyles'

import PercentageArrows from '../PercentageArrows'

type IFormRequestInfo = {
  checked: boolean
  percentage: number
  reqAmount: string
}

interface ISubaccountBalanceRow {
  row: ISettlementBalance
  values: Record<string, IFormRequestInfo>
  setPercentage: (
    percentageField: string,
    amountField: string,
    amount: number | string,
    availableBalance: number
  ) => void
  setAmount: (
    amountField: string,
    percentageField: string,
    amount: string | string,
    availableBalance: number
  ) => void
  setChangePercentage: (
    currency: string,
    percentageField: string,
    amountField: string,
    availableBalance: number,
    alg: string,
    sid?: string
  ) => void
}

function SubaccountBalanceRow({
  row,
  values,
  setPercentage,
  setAmount,
  setChangePercentage,
}: ISubaccountBalanceRow) {
  const classes = useSubaccountBalanceRowStyles()

  const checked = values[row.currency]?.checked
  const availableBalance = Number((row.balance - row.locked).toFixed(2))
  const isZeroBalance = availableBalance === 0
  const currencyFieldPrefix = `${row.currency}`

  useEffect(() => {
    setPercentage(
      `${currencyFieldPrefix}.req`,
      `${currencyFieldPrefix}.reqAmount`,
      0,
      Number(availableBalance.toFixed(2))
    )
  }, [])

  const changePercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    setAmount(
      `${currencyFieldPrefix}.reqAmount`,
      `${currencyFieldPrefix}.req`,
      inputValue,
      Number(availableBalance.toFixed(2))
    )
  }

  const changeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    setPercentage(
      `${currencyFieldPrefix}.req`,
      `${currencyFieldPrefix}.reqAmount`,
      inputValue,
      Number(availableBalance.toFixed(2))
    )
  }

  const changePercentageWrapper = (alg: string) => {
    setChangePercentage(
      `${currencyFieldPrefix}`,
      `${currencyFieldPrefix}.req`,
      `${currencyFieldPrefix}.reqAmount`,
      Number(availableBalance.toFixed(2)),
      alg
    )
  }

  const fillAvailableAmount = () => {
    if (availableBalance !== 0) {
      setPercentage(
        `${currencyFieldPrefix}.req`,
        `${currencyFieldPrefix}.reqAmount`,
        Number(availableBalance.toFixed(2)),
        Number(availableBalance.toFixed(2))
      )
    }
    return null
  }

  return (
    <>
      <TableRow
        className={clsx(classes.row, checked ? classes.selectedRow : '')}
      >
        <TableCell className={classes.cellCheckbox}>
          <Typography
            variant="body2"
            component="div"
            className={classes.checkboxWrapper}
          >
            <Checkbox
              className={classes.checkbox}
              name={`${currencyFieldPrefix}.checked`}
              disabled={isZeroBalance}
            />
            <Typography
              variant="body2"
              component="span"
              className={classes.cellTotal}
            >
              {row.currency}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell
          className={isZeroBalance ? classes.disabled : ''}
          align="right"
        >
          <Typography variant="body2" component="span">
            {row.balance.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2" component="span">
            {row.locked > 0 ? row.locked.toFixed(2) : '—'}
          </Typography>
        </TableCell>
        <TableCell
          className={isZeroBalance ? classes.disabled : ''}
          align="right"
        >
          <Typography
            onClick={() => {
              if (!isZeroBalance) {
                fillAvailableAmount()
              }
              return null
            }}
            variant="body2"
            component="span"
            className={!isZeroBalance ? classes.available : ''}
          >
            {availableBalance}
          </Typography>
        </TableCell>
        <TableCell align="center" className={classes.cellPercentage}>
          {!isZeroBalance && (
            <Input
              name={`${currencyFieldPrefix}.req`}
              type="number"
              className={clsx(
                classes.field,
                classes.inputPercentage,
                checked && classes.checked
              )}
              validate={composeValidators(onlyNumber, (v: any) =>
                availableValue(v, 100, 0)
              )}
              variant="outlined"
              onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
                changePercentage(event)
              }
              format={(value: string | number | undefined) =>
                formatTextInput(value)
              }
              InputProps={{
                endAdornment: (
                  <PercentageArrows
                    handleUp={() => changePercentageWrapper('increment')}
                    handleDown={() => changePercentageWrapper('decrement')}
                  />
                ),
              }}
            />
          )}
        </TableCell>
        <TableCell className={classes.cellAmount}>
          {!isZeroBalance && (
            <Input
              variant="outlined"
              validate={composeValidators(onlyNumber, (v: any) =>
                availableValue(v, Number(availableBalance.toFixed(2)), 0)
              )}
              inputProps={{ maxLength: 10 }}
              className={clsx(
                classes.field,
                classes.inputAmount,
                checked && classes.checked
              )}
              format={(value: string | number | undefined) =>
                formatTextInput(value)
              }
              name={`${currencyFieldPrefix}.reqAmount`}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
                changeAmount(event)
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

export default SubaccountBalanceRow
