import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

export default function useChangeLanguage(apm = '') {
  const { i18n } = useTranslation()

  useEffect(() => {
    if (['blik', 'BLK_WLT', 'BLIK'].includes(apm)) {
      window.localStorage.setItem('lang', 'pl-PL')
      i18n.changeLanguage('pl-PL')
    }
  }, [apm])
}
