import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import OperationsButtons from './OperationsButtons'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import { getAlt, getLogo } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 112,
  },
  wrap: {
    maxWidth: 1142,
    height: 112,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  logo: {
    fontSize: 24,
    cursor: 'default',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  mobileRight: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
  },
  image: {
    maxHeight: 45,
    maxWidth: 150,
  },
  userMenu: {
    'width': 40,
    'height': 40,
    'borderRadius': 20,
    'background': '#01A299',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'fontSize': 20,
    'textTransform': 'uppercase',
    'color': '#fff',
    'border': 'none',
    'position': 'relative',
    '& span': {
      cursor: 'pointer',
    },
  },
  userPopup: {
    'position': 'absolute',
    'right': 0,
    'top': 50,
    'background': '#fff',
    'borderRadius': 8,
    'padding': `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    'boxShadow':
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
    'zIndex': 10000,
    'minWidth': 230,
    '& .logout': {
      color: theme.palette.error.main,
      background: 'rgba(125, 147, 168, 0.08)',
      fontSize: 14,
      fontWeight: 500,
    },
    '& .email': {
      textTransform: 'none',
      marginBottom: theme.spacing(3),
      fontSize: 16,
    },
  },
  backdrop: {
    zIndex: 100,
  },
  desktopShow: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileShow: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}))

interface IProps {
  loggedIn?: boolean
  logout: any
  email?: string
}

export default function Header({ loggedIn, logout, email }: IProps) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { t } = useTranslation()
  const [userPopup, setUserPopup] = useState(false)

  const popup = (
    <div className={classes.userPopup}>
      <Typography
        variant="body2"
        align="center"
        component="div"
        color="textPrimary"
        className="email"
      >
        {email}
      </Typography>
      <Button
        onClick={logout}
        className={clsx(globalClasses.button, 'small', 'logout')}
      >
        {t('Logout')}
      </Button>
    </div>
  )

  return (
    <div className={classes.root}>
      {userPopup && (
        <Backdrop
          open={userPopup}
          onClick={() => setUserPopup(false)}
          className={classes.backdrop}
        />
      )}
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <img
            src={getLogo(MIRROR)}
            alt={getAlt(MIRROR)}
            className={classes.image}
          />
          {loggedIn && (
            <div className={classes.mobileShow}>
              <div className={classes.mobileRight}>
                <div
                  onClick={() => setUserPopup(true)}
                  className={classes.userMenu}
                >
                  <span>{email?.charAt(0) || 'U'}</span>
                  {userPopup && popup}
                </div>
              </div>
            </div>
          )}
        </div>
        {loggedIn && (
          <div className={classes.rightSide}>
            <OperationsButtons />
            <div
              onClick={() => setUserPopup(true)}
              className={clsx(classes.userMenu, classes.desktopShow)}
            >
              <span>{email?.charAt(0) || 'U'}</span>
              {userPopup && popup}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
