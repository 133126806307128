import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import createPalette from './palette';
import overrides from './overrides';
import { IPalette } from '../api/merchant';

const theme = (styles: IPalette) => {
  const palette = createPalette(styles);

  return createMuiTheme({
    palette,
    // @ts-ignore
    overrides: overrides(palette)
  });
}

export default theme;
