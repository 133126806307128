import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { KycStatus } from '../../../api/kyc';
import { OrderStatus, useOrderInfo } from '../../../api/order';
import { PaymentNext, PaymentSource, usePaymentInit } from '../../../api/payment';
import Loader from '../../../components/Loader';
import PaymentFrame from '../../../components/PaymentFrame';
import ServerMessage from '../../../components/UI/ServerMessage';
import SafariPaymentForm from '../../Payment/Safari';
import { IExchangeFormProps } from '../index';
import useFormStyles from './useFormStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  note: {
    maxWidth: 600,
    margin: `0 auto ${theme.spacing(2)}px`,
    fontSize: 12,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2)
    }
  },
  frame: {
    width: '100%',
    minHeight: '705px',
    border: 'none'
  }
}));

export interface I3DsData {
  sessionToken: string;
  env: string;
  merchantId: string;
  merchantSiteId: string;
}

export default function CardForm({ setStep, order, t, ui, language, customer, email, setPaymentError }: IExchangeFormProps) {
  const { form: { borderRadius } } = ui;
  const formClasses = useFormStyles({ borderRadius });
  const classes = useStyles();
  const [{ data: paymentData, error }, loading, loadData] = usePaymentInit(`${order.id}`);
  const [{ data: updatedOrder }, loadingOrder, loadOrder] = useOrderInfo();
  const [result, setResult] = useState('');
  const [billing, setBilling] = useState(false);
  const [serverError, setServerError] = useState('');
  const [auth3ds, setAuth3ds] = useState(false);
  const [data3ds, setData3ds] = useState<I3DsData | undefined>();
  const isInternalForm = paymentData.nextStep === PaymentNext.CardForm;
  const is3ds = paymentData.nextStep === PaymentNext.ThreeDS;

  function getMessageFromFrame(msg: any) {
    try {
      const mess = JSON.parse(msg.data);
      if (mess.type !== 'paymentResult') {
        return;
      }
      setResult(mess.success ? 'success' : 'error');
      setPaymentError(mess.success ? undefined : mess.error);
      loadOrder({ id: order.id, currency: order.paymentCurrency });
    } catch (e) {
    }
  }

  useEffect(() => {
    window.addEventListener('message', getMessageFromFrame);

    return () => {
      window.removeEventListener('message', getMessageFromFrame);
    }
  }, []);

  useEffect(() => {
    if (order.id) {
      loadData({ source: PaymentSource.Card, orderId: order.id });
    }
  }, [order.id]);

  useEffect(() => {
    if (updatedOrder.id) {
      if (updatedOrder.status === OrderStatus.VERIFICATION
        && ![KycStatus.APPROVED, KycStatus.NEW, KycStatus.PENDING, KycStatus.REVIEWED, KycStatus.WAITING_FOR_APPROVAL].includes(customer.kycStatus)) {
        setStep('verification');
      } else if (result.length > 0) {
        setStep(result);
      }
    }
  }, [updatedOrder.id])

  useEffect(() => {
    if (error && Object.keys(error).length) {
      if (error.type === 'GEODATA_NOT_RECOGNIZED') {
        return setBilling(true);
      }
      setServerError('somethingWentWrong');
    }
  }, [error])

  useEffect(() => {
    if (paymentData.token && paymentData.token.length) {
      setAuth3ds(true);
      const [sessionToken, merchantId, merchantSiteId, env] = atob(paymentData.token).split(';');
      setData3ds({
        sessionToken,
        merchantId,
        merchantSiteId,
        env
      });
      setAuth3ds(false);
    }
  }, [paymentData.token])

  // if (billing) {
  //   return (
  //     <BillingForm
  //       onSuccess={() => {
  //         loadData({ source: PaymentSource.Card, orderId: order.id });
  //         setBilling(false);
  //       }}
  //       order={order}
  //       t={t}
  //       ui={ui}
  //       language={language}
  //       customer={customer}
  //       setStep={setStep}
  //       email={email}
  //     />
  //   );
  // }

  return (
    <Loader loading={loading || loadingOrder || auth3ds}>
      <div className={formClasses.root}>
        {serverError.length > 0
          ? (
            <ServerMessage type="error" text={serverError} isAccount />
          ) : (
            <div>
              <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('payment_information') }} />
              {paymentData.id && (
                <div>
                  {isInternalForm
                    /** ZHPW-845 use vanilla form for all */
                    ? (
                      <SafariPaymentForm
                        paymentId={paymentData.id}
                        onFinish={setStep}
                        is3ds={is3ds}
                        data3ds={data3ds}
                        order={order}
                        customer={customer}
                        provider={paymentData.provider}
                        initPayment={() => loadData({
                          source: PaymentSource.Card,
                          orderId: order.id
                        })}
                      />
                    // ? <PaymentForm paymentId={paymentData.id} onFinish={setStep} />
                    ) : <PaymentFrame paymentData={paymentData} classes={classes} />}
                </div>
              )}
            </div>
          )}
      </div>
    </Loader>
  );
}
