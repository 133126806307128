import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Input, MultiSelect } from '../../components/Form';
import { EmploymentType } from './index';
import { required } from '../../validators';
import { ISelectOption } from '../../components/Form/MultiSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid ' + theme.palette.divider,
    borderRadius: 8,
    padding: '16px 19px',
    marginBottom: theme.spacing(2),
    '&.active': {
      border: '2px solid ' + theme.palette.primary.main
    }
  },
  title: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%'
  },
  radio: {
    padding: 0,
    marginRight: 18,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  },
  form: {
    marginTop: 12,
    marginLeft: 42,
    '& > div': {
      marginBottom: 10,
      paddingBottom: 0,
      '& > div': {
        transition: 'margin-bottom 250ms ease 0s',
        '&.Mui-error': {
          marginBottom: 16
        }
      }
    }
  }
}));

interface IProps {
  type: EmploymentType;
  active: boolean;
  setType(type: EmploymentType): void;
  values: any;
}

const sourceOfFunds: ISelectOption[] = [
  { value: 'Salary', label: 'Salary' },
  { value: 'Self-employed income', label: 'Self-employed income' },
  { value: 'Savings', label: 'Savings' },
  { value: 'Trading income', label: 'Trading income' },
  { value: 'Rent income', label: 'Rent income' },
  { value: 'Return on investment', label: 'Return on investment' },
  { value: 'Loan', label: 'Loan' },
  { value: 'Gambling', label: 'Gambling' },
  { value: 'Gift', label: 'Gift' },
  { value: 'Inheritance', label: 'Inheritance' },
  { value: 'Other', label: 'Other' },
];

export default function EmploymentItem({ type, setType, active, values }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, { active })}>
      <FormControlLabel
        control={<Radio checked={active} className={classes.radio} />}
        label={t(type)}
        onClick={() => setType(type)}
        className={classes.title}
      />
      {active && (
        <div className={classes.form}>
          <Typography variant="body2" component="div" color="textSecondary">
            {t('employmentFormTitle')}
          </Typography>
          {type !== EmploymentType.UNEMPLOYED && (
            <Input
              name="company"
              label={t(`${type}_labelCompany`)}
              validate={required}
              fullWidth
            />
          )}
          {type === EmploymentType.EMPLOYED && (
            <Input
              name="position"
              label={t(`${type}_labelPosition`)}
              validate={required}
              fullWidth
            />
          )}
          {type === EmploymentType.SELF_EMPLOYED && (
            <Input
              name="businessArea"
              label={t(`${type}_labelBusinessArea`)}
              validate={required}
              fullWidth
            />
          )}
          <MultiSelect
            name="sourceOfFunds"
            label={t(`${type}_labelSourceOfFunds`)}
            options={sourceOfFunds}
            validate={required}
            fullWidth
          />
          {values.sourceOfFunds?.includes('Other') && (
            <Input
              name="sourceOfFundsOther"
              label={t('labelOtherSourceOfFunds')}
              validate={required}
              fullWidth
            />
          )}
        </div>
      )}
    </div>
  )
}
