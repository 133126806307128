import {
  ICheckedSidRequest,
  ICheckedSubaccountRequest,
  ISubaccountRequestInfo,
} from '@/types/settlements'

type IFormSubaccountData = Record<string, ISubaccountRequestInfo>

type IFormMasterData = Record<string, Record<string, any>>

export const checkAvailableRequests = (
  values: IFormSubaccountData,
  checkedRequests: ICheckedSubaccountRequest[]
) => {
  Object.entries(values).map(([currency, request]) => {
    if (
      request?.checked &&
      request?.reqAmount &&
      isFinite(request.reqAmount) &&
      request.reqAmount > 0
    ) {
      checkedRequests.push({
        currency,
        amount: Number(request.reqAmount),
      })
    }
    return
  })
}

export const getAmount = (input: number, available: number) => {
  return Number((input * available) / 100).toFixed(2)
}

export const getPercentage = (input: number, available: number) => {
  return Math.round((input * 100) / available)
}

export const isSelectedSid = (name: string, selectedSids: string[]) => {
  return selectedSids.indexOf(name) !== -1
}

export const checkAvailableSidRequests = (
  values: IFormMasterData,
  checkedSidRequests: ICheckedSidRequest[]
) => {
  Object.entries(values).map(([sid, request]) => {
    if (
      request?.checked &&
      request?.reqAmount > 0 &&
      request?.reqAmount <= request?.availableBalance
    ) {
      checkedSidRequests.push({
        sid,
        amount: Number(request.reqAmount),
      })
    }
    return
  })
}
