import { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

export const useFilterStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  searchFieldWrapper: {
    'display': 'grid',
    'gridTemplateColumns': '150px 120px 120px',
    'columnGap': '10px',
    'alignItems': 'center',
    'marginRight': theme.spacing(6),
    'height': 38,
    'justifyContent': 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: '10px',
    },
    '& label': {
      fontSize: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      },
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 6
    },
    '& .MuiTextField-root': {
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .MuiFormHelperText-root': {
      top: 36,
      [theme.breakpoints.down('xs')]: {
        top: 34,
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
      [theme.breakpoints.down('xs')]: {
        transform: 'translate(14px, -6px) scale(1)',
      },
    },
    '& input': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: 'auto',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      width: '100%',
      gap: '15px',
    },
  },
  button: {
    position: 'absolute',
    right: 0,
    top: -65,
    color: '#000',
    '&.active': {
      background: theme.palette.primary.main,
      color: '#fff'
    }
  },
  filters: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      background: '#fff',
      padding: '15px 0 60px',
      zIndex: 100,
      overflow: 'auto',
      width: '100%',
    }
  },
  close: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      position: 'absolute',
      right: 21,
      top: 19,
      padding: 0,
      height: 20,
      width: 20,
      '& svg': {
        height: 20,
        width: 20
      }
    }
  },
  title: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 15,
      textAlign: 'center',
      fontSize: 20,
      color: '#000',
      fontWeight: 500,
      letterSpacing: 1.25
    }
  },
  actions: {
    display: 'flex',
    width: '50%',
    '& > *': {
      height: 48,
      fontSize: 14,
      '&:first-child': {
        marginRight: theme.spacing(0.5)
      },
      '&:last-child': {
        marginLeft: theme.spacing(0.5),
        color: theme.palette.primary.main
      }
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      width: '100%',
      left: 0,
      right: 0,
      bottom: 0,
      background: '#fff',
      height: 60,
      zIndex: 1000,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }
  },
  filter: {
    margin: '20px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: {
      margin: '20px 16px'
    }
  },
  options: {
    display: 'flex',
    flexFlow: 'row wrap',
    padding: '20px 0',
    '&.view': {
      padding: '0 0 4px',
    },
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  },
  optionsDate: {
    'display': 'flex',
    'alignItems': 'center',
    'padding': '20px 0',
    '&.view': {
      padding: '0 0 4px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  chips: {
    'display': 'flex',
    'flexFlow': 'row wrap',
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  datepicker: {
    'position': 'relative',
    'marginBottom': theme.spacing(1),
    '& .wrapper': {
      position: 'absolute',
      left: '-50%',
      top: 34,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
    },
    '& .react-datepicker__input-container': {
      'opacity': 0,
      'height': 32,
      '& input': {
        width: '100%',
        height: 32,
      },
    },
  },
  viewOptions: {
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  mobile: {
    [theme.breakpoints.down('xs')]: {
      position: 'static',
    },
  },
  wrapperButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
    },
  },
}));

export default function useFilter(page: string, defaultFilter: any): [(filter: any) => void, any] {
  const initial = defaultFilter;
  if (defaultFilter.dateTo) {
    initial.dateTo = defaultFilter.dateTo;
  }
  const [filter, setFilter] = useState(initial);
  
  function setFilterState(f: any, load?: any) {
    const tmp: any = {};
    Object.keys(f).forEach((k) => {
      if (f[k] !== null && isNaN(f[k]) && !f[k].length) {
        tmp[k] = null;
      } else {
        tmp[k] = f[k];
      }
    });
    const upd = { ...filter, ...tmp };
    if (upd.dateFrom) {
      upd.dateFrom = startOfDay(upd.dateFrom).valueOf();
    }
    if (upd.dateTo) {
      upd.dateTo = endOfDay(upd.dateTo).valueOf();
    }
    setFilter(upd);
    if (load) {
      load({ filter: upd });
    }
  }

  return [setFilterState, filter];
}