import update from 'immutability-helper';
import merge from 'lodash/merge';

/** 
 * Flow
 * - direct
 * - order
 * 
 * Step
 * - AuthForm
 * - OTP
 * - Residense
 * - APM
 * - Result
 * 
 * KycStatus
 **/

export enum ActiveForm {
  AUTH_FORM = 'AUTH_FORM',
  OTP='OTP',
  RESIDENCE='RESIDENCE',
  APM='APM',
  RESULT='RESULT',
}


const initialState = {
  flow: '',
  activeForm: '',
  kycStatus: ''
};

export default function routing(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_ACTIVE_FORM':
      return update(state, { activeForm: { $set: action.activeForm || '' } });
    case 'SET_KYC_STATUS':
      return update(state, { kycStatus: { $set: action.kycStatus || '' } });
    default:
      return state;
  }
}