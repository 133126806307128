import React from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {OrderStatus} from '../../api/order';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap'
  },
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  error: {
    color: theme.palette.error.main
  }
}));

interface IProps {
  status: OrderStatus;
  type?: 'merchant' | 'client';
}

export default function OrderStatusView({ status, type }: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  let title = 'In progress';
  let className = classes.warning;
  switch (status) {
    case OrderStatus.NEW:
    case OrderStatus.ACCEPTED:
      title = 'Order created';
      break;
    case OrderStatus.PROCESSING:
      title = 'Payment pending';
      break;
    case OrderStatus.VERIFICATION:
      title = 'Pending verification';
      break;
    case OrderStatus.SUSPICIOUS:
      title = 'Verification in progress';
      break;
    case OrderStatus.EXECUTED:
      title = 'Sending Crypto';
      break;
    case OrderStatus.EXPIRED:
      title = 'Expired';
      break;
    case OrderStatus.SUCCESS:
      title = 'Success';
      className = classes.success;
      break;
    case OrderStatus.ERROR:
      title = 'Error';
      className = classes.error;
      break;
  }

  return <span className={clsx(classes.root, className)}>{t(type === 'merchant' ? status : title)}</span>;
}
