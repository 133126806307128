import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export default {
  root: {
    padding: '32px 40px',
    [breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
    }
  }
};
