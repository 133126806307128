import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { isInIframe } from '../../helpers/iframe';

export default function PaymentResult() {
  const { status } = useParams();
  const [query] = useQuery({});
  const { pathname } = useLocation();
  const history = useHistory();
  const iframe = isInIframe();
  const pending = pathname.includes('pending');
  useEffect(() => {
    if (status !== undefined || pending) {
      const success = status?.toLowerCase() === 'true';
      if (iframe) {
        window.parent?.postMessage(JSON.stringify({
          success,
          error: query.error,
          pending,
          type: 'paymentResult'
        }), '*');
      } else if (query.order) {
        history.push(`/order/${query.order}/${pending ? 'pending' : (success ? 'success' : 'error')}`);
      }
    }
  }, [status, iframe, pending, query]);

  return <div />;
}
