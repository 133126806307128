import * as Sentry from '@sentry/react';

const sentryEnabled = !!process.env.REACT_APP_SENTRY_URL;

export default function useFingerprintedRequest(session_id: string): (request: any, data: any) => void {
  if (window.seon) {
    let seonErr = false;
    seon.config({
      host: 'seondf.com',
      session_id,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onError: function (message: any) {
        seonErr = true;
        if (sentryEnabled) {
          Sentry.captureException(message, { tags: { section: 'SEON' } });
        }
      }
    });

    if (!seonErr) {
      return async (request: any, reqData: any) => {
        const req = { ...reqData };
        try {
          const data = await seon.getBase64Session();
          if (data) {
            req.device = data;
          } else {
            if (sentryEnabled) {
              Sentry.captureException('Failed to retrieve session data.', { tags: { section: 'SEON' } });
            }
          }
        } catch (e) {
          if (sentryEnabled) {
            Sentry.captureException(e, { tags: { section: 'SEON' } });
          }
        }
        return await request(req);
      }
    }
  }

  return (request: any, reqData: any) => request(reqData);
}
