import React, { useState, FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Big from 'big.js'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import { IOrder } from '@/api/order'
import { OrderExtendedType } from '@/api/private/orders'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import CurrencyNameMapper from '../../components/CurrencyNameMapper/CurrencyNameMapper'
import { defineNFTFlow } from '@/helpers/nftHelpers/nftFlow'

const useStyles = makeStyles({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 12,
  },
  buttonIcon: {
    width: 18,
    height: 18,
  }
})

interface IProps {
  order: IOrder
  styles: any
}

const DetailsRow: FC<PropsWithChildren<{ title: string, styles: any }>> = ({
  title,
  children,
  styles,
}) => (
  <div className={styles.orderDetailsRow}>
    <Typography
      component="div"
      variant="body2"
      color="textSecondary"
      className="dTitle"
    >
      {title}
    </Typography>
    <Typography
      component="div"
      variant="body2"
      align="right"
      translate="no"
    >
      {children}
    </Typography>
  </div>
)

export default function OrderDetails({ styles, order }: IProps) {
  const globalClasses = useGlobalStyles()
  const { t } = useTranslation()
  const [showAddress, setShowAddress] = useState(false)
  const [open, setOpen] = useState(false)

  const [address, memoTag] = (order?.address || '').split(':')
  const bonus = order?.bonus || 0
  let total = order?.amount
  if (bonus) {
    try {
      total = Big(order.amount).plus(bonus).toNumber()
    } catch {}
  }
  const isPayout = [OrderExtendedType.CRYPTO_TO_FIAT, OrderExtendedType.WALLET_TO_FIAT].includes(order.type!)
  const classes = useStyles()
  const ButtonIcon = open ? ExpandLess : ExpandMore
  const isOrderDetailsShown = order?.showOrderDetails
  const isNFTFlow = defineNFTFlow(order?.currency)

  return (
    <div className={styles.formGroup}>
      {isOrderDetailsShown && !isPayout && (
        <div className={styles.blockTitle}>{t('Order details')}</div>
      )}
      {(!isOrderDetailsShown || isPayout) && (
        <div
          onClick={() => setOpen(!open)}
          className={clsx(styles.blockTitle, classes.button)}
        >
          {t('Order details')}
          <ButtonIcon className={classes.buttonIcon} />
        </div>
      )}
      {(isOrderDetailsShown || open) && !isPayout && (
        <div>
          <DetailsRow title={t('User email')} styles={styles}>
            {order?.acceptedByEmail}
          </DetailsRow>
          {order?.unDomain && (
            <DetailsRow title={t('Wallet address')} styles={styles}>
              {order.unDomain}
            </DetailsRow>
          )}
          {address && !order?.unDomain && (
            <div className={styles.orderDetailsRow}>
              <Typography
                component="div"
                variant="body2"
                color="textSecondary"
                className="dTitle"
              >
                {t('Wallet address')}
              </Typography>
              <Typography
                component="div"
                variant="body2"
                className={globalClasses.xsHide}
                align="right"
                translate="no"
              >
                {address}
              </Typography>
              <Typography
                component="div"
                variant="body2"
                className={globalClasses.xsShow}
              >
                <div className={styles.addressMobile} translate="no">
                  {showAddress ? (
                    address
                  ) : (
                    <>
                      {address.substr(0, 4)}...
                      {address.substr(address.length - 4, address.length)}
                    </>
                  )}
                  &nbsp;
                  <IconButton onClick={() => setShowAddress(!showAddress)}>
                    {showAddress ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </div>
              </Typography>
            </div>
          )}
          {Boolean(bonus) && !isPayout && <Divider />}
          {memoTag && !isPayout && (
            <div className={styles.orderDetailsRow}>
              <Typography
                component="div"
                variant="body2"
                color="textSecondary"
                className="dTitle"
              >
                {t('memo.label')}
              </Typography>
              <Typography
                component="div"
                variant="body2"
                className={globalClasses.xsHide}
                align="right"
                translate="no"
              >
                {memoTag}
              </Typography>
            </div>
          )}
          {!isNFTFlow && (
            <DetailsRow title={t('Amount')} styles={styles}>
              {order?.amount} <CurrencyNameMapper currency={order.currency} />
            </DetailsRow>
          )}
          {Boolean(bonus) && (
            <>
              <DetailsRow title={t('Bonus')} styles={styles}>
                {bonus} <CurrencyNameMapper currency={order.currency} />
              </DetailsRow>
              <DetailsRow title={t('Total')} styles={styles}>
                {total} <CurrencyNameMapper currency={order.currency} />
              </DetailsRow>
            </>
          )}
        </div>
      )}
      {open && isPayout && (
        <DetailsRow title={t('payout.amountTitle')} styles={styles}>
          {order?.paymentAmount} <CurrencyNameMapper currency={order.paymentCurrency} />
        </DetailsRow>
      )}
    </div>
  )
}
