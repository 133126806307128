import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'

import { setLogIn } from '@/store/actions/account'
import {
  setCloudflareLimitError,
  setCustomerNotSupported,
  setMaintenance,
} from '@/store/actions/common'
import useUpdateApi from '@/hooks/useUpdate'
import isCFError from '@/helpers/limitError'

import {
  PrivateApi,
  setAuthToken,
  getAuthToken,
  clearAuthToken,
} from '../index'

function useAuthApi(
  remote: any,
  callback: any
): [(...args: any) => void, boolean] {
  const [updating, setUpdating] = useState(false)
  const dispatch = useDispatch()

  const req = async (data: any, options?: any) => {
    setUpdating(true)

    try {
      const res = await remote(data, options)
      if (res.headers && res.headers['x-auth-token']) {
        if (res.headers['x-auth-token'] === '-') {
          clearAuthToken()
          dispatch(setLogIn({}))
        } else {
          setAuthToken(res.headers['x-auth-token'])
        }
      }
      callback(res.data)
    } catch (e) {
      if (e.response) {
        if (['502', '503'].includes(e.response.status.toString())) {
          dispatch(setMaintenance(true))
        }
        if (e.response.status.toString() === '429' && isCFError(e.response)) {
          dispatch(setCloudflareLimitError())
        }
        if (e.response?.data?.error?.type === 'CUSTOMER_NOT_SUPPORTED') {
          dispatch(setCustomerNotSupported(true))
        }
        if (e.response.data && e.response.data.error) {
          if (process.env.REACT_APP_SENTRY_URL) {
            Sentry.captureException(
              new Error(
                `AUTH API ERROR status: ${e.response.status.toString()}`
              ),
              { extra: e.response.data.error }
            )
          }
          callback(e.response.data)
        }
      }
    }

    setUpdating(false)
  }

  return [req, updating]
}

export function useCustomerLogin(cb: any) {
  return useAuthApi(
    async (...data: any) => PrivateApi.post('/auth/login', ...data),
    cb
  )
}

export function useSignUp(cb: any) {
  return useAuthApi(
    async (...data: any) => PrivateApi.post('/auth/register', ...data),
    cb
  )
}

export function useOTPAuth(cb: any) {
  return useAuthApi(
    async (...data: any) => PrivateApi.post('/auth/otp', ...data),
    cb
  )
}

export function usePasswordAuth(cb: any) {
  return useAuthApi(
    async (...data: any) => PrivateApi.post('/auth/password', ...data),
    cb
  )
}

export function useEmailSet(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/auth/email/set', ...data),
    cb
  )
}

export function useEmailSetConfirm(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/auth/email/confirm', ...data),
    cb
  )
}

export function useSetPassword(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/auth/password/set', ...data),
    cb
  )
}

export function useSetPasswordConfirm(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/auth/password/confirm', ...data),
    cb
  )
}

export function useChangePassword(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/auth/password/change', ...data),
    cb
  )
}

export function useForgotPassword(cb: any) {
  return useAuthApi(
    async (...data: any) => PrivateApi.post('/auth/password/forget', ...data),
    cb
  )
}

export function useResetPassword(cb: any) {
  return useAuthApi(
    async (...data: any) => PrivateApi.post('/auth/password/reset', ...data),
    cb
  )
}

export function useCustomerLogout(cb: any) {
  return useAuthApi(
    async () =>
      PrivateApi.post('/auth/logout', {}, { headers: getAuthToken() }),
    cb
  )
}

export function useOTPDisable(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/auth/otp/disable', ...data),
    cb
  )
}

export function useOTPDisableConfirm(cb: any) {
  return useUpdateApi(
    async (...data: any) =>
      PrivateApi.post('/auth/otp/disable/confirm', ...data),
    cb
  )
}

export function useOTPEnable(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/auth/otp/enable', ...data),
    cb
  )
}

export function useOTPEnableConfirm(cb: any) {
  return useUpdateApi(
    async (...data: any) =>
      PrivateApi.post('/auth/otp/enable/confirm', ...data),
    cb
  )
}
