import React from 'react'

interface IProps {
  step: string
}

export function FinalScreenIcon({ step }: IProps) {
  return (
    <div>
      {step === 'success' && (
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0226 41.9778C1.19819 33.1533 1.19819 18.8461 10.0226 10.0216C18.8471 1.19721 33.1543 1.19721 41.9787 10.0216C50.8032 18.8461 50.8032 33.1533 41.9787 41.9778C33.1543 50.8022 18.8471 50.8022 10.0226 41.9778ZM7.90131 7.90033C-2.09471 17.8963 -2.09471 34.1031 7.90131 44.0991C17.8973 54.0951 34.1041 54.0951 44.1001 44.0991C54.0961 34.1031 54.0961 17.8963 44.1001 7.90033C34.1041 -2.09568 17.8973 -2.09568 7.90131 7.90033ZM16.039 25.8194L22.6911 31.6033L36.1085 18.1858L38.2298 20.3072L23.8232 34.7138L22.8341 35.703L21.7784 34.7851L14.0706 28.0834L16.039 25.8194Z"
            fill="#27AE60"
          />
        </svg>
      )}
      {step === 'pending' && (
        <svg
          width="84"
          height="83"
          viewBox="0 0 84 83"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4697 14.2764C-0.565437 29.3116 -0.565437 53.6884 14.4697 68.7236C29.5049 83.7588 53.8818 83.7588 68.917 68.7236C83.9522 53.6884 83.9522 29.3116 68.917 14.2764C53.8818 -0.758795 29.5049 -0.758795 14.4697 14.2764ZM12.3484 70.8449C-3.85833 54.6382 -3.85833 28.3618 12.3484 12.1551C28.5552 -4.05169 54.8315 -4.05169 71.0383 12.1551C87.245 28.3618 87.245 54.6382 71.0383 70.8449C54.8315 87.0517 28.5552 87.0517 12.3484 70.8449Z"
            fill="#F2C94C"
          />
          <path
            d="M31.1934 25H52.1934V33.3603L41.6934 42L31.1934 33.3603V25Z"
            stroke="#F2C94C"
            strokeWidth="3"
          />
          <path
            d="M31.1934 59H52.1934V50.6397L41.6934 42L31.1934 50.6397V59Z"
            stroke="#F2C94C"
            strokeWidth="3"
          />
          <path d="M26.1934 25H57.1934" stroke="#F2C94C" strokeWidth="3" />
          <path d="M26.1934 59H57.1934" stroke="#F2C94C" strokeWidth="3" />
        </svg>
      )}
      {['error', 'unavailable-apm'].includes(step) && (
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0226 10.0226C1.19819 18.8471 1.19819 33.1543 10.0226 41.9787C18.8471 50.8032 33.1543 50.8032 41.9787 41.9787C50.8032 33.1543 50.8032 18.8471 41.9787 10.0226C33.1543 1.19819 18.8471 1.19819 10.0226 10.0226ZM7.90131 44.1001C-2.09471 34.104 -2.09471 17.8973 7.90131 7.9013C17.8973 -2.09471 34.1041 -2.09471 44.1001 7.9013C54.0961 17.8973 54.0961 34.104 44.1001 44.1001C34.1041 54.0961 17.8973 54.0961 7.90131 44.1001ZM24.1411 26.2628L17.1084 19.23L19.2297 17.1087L26.2624 24.1414L33.2952 17.1087L35.4165 19.2301L28.3838 26.2628L35.4165 33.2955L33.2952 35.4168L26.2624 28.3841L19.2297 35.4168L17.1084 33.2955L24.1411 26.2628Z"
            fill="black"
            fillOpacity="0.12"
          />
        </svg>
      )}
    </div>
  )
}
