import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import countryNames from '../../../helpers/country'
import { Form } from 'react-final-form'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import { OnChange, OnBlur } from 'react-final-form-listeners'
import { AutocompleteInput, Input, Checkbox } from '../../../components/Form'
import {
  composeValidators,
  required,
  isEmail,
  lengthRequired,
} from '../../../validators'
import { useAccountFormStyles } from '../../../hooks/useAccountFormStyles'
import { setLogIn } from '../../../store/actions/account'
import {
  useEmailSet,
  useCustomerLogout,
  useEmailSetConfirm,
} from '../../../api/private/auth'
import { useSetResidence } from '../../../api/private/account'
import { IAutocompleteOption } from '../../../components/Form/AutocompleteField'
import LoginLayout from '../../../components/Layout/Login'
import { LoginType, useLoginStyles } from './index'
import Loader from '../../../components/Loader'
import captchaRequest from '../../../helpers/captcha'
import plural from '../../../helpers/plural'
import ServerMessage from '../../../components/UI/ServerMessage'
import useEmailSuggest from '../../../hooks/useEmailSuggest'
import globalConfig from '../../../config/global.json'
import { getPrivacyPolicyLink, getTermsOfUseLink } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

interface IProps {
  user: any
  countries: any[]
}

export default function EmailSetForm({ user, countries }: IProps) {
  const formClasses = useAccountFormStyles()
  const classes = useLoginStyles()
  const dispatch = useDispatch()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [email, setEmail] = useState()
  const [confirm, setConfirm] = useState(false)
  const [resendCodeTimeout, setResendCodeTimeout] = useState(0)
  const [duplicatedEmail, setDuplicatedEmail] = useState(false)
  const { registrationMethod } = useSelector((state: any) => state.account)
  const countryOptions: IAutocompleteOption[] = []
  const [options, setOptions] = useState(countryOptions)
  const [code, setCode] = useState(user.countryByIp || '')
  const [termsErr, setTermsErr] = useState(false)
  const [serverError, setServerError] = useState('')
  const [emailSuggestion, checkEmail] = useEmailSuggest()

  const termsAcceptedRefValue = useRef({ value: false })

  const [saveResidence, savingResidence] = useSetResidence((res: any) => {
    if (res.success) {
      return dispatch(setLogIn({ ...user, country: code, termsAccepted: true }))
    }
    setServerError('somethingWentWrong')
  })
  const lang = language.split('-')[0]

  useEffect(() => {
    if (countries.length) {
      const countryList = countryNames.getNames(lang)
      countries.forEach((c: any) => {
        countryOptions.push({
          value: c.code,
          label: countryList[c.code] || c.name,
          name: c.name,
        })
      })
      setOptions(countryOptions)
    }
  }, [countries, lang])
  const [saveEmail, savingEmail] = useEmailSet((res: any) => {
    if (res.success) {
      setDuplicatedEmail(false)
      return setConfirm(true)
    }
    if (res.error && res.error.type === 'DUPLICATED_EMAIL') {
      return setDuplicatedEmail(true)
    }
    setServerError('somethingWentWrong')
  })
  const [confirmEmail] = useEmailSetConfirm((res: any) => {
    if (res.success) {
      return dispatch(setLogIn({ ...user, email }))
    }
    setServerError('authError')
  })
  const [logout, logoutProcess] = useCustomerLogout((res: any) => {
    if (res.success) {
      dispatch(setLogIn({}))
    }
  })

  useEffect(() => {
    if (confirm && resendCodeTimeout > 0) {
      setTimeout(() => setResendCodeTimeout(resendCodeTimeout - 1), 1000)
    }
  }, [resendCodeTimeout, confirm])

  return (
    <Loader loading={logoutProcess}>
      <LoginLayout>
        <Form
          onSubmit={(values) => {
            if (confirm) {
              return captchaRequest(
                '/api/private/v1/auth/email/confirm',
                confirmEmail,
                { email: values.email, code: values.code },
                () => setServerError('captchaError')
              )
            }
            if (!user.termsAccepted && !values.terms) {
              return setTermsErr(true)
            }
            if (!user.email) {
              captchaRequest(
                '/api/private/v1/auth/email/set',
                saveEmail,
                { email: values.email },
                () => setServerError('captchaError')
              )
            }
            if (!user.country) {
              saveResidence({ country: code })
            }
          }}
          mutators={{
            setCountry: (args, state, { changeValue }) => {
              const [country] = args
              changeValue(state, 'residence', () =>
                country ? country.name : ''
              )
              setCode(country && country.value)
            },
            setEmail: (args, state, { changeValue }) =>
              changeValue(state, 'email', () => args[0]),
          }}
          initialValues={{
            email: '',
            terms: termsAcceptedRefValue.current.value,
            residence: code,
            code: '',
          }}
          render={({ handleSubmit, form, values, errors }) => {
            //to prevent checkbox value reset after changing country
            termsAcceptedRefValue.current.value = values.terms

            return (
              <form onSubmit={handleSubmit}>
                {registrationMethod !== null ? (
                  <div>
                    <div className={classes.noUser}>
                      <Typography variant="body2">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t('userNotFound', {
                              loginType: t(
                                `loginType${
                                  registrationMethod === LoginType.PHONE
                                    ? 'Phone'
                                    : 'Email'
                                }`
                              ),
                              login: user[registrationMethod],
                            }),
                          }}
                        />
                      </Typography>
                    </div>
                    <Typography variant="h4" className={classes.title}>
                      {t('createAnAccount')}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="h4" className={classes.title}>
                    {t('Account information')}
                  </Typography>
                )}
                {serverError.length > 0 && (
                  <ServerMessage type="error" text={serverError} />
                )}

                {!user.country && (
                  <div>
                    <AutocompleteInput
                      onChange={form.mutators.setCountry}
                      label={t('labels.country of residence')}
                      options={options}
                      value={code}
                      required={false}
                    />
                    <Input
                      name="residence"
                      label=""
                      type="hidden"
                      validate={required}
                    />
                    {errors.residence && (
                      <ServerMessage
                        type="error"
                        text="validation.country of residence"
                      />
                    )}
                  </div>
                )}
                {!user.email && (
                  <div style={{ position: 'relative' }}>
                    {duplicatedEmail && (
                      <ServerMessage
                        type="error"
                        text="validation.duplicateEmail"
                      />
                    )}
                    <Input
                      name="email"
                      type="email"
                      label={t('labels.Email')}
                      fullWidth
                      validate={composeValidators(required, isEmail)}
                      disabled={confirm}
                      autoFocus={!confirm}
                      required={false}
                      inputProps={{
                        spellCheck: false,
                      }}
                    />
                    {!confirm && emailSuggestion.length > 0 && (
                      <FormHelperText variant="filled">
                        {t('Do you mean')}{' '}
                        <Link
                          onClick={() =>
                            form.mutators.setEmail(emailSuggestion)
                          }
                          className={classes.link}
                        >
                          {emailSuggestion}
                        </Link>
                        ?
                      </FormHelperText>
                    )}
                    <OnChange name="email">
                      {(val: any) => {
                        setEmail(val)
                        if (globalConfig.useEmailSuggest) {
                          checkEmail(form.getFieldState('email'))
                        }
                      }}
                    </OnChange>
                    <OnBlur name="email">
                      {() => {
                        if (globalConfig.useEmailSuggest) {
                          checkEmail(form.getFieldState('email'))
                        }
                      }}
                    </OnBlur>
                  </div>
                )}
                {!user.termsAccepted && (
                  <div>
                    {termsErr && (
                      <ServerMessage type="error" text="termsError" />
                    )}
                    <div className={classes.checkBox}>
                      <Checkbox
                        label={
                          <Typography
                            variant="body2"
                            component="span"
                            className={classes.checkBoxText}
                          >
                            <Trans i18nKey="acceptTermsAndPolicy">
                              I read and accept{' '}
                              <Link
                                href={getTermsOfUseLink(MIRROR)}
                                target="_blank"
                              >
                                Terms of Use
                              </Link>{' '}
                              and{' '}
                              <Link
                                href={getPrivacyPolicyLink(MIRROR)}
                                target="_blank"
                              >
                                Privacy Policy
                              </Link>
                            </Trans>
                          </Typography>
                        }
                        name="terms"
                        className={termsErr ? classes.errCheckBox : undefined}
                      />
                    </div>
                  </div>
                )}
                {confirm ? (
                  <div>
                    <div className={formClasses.row}>
                      <Input
                        name="code"
                        label={t('labels.Authorization code')}
                        validate={composeValidators(required, (v: any) =>
                          lengthRequired(v, 6)
                        )}
                        type="number"
                        fullWidth
                        autoFocus
                      />
                      <Typography
                        variant="body2"
                        align="center"
                        component="div"
                      >
                        <p>
                          {t('Authorisation code was sent to your')}{' '}
                          {t('email')}
                        </p>
                        <div className="hint" translate="no">
                          {resendCodeTimeout > 0 ? (
                            <p className={classes.resendTimeout}>
                              {t('Resend confirmation code in')}{' '}
                              {resendCodeTimeout}{' '}
                              {t(
                                plural(resendCodeTimeout, [
                                  'secPlural1',
                                  'secPlural2',
                                  'secPlural5',
                                ])
                              )}
                              .
                            </p>
                          ) : (
                            <Link
                              onClick={() =>
                                captchaRequest(
                                  '/api/private/v1/auth/email/set',
                                  saveEmail,
                                  { email },
                                  () => setServerError('captchaError')
                                )
                              }
                              className={formClasses.link}
                            >
                              {t('Resend code')}
                            </Link>
                          )}
                        </div>
                      </Typography>
                      <OnChange name="code">
                        {(val: string) => {
                          if (val.length === 6) {
                            handleSubmit()
                          }
                        }}
                      </OnChange>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Button
                      disabled={savingResidence || savingEmail}
                      type="submit"
                      className={classes.button}
                      variant="contained"
                      color="primary"
                    >
                      {t('Save')}
                    </Button>
                  </div>
                )}
                <Typography variant="body2" className={classes.hint}>
                  <Link onClick={logout} className={formClasses.link}>
                    {t('Use another account')}
                  </Link>
                </Typography>
              </form>
            )
          }}
        />
      </LoginLayout>
    </Loader>
  )
}
