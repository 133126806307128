import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from "clsx";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { isChainValley } from '@/helpers/mirrorHelper'
import useGlobalStyles from '@/hooks/useGlobalStyles'

const useStyles = makeStyles(() => ({
  titleFlag: {
    height: 16,
    width: 21,
    paddingLeft: 0,
    backgroundPosition: '0 0 !important'
  },
}));


const LanguageSelect: FC = () => {
  const { i18n } = useTranslation()

  const globalClasses = useGlobalStyles({ isChainValley })

  const classes = useStyles()

  return (
    <TextField
      select
      value={i18n.language || 'en-US'}
      onChange={(e) => {
        const lang = e.target.value
        window.localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang)
      }}
      className={globalClasses.lang}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <div
              className={clsx(
                globalClasses.langItem,
                classes.titleFlag,
                i18n.language || 'en-US'
              )}
            />
          </InputAdornment>
        )
      }}
    >
      <MenuItem
        disableGutters
        value="en-US"
        className={clsx(globalClasses.langItem, 'en')}
      >
        English
      </MenuItem>
      {/* <MenuItem value="ru-RU" className={clsx(globalClasses.langItem, 'ru')}>Russian</MenuItem> */}
      <MenuItem
        disableGutters
        value="fr-FR"
        className={clsx(globalClasses.langItem, 'fr')}
      >
        Français
      </MenuItem>
      <MenuItem
        disableGutters
        value="pt-PT"
        className={clsx(globalClasses.langItem, 'pt')}
      >
        Português
      </MenuItem>
      <MenuItem
        disableGutters
        value="pl-PL"
        className={clsx(globalClasses.langItem, 'pl')}
      >
        Polski
      </MenuItem>
    </TextField>
  )
}

export default LanguageSelect
