import { MIRROR } from './constants'

import UtorgLogo from '../components/Layout/img/logo-mini.svg'
import UtpayLogo from '../components/Layout/img/logo-mini.svg'
import CVLogo from '../components/Layout/img/CV.png'

export const hostName = window.location.hostname
export const isUtpay = MIRROR === 'utpay'
export const isChainValley = MIRROR === 'chainvalley'

export function getMirrorName(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return { name: 'Chain-Valley' }
    default: {
      return { name: 'Utorg Pro' }
    }
  }
}

export function getButtonText(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return 'BuySellCrypto'
    default: {
      return 'DEPOSIT'
    }
  }
}

export function getDescription(MIRROR: string) {
  switch (MIRROR) {
    case 'utpay':
      return 'footerUtpayDescription'
    case 'chainvalley':
      return 'footerChainValleyDescription'
    default: {
      return 'footerDescription'
    }
  }
}

export function getLogo(MIRROR: string) {
  switch (MIRROR) {
    case 'utpay':
      return UtpayLogo
    case 'chainvalley':
      return CVLogo
    default: {
      return UtorgLogo
    }
  }
}

export function getAlt(MIRROR: string) {
  switch (MIRROR) {
    case 'utpay':
      return 'UTPAY'
    case 'chainvalley':
      return 'Chain-Valley'
    default: {
      return 'Utorg PRO'
    }
  }
}

export function getPrivacyPolicyLink(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return 'https://chainvalley.pro/pp'
    default: {
      return 'https://utpay.io/privacy-policy'
    }
  }
}

export function getTermsOfUseLink(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return 'https://chainvalley.pro/tou'
    default: {
      return 'https://utpay.io/terms-of-use'
    }
  }
}

export function getKycAmlPolicyLink(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return 'https://chainvalley.pro/kyc'
    default: {
      return 'https://utpay.io/kyc-aml-policy'
    }
  }
}

export function getTitle(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return 'Chain-Valley'
    case 'utpay':
      return 'UTPAY'
    default: {
      return 'UTORG Pro'
    }
  }
}

export function getFavicon(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return '/chain-valley-favicon.png'
    default: {
      return '/favicon.png'
    }
  }
}

export function getAppleTouchIcon(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return '/chain-valley-favicon.png'
    default: {
      return '/apple-touch-icon.png'
    }
  }
}

export function getSupportEmail(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return 'mailto:support@chainvalley.pro'
    default: {
      return 'mailto:support@utorg.pro'
    }
  }
}

export function getButtonApmBorderRadius() {
  if (isChainValley) {
    return '8px'
  }

  return '4px'
}

export function getLegalEntity(MIRROR: string) {
  switch (MIRROR) {
    case 'chainvalley':
      return 'Chain Valley Sp. z o.o'
    default: {
      return 'Utrg UAB'
    }
  }
}
