export const QA_ATTR = 'data-qat'

const IS_DEV = process.env.REACT_APP_WIDGET_ENV !== 'prod'

export const qaAttrValue = (value: string) => {
  return IS_DEV ? value : undefined
}

export const qaAttr = (value: string) => {
  return { [QA_ATTR]: qaAttrValue(value) }
}
