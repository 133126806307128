import { useState, useEffect, useRef } from 'react'

import * as Sentry from '@sentry/react'

import { IOrder } from '@/api/order'
import { WIIDGET_ENV } from '@/helpers/constants'
import { ICustomer } from '@/types/customer'
import {
  getGoogleIsReadyToPayRequest,
  getGooglePaymentDataRequest,
  IGateway,
} from '@/helpers/googlePay'

const IS_DEV = WIIDGET_ENV !== 'prod'

export default function useGooglePay() {
  const clientRef = useRef<any>()

  const gPayWidget = document.getElementById('google-pay-widget')
  const [isGooglePayInited, setIsGooglePayInited] = useState(false)
  const [isGooglePayLoading, setIsGooglePayLoading] = useState(true)
  const [isGoogleModalOpen, setIsGoogleModalOpen] = useState(false)

  function onGooglePayLoaded() {
    const client = new google.payments.api.PaymentsClient({
      environment: IS_DEV ? 'TEST' : 'PRODUCTION',
    })

    clientRef.current = client

    client
      .isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(({ result }: { result: boolean }) => {
        setIsGooglePayInited(true)
        setIsGooglePayLoading(false)
        console.log('Is ready to pay: ', result)
      })
      .catch(() => {
        setIsGooglePayInited(false)
        setIsGooglePayLoading(false)
      })
  }

  useEffect(() => {
    if (gPayWidget && isGooglePayInited) {
      return
    }

    if (gPayWidget && !isGooglePayInited) {
      if (!window.google?.payments) {
        return
      }
      onGooglePayLoaded()
      return
    }

    if (!gPayWidget) {
      loadGpayScript(onGooglePayLoaded)
    }
  }, [gPayWidget])

  const initButton = (
    orderData: IOrder,
    customer: ICustomer,
    onMobilePayment: (token: string, cardholderName?: string) => void,
    gateway: IGateway
  ) => {
    if (
      clientRef.current &&
      !document.querySelector('.gpay-card-info-container')
    ) {
      const button = clientRef.current.createButton({
        onClick: () => {
          setIsGoogleModalOpen(true)
          const googlePaymentDataRequest = getGooglePaymentDataRequest(
            orderData,
            customer,
            gateway
          )
          clientRef.current
            .loadPaymentData(googlePaymentDataRequest)
            .then((res: Record<string, any>) => {
              const token = res?.paymentMethodData?.tokenizationData?.token
              const cardholderName =
                res?.paymentMethodData?.info?.billingAddress?.name || ''
              onMobilePayment(token, cardholderName)
              setIsGoogleModalOpen(false)
            })
            .catch((err: Error) => {
              if (process.env.REACT_APP_SENTRY_URL) {
                Sentry.captureException(err, {
                  tags: { section: 'GOOGLE PAY' },
                })
              }
              setIsGoogleModalOpen(false)
              console.error(err)
            })
        },
      })

      return button
    }
  }

  return {
    isGooglePayInited,
    isGooglePayLoading,
    isGoogleModalOpen,
    initGPayButton: initButton,
  }
}

function loadGpayScript(callback: () => void) {
  if (!document.getElementById('google-pay-widget')) {
    const script = document.createElement('script')
    script.id = 'google-pay-widget'
    script.src = 'https://pay.google.com/gp/p/js/pay.js'
    document.head.appendChild(script)
    script.onload = callback
  }
}
