import React from 'react'

import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import { isSf } from '@/helpers/constants'
import { isChainValley } from '@/helpers/mirrorHelper'

const checkedIconFramed = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 4H19C19.5545 4 20 4.44903 20 5V19C20 19.551 19.5545 20 19 20H5C4.44554 20 4 19.551 4 19V5C4 4.44903 4.44554 4 5 4Z"
      stroke="#8B959E"
      strokeWidth="2"
    />
    <path
      d="M9.99969 17.0001L6 13L7.41 11.59L9.99969 14.1701L16.5897 7.58008L17.9997 9.00008L9.99969 17.0001Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
)

const checkedIconUnframed = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3Z"
      fill="#F8F8FA"
    />
    <path
      d="M9.99969 17.0001L6 13L7.41 11.59L9.99969 14.1701L16.5897 7.58008L17.9997 9.00008L9.99969 17.0001Z"
      fill="#7F8AAC"
    />
  </svg>
)

function getCheckedIcon() {
  if (isSf && !isChainValley) {
    return checkedIconFramed
  }

  if (isSf && isChainValley) {
    return checkedIconUnframed
  }

  return <CheckBoxIcon />
}

const useStyles = makeStyles((theme) => ({
  root: {
    'paddingBottom': 0,
    '& .MuiFormHelperText-root': {
      position: 'static',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.5),
    },
    '&.error .MuiCheckbox-root': {
      color: theme.palette.error.main,
    },
  },
}))

interface ICBoxProps {
  label?: any
  className?: string
  meta?: any
  name: string
  inputRef?: any
  buttonRef?: any
  value?: boolean
  defaultChecked?: boolean
  checked?: boolean
  onChange?: any
  onClick?: any
  id?: string
  setAction?: any
}

const CBoxBase = ({
  label,
  className,
  meta,
  setAction,
  ...rest
}: ICBoxProps) => {
  const classes = useStyles()
  return (
    <FormControl
      className={clsx(classes.root, {
        error: meta && meta.touched && meta.invalid,
      })}
    >
      <FormControlLabel
        className={className}
        control={
          <Checkbox
            {...rest}
            action={setAction}
            checkedIcon={getCheckedIcon()}
          />
        }
        label={label}
      />
      {meta && meta.touched && meta.invalid && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </FormControl>
  )
}

export default CBoxBase
