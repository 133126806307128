import React, { FC, Suspense } from 'react'
import { Router } from 'react-router-dom'
import { pdfjs } from 'react-pdf'

import { useSelector } from 'react-redux'

import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'

import { createBrowserHistory } from 'history'

import { SnackbarProvider } from 'notistack'

import ThemeProvider from '@material-ui/styles/ThemeProvider'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { useUTARedirect } from '@/hooks'
import Maintenance from '@/components/Layout/Maintenance'
import CloudflareLimit from '@/components/Layout/CloudflareLimit'
import CustomerNotSupported from '@/components/Layout/CustomerNotSupported'

import theme from './theme'
import i18n from './i18n'
import Routes from './Routes'
import Loader from './components/Loader'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const history = createBrowserHistory()

const App: FC = () => {
  useUTARedirect()
  const notistackRef: any = React.createRef()
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key)
  }
  const { maintenance, ui, uiLoaded, cloudFlareLimit, customerNotSupported } =
    useSelector((state: any) => state.common)

  if (uiLoaded && ui?.global?.locale?.defaultLang?.length) {
    i18n.changeLanguage(ui.global.locale.defaultLang)
  }

  let content: React.ReactNode = <Routes />

  if (maintenance) {
    content = <Maintenance />
  }
  if (cloudFlareLimit) {
    content = <CloudflareLimit />
  }
  if (customerNotSupported) {
    content = <CustomerNotSupported />
  }

  return (
    <Suspense fallback={<Loader loading={true} />}>
      <CssBaseline />
      <ThemeProvider theme={theme(ui.global.palette)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            hideIconVariant={false}
            ref={notistackRef}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseIcon style={{ color: 'white ' }} />
              </IconButton>
            )}
          >
            <Router history={history}>{content}</Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
