export function isInIframe(checkSameOrigin = false) {
  let inFrame;
  try {
    inFrame = window.self !== window.top;
    if (inFrame && checkSameOrigin) {
      inFrame = !window.top.location.hostname.includes('utorg.pro');
    }
  } catch (e) {
    inFrame = true;
  }
  return inFrame;
}

export function isLedgerIframe() {
  return isInIframe() && window.top.location.hostname.includes('ledger.com');
}
