import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ServerMessage from '../../../components/UI/ServerMessage';
import { Input } from '../../../components/Form';
import { composeValidators, isEmail, required } from '../../../validators';
import { useForgotPassword } from '../../../api/private/auth';
import captchaRequest from '../../../helpers/captcha';
import { useLoginStyles } from './index';

export default function PasswordRestore() {
  const classes = useLoginStyles()
  const [passwordRestore, setPasswordRestore] = useState(false)
  const [captchaError, setCaptchaError] = useState(false)
  const [errorRestore, setErrorRestore] = useState('')
  const { t } = useTranslation();
  const [forgotPassword] = useForgotPassword((res: any) => {
    if (res.success) {
      setPasswordRestore(true)
    }
    if (!res.success) {
      if (res.error?.type === 'PASSWORD_NOT_SET') {
        setErrorRestore(res.error?.type)
      } else {
        setErrorRestore('passwordRestoreError')
      }
    }
  })

  if (passwordRestore) {
    return (
      <div className={classes.root}>
        <div className={classes.form}>
          <Typography variant="body1">{t('passwordResetInstructions')}</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Form
        initialValues={{
          email: ''
        }}
        onSubmit={(values) => {
          captchaRequest(
            '/api/private/v1/auth/password/forget',
            forgotPassword,
            { email: values.email },
            () => setCaptchaError(true)
          )
        }}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" className={classes.title}>{t('Password restore')}</Typography>
            {captchaError && <ServerMessage type="error" text="captchaError" />}
            {errorRestore && <ServerMessage type="error" text={errorRestore} />}
            <div>
              <Input
                name="email"
                label={t('labels.Email')}
                fullWidth
                validate={composeValidators(required, isEmail)}
                autoFocus
              />
            </div>
            <div>
              <Button
                disabled={invalid}
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
              >
                {t('Next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
}
