import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { OnChange } from 'react-final-form-listeners'
import { Input } from '../../../components/Form'
import {
  composeValidators,
  lengthRequired,
  required,
} from '../../../validators'
import plural from '../../../helpers/plural'
import Typography from '@material-ui/core/Typography'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import Link from '@material-ui/core/Link'
import ServerMessage from '../../../components/UI/ServerMessage'
import { useLoginStyles } from '@/views/Account/Login/Login'

interface ICodePhoneFormProps {
  serverError: string
  phone: string
  onResend(): void
  onSubmit(code: string): void
}

interface ICodePhoneForm {
  code: string
}

const CODE_LENGTH = 6

const CodePhoneForm: FC<ICodePhoneFormProps> = ({
  serverError = '',
  phone,
  onResend,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const globalClasses = useGlobalStyles()
  const classes = useLoginStyles()
  const [resendCodeTimeout, setResendCodeTimeout] = useState(40)

  useEffect(() => {
    let timeout: any
    if (resendCodeTimeout > 0) {
      timeout = setTimeout(
        () => setResendCodeTimeout(resendCodeTimeout - 1),
        1000
      )
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [resendCodeTimeout])

  return (
    <Form<ICodePhoneForm>
      onSubmit={(values) => {
        onSubmit(values.code)
      }}
      initialValues={{ code: '' }}
      render={({ handleSubmit }) => (
        <div>
          <Typography variant="h4" className={globalClasses.title}>
            {t('labels.confirmYourPhone')}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            className={globalClasses.subtitle}
          >
            {t('verifyCodeDescription')} <strong>{phone}</strong>
          </Typography>
          {serverError.length > 0 && (
            <ServerMessage type="error" text={serverError} />
          )}
          <div>
            <Input
              name="code"
              label={t('labels.Authorization code')}
              validate={composeValidators(required, (v: any) =>
                lengthRequired(v, CODE_LENGTH)
              )}
              inputProps={{ inputMode: 'numeric' }}
              type="number"
              fullWidth
              autoFocus
            />
            <OnChange name="code">
              {(val: string) => {
                if (val.length === CODE_LENGTH) {
                  handleSubmit()
                }
              }}
            </OnChange>
          </div>
          <div className={globalClasses.hint} translate="no">
            {resendCodeTimeout > 0 ? (
              <p className={globalClasses.resendTimeout}>
                {t('Resend confirmation code in')} {resendCodeTimeout}{' '}
                {t(
                  plural(resendCodeTimeout, [
                    'secPlural1',
                    'secPlural2',
                    'secPlural5',
                  ])
                )}
                .
              </p>
            ) : (
              <p>
                {t('notReceivedCode')}&nbsp;
                <Link
                  className={classes.link}
                  onClick={() => {
                    onResend()
                    setResendCodeTimeout(40)
                  }}
                >
                  {t('Resend code')}
                </Link>
              </p>
            )}
          </div>
        </div>
      )}
    />
  )
}

export default CodePhoneForm
