import { AnyObject, MutableState, Tools } from 'final-form'

import { getAmount, getPercentage } from './settlements'

export const setPercentage = (
  args: [string, string, number, number],
  state: MutableState<AnyObject>,
  { changeValue }: Tools<any>
) => {
  const percentageField = args[0]
  const amountField = args[1]
  const amount = args[2]
  const availableBalance = args[3]

  if (isNaN(amount) || amount <= 0) {
    changeValue(state, percentageField, () => 0)
    changeValue(state, amountField, () => 0)
  } else {
    if (amount > availableBalance) {
      changeValue(state, percentageField, () => 100)
      changeValue(state, amountField, () => availableBalance)
    } else {
      changeValue(state, percentageField, () =>
        getPercentage(amount, availableBalance)
      )
      changeValue(state, amountField, () => amount)
    }
  }
}

export const setAmount = (
  args: [string, string, number, number],
  state: MutableState<AnyObject>,
  { changeValue }: Tools<any>
) => {
  const amountField = args[0]
  const percentageField = args[1]
  const amount = args[2]
  const availableBalance = args[3]

  if (amount < 0) {
    changeValue(state, amountField, () => 0)
    changeValue(state, percentageField, () => 0)
  } else {
    if (amount > 100) {
      changeValue(state, percentageField, () => 100)
      changeValue(state, amountField, () => availableBalance)
    } else {
      changeValue(state, amountField, () => getAmount(amount, availableBalance))
      changeValue(state, percentageField, () => amount)
    }
  }
}

export const setCustomerId = (
  args: [string, number],
  state: MutableState<AnyObject>,
  { changeValue }: Tools<any>
) => {
  changeValue(state, args[0], () => args[1])
}

export const setChecked = (
  args: [string, boolean],
  state: MutableState<AnyObject>,
  { changeValue }: any
) => {
  changeValue(state, args[0], () => args[1])
}

export const setAvailableBalance = (
  args: [string, number],
  state: MutableState<AnyObject>,
  { changeValue }: Tools<any>
) => {
  changeValue(state, args[0], () => args[1])
}

export const setChangePercentage = (
  args: [string, string, string, number, string, string | undefined],
  state: MutableState<AnyObject>,
  tools: Tools<any>
) => {
  const currency = args[0]
  const percentageField = args[1]
  const amountField = args[2]
  const availableBalance = args[3]
  const alg = args[4]
  const sid = args[5]
  const currentState: any = state.formState.values
  let inputInfo = currentState[currency]

  if (sid) {
    inputInfo = currentState[currency][sid]
  }

  const isIncrement = alg === 'increment'
  const percentage = Number(inputInfo?.req || 0)
  let newValue = isIncrement ? percentage + 1 : percentage - 1

  if (newValue > 100) {
    newValue = 100
  }
  if (newValue < 0) {
    newValue = 0
  }
  setAmount(
    [amountField, percentageField, newValue, availableBalance],
    state,
    tools
  )
}
