import React from 'react'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { InputAdornment } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '18px',
    height: '10px',
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.08)',
  },
  arrowUp: {
    borderRadius: '4px 4px 0px 0px',
  },
  arrowDown: {
    'borderRadius': '0px 0px 4px 4px',
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  arrowsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3px',
  },
  arrowButtons: {
    cursor: 'ponter',
  },
}))

interface IPercentageArrows {
  handleUp: () => void
  handleDown: () => void
}

function PercentageArrows({ handleUp, handleDown }: IPercentageArrows) {
  const classes = useStyles()

  return (
    <InputAdornment position="end">
      <div className={classes.arrowsWrapper}>
        <span>%</span>
        <div className={classes.arrowButtons}>
          <div
            className={clsx(classes.button, classes.arrowUp)}
            onClick={() => handleUp()}
          >
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.18359 0L9.68359 4.5H0.683594L5.18359 0Z"
                fill="#09121F"
              />
            </svg>
          </div>
          <div
            className={clsx(classes.button, classes.arrowDown)}
            onClick={() => handleDown()}
          >
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.18359 0L9.68359 4.5H0.683594L5.18359 0Z"
                fill="#09121F"
              />
            </svg>
          </div>
        </div>
      </div>
    </InputAdornment>
  )
}
export default PercentageArrows
