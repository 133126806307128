import { useEffect } from 'react'
import { getParamByISO } from 'iso-country-currency'
import { useCountryByIp } from '../api/customer'
import { addTagSentry } from '@/helpers/sentryParams'

export const useCurrencyByIp = (loadOnInit?: boolean) => {
  const [countryByIp, countryByIpLoading, loadCountryByIp] =
    useCountryByIp(loadOnInit)

  useEffect(() => {
    if (!loadOnInit) {
      return
    }
    loadCountryByIp()
  }, [])

  const country = countryByIp.data

  useEffect(() => {
    if (country) {
      addTagSentry('IpCountry', country)
    }
  }, [country])

  return {
    loadCountryByIp,
    currencyByIp: country ? getParamByISO(country, 'currency') : undefined,
    isCurrencyByIpLoading: countryByIpLoading,
    ipCountry: country,
  }
}
