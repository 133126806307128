import React from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { Typography } from '@material-ui/core'

import { getLayoutApmView } from '@/helpers/getLayoutApmView'
import { getImageByAmount } from '@/helpers/nftHelpers/getImageByAmount'
import { IOrder } from '@/api/order'
import usePaymentFormStyles from '@/views/components/usePaymentFormStyles'
import { getOrderAmount } from '@/helpers/getOrderAmount'

const useStyles = makeStyles((theme) => ({
  orderInfoWrapper: {
    display: 'flex',
  },
  orderInfoImage: {
    marginLeft: theme.spacing(2),
  },
}))

interface IOrderInfo {
  order: IOrder
  step: string
  apm?: any
}

function NftOrderInfo({ order, step, apm }: IOrderInfo) {
  const { t } = useTranslation()

  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.down('xs'))
  const isThreeHundredSixtyFive = useMediaQuery(theme.breakpoints.down(365))

  const showApmView = getLayoutApmView(step, order)

  const paymentFormStyles = usePaymentFormStyles()
  const classes = useStyles()

  return (
    <div className={classes.orderInfoWrapper}>
      <div
        className={clsx(
          !showApmView
            ? paymentFormStyles.orderInfo
            : paymentFormStyles.orderInfoCenter
        )}
      >
        <Typography variant="body2" className="id" component="div">
          {!isXS && (
            <>
              {t('Exchange order')} #{order.id}
            </>
          )}
          {isXS && (
            <>
              {!isThreeHundredSixtyFive && t('Order')} #{order.id}
            </>
          )}
        </Typography>
        <div className="amount" translate="no">
          {getOrderAmount(order, apm?.apmAmount)}
        </div>
      </div>
      <div className={classes.orderInfoImage}>
        <img
          src={getImageByAmount(order.paymentAmount)}
          width={50}
          height={50}
          alt="NFT"
        />
      </div>
    </div>
  )
}
export default NftOrderInfo
