import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Loader from '../../Loader'
import Panel from '../../Panel/Panel'
import { useCustomerInfo } from '../../../api/customer'
import { setLogIn } from '../../../store/actions/account'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import LanguageSelect from '../../LanguageSelect'
import { INTERNAL_LINK_TARGET_BLANK } from '../../../helpers/internalTarget'
import {
  getAppleTouchIcon,
  getDescription,
  getFavicon,
  getKycAmlPolicyLink,
  getPrivacyPolicyLink,
  getTermsOfUseLink,
  getTitle,
  isChainValley,
} from '@/helpers/mirrorHelper'
import appstore from '@/components/icons/appstore.png'
import googleplay from '@/components/icons/googleplay.png'
import { MIRROR } from '@/helpers/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    paddingBottom: 120,
    paddingTop: 30,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      'paddingTop': 0,
      'alignItems': 'flex-start',
      '& > div': {
        width: '100%',
      },
    },
  },
}))

interface IProps {
  children: any
}

const reCaptcha3Key =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  '6LeIDKgZAAAAABpUZ9JyPtgHEf_OwylbsKDcrPBR'

export default function LoginLayout({ children }: IProps) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false)
  const [, loading, load] = useCustomerInfo(setLogIn)
  const { t } = useTranslation()

  useEffect(() => {
    // @ts-ignore
    if (!window.grecaptcha) {
      const s = document.createElement('script')
      s.src = `https://www.google.com/recaptcha/api.js?render=${reCaptcha3Key}`
      s.onload = function () {
        setRecaptchaLoaded(true)
      }
      s.onerror = function () {
        setRecaptchaLoaded(true)
      }
      s.onabort = function () {
        setRecaptchaLoaded(true)
      }
      document.body.appendChild(s)
    } else {
      setRecaptchaLoaded(true)
    }
    load()
  }, [])

  return (
    <Loader loading={!recaptchaLoaded || loading}>
      <Helmet>
        <link rel="icon" href={getFavicon(MIRROR)} />
        <link rel="apple-touch-icon" href={getAppleTouchIcon(MIRROR)} />
        <title>{getTitle(MIRROR)}</title>
      </Helmet>
      <div className={classes.root}>
        <div>
          <Panel logoPullRight>{children}</Panel>
          <div>
            <div className={globalClasses.bottom}>
              <List component="nav" className={globalClasses.menu}>
                <ListItem className={globalClasses.bottomMenuLink}>
                  <Link
                    href={getPrivacyPolicyLink(MIRROR)}
                    target={INTERNAL_LINK_TARGET_BLANK}
                  >
                    {t('Privacy policy')}
                  </Link>
                </ListItem>
                <ListItem className={globalClasses.bottomMenuLink}>
                  <Link
                    href={getTermsOfUseLink(MIRROR)}
                    target={INTERNAL_LINK_TARGET_BLANK}
                  >
                    {t('Terms of use')}
                  </Link>
                </ListItem>
                <ListItem className={globalClasses.bottomMenuLink}>
                  <Link
                    href={getKycAmlPolicyLink(MIRROR)}
                    target={INTERNAL_LINK_TARGET_BLANK}
                  >
                    {t('KYC / AML policy')}
                  </Link>
                </ListItem>
              </List>
              <LanguageSelect />
            </div>
            {!isChainValley && (
              <div>
                <div className={globalClasses.promoButtons}>
                  <Link
                    href="https://cutt.ly/appstorewidget"
                    target={INTERNAL_LINK_TARGET_BLANK}
                  >
                    <img
                      className={globalClasses.promoBtn}
                      src={appstore}
                      width={160}
                      height={46}
                    />
                  </Link>
                  <Link
                    href="https://cutt.ly/6wbxnrJT"
                    target={INTERNAL_LINK_TARGET_BLANK}
                  >
                    <img
                      className={globalClasses.promoBtn}
                      src={googleplay}
                      width={160}
                      height={46}
                    />
                  </Link>
                </div>
                <div className={globalClasses.promoWrapper}>
                  <div className={globalClasses.promoText}>
                    {t('downloadApp')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={globalClasses.footer}>
          <Typography
            variant="body2"
            component="div"
            className="small"
            dangerouslySetInnerHTML={{ __html: t(getDescription(MIRROR)) }}
          />
          <Typography align="center">
            This site is protected by reCAPTCHA
            <br />
            and the Google&nbsp;
            <Link
              href="https://policies.google.com/privacy"
              target={INTERNAL_LINK_TARGET_BLANK}
            >
              Privacy Policy
            </Link>{' '}
            and&nbsp;
            <Link
              href="https://policies.google.com/terms"
              target={INTERNAL_LINK_TARGET_BLANK}
            >
              Terms of Service
            </Link>{' '}
            apply.
          </Typography>
        </div>
      </div>
    </Loader>
  )
}
