export function countryByApm(apm: string) {
  if (['WLT', 'NTL', 'OBT', 'PSC', 'GLU_WLT', 'GIR_WLT'].includes(apm)) {
    return 'DE'
  }
  if (['SPX', 'CHP', 'ONB'].includes(apm)) {
    return 'BR'
  }
  if (['MUB', 'MBW'].includes(apm)) {
    return 'PT'
  }
  if (['SPI'].includes(apm)) {
    return 'MX'
  }
  if (['BLK_WLT', 'PWY_WLT'].includes(apm)) {
    return 'PL'
  }
  if (['MAH', 'KHP'].includes(apm)) {
    return 'CL'
  }
  if (['MCH_WLT'].includes(apm)) {
    return 'BE'
  }
  return 'PE'
}
