import React, { FC, useState, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import CheckIcon from '@material-ui/icons/Check'
import Button from '@material-ui/core/Button'

import { usePopupSelectStyles } from './ResidenceStyles'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import { IAutocompleteOption } from '../../../components/Form/AutocompleteField'
import { trackAddCor, trackUsFlowInfo } from '@/helpers/analytics'
import { IOrder } from '@/api/order'

interface IResidenceDialogProps {
  title: string
  open: boolean
  checked: IAutocompleteOption | null
  detected?: IAutocompleteOption | null
  setChecked: Dispatch<SetStateAction<IAutocompleteOption | null>>
  options: IAutocompleteOption[]
  classes: any
  setCode: any
  closeModal(): void
  order?: IOrder
  checkedCountry?: IAutocompleteOption | null
  code?: string
}

const inputProps = {
  startAdornment: (
    <InputAdornment position="start" style={{ paddingLeft: 16 }}>
      <SearchIcon color="disabled" />
    </InputAdornment>
  ),
}

const ResidenceDialog: FC<IResidenceDialogProps> = ({
  title,
  open,
  checked,
  setChecked,
  detected,
  options,
  closeModal,
  setCode,
  classes,
  order,
  checkedCountry,
  code,
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>('')

  const localClasses = usePopupSelectStyles()
  const globalClasses = useGlobalStyles()

  const onSearch: TextFieldProps['onChange'] = (event) => {
    setSearch(event.target.value)
  }
  const onConfirm = () => {
    if (!checked) {
      setChecked(detected ?? null)
    }
    if (checked && title === 'Select your country of residence') {
      trackAddCor(
        'add_residence_country_success',
        order,
        checked.label,
        'onramp_personal_info'
      )
    }
    if (title === 'Select your state' && checked)
      trackUsFlowInfo(
        'add_state_success',
        order,
        checkedCountry?.label,
        checked.label,
        'onramp_personal_info'
      )
    setCode(checked?.value || detected?.value)
    closeModal()
    setSearch('')
  }

  const onClose = () => {
    if (code !== checked?.value) {
      setChecked(null)
      setCode('')
    }
    setSearch('')
    closeModal()
  }

  const filteredOptions = options.filter((o) => {
    if (!search) {
      return true
    }
    return (
      o.name?.toLowerCase().includes(search.toLowerCase()) ||
      o.label?.toLowerCase().includes(search.toLowerCase()) ||
      o.value.toLowerCase().includes(search.toLowerCase())
    )
  })

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={clsx(classes.dialog, localClasses.countries)}>
        <div className={classes.dialogHeader}>
          <Typography component="div" className={classes.dialogTitle}>
            {title}
          </Typography>
          <TextField
            className={classes.field}
            placeholder={`${t('Search')}...`}
            fullWidth
            onChange={onSearch}
            InputProps={inputProps}
            autoFocus
          />
        </div>
        <div className={classes.dialogList}>
          {filteredOptions.map((option) => {
            const isChecked = checked?.value === option.value
            const isDetected = option.value === detected?.value

            const onCheck = () => {
              setChecked(option)
            }

            return (
              <div
                className={clsx(localClasses.option, {
                  active: isChecked,
                  detected: isDetected,
                })}
                onClick={onCheck}
                key={option.value}
              >
                <div className="details">
                  {option.icon && (
                    <img
                      src={`/img/flags/icon-${option.icon}.svg`}
                      alt={option.value}
                    />
                  )}
                  <Typography variant="body2">
                    {option.label || option.name}
                  </Typography>
                </div>
                <div className="details">
                  {isDetected && (
                    <Typography
                      variant="caption"
                      align="right"
                      className="detected-mark"
                      dangerouslySetInnerHTML={{
                        __html: t('Detected automatically'),
                      }}
                    />
                  )}
                  {isChecked && <CheckIcon color="inherit" className="check" />}
                </div>
              </div>
            )
          })}
        </div>
        <div className={localClasses.grad} />
        <div className={classes.dialogFooter}>
          <Button
            onClick={onClose}
            className={clsx(globalClasses.button, 'small')}
            color="primary"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={onConfirm}
            className={clsx(globalClasses.button, 'small')}
            variant="contained"
            color="primary"
          >
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ResidenceDialog
