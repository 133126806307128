import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { Form } from 'react-final-form'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, Chip } from '@material-ui/core'

import { useSettlementInit } from '@/api/private/account'
import line from '@/icons/line.svg'

import RowBalance, { IRowBalance } from './RowBalance'
import SettlementRequestResult from './SettlementRequestResult'
import SettlementConfirmation from './SettlementConfirmation'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import { checkAvailableSidRequests } from '@/helpers/settlements'
import { ICheckedSidRequest } from '@/types/settlements'
import {
  setAmount,
  setAvailableBalance,
  setChecked,
  setCustomerId,
  setChangePercentage,
  setPercentage,
} from '@/helpers/settlementFormMutators'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  button: {
    height: 36,
    maxWidth: '210px',
  },
  headerForm: {
    display: 'grid',
    gridTemplateColumns: '1fr 210px',
    columnGap: '16px',
    marginBottom: theme.spacing(4),
  },
  cellAmount: {
    paddingLeft: 0,
  },
  availableCell: {
    paddingRight: 0,
    paddingLeft: theme.spacing(1),
    width: '145px',
  },
  selectedBalances: {
    fontSize: '0.875rem',
    lineHeight: 1.71,
    letterSpacing: '0.01em',
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
  },
  selectedItems: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    paddingTop: '6px',
  },
  formChip: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
}))

interface IResetRowHandle {
  resetRow: () => void
}

interface IDesktopBalancesList {
  balances: IRowBalance[]
  loadBalances: () => void
}

const DesktopBalancesList = forwardRef(function DesktopBalancesList(
  { balances, loadBalances }: IDesktopBalancesList,
  forwardedRef
) {
  const { t } = useTranslation()

  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  const rowsRefs = useRef<(IResetRowHandle | null)[]>([])

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [isOpenResultModal, setIsOpenResultModal] = useState(false)
  const [formData, setFormData] = useState({})
  const [createdSettlement, setCreatedSettlement] = useState({ id: '' })

  const [create, creating] = useSettlementInit((res: any) => {
    if (res.success) {
      setCreatedSettlement(res.data)
    } else {
      setCreatedSettlement(res)
    }

    setIsOpenResultModal(true)
    setIsOpenConfirmModal(false)
  })

  const closeModal = () => {
    if (createdSettlement?.id) {
      loadBalances()
    }
    setIsOpenResultModal(false)
  }

  useImperativeHandle(
    forwardedRef,
    () => ({
      resetForm: () => {
        rowsRefs.current.forEach((rowRef) => rowRef?.resetRow())
      },
    }),
    [balances]
  )

  return (
    <div>
      <Form
        onSubmit={(values) => {
          setFormData(values)
          setIsOpenConfirmModal(true)
        }}
        mutators={{
          setPercentage,
          setChangePercentage,
          setChecked,
          setAvailableBalance,
          setAmount,
          setCustomerId,
        }}
        render={({ handleSubmit, form, values, invalid }) => {
          const setAmount = form.mutators.setAmount
          const setPercentage = form.mutators.setPercentage
          const setChangePercentage = form.mutators.setChangePercentage
          const setChecked = form.mutators.setChecked
          const setAvailableBalance = form.mutators.setAvailableBalance
          const setCustomerId = form.mutators.setCustomerId

          const parsedFormData = Object.entries(values).map(
            ([currency, requestInfo]) => {
              const checkedSidRequests: ICheckedSidRequest[] = []

              checkAvailableSidRequests(requestInfo, checkedSidRequests)

              return {
                currency,
                sids: checkedSidRequests,
              }
            }
          )
          const filteredRequests = parsedFormData.filter(
            (item) => item.sids.length > 0
          )
          const receivedTotalBalances = filteredRequests.map((item) => {
            const { currency, sids } = item
            const totalBalance = sids.reduce(
              (acc: number, sid) => acc + sid.amount,
              0
            )
            return { currency, amount: totalBalance }
          })
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.headerForm}>
                <div className={classes.selectedItems}>
                  <>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.selectedBalances}
                      dangerouslySetInnerHTML={{
                        __html: t('settlements.selectedBalances', {
                          amount: filteredRequests.length,
                        }),
                      }}
                    />
                    {receivedTotalBalances.length > 0 && (
                      <img src={line} height={24} />
                    )}
                  </>
                  <div className={classes.formChip}>
                    {receivedTotalBalances.length > 0 &&
                      receivedTotalBalances.map((item) => (
                        <Chip
                          size="small"
                          key={item.currency}
                          label={`${item.amount.toFixed(2)} ${item.currency}`}
                        />
                      ))}
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    className={clsx(globalClasses.button, classes.button)}
                    color="primary"
                    type="submit"
                    disabled={filteredRequests.length === 0 || invalid}
                  >
                    {t('settlements.requestSettlement')}
                  </Button>
                </div>
              </div>
              <Table className={globalClasses.tableSimple}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" component="span"></Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" component="span">
                        {t('Currency')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" component="span">
                        {t('Total')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" component="span">
                        {t('Locked')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" className={classes.availableCell}>
                      <Typography variant="body2" component="span">
                        {t('Available')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" component="span">
                        {t('settlements.req')}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cellAmount}>
                      <Typography variant="body2" component="span">
                        {t('settlements.reqAmount')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balances.map((balance, index) => (
                    <RowBalance
                      ref={(rowRef: IResetRowHandle) =>
                        (rowsRefs.current[index] = rowRef)
                      }
                      sortedFormDataByAvailableSids={parsedFormData}
                      key={balance.currency}
                      row={balance}
                      setPercentage={setPercentage}
                      setAmount={setAmount}
                      setChangePercentage={setChangePercentage}
                      setChecked={setChecked}
                      setAvailableBalance={setAvailableBalance}
                      setCustomerId={setCustomerId}
                    />
                  ))}
                </TableBody>
              </Table>
            </form>
          )
        }}
      />
      <SettlementRequestResult
        isOpen={isOpenResultModal}
        settlement={createdSettlement}
        closeModal={() => closeModal()}
      />
      <SettlementConfirmation
        create={create}
        creating={creating}
        formData={formData}
        setIsOpenModal={setIsOpenConfirmModal}
        isOpenModal={isOpenConfirmModal}
      />
    </div>
  )
})

export default DesktopBalancesList
