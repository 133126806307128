import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '1000px',
    margin: '0 auto',
    paddingBottom: '100px',
  }
}));

export default function KycAmlPolicy() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div dangerouslySetInnerHTML={{ __html: t('pages.kyc') }} />
    </div>
  );
}
