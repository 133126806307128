import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import ServerMessage from '../../../components/UI/ServerMessage';
import { Input } from '../../../components/Form';
import { required, composeValidators, passwordValid } from '../../../validators';
import { useResetPassword } from '../../../api/private/auth';
import { getMaskedEmail } from '../../../helpers/mask';
import captchaRequest from '../../../helpers/captcha';
import { useLoginStyles } from './index';

export default function PasswordReset() {
  const classes = useLoginStyles();
  const { token } = useParams();
  const { t } = useTranslation();
  const [resetErr, setResetErr] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const history = useHistory();
  const [resetPassword] = useResetPassword((res: any) => {
    if (res.success) {
      history.push('/account/login?from=restore');
    } else {
      setResetErr(true);
    }
  })
  const [code, email] = token ? decodeURIComponent(token).split(':') : [undefined, undefined];

  if (!code || !email || resetErr) {
    return (
      <div className={classes.root}>
        <div className={classes.form}>
          <Typography variant="body1" align="center">
            {t(resetErr ? 'passwordResetError' : 'restoreTokenInvalid')}
          </Typography>
          <Typography variant="body1" align="center">
            <NavLink to="/account">{t('Return to log in page')}</NavLink>
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <Form
      initialValues={{
        email: getMaskedEmail(email),
        password: ''
      }}
      onSubmit={(values) => {
        captchaRequest(
          '/api/private/v1/auth/password/reset',
          resetPassword,
          { password: values.password, code, email },
          () => setCaptchaError(true)
        );
      }}
      render={({ handleSubmit, invalid, form }) => {
        const pwdFieldState = form.getFieldState('password');
        return (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" className={classes.title}>{t('Password restore')}</Typography>
            {captchaError && <ServerMessage type="error" text="captchaError" />}
            <div>
              <Input
                name="email"
                label={t('labels.Email')}
                fullWidth
                disabled
                required={false}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <Input
                name="password"
                label={t('Password')}
                type="password"
                fullWidth
                validate={composeValidators(required, passwordValid)}
                autoComplete="new-password"
                autoFocus
              />
              {(!pwdFieldState || (!pwdFieldState.touched && !pwdFieldState.valid)) && (
                <FormHelperText variant="filled">{t('validation.passwordNotValid')}</FormHelperText>
              )}
            </div>
            <div>
              <Button
                disabled={invalid}
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
              >
                {t('Save')}
              </Button>
            </div>
          </form>
        )
      }}
    />
  );
}
