import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Loader from '../../../components/Loader';
import { useCardsList, useCardDelete } from '../../../api/private/payment';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardDeletePopup from './CardDeletePopup';
import useGlobalStyles from '../../../hooks/useGlobalStyles';

export default function CardsPage() {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const [selected, setSelected] = useState({} as any);
  const [{ data: cards }, loading, load] = useCardsList();
  const [deleteError, setDeleteError] = useState('');
  const [deleteCard, deletingCard] = useCardDelete((res: any) => {
    if (res.success) {
      setSelected({} as any);
      setDeleteError('');
      return load();
    }
    setDeleteError('submitError');
  })

  useEffect(() => {
    load();
  }, []);

  return (
    <Loader loading={loading}>
      {selected.id && (
        <CardDeletePopup
          onConfirm={() => deleteCard({ id: selected.id })}
          onCancel={() => {
            setSelected({} as any);
            setDeleteError('');
          }}
          deleting={deletingCard}
          card={selected}
          error={deleteError}
        />
      )}
      <div className={globalClasses.halfContent}>
        <Typography variant="h4" className={globalClasses.title}>{t('Saved cards')}</Typography>
        {cards.length > 0
          ? (
            <Table className={globalClasses.tableSimple}>
              <TableBody>
                {cards.map((c: any) => (
                  <TableRow key={c.id}>
                    <TableCell className="title" style={{ width: '140px' }}>
                      <Typography variant="body2" component="span">{c.number}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        className={clsx(globalClasses.button, 'small')}
                        onClick={() => setSelected(c)}
                        variant="text"
                        color="primary"
                        style={{ width: '150px' }}
                      >
                        {t('Delete card')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : <Typography variant="body1">{t('You have not saved cards')}</Typography>}
      </div>
    </Loader>
  );
}
