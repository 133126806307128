import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  DialogContent,
  Dialog,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core'

import { IOrder } from '@/api/order'
import { useOrderCancel } from '@/api/private/orders'
import { isSf } from '@/helpers/constants'
import useGlobalStyles from '@/hooks/useGlobalStyles'

interface IFormChangeConfirmProps {
  onConfirm: () => void
  onCancel: () => void
  order?: IOrder
  nextStep?: (error: string) => void
  setResultError?: (error: undefined | string) => void
  setServerError?: (error: string) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    maxWidth: '100%',
    padding: '12px 16px',
    background: '#fff',
    borderRadius: 8,
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}))

export default function FormChangeConfirm({
  onConfirm,
  onCancel,
  order,
  nextStep,
  setResultError,
  setServerError,
}: IFormChangeConfirmProps) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { t } = useTranslation()
  const [isConfirmCancel, setIsConfirmCancel] = useState(false)

  const [cancelOrder, cancellation] = useOrderCancel((res: any) => {
    if (res.success && nextStep && setResultError) {
      window.localStorage.setItem(
        'pe',
        JSON.stringify({ oid: order?.id, e: 'ORDER_CANCEL' })
      )
      setResultError('ORDER_CANCEL')
      nextStep('error')
      return
    }
    if (setServerError) {
      setServerError('somethingWentWrong')
    }
  })

  return (
    <Dialog open>
      {!isSf && (
        <div className={classes.root}>
          <DialogContent>
            <Typography variant="h6" className={classes.title}>
              {t('formChangeConfirmTitle')}
            </Typography>
            <Typography variant="body2" className={globalClasses.subtitle}>
              {t('formChangeConfirmText')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              className={clsx(globalClasses.button, 'small')}
              variant="contained"
              color="primary"
              onClick={onCancel}
              id="cancel-wallet-change"
            >
              {t('cancelChangeAddress')}
            </Button>
            <Button
              className={clsx(globalClasses.button, 'small')}
              color="primary"
              onClick={onConfirm}
              id="confirm-wallet-change"
            >
              {t('changeAddress')}
            </Button>
          </DialogActions>
        </div>
      )}
      {isSf && (
        <div className={classes.root}>
          <DialogContent>
            <Typography variant="h6" className={classes.title} translate="no">
              {!isConfirmCancel && t('formChangeConfirmTitle')}
              {isConfirmCancel && t('formChangeCCWOrderTitle')}
            </Typography>
            {!isConfirmCancel && (
              <Typography
                variant="body2"
                className={globalClasses.subtitle}
                translate="no"
              >
                {t('formChangeConfirmText')}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              translate="no"
              className={clsx(globalClasses.button, 'small')}
              variant={!isConfirmCancel ? 'contained' : 'text'}
              color="primary"
              disabled={cancellation}
              onClick={
                !isConfirmCancel
                  ? onCancel
                  : () => cancelOrder({ id: order?.id })
              }
              id="confirm-cancel-order"
            >
              {!isConfirmCancel
                ? t('cancelChangeAddress')
                : t('cancelOrderCCW')}
            </Button>
            <Button
              translate="no"
              className={clsx(globalClasses.button, 'small')}
              color="primary"
              variant={isConfirmCancel ? 'contained' : 'text'}
              onClick={
                !isConfirmCancel ? () => setIsConfirmCancel(true) : onCancel
              }
              id="cancel-cancel-order"
            >
              {!isConfirmCancel ? t('cancelOrderCCW') : t('close')}
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
