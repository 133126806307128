import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import cardValidator from 'card-validator';
import clsx from 'clsx';
import IMask from 'imask';
import * as Sentry from '@sentry/react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CBoxBase from '../../components/Form/CheckboxBase';
import ThreeDS from './ThreeDS';
import Loader from '../../components/Loader';
import ServerMessage from '../../components/UI/ServerMessage';
import CardDeletePopup from '../Account/Cards/CardDeletePopup';
import Select from '../../components/Form/SelectBase';
import { composeValidators, required, isCardNumber, isValidCardExpires, lengthRequired, maxLength } from '../../validators';
import { usePrivatePaymentPayNew, useCardsList, usePrivatePaymentPay, useCardDelete, useThreeDSGet } from '../../api/private/payment';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { I3DsData } from '../Exchange/forms/CardForm';
import { IOrder } from '../../api/order';
import useSafeCharge from '../../hooks/useSafeCharge';
import VisaIcon from './img/visa.svg'
import MCIcon from './img/mc.svg'
import MSIcon from './img/ms.svg'

const useCardFormStyles = makeStyles((theme) => ({
  root: {},
  holder: {
    width: 480,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: 344
    }
  },
  saved: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    '& .cardSelect': {
      marginRight: theme.spacing(1),
      width: 320,
      flex: '0 0 320px',
      [theme.breakpoints.down('xs')]: {
        flex: 0,
        marginBottom: -theme.spacing(2)
      }
    }
  },
  form: {
    position: 'relative',
    height: 240,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      height: 300
    }
  },
  card: {
    position: 'absolute',
    height: 225,
    width: 320,
    borderRadius: 20,
    '& .MuiFilledInput-root': {
      background: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      height: '50px !important',
      '&.MuiFilledInput-underline': {
        '&.Mui-focused': {
          backgroundImage: `linear-gradient(#fff 46px, ${theme.palette.primary.main} 2px) !important`,
          borderBottomColor: theme.palette.primary.main
        },
        '&.Mui-error': {
          backgroundImage: `linear-gradient(#fff 46px, ${red[600]} 2px) !important`,
          borderBottomColor: red[600]
        },
      },
      '& .MuiFilledInput-input': {
        padding: '15px 12px 14px'
      }
    },
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap',
      top: -27,
      left: -10,
      fontSize: 16,
      textTransform: 'uppercase'
    },
    '& .MuiFormHelperText-root': {
      top: 50,
      left: -13,
      whiteSpace: 'nowrap'
    }
  },
  frontSide: {
    top: 0,
    left: 0,
    background: '#F5F6F8',
    zIndex: 100,
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06), 0px 24px 38px rgba(0, 0, 0, 0.07)',
    '& .systems': {
      position: 'absolute',
      top: 15,
      right: theme.spacing(2),
      width: 56,
      height: 45,
      filter: 'grayscale(100%)',
      '-webkit-filter': 'grayscale(100%)',
      opacity: 0,
      transition: 'all 700ms ease 0s',
      '&.visa': {
        '-webkit-filter': 'none',
        background: `url("${VisaIcon}") no-repeat center`,
        filter: 'none',
        opacity: 1
      },
      '&.mastercard': {
        '-webkit-filter': 'none',
        filter: 'none',
        background: `url("${MCIcon}") no-repeat center`,
        opacity: 1
      },
      '&.maestro': {
        '-webkit-filter': 'none',
        filter: 'none',
        background: `url("${MSIcon}") no-repeat center`,
        opacity: 1
      }
    },
    '& .cardNumber': {
      marginTop: 64,
      height: 75,
      padding: '0 15px'
    },
    '& .dateAndName': {
      height: 70,
      display: 'flex',
      padding: '0 15px',
      justifyContent: 'space-between',
      marginTop: 17,
    },
    '& .cardExpire': {
      width: 80
    },
    '& .cardHolder': {
      width: 200,
      '& input': {
        textTransform: 'uppercase'
      }
    },
  },
  backSide: {
    bottom: 0,
    right: 0,
    background: '#F5F6F8',
    '& .magnitLine': {
      marginTop: 23,
      background: '#D2D7E0',
      height: 47
    },
    '& .cvv': {
      width: 87,
      marginTop: 25,
      marginLeft: 179,
      [theme.breakpoints.down('xs')]: {
        marginTop: 86,
      },
      '& input': {
        fontFamily: 'dots',
        fontSize: 16,
        letterSpacing: '-5px'
      }
    },
    '& .helper': {
      fontSize: 12,
      margin: '-10px 15px 0 179px',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: 175,
        right: 140,
        width: 140,
        textAlign: 'right'
      },
    }
  },
  actions: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    height: 50,
    width: 480,
    [theme.breakpoints.down('xs')]: {
      width: 344
    }
  },
  frame: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    background: '#fcfcfc',
    zIndex: 10000,
    // width: '100%',
    // minHeight: '500px',
    border: 'none'
  }
}));

interface IProps {
  paymentId: number;
  is3ds?: boolean;
  onFinish?: any;
  data3ds?: I3DsData;
  order: IOrder;
  customer: any;
  provider: string;
  initPayment: any;
}

export interface IAuth3dRequest {
  sessionToken: string;
  merchantId: string;
  merchantSiteId: string;
  currency: string;
  amount: string;
  userTokenId: string;
  paymentOption: {
    card: {
      cardNumber: number;
      cardHolderName: string;
      expirationMonth: string;
      expirationYear: string;
      CVV: number;
    }
  }
  billingAddress: {
    country: string;
    email: string;
  },
}

export default function SafariPaymentForm({ paymentId, onFinish, is3ds, data3ds, order, customer, provider, initPayment }: IProps) {
  const classes = useCardFormStyles();
  const globalClasses = useGlobalStyles();
  const { t, i18n: { language } } = useTranslation();
  const numberField = useRef(null);
  const expireField = useRef(null);
  const cvvField = useRef(null);
  const holderField = useRef(null);
  const rememberField = useRef(null);
  const [cardType, setCardType] = useState('');
  const [form3ds, setForm3ds] = useState({} as any);
  const [cardId, setCardId] = useState<number | undefined>();
  const [serverError, setServerError] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [cardDelConfirm, setCardDelConfirm] = useState(false);
  const [numberError, setNumberError] = useState<string | undefined>();
  const [expireError, setExpireError] = useState<string | undefined>();
  const [cvvError, setCvvError] = useState<string | undefined>();
  const [holderError, setHolderError] = useState<string | undefined>();
  const [setSaved, setSetSaved] = useState(false);
  const [auth3d, setAuth3d] = useState(false);
  const [payReq, setPayReq] = useState<any | undefined>();
  const [needReInit, setNeedReInit] = useState(false);
  const [{ data: cards }, loading, load] = useCardsList();
  useSafeCharge(order);
  const history = useHistory();

  const numById = document.getElementById('cardnumber');
  const expById = document.getElementById('expires');
  const cvvById = document.getElementById('cvvField');

  const [payNew, processing] = usePrivatePaymentPayNew((res: any) => {
    setAuth3d(false);
    if (res.success) {
      // destroySC();
      setServerError('');
      if (res.data.nextStep === 'THREEDS') {
        setForm3ds(res.data.form);
      } else {
        if (onFinish) {
          return onFinish(res.data.nextStep.toLowerCase());
        }
        history.push(`/account/orders/create/deposit/${res.data.nextStep.toLowerCase()}`);
      }
    } else if (res.error.type === 'CONSTRAINT_VIOLATION' && res.error.message === 'Card already exists') {
      setServerError('cardExist');
      if (document.getElementById('cardnumber')) {
        // @ts-ignore
        document.getElementById('cardnumber').focus();
      }
    } else if (res.error.type === 'CARD_NOT_SUPPORTED') {
      setServerError('cardNotSupported');
      if (document.getElementById('cardnumber')) {
        // @ts-ignore
        document.getElementById('cardnumber').focus();
      }
    } else if (res.error.type === 'CARD_PROCESSING_ERROR') {
      setServerError('cardProcessingError');
      if (document.getElementById('cardnumber')) {
        // @ts-ignore
        document.getElementById('cardnumber').focus();
      }
    } else {
      setServerError('somethingWentWrong');
    }
  });
  const [pay, processingPay] = usePrivatePaymentPay((res: any) => {
    setAuth3d(false);
    if (res.success) {
      // destroySC();
      setServerError('');
      if (res.data.nextStep === 'THREEDS') {
        setForm3ds(res.data.form);
      } else {
        if (onFinish) {
          return onFinish(res.data.nextStep.toLowerCase());
        }
        history.push(`/account/orders/create/deposit/${res.data.nextStep.toLowerCase()}`);
      }
    } else if (res.error.type === 'CONSTRAINT_VIOLATION' && res.error.message === 'Card already exists') {
      setServerError('cardExist');
    } else if (res.error.type === 'CARD_NOT_SUPPORTED') {
      setServerError('cardNotSupported');
    } else {
      setServerError('somethingWentWrong');
    }
  });
  const [deleteCard, cardDeleting] = useCardDelete((res: any) => {
    if (res.success) {
      setCardId(undefined);
      setCardDelConfirm(false);
      setDeleteError('');
      return load();
    }
    setDeleteError('submitError');
  });
  const [get3ds, getting3ds] = useThreeDSGet((res: any) => {
    if (res.success) {
      setForm3ds(res.data.form);
    } else if (res.error.type === 'ORDER_ALREADY_PROCESSED') {
      setServerError('ORDER_ALREADY_PROCESSED');
    } else {
      setServerError('somethingWentWrong')
    }
  });
  const isSafeCharge = provider === 'SC';

  let reinitTimeout: any;
  useEffect(() => {
    if (!isSafeCharge) {
      load();
    } else {
      reinitTimeout = setTimeout(() => setNeedReInit(true), 4 * 60000);
    }
  }, [])

  useEffect(() => {
    if (is3ds) {
      get3ds({ id: paymentId });
    }
  }, [is3ds])

  useEffect(() => {
    if (numById) {
      IMask(numById, { mask: '0000 0000 0000 000000' });
    }
  }, [numById, cardId])

  useEffect(() => {
    if (expById) {
      IMask(expById, { mask: '00/0000' });
    }
  }, [expById])

  useEffect(() => {
    if (cvvById) {
      IMask(cvvById, { mask: '000' });
    }
  }, [cvvById])

  useEffect(() => {
    if (numberError && numberField.current) {
      // @ts-ignore
      const v = numberField.current.value;
      setNumberError(composeValidators(() => required(v), () => isCardNumber(v)));
    }
    if (expireError && expireField.current) {
      // @ts-ignore
      const v = expireField.current.value;
      setExpireError(composeValidators(() => required(v), () => isValidCardExpires(v)));
    }
    if (cvvError && cvvField.current) {
      // @ts-ignore
      const v = cvvField.current.value;
      setCvvError(composeValidators(() => required(v), () => lengthRequired(v, 3)));
    }
    if (holderError && holderField.current) {
      // @ts-ignore
      const v = holderField.current.value;
      setHolderError(composeValidators(() => required(v), () => maxLength(v, 64)));
    }
  }, [language])

  function scSend(request: any, data3d: I3DsData, reinit = false) {
    setPayReq(request);
    setAuth3d(true);
    if (reinit) {
      return initPayment();
    }
    clearTimeout(reinitTimeout);
    const { sessionToken, merchantSiteId, merchantId } = data3d;
    const auth3dReq: IAuth3dRequest = {
      sessionToken,
      merchantId,
      merchantSiteId,
      // @ts-ignore
      currency: order.paymentCurrency,
      amount: order.paymentAmount.toString(),
      userTokenId: customer.id.toString(),
      paymentOption: {
        card: {
          cardNumber: request.number,
          cardHolderName: request.cardholder,
          expirationMonth: request.monthExpire,
          expirationYear: '20' + request.yearExpire,
          CVV: request.securityCode
        }
      }
    };
    // @ts-ignore
    const sfc = window.SafeCharge({ env: data3ds.env });
    sfc.authenticate3d(auth3dReq, function (res: any) {
      setAuth3d(false);
      if (res.result === 'ERROR' || (res.status && res.status !== 'SUCCESS') || !['5', '2'].includes(res.eci?.toString())) {
        Sentry.captureException(res, { tags: { section: 'SC authenticate3d' }})
        if (res.result === 'ERROR') {
          return setServerError('cardProcessingError');
        }
      }
      const { cavv, eci, xid, dsTransID } = res;
      payNew({
        ...request,
        authentication: btoa(`${sessionToken};${cavv};${eci};${xid};${dsTransID}`)
      })
    })
  }

  useEffect(() => {
    if (payReq && data3ds) {
      scSend({ ...payReq, paymentId }, data3ds);
    }
  }, [paymentId, data3ds])

  return (
    <Loader loading={loading || processing || processingPay || setSaved || getting3ds || auth3d}>
      <div className={classes.root}>
        {serverError.length > 0 && <ServerMessage type="error" text={serverError} />}
        {form3ds.action
          ? (
            <ThreeDS form={form3ds} className={classes.frame} />
          ) : (
            <form
              className={classes.holder}
              onSubmit={(e) => {
                e.preventDefault();
                // @ts-ignore
                const securityCode = cvvField.current.value;
                const cvvErr = (composeValidators(required, (v: any) => lengthRequired(v, 3)))(securityCode);

                if (!!cardId && !cvvErr) {
                  // @ts-ignore
                  return pay({ paymentId, cardId: parseInt(cardId), securityCode });
                }

                // @ts-ignore
                const expireVal = expireField.current.value;
                const expErr = (composeValidators(required, isValidCardExpires))(expireVal);
                // @ts-ignore
                const numVal = numberField.current.value;
                const numErr = (composeValidators(required, isCardNumber))(numVal);
                // @ts-ignore
                const holderVal = holderField.current.value;
                const holderErr = (composeValidators(required, (v: any) => maxLength(v, 64)))(holderVal);

                let errorFocused = false;
                if (!!numErr) {
                  setNumberError(numErr);
                  // @ts-ignore
                  numberField.current.focus();
                  errorFocused = true;
                }
                if (!!expErr) {
                  setExpireError(expErr);
                  if (!errorFocused) {
                    // @ts-ignore
                    expireField.current.focus();
                    errorFocused = true;
                  }
                }
                if (!!cvvErr) {
                  setCvvError(cvvErr);
                  if (!errorFocused) {
                    // @ts-ignore
                    cvvField.current.focus();
                    errorFocused = true;
                  }
                }
                if (!!holderErr) {
                  setHolderError(holderErr);
                  if (!errorFocused) {
                    // @ts-ignore
                    holderField.current.focus();
                    errorFocused = true;
                  }
                }
                if (errorFocused) {
                  return;
                }
                const expire = expireVal.split('/');
                const monthExpire = expire[0];
                const yearExpire = parseInt(expire[1].length === 2 ? expire[1] : expire[1].substring(2, 4));
                // @ts-ignore
                const number = numberField.current.value.replace(/\s/g, '');
                // @ts-ignore
                const cardholder = holderField.current.value;
                const payReq = {
                  paymentId,
                  securityCode,
                  number,
                  cardholder,
                  monthExpire,
                  yearExpire,
                  // @ts-ignore
                  save: !isSafeCharge && rememberField.current.checked
                };

                if (isSafeCharge && data3ds) {
                  scSend(payReq, data3ds, needReInit);
                } else {
                  payNew(payReq);
                }
              }}
            >
              {cardDelConfirm && !!cardId && (
                <CardDeletePopup
                  onConfirm={() => {
                    // @ts-ignore
                    deleteCard({ id: parseInt(cardId) })
                  }}
                  onCancel={() => {
                    setCardDelConfirm(false);
                    setDeleteError('');
                  }}
                  deleting={cardDeleting}
                  // @ts-ignore
                  card={cards.find((c: any) => c.id === parseInt(cardId))}
                  error={deleteError}
                />
              )}
              {cards.length > 0 && (
                <div className={classes.saved}>
                  <div className="cardSelect">
                    <Select
                      name="cardId"
                      label={t('Select card')}
                      options={
                        loading ? [] : [{ value: '', label: t('New card') }].concat(cards.map((c: any) => ({ value: c.id, label: c.number })))
                      }
                      onChange={(e: any) => {
                        const val = e.target.value;
                        if (val.length) {
                          setCardId(val);
                          // @ts-ignore
                          cvvField.current.focus();
                        } else {
                          setCardId(undefined);
                        }
                        setSetSaved(true);
                        setTimeout(() => setSetSaved(false), 300);
                      }}
                      value={cardId}
                      loading={loading}
                    />
                  </div>
                  {cardId && (
                    <Button
                      onClick={() => setCardDelConfirm(true)}
                      variant="text"
                      className={globalClasses.button}
                      color="primary"
                    >
                      {t('Delete card')}
                    </Button>
                  )}
                </div>
              )}
              <div className={classes.form}>
                <div className={clsx(classes.card, classes.frontSide)}>
                  <div className={clsx('systems', cardType)} />
                  <div className="cardNumber">
                    <TextField
                      name="cardnumber"
                      id={!cardId ? 'cardnumber' : undefined}
                      label={t('Card number')}
                      fullWidth
                      type="text"
                      disabled={!!cardId}
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      autoFocus
                      placeholder="0000 0000 0000 0000"
                      // @ts-ignore
                      defaultValue={!!cardId ? cards.find((c: any) => c.id === parseInt(cardId)).number : ''}
                      // @ts-ignore
                      // value={!!cardId ? cards.find((c: any) => c.id === parseInt(cardId)).number : undefined}
                      inputRef={numberField}
                      onKeyUp={(e: any) => {
                        const val = e.target.value;
                        const cData = cardValidator.number(val);
                        if (cData && cData.card) {
                          const type = cData.card.type;
                          setCardType(type);
                          if (!['visa', 'mastercard', 'maestro'].includes(type)) {
                            return setServerError('cardNotSupported');
                          }
                          setServerError('');
                          if (isCardNumber(val) === undefined) {
                            // @ts-ignore
                            expireField.current.focus();
                          }
                        } else {
                          setCardType('');
                        }
                      }}
                      onBlur={(e: any) => {
                        const v = e.target.value;
                        setNumberError(composeValidators(() => required(v), () => isCardNumber(v)))
                      }}
                      error={!cardId && !!numberError}
                      helperText={!cardId && numberError}
                      tabIndex={1}
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  </div>
                  <div className="dateAndName">
                    <div className="cardExpire">
                      <TextField
                        name="exp-date"
                        id={!cardId ? 'expires' : undefined}
                        label={t('Valid thru')}
                        fullWidth
                        type="text"
                        disabled={!!cardId}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"
                        defaultValue={!!cardId ? '**/**' : undefined}
                        inputRef={expireField}
                        placeholder="00/00"
                        onKeyUp={(e: any) => {
                          const val = e.target.value;
                          if (val && val.length === 5 && isValidCardExpires(val) === undefined) {
                            // @ts-ignore
                            holderField.current.focus();
                          }
                        }}
                        onBlur={(e: any) => {
                          const val = e.target.value;
                          setExpireError(composeValidators(() => required(val), () => isValidCardExpires(val)))
                        }}
                        error={!cardId && !!expireError}
                        helperText={!cardId && expireError}
                        tabIndex={2}
                        inputProps={{ inputMode: 'numeric' }}
                      />
                    </div>
                    <div className="cardHolder">
                      <TextField
                        type="text"
                        name="ccname"
                        label={t('Cardholder name')}
                        fullWidth
                        placeholder="JOHN DOE"
                        inputRef={holderField}
                        required={false}
                        disabled={!!cardId}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"
                        defaultValue={!!cardId ? '*** ***' : undefined}
                        tabIndex={3}
                        onBlur={(e: any) => {
                          const val = e.target.value;
                          setHolderError(composeValidators(() => required(val), () => maxLength(val, 64)))
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={clsx(classes.card, classes.backSide)}>
                  <div className="magnitLine" />
                  <div className="cvv">
                    <TextField
                      name="secureCode"
                      label={t('CVV')}
                      id="cvvField"
                      type="text"
                      fullWidth
                      placeholder="000"
                      required={false}
                      inputRef={cvvField}
                      InputLabelProps={{ shrink: true }}
                      variant="filled"
                      onKeyUp={(e: any) => {
                        const val = e.target.value;
                        if (val && val.length === 3 && !cardId) {
                          setCvvError(undefined);
                          if (rememberField.current) {
                            // @ts-ignore
                            rememberField.current.focus();
                          }
                        }
                      }}
                      onBlur={(e: any) => {
                        const val = e.target.value;
                        setCvvError(composeValidators(() => required(val), () => lengthRequired(val, 3)))
                      }}
                      error={!!cvvError}
                      helperText={cvvError}
                      autoFocus={!!cardId}
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  </div>
                  <Typography variant="body2" component="div" className="helper">{t('cvvHelp')}</Typography>
                </div>
              </div>
              {!cardId && !isSafeCharge && (
                <div className={globalClasses.checkBox}>
                  <CBoxBase
                    name="save"
                    label={t('rememberCard')}
                    inputRef={rememberField}
                  />
                </div>
              )}
              <div className={classes.actions}>
                <Button
                  className={globalClasses.button}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t('Pay')}
                </Button>
              </div>
            </form>
          )
        }
      </div>
    </Loader>
  );
}
