interface IGetRequestError {
  error: any
  defaultError?: string
}

export const getRequestError = ({
  error,
  defaultError = 'Error',
}: IGetRequestError): string => {
  console.error(error)
  if (error.isAxiosError) {
    return error.response?.data?.error?.message ?? defaultError
  }

  return defaultError
}
