import countries from 'i18n-iso-countries'
import cRu from 'i18n-iso-countries/langs/ru.json'
import cEn from 'i18n-iso-countries/langs/en.json'
import cPt from 'i18n-iso-countries/langs/pt.json'
import cFr from 'i18n-iso-countries/langs/fr.json'

countries.registerLocale(cEn)
countries.registerLocale(cRu)
countries.registerLocale(cPt)
countries.registerLocale(cFr)

export default countries
