import React from 'react'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useSelector } from 'react-redux'
// import LogoUtpay from '../Layout/img/Logo_utPay.svg'
import { getLogo, isChainValley, isUtpay } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

const useStyles = makeStyles({
  root: {
    width: `${isUtpay || isChainValley ? `96px` : `81px`}`,
    height: `${isUtpay || isChainValley ? `36px` : `19px`}`,
    background: `center/contain url("${getLogo(MIRROR)}") no-repeat`,
  },
  external: {
    height: 40,
  },
})

export default function SiteLogo({ className }: any) {
  const classes = useStyles()
  const { uiLoaded, ui } = useSelector((state: any) => state.common)

  if (uiLoaded) {
    return ui.global.logo ? (
      <img src={ui.global.logo} className={clsx(classes.external, className)} />
    ) : (
      <div className={clsx(classes.root, className)} />
    )
  }

  return null
}
