import React, {useCallback} from 'react';
import clsx from 'clsx';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SelfiePassportIcon from '../../icons/selfie.svg';
import IDIcon from '../../icons/idPhoto.svg';
import PassportIcon from '../../icons/passportPhoto.svg';
import SelfieIdIcon from '../../icons/selfieId.svg';
import ResidenceIcon from '../../icons/residence.svg';
import IdBackIcon from '../../icons/idBackPhoto.svg';
import { IdPhotoType, PhotoTypes } from '../../views/Exchange/forms/VerificationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '2px dashed rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    textAlign: 'center',
    background: 'rgba(125, 147, 168, 0.08) center 30px no-repeat',
    paddingTop: 160,
    paddingBottom: 40,
    height: 270,
    '&.id-id, &.id-driverLicense': {
      backgroundImage: `url(${IDIcon})`
    },
    '&.passport, &.id-passport': {
      backgroundImage: `url(${PassportIcon})`
    },
    '&.backside-id, &.backside-driverLicense': {
      backgroundImage: `url(${IdBackIcon})`
    },
    '&.selfie-passport': {
      backgroundImage: `url(${SelfiePassportIcon})`
    },
    '&.selfie-id': {
      backgroundImage: `url(${SelfieIdIcon})`
    },
    '&.residence': {
      backgroundImage: `url(${ResidenceIcon})`
    },
    '&.ProofOfFunds-undefined': {
      height: 'auto',
      paddingTop: 25,
      paddingBottom: 25
    },
    '&:focus': {
      outline: 'none'
    }
  },
  icon: {
    display: 'flex',
    justifyContent: 'center'
  },
  format: {
    color: 'rgba(0, 0, 0, 0.38)'
  }
}));

export interface IUploadedFile {
  name: string;
  type: string;
  size: string;
  data: string | ArrayBuffer | null;
}

interface IUploadFieldProps {
  onLoad(f: IUploadedFile): void;
  input: any;
  type: PhotoTypes;
  idType?: IdPhotoType;
  className?: any;
}

function getFileSize(s: number): string {
  let size = s / 1024;
  if (size > 1024) {
    return (size / 1024).toFixed(1) + ' MB';
  }
  return Math.ceil(size) + ' KB';
}

export default function UploadField({ onLoad, input, type, idType, className, ...rest }: IUploadFieldProps) {
  const onDrop = useCallback((files: File[]) => {
    files.forEach((f) => {
      const file: IUploadedFile = {
        name: f.name,
        type: f.type,
        size: getFileSize(f.size),
        data: null
      };
      onLoad(file);
      const reader = new FileReader();
      reader.onload = () => onLoad({
        ...file,
        data: reader.result
      });
      reader.readAsDataURL(f);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(classes.root, `${type}-${idType}`, className)}
      {...getRootProps()}
      {...rest}
    >
      <input {...getInputProps()} {...input} />
      {isDragActive
        ? <p>{t('labels.dropFile')}</p>
        : (
          <div>
            <div className={classes.icon}>
              <CloudUploadIcon color="primary" />
            </div>
            <div>
              <Button component="span" variant="text" color="primary">
                {t(input.multiple ? 'Upload documents' : 'Upload document')}
              </Button>
            </div>
            <Typography variant="body2" className={classes.format}>{t('imageFormats')}</Typography>
          </div>
        )
      }
    </div>
  );
}
