import level_1 from '@/icons/cats/level_1.png'
import level_2 from '@/icons/cats/level_2.png'
import level_3 from '@/icons/cats/level_3.png'
import level_4 from '@/icons/cats/level_4.png'

export function getImageByAmount(amount: number) {
  if (amount > 0 && amount <= 10) {
    return level_1
  }
  if (amount > 10 && amount <= 25) {
    return level_2
  }
  if (amount > 25 && amount <= 50) {
    return level_3
  }
  if (amount > 50 && amount <= 100) {
    return level_4
  }
}
