import React, { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

import BaseRestrictedScreen from './BaseRestrictedScreen'
import { useLoginStyles } from '../Login'
import { trackUsFlowInfo } from '@/helpers/analytics'
import { IOrder } from '@/api/order'
import { getSupportEmail } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

interface IStateRestrictedProps {
  order?: IOrder
  country?: string
  state?: string
}

const StateRestricted: FC<IStateRestrictedProps> = ({
  order,
  country,
  state,
}) => {
  const { t } = useTranslation()
  const classes = useLoginStyles()

  useEffect(() => {
    trackUsFlowInfo(
      'view_state_error',
      order,
      country,
      state,
      'onramp_personal_info'
    )
  }, [])

  return (
    <BaseRestrictedScreen
      title={t('validation.stateNotSupported')}
      caption={t('incorrectState')}
    >
      <p>
        <Link
          className={clsx(classes.button, 'text')}
          component={Button}
          href={getSupportEmail(MIRROR)}
        >
          {t('Contact support')}
        </Link>
      </p>
    </BaseRestrictedScreen>
  )
}

export default StateRestricted
