import React, { useEffect, useState } from 'react';
import { getParamByISO } from 'iso-country-currency';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useCustomerBillingAddressSet } from '../../../api/customer';
import { IOrder, OrderStatus, useOrderInfo } from '../../../api/order';
import { PaymentNext, PaymentSource } from '../../../api/payment';
import { useBalances } from '../../../api/private/account';
import { useCurrencies } from '../../../api/private/currency';
import { useKycDetails } from '../../../api/private/kyc';
import { OrderType, useOrderInit, useWithdrawalAccept } from '../../../api/private/orders';
import { usePrivatePaymentInit } from '../../../api/private/payment';
import Loader from '../../../components/Loader';
import PaymentFrame from '../../../components/PaymentFrame';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import { I3DsData } from '../../Exchange/forms/CardForm';
import SafariPaymentForm from '../../Payment/Safari';
import OrderInfo from './Create/Confirm';
import OrderVerification from './Create/Verification';
import OrderForm from './OrderForm';
import TXLimitError, { ILimitError } from '../../../components/TxLimitsError';
import captchaRequest from '@/helpers/captcha';
import {updateCurrencies} from "../../../store/actions/appData";
import {ICurrency} from "../../../types/currency";
import {currencyMapper} from "../../../helpers/currency";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 480,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  frame: {
    width: '100%',
    minHeight: '705px',
    border: 'none'
  },
}));

export default function OrderCreate() {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const [{ data: currencies }, currenciesLoading, loadCurrencies] = useCurrencies();
  const [{ items: balances }, balancesLoading, loadBalances] = useBalances();
  const [order, setOrder] = useState({} as IOrder);
  const [paymentData, setPaymentData] = useState({} as any);
  const [serverError, setServerError] = useState<string | undefined>();
  const [acceptError, setAcceptError] = useState('');
  const [initError, setInitError] = useState('');
  const [limitError, setLimitError] = useState<ILimitError>({ error: '', limit: '', used: '' });
  const [billing, setBilling] = useState(false);
  const [data3ds, setData3ds] = useState<I3DsData | undefined>();
  const history = useHistory();
  const { user } = useSelector((state: any) => state.account);
  const { type, step } = useParams();
  const orderType = type ? type.toUpperCase() as OrderType : OrderType.DEPOSIT;


  const [init, initing] = useOrderInit((res: any) => {
    if (res.success) {
      setOrder(res.data);
      setResult('');
      setPaymentData({} as any);
      setData3ds(undefined);
      history.push(`/account/orders/create/${orderType.toLowerCase()}/confirm`);
    } else {
      if (['TX_COUNT_LIMIT_REACHED', 'AMOUNT_LIMIT_REACHED'].includes(res.error.type)) {
        return setLimitError({
          error: `${res.error.type}_${res.error.details[0].type}`,
          limit: res.error.details[0].limitAmount ? parseFloat(res.error.details[0].limitAmount).toFixed(2) : res.error.details[0].limit,
          used: parseFloat(res.error.details[0].usedAmount).toFixed(2),
        });
      }
      if (res.error.details) {
        let errs: any = {};
        res.error.details.forEach((d: any) => {
          Object.keys(d).forEach((k) => {
            if (d[k].includes('<=')) {
              errs[k] = t('validation.maxValue', { max: d[k].split('<= ')[1] })
            } else if (d[k].includes('>=')) {
              errs[k] = t('validation.minValue', { min: d[k].split('>= ')[1] })
            } else if (d[k].includes('must be any type of enum')) {
              let field = k;
              if (k === 'currency') {
                field = 'amount';
              }
              if (k === 'paymentCurrency') {
                field = 'paymentAmount';
              }
              const currs = d[k].replace('must be any type of enum: [', '').replace(']', '').split(',');
              errs[field] = t('validation.mustCurrency', { currency: currs.join(', ') });
            } else {
              errs[k] = d[k];
            }
          })
        });
        setServerError(errs);
      } else {
        setInitError('somethingWentWrong');
      }
    }
  });
  const [getPaymentData, gettingPaymentData] = usePrivatePaymentInit(`${order.id}`, (res: any) => {
    if (res.success) {
      return setPaymentData(res.data);
    }
    if (res.error.type === 'GEODATA_NOT_RECOGNIZED') {
      return setBilling(true);
    } else if (res.error.type === 'PROVIDER_DISABLED') {
      return setAcceptError(res.error.type);
    }
    setAcceptError('somethingWentWrong');
  });
  const [acceptWithdrawal, withdrawalAccepting] = useWithdrawalAccept((res: any) => {
    if (res.success) {
      const status = res.data.status === 'ERROR' ? 'error' : 'success';
      return setResult(status);
      // return history.push(`/account/orders/finish/${orderType.toLowerCase()}/${status}`);
    }
    if (res.error.type === 'WRONG_CODE') {
      return setAcceptError('wrongCode');
    }
    setAcceptError('somethingWentWrong');
  });
  const [saveBilling, savingBilling] = useCustomerBillingAddressSet((res: any) => {
    if (res.success) {
      return getPaymentData({
        source: PaymentSource.Card,
        orderId: order.id
      })
    }
    setServerError('somethingWentWrong');
  });
  const [{ data: orderData }, loadingOrder, loadOrder] = useOrderInfo();
  const [result, setResult] = useState('');
  const [, loadingKyc, loadKyc] = useKycDetails();
  const { t } = useTranslation();

  if (orderType === OrderType.DEPOSIT && user.authorities && user.authorities.includes('ROLE_MERCHANT')) {
    history.push('/account/orders');
  }

  function sendWdAccept(data: any) {
    captchaRequest(
      '/api/private/v1/order/accept',
      acceptWithdrawal,
      data
    );
  }

  function getMessageFromFrame(msg: any) {
    try {
      const mess = JSON.parse(msg.data);
      if (mess.type !== 'paymentResult') {
        return;
      }

      setResult(mess.success ? 'success' : 'error');
    } catch (e) {
    }
  }

  useEffect(() => {
    window.addEventListener('message', getMessageFromFrame);

    return () => {
      window.removeEventListener('message', getMessageFromFrame);
    }
  }, [])

  useEffect(() => {
    loadCurrencies();
    loadBalances();
  }, [type])

  useEffect(() => {
    if (!step) {
      setOrder({} as IOrder);
      setPaymentData({} as any);
      loadBalances();
    } else if (!!step && !Object.keys(order).length) {
      history.push(`/account/orders/create/${orderType.toLowerCase()}`);
    }
  }, [step])

  useEffect(() => {
    setPaymentData({} as any);
    if (result.length) {
      if (result === 'success' && orderType === OrderType.DEPOSIT) {
        loadOrder({ id: order.id });
      } else if (result === 'verification') {
        history.push(`/account/orders/create/${orderType.toLowerCase()}/verification`);
      } else {
        window.localStorage.setItem('lo', JSON.stringify(order));
        history.push(`/account/orders/finish/${orderType.toLowerCase()}/${result}`);
      }
    }
  }, [result])

  useEffect(() => {
    if (orderData.id) {
      if (orderData.status && orderData.status === OrderStatus.VERIFICATION) {
        history.push(`/account/orders/create/${orderType.toLowerCase()}/verification`);
      } else {
        window.localStorage.setItem('lo', JSON.stringify(orderData));
        history.push(`/account/orders/finish/${orderType.toLowerCase()}/${result}`);
      }
    }
  }, [orderData])

  useEffect(() => {
    if (paymentData.token && paymentData.token.length) {
      const [sessionToken, merchantId, merchantSiteId, env] = atob(paymentData.token).split(';');
      setData3ds({
        sessionToken,
        merchantId,
        merchantSiteId,
        env
      });
    }
  }, [paymentData.token])

  if (step === 'verification') {
    return <OrderVerification type={orderType} onFinish={loadKyc} order={orderData} />;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={globalClasses.title}>{t(`account-${orderType}`)}</Typography>
      <Loader loading={loadingOrder || loadingKyc}>
        {order.id
          ? (
            <div>
              {paymentData.id
                ? (
                  <div>
                    {paymentData.nextStep === PaymentNext.ExternalUrl
                      ? <PaymentFrame paymentData={paymentData} classes={classes} />
                      : (
                        <SafariPaymentForm
                          paymentId={paymentData.id}
                          onFinish={(res: string) => {
                            setResult(res);
                            setPaymentData({} as any);
                          }}
                          order={order}
                          customer={user}
                          provider={paymentData.provider}
                          data3ds={data3ds}
                          initPayment={() => getPaymentData({
                            source: PaymentSource.Card,
                            orderId: order.id
                          })}
                        />
                      )
                    }
                  </div>
                ) : (
                  <OrderInfo
                    order={order}
                    accept={orderType === OrderType.DEPOSIT ? getPaymentData : sendWdAccept}
                    accepting={orderType === OrderType.DEPOSIT ? gettingPaymentData : withdrawalAccepting}
                    currencies={currencies}
                    type={orderType}
                    acceptError={acceptError}
                    billing={billing}
                    saveBilling={saveBilling}
                    savingBilling={savingBilling}
                  />
                )
              }
            </div>
          ) : (
            <div>
              <OrderForm
                type={orderType}
                currencies={currencies}
                currenciesLoading={currenciesLoading}
                init={init}
                initing={initing}
                formErrors={serverError}
                setFormErrors={setServerError}
                balances={balances}
                balancesLoading={balancesLoading}
                kycStatus={user.kycStatus}
                error={initError}
                currencyByCountry={getParamByISO(user.country, 'currency')}
              />
              {limitError.error.length > 0 && <TXLimitError error={limitError} isAccount />}
            </div>
          )
        }
      </Loader>
    </div>
  );
}
