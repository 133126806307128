import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setMaintenance,
  setCloudflareLimitError,
  setCustomerNotSupported,
} from '../store/actions/common'
import { setBlockedUser, setLogIn } from '../store/actions/account'
import { getAuthToken, setAuthToken, clearAuthToken } from '../api'
import isCFError from '../helpers/limitError'
import * as Sentry from '@sentry/react'
import createReport from '@/sentryReporter'

export default function useFetchApi(
  remote: any,
  initialData: any = { data: [] },
  dispatchAction?: any,
  loadOnInit = false,
  sentryIgnoreErrors: string[] = []
): [any, boolean, (args?: any) => Promise<any>] {
  const [data, setData] = useState({ ...initialData, error: {} })
  const [loading, setLoading] = useState(loadOnInit)
  const dispatch = useDispatch()

  const load = async (args?: any): Promise<any> => {
    setLoading(true)
    let res = initialData
    try {
      res = await remote(args || {}, { headers: { ...getAuthToken() } })
      dispatch(setBlockedUser(true))
    } catch (e) {
      if (e.response) {
        if (
          !e.response.data?.error &&
          e.response.status.toString().startsWith('5')
        ) {
          dispatch(setMaintenance(true))
        }
        if (e.response?.data?.error?.type === 'CUSTOMER_NOT_SUPPORTED') {
          dispatch(setCustomerNotSupported(true))
        }
        if (e.response.status.toString() === '429' && isCFError(e.response)) {
          dispatch(setCloudflareLimitError())
        }
        if (e.response.data?.error) {
          if (
            process.env.REACT_APP_SENTRY_URL &&
            !sentryIgnoreErrors.includes(e.response.data.error.type)
          ) {
            const report = createReport('useFetchApi', e)
            report && Sentry.captureException(report?.error, report?.data)
          }
          // if (e.response.data.error.type === 'ACCESS_DENIED') {
          //   dispatch(setBlockedUser());
          // }
          if (e.response.data.error.type === 'UNAUTHORIZED') {
            clearAuthToken()
            dispatch(setLogIn({}))
          }
          setData({ ...data, error: e.response.data.error })
        }
      }
      if (typeof dispatchAction === 'function') {
        dispatch(dispatchAction(initialData.data))
      }
    }

    if (res.headers && res.headers['x-auth-token']) {
      if (res.headers['x-auth-token'] === '-') {
        clearAuthToken()
        dispatch(setLogIn({}))
      } else {
        setAuthToken(res.headers['x-auth-token'])
      }
    }

    let result: any = null
    if (res.data) {
      if (res.data.error) {
        setData({ ...data, error: res.data.error })
      } else if (res.data.data !== undefined) {
        result = res.data.data
        setData({ ...data, data: result, error: {} })
        if (typeof dispatchAction === 'function') {
          dispatch(dispatchAction(result))
        }
      }
    }
    setLoading(false)
    return result
  }

  return [data, loading, load]
}
