import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { IOrder } from '../../api/order';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import ServerMessage from '../UI/ServerMessage';
import { getSupportEmail } from '@/helpers/mirrorHelper';
import { MIRROR } from '@/helpers/constants';

export interface ILimitError {
  error: string;
  limit: string;
  used: string;
}

interface IProps {
  error: ILimitError;
  failUrl?: string;
  isAccount?: boolean;
}

export default function TXLimitError({ error, failUrl, isAccount }: IProps) {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: 16 }}>
      <ServerMessage type="alert" align="v-top" isAccount={isAccount}>
        <div>
          <Typography variant="body2" color="textPrimary" style={{ marginBottom: 8 }}>
            <strong>{t('txLimitTitle')}</strong>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            dangerouslySetInnerHTML={{ __html: t(error.error, error) }}
          />
        </div>
      </ServerMessage>
      <div style={{ marginTop: 16 }}>
        <Button
          className={globalClasses.button}
          href={getSupportEmail(MIRROR)}
          variant="contained"
          color="primary"
          id="back-to-merchant-fail"
        >
          {t('Contact support')}
        </Button>
      </div>
      {failUrl && (
        <div style={{ marginTop: 16 }}>
          <Button
            className={globalClasses.button}
            href={failUrl}
            variant="contained"
            color="primary"
            id="back-to-merchant-fail"
          >
            {t('failBack')}
          </Button>
        </div>
      )}
    </div>
  )
}
