import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isMobile } from 'react-device-detect';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Modal from '@material-ui/core/Modal';
import {PhotoTypes} from '../VerificationForm';

const useStyles = makeStyles((theme) => ({
  root: {},
  camera: {
    position: 'absolute',
    width: isMobile ? '100%' : '800px',
    height: isMobile ? '100%' : '450px',
    left: isMobile ? 0 : '50%',
    top: isMobile ? 0 : 10,
    margin: isMobile ? 0 : '0 auto auto -400px',
    background: isMobile ? 'white' : 'transparent',
    '&:focus': {
      outline: 'none'
    }
  },
  camWrap: {
    marginBottom: theme.spacing(2)
  },
  camBtn: {
    margin: '0 auto',
    display: 'block'
  },
  mobileCamBtn: {
    position: 'fixed',
    bottom: '10px',
    width: '90%',
    left: '50%',
    marginLeft: '-45%',
  }
}));

interface IProps {
  open: boolean;
  onClose: any;
  type: PhotoTypes;
  onShoot: any;
}

export default function Camera({ open, onClose, type, onShoot }: IProps) {
  const classes = useStyles();
  const webCam = useRef(null);
  const { t } = useTranslation();

  const captureWebCam = React.useCallback(
    () => {
      // @ts-ignore
      const photo = webCam.current.getScreenshot() || '';
      onShoot(photo);
      onClose(false);
    },
    [webCam]
  );

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <div className={classes.camera}>
        <div className={classes.camWrap}>
          <Webcam
            audio={false}
            ref={webCam}
            screenshotFormat="image/jpeg"
            // @ts-ignore
            width={isMobile ? window.innerWidth : 800}
            minScreenshotHeight={1024}
            minScreenshotWidth={1280}
            screenshotQuality={1}
            videoConstraints={{
              width: 1920,
              height: 1080,
              facingMode: type === PhotoTypes.ID ? 'environment' : 'user'
            }}
          />
        </div>
        <Button
          onClick={captureWebCam}
          variant="contained"
          type="button"
          color="primary"
          className={clsx({[classes.camBtn]: true, [classes.mobileCamBtn]: isMobile})}
        >{t('Take picture')}</Button>
      </div>
    </Modal>
  );
}
