import useFetchApi from '../../hooks/useFetch';
import Api, { PrivateApi, usePrivateFetchApi } from '../index';
import useUpdateApi from '../../hooks/useUpdate';
import { setUserKyc } from '../../store/actions/account';

export enum DocumentType {
  ID_CARD_SELFIE = 'ID_CARD_SELFIE',
  ID_CARD = 'ID_CARD',
  RESIDENCE = 'RESIDENCE',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD_BACKSIDE = 'ID_CARD_BACKSIDE',
  DRIVING_LICENSE_BACKSIDE = 'DRIVING_LICENSE_BACKSIDE',
  PROOF_OF_FUND = 'PROOF_OF_FUND'
}

export function useKycDetails() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/kyc/info', ...data),
    { data: {} },
    setUserKyc,
    'KYC application was not submitted',
    false,
    ['NOT_FOUND']
  );
}

export function usePrivateKycRequest(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/kyc/init', ...data),
    cb
  );
}

export function useKycFlow() {
  return usePrivateFetchApi(
    async (...data: any) => PrivateApi.post('/kyc/flow', ...data),
    { data: {} }
  )
}

export function useKycToken() {
  return useFetchApi(
    async (...data: any) => PrivateApi.post('/kyc/getAuthToken', ...data),
    { data: '' },
    undefined,
    true
  )
}

export function useKycTokenRefresh() {
  return useFetchApi(
    async (...data: any) => PrivateApi.post('/kyc/getAuthToken', ...data),
    { data: '' }
  )
}

export function useKycDocumentsRequest(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/kyc/documents/add', ...data),
    cb
  );
}
