import React, { useRef, FC, ReactElement } from 'react';
import { Field } from 'react-final-form';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectProps } from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface ISelectOption {
  value: string;
  label: string;
  [key: string]: string
}

interface ISelectProps extends SelectProps {
  input: any;
  helperText?: string;
}

const SelectWrapper: FC<ISelectProps> = (props) => {
  const inputLabel = useRef(null);
  const {
    input: {
      name, value, onChange, ...restInput
    },
    label,
    helperText,
    native = true,
    ...rest
  } = props;
  return (
    <FormControl fullWidth variant="filled">
    {label && <InputLabel htmlFor={name} ref={inputLabel} variant="filled">{label}</InputLabel>}
    <Select
      native={native}
      name={name}
      inputProps={restInput}
      value={value}
      onChange={onChange}
      variant="filled"
      MenuProps={{
        translate: "no"
      }}
      {...rest}
    />
    {helperText && <FormHelperText variant="filled">{helperText}</FormHelperText>}
    </FormControl>
  );
}

export interface ISelectFieldProps extends Partial<ISelectProps> {
  name: string;
  options: ISelectOption[];
  validate?: any;
  disabled?: boolean;
  loading?: boolean;
  inputRef?: any;
  onFocus?: any;
  onBlur?: any;
  useEmptyOption?: boolean;
  className?: any;
  renderOption?: (option: ISelectOption | null) => ReactElement;
}

function Loading() {
  return <CircularProgress size={20} style={{ marginRight: 10 }} />;
}

const SelectField: FC<ISelectFieldProps> = ({
  name,
  label,
  helperText,
  validate,
  disabled,
  loading,
  inputRef,
  onFocus,
  onBlur,
  className,
  useEmptyOption,
  options,
  renderOption = (o) => {
    if(!o) {
      return  <option />
    }

    return <option value={o.value} key={o.value}>{o.label}</option>
  },
  ...props
}) => {
  return (
    <Field
      name={name}
      // @ts-ignore
      component={SelectWrapper}
      label={label}
      helperText={helperText}
      validate={validate}
      disabled={disabled}
      IconComponent={loading ? Loading : disabled ? () => null : ExpandMoreIcon}
      inputRef={inputRef}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      {...props}
    >
    {useEmptyOption && renderOption(null)}
    {options.map(renderOption)}
    </Field>
  );
}

export default SelectField
