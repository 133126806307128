import React, { FC, PropsWithChildren, useState } from 'react'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // justifyContent: 'space-between',
    borderBottom: '1px solid #F8F8FA',
    padding: '12px 0',
    cursor: 'pointer',
  },
  icon: {
    width: 18,
    height: 18,
    color: '#B2B9C8',
    '&.left': {
      marginRight: 10,
    },
    '&.right': {
      marginLeft: 10,
    },
  },
  content: {
    flex: 1,
    color: '#7F8AAC',
  },
}))

interface IPayoutAlert {
  title: string
  text: string
}

export const PayoutAlert: FC<IPayoutAlert> = ({
  title,
  text,
}) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const Button = open ? ExpandLess : ExpandMore

  return (
    <div className={classes.root} onClick={() => setOpen(!open)}>
      <ErrorOutline className={clsx(classes.icon, 'left')} />
      <Typography
        className={classes.content}
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: open ? text : title,
        }}
        component="div"
      />
      <Button className={clsx(classes.icon, 'right')} />
    </div>
  )
}
