import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import Loader from '@/components/Loader'
import Layout from '@/components/Layout'

export default function PaymentStatus() {
  const history = useHistory()

  const orderId = window.localStorage.getItem('orderId')
  const paymentId = window.localStorage.getItem('paymentId')

  useEffect(() => {
    if (orderId && paymentId) {
      history.push(`/order/${orderId}/pending`)
    }
  }, [orderId, paymentId])

  return (
    <Layout>
      <Loader loading={true} height={400} />
    </Layout>
  )
}
