import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import cookies from 'react-cookies';

interface IReferralUrl {
  referralId: string;
  fromCurrency: string;
  toCurrency: string;
}

export default function RedirectToReferralFlow() {
  const { referralId, fromCurrency, toCurrency } = useParams<IReferralUrl>();
  const history = useHistory();

  useEffect(() => {
    if (referralId) {
      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      cookies.save('r', referralId, { expires, path: '/' });
    }
    history.push('/exchange/transaction', {
      fromCurrency: fromCurrency.toUpperCase(),
      toCurrency: toCurrency.toUpperCase()
    });
  }, [referralId, fromCurrency, toCurrency])

  return <div />;
}
