import React from 'react'

import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useGlobalStyles from '@/hooks/useGlobalStyles'
import successIcon from '@/icons/resultIcons/success.svg'
import errorIcon from '@/icons/resultIcons/error.svg'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01em',
    maxWidth: '380px',
  },
  content: {
    'padding': '0px',
    '&:first-child': {
      padding: '0px',
    },
    'marginBottom': theme.spacing(6),
  },
  icon: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.5,
    letterSpacing: '0.01em',
    marginBottom: theme.spacing(1),
  },
  errorTitle: {
    marginBottom: theme.spacing(3),
  },
  requestInfo: {
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.02em',
    marginBottom: theme.spacing(3),
  },
  id: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  actionsButtons: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  button: {
    width: '100%',
    margin: '0px',
    height: '52px',
    maxWidth: '100%',
  },
  actions: {
    padding: '0px',
    display: 'block',
  },
  modal: {
    width: '449px',
    padding: '42px 24px 26px',
    background: '#fff',
    borderRadius: 8,
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down(515)]: {
      width: '100%',
    },
  },
  check: {
    'backgroundColor': 'rgba(125, 147, 168, 0.08)',
    'color': 'rgba(0, 0, 0, 1)',
    '&:hover': {
      backgroundColor: 'rgba(125, 147, 168, 0.12)',
    },
  },
}))

interface ISettlementRequestResult {
  closeModal: () => void
  isOpen: boolean
  settlement: any
}

function SettlementRequestResult({
  closeModal,
  isOpen,
  settlement,
}: ISettlementRequestResult) {
  const history = useHistory()

  const { t } = useTranslation()

  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  return (
    <Dialog open={isOpen}>
      <div className={classes.modal}>
        <DialogContent className={classes.content}>
          <div className={classes.icon}>
            {settlement?.id ? (
              <img src={successIcon} />
            ) : (
              <img src={errorIcon} />
            )}
          </div>
          <Typography
            variant="h6"
            align="center"
            className={clsx(
              classes.title,
              !settlement?.id && classes.errorTitle
            )}
          >
            {settlement?.id
              ? t('settlements.successTitle')
              : t('settlements.errorTitle')}
          </Typography>
          {settlement?.id && (
            <Typography
              variant="body1"
              align="center"
              className={classes.requestInfo}
            >
              <span className={classes.id}>{t('settlements.requestID')} </span>#
              {settlement.id}
            </Typography>
          )}
          <Typography
            variant="body1"
            align="center"
            className={classes.subtitle}
          >
            {settlement?.id
              ? t('settlements.successSubtitle')
              : settlement?.error?.message}
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.actions}>
          <div className={classes.actionsButtons}>
            {settlement?.id && (
              <Button
                className={clsx(
                  globalClasses.button,
                  classes.button,
                  classes.check
                )}
                color="primary"
                variant="contained"
                id="check-settlement"
                onClick={() => history.push('/account/settlements')}
              >
                {t('settlements.checkHistory')}
              </Button>
            )}

            {!settlement?.id && (
              <Link
                className={clsx(globalClasses.button, 'text')}
                component={Button}
                href="mailto:support@utorg.pro"
              >
                {t('Contact support')}
              </Link>
            )}

            <Button
              className={clsx(globalClasses.button, classes.button)}
              color="primary"
              variant="contained"
              id="close-settlement"
              onClick={() => closeModal()}
            >
              {t('settlements.close')}
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}
export default SettlementRequestResult
