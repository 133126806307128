import { IAPM } from '@/api/settings'
import logo2C2P from '../icons/apm/2C2P.svg';
import ApplePay from '../icons/apm/ApplePay.svg';
import MasterCard from '../icons/apm/MasterCard.svg';
import NoLogo from '../icons/apm/NoLogo.svg';
import PayPal from '../icons/apm/PayPal.svg';
import Sofort from '../icons/apm/Sofort.png';
import UPI from '../icons/apm/UPI.svg';
import Visa from '../icons/apm/Visa.svg';
import { isChainValley } from './mirrorHelper'

export default {
  "2C2P": {
    name: "apmgw_2C2P_APMs",
    logo: logo2C2P
  },
  "ApplePay": {
    name: "ApplePay",
    logo: ApplePay
  },
  "MasterCard": {
    name: "MasterCard",
    logo: MasterCard
  },
  "NoLogo": {
    name: "NoLogo",
    logo: NoLogo
  },
  "apmgw_expresscheckout": {
    name: "PayPal",
    logo: PayPal
  },
  "apmgw_Sofort": {
    name: "Sofort (Klarna)"
  },
  "apmgw_UPI": {
    name: "UPI",
    logo: UPI
  },
  "Visa": {
    name: "Visa",
    logo: Visa
  },
  "apmgw_Netbanking_TW": {
    name: "Netbanking",
    logo: NoLogo
  },
  "apmgw_NeoSurf_Direct_Integration_": {
    name: "NeoSurf"
  }
}

export const filteredApms = (apms: IAPM[]) => {
  if (isChainValley) {
    return apms.filter(
      (apm) => !['OBI', 'googlepay', 'applepay'].includes(apm.id)
    )
  }

  return apms.filter(
    (apm) => !['SFT_WLT', 'SFT', 'googlepay', 'applepay'].includes(apm.id)
  )
}
