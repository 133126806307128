import { useSelector } from 'react-redux'

export const useCurrencyNameMapper = () => {
  const map = useSelector((state: any) => {
    return state.appData.currencies.map
  })

  return (currency: string) => {
    const currencyObject = map[currency]
    return currencyObject?.display ?? currency
  }
}

export default useCurrencyNameMapper
