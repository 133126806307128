import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import clsx from 'clsx'

import useCurrencyNameMapper from '../../../hooks/useCurrencyNameMapper'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import { qaAttr } from '../../../helpers/qaHelpers'
import countryNames from '../../../helpers/country'
import { ILangType } from '../../../locales'
import makeStyles from '@material-ui/styles/makeStyles/makeStyles'
import warningIcon from '../../../icons/warning.svg'
import Typography from '@material-ui/core/Typography'
import { getSupportEmail } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

interface ICurrencyRestricted {
  currency: string
  country: string
  fallBackUrl?: string
}

const CurrencyRestricted: FC<ICurrencyRestricted> = ({
  fallBackUrl,
  currency,
  country,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const globalClasses = useGlobalStyles()
  const localClasses = useStyles()

  const currencyNameMapper = useCurrencyNameMapper()
  const lang = language.split('-')[0] as ILangType

  const countryName = useMemo(() => {
    return countryNames.getNames(lang)[country]
  }, [lang, country])

  return (
    <div className={localClasses.root}>
      <Typography variant="h6" align="center" className={localClasses.title}>
        {t('restrictedCurrencyIsCountry.title')}
      </Typography>
      <Typography variant="body1" align="center" className={localClasses.info}>
        {t('restrictedCurrencyIsCountry.caption', {
          crypto: currencyNameMapper(currency),
          country: countryName,
        })}
      </Typography>
      {fallBackUrl && (
        <Button
          className={globalClasses.button}
          href={fallBackUrl}
          variant="contained"
          color="primary"
          {...qaAttr('unavailable-currency-back-btn')}
        >
          {t('failBack')}
        </Button>
      )}
      <p>
        <Link
          className={clsx(globalClasses.button, 'text')}
          component={Button}
          href={getSupportEmail(MIRROR)}
        >
          {t('Contact support')}
        </Link>
      </p>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 75,
    background: `url("${warningIcon}") no-repeat center top`,
  },
  title: {
    marginBottom: '24px !important',
  },
  info: {
    marginBottom: '48px !important',
  },
}))

export default CurrencyRestricted
