import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useKycRequest } from '../../../api/kyc'
import { OrderStatus, useOrder } from '../../../api/order'
import { useKycFlow } from '../../../api/private/kyc'
import { Checkbox } from '../../../components/Form'
import Loader from '../../../components/Loader'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import ProofOfFunds from '../../ProofOfFunds'
import { IExchangeFormProps } from '../index'
import useFormStyles from './useFormStyles'
import VerificationDocument from './verification/Document'
import DocumentTypeIcon from './verification/DocumentTypeIcon'
import SumSub from './verification/SumSub'
import { INTERNAL_LINK_TARGET_BLANK } from '../../../helpers/internalTarget'
import { getKycAmlPolicyLink } from '@/helpers/mirrorHelper'
import { MIRROR } from '@/helpers/constants'

export const useVerificationStyles = makeStyles((theme) => ({
  root: {},
  document: {
    'padding': `${theme.spacing(7)}px 0`,
    'borderBottom': `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  files: {
    maxWidth: 900,
  },
  text: {
    maxWidth: 500,
  },
  title: {},
  field: {
    maxWidth: 400,
  },
  item: {
    'marginBottom': theme.spacing(2),
    '& button': {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  image: {
    width: isMobile ? `${window.innerWidth - theme.spacing(4)}px` : 380,
    display: 'block',
    margin: '0 auto',
  },
  actions: {
    marginTop: theme.spacing(2),
    width: 480,
  },
  correction: {
    color: theme.palette.warning.main,
  },
  camera: {
    'position': 'absolute',
    'width': isMobile ? '100%' : '800px',
    'height': isMobile ? '100%' : '490px',
    'left': isMobile ? 0 : '50%',
    'top': isMobile ? 0 : '50%',
    'margin': isMobile ? 0 : '-245px auto auto -400px',
    'background': isMobile ? 'white' : 'transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  camWrap: {
    marginBottom: theme.spacing(2),
  },
  camBtn: {
    margin: '0 auto',
    display: 'block',
  },
  mobileCamBtn: {
    position: 'fixed',
    bottom: '10px',
    width: '90%',
    left: '50%',
    marginLeft: '-45%',
  },
  documentTypeSelect: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  documentTypeItem: {
    'width': 150,
    'marginRight': theme.spacing(2),
    'marginBottom': theme.spacing(6),
    'border': '1px solid rgba(0, 0, 0, 0.12)',
    'borderRadius': 12,
    'padding': '20px 10px 20px',
    'textAlign': 'center',
    'cursor': 'pointer',
    'transition': 'all 300ms ease 0s',
    '& svg': {
      margin: '0 auto',
    },
    '&:last-child': {
      marginRight: 0,
    },
    '&.active': {
      'border': '2px solid #2F66E3 !important',
      'backgroundColor': 'rgba(47, 102, 227, 0.08) !important',
      'cursor': 'default',
      '& svg > *': {
        stroke: '#2F66E3',
      },
    },
    '&:hover': {
      background: 'rgba(125, 147, 168, 0.08)',
      borderColor: '#A3ABBC',
    },
  },
}))

export enum PhotoTypes {
  ID = 'id',
  Selfie = 'selfie',
  Backside = 'backside',
  Residence = 'residence',
  ProofOfFunds = 'proofOfFunds',
}

export enum IdPhotoType {
  ID = 'id',
  DriverLicense = 'driverLicense',
  Passport = 'passport',
}

interface IProps extends IExchangeFormProps {
  paymentResult?: string,
  apm?: string
}

export default function VerificationForm({
  setStep,
  t,
  customer,
  setCustomer,
  order,
  apm,
  ui: {
    form: { buttonColor, borderRadius },
  },
  paymentResult,
}: IProps) {
  const viewIntervalRef = useRef<any>()
  const formClasses = useFormStyles({ buttonColor, borderRadius })
  const globalClasses = useGlobalStyles()
  const classes = useVerificationStyles()
  const { step } = useParams()
  const isReconfirm = step === 'kyc-corrections'
  const isPreVerify = step === 'pre-verification'
  const isBeforePayment = isPreVerify || isReconfirm
  const [idPhoto, setIdPhoto] = useState('')
  const [selfiePhoto, setSelfiePhoto] = useState('')
  const [backsidePhoto, setBacksidePhoto] = useState('')
  const [currentStep, setCurrentStep] = useState('')
  const [serverError, setServerError] = useState('')
  const [idPhotoType, setIdPhotoType] = useState('passport')
  const [{ data: orderInfo }, loadingOrderInfo, loadOrderInfo] = useOrder()

  const [send, sending] = useKycRequest((res: any) => {
    if (res.success) {
      if (setCustomer) setCustomer({ ...customer, kycStatus: 'NEW' })
      if (
        isBeforePayment &&
        [OrderStatus.NEW, OrderStatus.ACCEPTED].includes(order.status)
      ) {
        return setStep('card')
      }
      return setStep(paymentResult || 'success')
    }
    setServerError('somethingWentWrong')
  })
  const [
    {
      data: { required, flow },
    },
    loadingFlow,
    loadFlow,
  ] = useKycFlow()

  useEffect(() => {
    if ([OrderStatus.ERROR, OrderStatus.EXPIRED].includes(order.status)) {
      return setStep('error')
    }
    if (order.id) {
      loadOrderInfo({ id: order.id })
    }
  }, [order.id, order.status])

  useEffect(() => {
    if (!orderInfo.id) {
      return
    }

    if (
      [
        OrderStatus.ACCEPTED,
        OrderStatus.EXECUTED,
        OrderStatus.SUSPICIOUS,
        OrderStatus.VERIFICATION,
      ].includes(orderInfo.status)
    ) {
      loadFlow({ orderId: orderInfo.id, apmId: apm })
    }

    if (OrderStatus.PROCESSING === orderInfo.status) {
      viewIntervalRef.current = setInterval(() => {
        loadOrderInfo({ id: orderInfo.id })
      }, 3000)
    }

    if (
      paymentResult?.toUpperCase() === OrderStatus.SUCCESS &&
      ['CARD', 'blik'].includes(orderInfo?.apm)
    ) {
      setStep('success')
    }

    if (OrderStatus.ERROR === orderInfo.status) {
      setStep('error')
    }

    return () => {
      clearInterval(viewIntervalRef.current)
    }
  }, [orderInfo.id, orderInfo.status])

  useEffect(() => {
    if (required === false) {
      setStep(isBeforePayment ? 'card' : paymentResult || 'success')
    }
  }, [required])

  function changeIdType(type: IdPhotoType) {
    setIdPhotoType(type)
    setIdPhoto('')
    setBacksidePhoto('')
  }

  if (flow !== undefined && flow !== 'pof') {
    return (
      <SumSub
        customer={customer}
        setStep={setStep}
        setCustomer={setCustomer}
        order={order}
        reconfirm={isReconfirm}
        preVerify={isPreVerify}
        // kycFlow={flow}
        paymentResult={paymentResult}
      />
    )
  }

  if (flow === 'pof') {
    return (
      <ProofOfFunds
        customer={customer}
        setCustomer={(c) => (setCustomer ? setCustomer(c) : null)}
        setStep={setStep}
        paymentResult={paymentResult}
      />
    )
  }

  return (
    <Loader loading={sending || loadingFlow || required === undefined}>
      <Form
        onSubmit={() => {
          const id = idPhoto.split(',')[1]
          const data = {
            [idPhotoType]:
              idPhotoType === IdPhotoType.Passport
                ? id
                : { front: id, back: backsidePhoto.split(',')[1] },
            selfie: selfiePhoto.split(',')[1],
          }
          send(data)
        }}
        initialValues={{ terms: false }}
        render={({ handleSubmit, form, invalid }) => (
          <div>
            {!currentStep.length && (
              <div>
                <Typography variant="h4" className={globalClasses.title}>
                  {t('getVerified')}
                </Typography>
                <Typography variant="body2" className={globalClasses.formTitle}>
                  {t('toCompleteTransaction')}
                </Typography>
                <Typography
                  variant="body2"
                  className={clsx(
                    globalClasses.subtitle,
                    globalClasses.formTitle
                  )}
                >
                  {t('verificationDescription')}
                </Typography>
                <div className={classes.documentTypeSelect}>
                  <div
                    className={clsx(classes.documentTypeItem, 'passport', {
                      active: idPhotoType === IdPhotoType.Passport,
                    })}
                    onClick={() => changeIdType(IdPhotoType.Passport)}
                  >
                    <DocumentTypeIcon type={IdPhotoType.Passport} />
                    <Typography variant="body2" component="div">
                      <strong>{t('Passport')}</strong>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      {t('Fase photo page')}
                    </Typography>
                  </div>
                  <div
                    className={clsx(classes.documentTypeItem, 'id', {
                      active: idPhotoType === IdPhotoType.ID,
                    })}
                    onClick={() => changeIdType(IdPhotoType.ID)}
                  >
                    <DocumentTypeIcon type={IdPhotoType.ID} />
                    <Typography variant="body2" component="div">
                      <strong>{t('National ID card')}</strong>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      {t('Front and back')}
                    </Typography>
                  </div>
                  <div
                    className={clsx(classes.documentTypeItem, 'driver', {
                      active: idPhotoType === IdPhotoType.DriverLicense,
                    })}
                    onClick={() => changeIdType(IdPhotoType.DriverLicense)}
                  >
                    <DocumentTypeIcon type={IdPhotoType.DriverLicense} />
                    <Typography variant="body2" component="div">
                      <strong>{t('Drivers license')}</strong>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      {t('Front and back')}
                    </Typography>
                  </div>
                </div>
                <div className={globalClasses.checkBox}>
                  <Checkbox
                    label={
                      <span>
                        {t('labels.I agree with')}{' '}
                        <Link
                          href={getKycAmlPolicyLink(MIRROR)}
                          target={INTERNAL_LINK_TARGET_BLANK}
                        >
                          {t('KYC / AML policy')}
                        </Link>
                      </span>
                    }
                    name="terms"
                    validate={required}
                    className={formClasses.terms}
                  />
                </div>
                <div>
                  <Button
                    className={globalClasses.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (invalid) {
                        return form.blur('terms')
                      }
                      setCurrentStep(PhotoTypes.ID)
                    }}
                  >
                    {t('Start verification')}
                  </Button>
                </div>
              </div>
            )}
            {currentStep === PhotoTypes.ID && (
              <VerificationDocument
                title={t(`verificationTitle.${idPhotoType}`)}
                onLoad={setIdPhoto}
                type={PhotoTypes.ID}
                stepBack={() => setCurrentStep('')}
                photo={idPhoto}
                save={() =>
                  setCurrentStep(
                    idPhotoType === IdPhotoType.Passport
                      ? PhotoTypes.Selfie
                      : PhotoTypes.Backside
                  )
                }
                idPhotoType={idPhotoType as IdPhotoType}
              />
            )}
            {currentStep === PhotoTypes.Backside && (
              <VerificationDocument
                title={t(`verificationTitle.backside-${idPhotoType}`)}
                onLoad={setBacksidePhoto}
                type={PhotoTypes.Backside}
                stepBack={() => setCurrentStep(PhotoTypes.ID)}
                photo={backsidePhoto}
                save={() => setCurrentStep(PhotoTypes.Selfie)}
                idPhotoType={idPhotoType as IdPhotoType}
              />
            )}
            {currentStep === PhotoTypes.Selfie && (
              <VerificationDocument
                title={t(`labels.Selfie-${idPhotoType}`)}
                description={t('selfiePhotoDescription')}
                onLoad={setSelfiePhoto}
                type={PhotoTypes.Selfie}
                stepBack={() =>
                  setCurrentStep(
                    idPhotoType === IdPhotoType.Passport
                      ? PhotoTypes.ID
                      : PhotoTypes.Backside
                  )
                }
                photo={selfiePhoto}
                save={handleSubmit}
                error={serverError}
                idPhotoType={idPhotoType as IdPhotoType}
              />
            )}
          </div>
        )}
      />
    </Loader>
  )
}
