import React, { useEffect, useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'

import { useHistory, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import cookies from 'react-cookies'

import merge from 'lodash/merge'

import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import { useCurrencies } from '../../api/currency'
import { initialUI, useMerchantSettings } from '../../api/merchant'
import { emptyOrder, OrderProcessingError, OrderStatus } from '../../api/order'
import Layout from '../../components/Layout'

import Loader from '../../components/Loader'
import AuthForm from './forms/AuthForm'
import ProcessingError from '../../components/Order/ProcessingError'
import useGlobalStyles from '../../hooks/useGlobalStyles'
import { ILangType } from '../../locales/'
import { useCurrencyByIp } from '../../hooks/useCurrencyByIp'
import { ICurrency } from '../../types/currency'
import defaults from '../../config/defaults.json'
import US_CURRENCIES from '../../config/usCurrencies.json'
import CurrencyRestricted from '../Account/Profile/CurrencyRestricted'
import { USA_CODE } from '../../helpers/usFlowHelper'
import { setOrderDataRdx } from '@/store/actions/appData'
import { isChainValley } from '@/helpers/mirrorHelper'

const defaultMerchantSid =
  process.env.REACT_APP_DEFAULT_MERCHANT_SID || 'LnYK5Qgh8e'

interface IState {
  sid: string
  wallet?: string
  fromCurrency?: string
  toCurrency?: string
  accountId?: string
  paymentId?: string
  amount?: any
  paymentAmount?: any
  lockCrypto?: boolean
  email?: string
  sourceUrl?: string
  sourceOrder?: string
  timestamp?: any
  publicKey?: string
  public_key?: string
  signature?: string
  alg?: string
  invalidReferrer: boolean
  contract?: string
  nftId?: string
  apm?: string
}

export default function DirectChange() {
  const dispatch = useDispatch()
  let { step } = useParams<any>()
  if (!step) {
    step = 'transaction'
  }
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const globalClasses = useGlobalStyles()
  const history = useHistory()
  let sid = defaultMerchantSid
  let wallet = ''
  const referralId = cookies.load('r')
  let extFromCurrency = defaults.currency.from
  let extToCurrency = defaults.currency.to
  let externalId = ''
  let amount
  let paymentAmount
  let lockCrypto = false
  let initialEmail = ''
  let lockEmail = false
  let acceptAddressUrl: string | undefined = undefined
  let sourceOrder: any
  let extTimestamp = 0
  let extPublicKey = ''
  let extSignature = ''
  let extAlg = ''
  let nftId
  let apm

  const state = history.location.state as IState

  if (state) {
    sid = state.sid || defaultMerchantSid
    apm = state.apm
    wallet =
      state.wallet ||
      (state.toCurrency === 'NEAR' && state.accountId ? state.accountId : '')
    extFromCurrency = (
      state.fromCurrency || defaults.currency.from
    ).toUpperCase()
    extToCurrency = (state.toCurrency || defaults.currency.to).toUpperCase()
    externalId = state.paymentId || `${sid}-${Date.now()}`
    amount = state.amount
    paymentAmount = state.paymentAmount
    lockCrypto = state.lockCrypto || false
    if (state.email) {
      initialEmail = state.email
      lockEmail = true
    }
    acceptAddressUrl = state.sourceUrl
    if (state.sourceOrder) {
      sourceOrder = JSON.parse(state.sourceOrder)
    }
    extTimestamp = state.timestamp && parseInt(state.timestamp)
    extPublicKey = state.publicKey || state.public_key || ''
    extSignature = state.signature || ''
    extAlg = state.alg || ''
    nftId = state.nftId
  }

  const classes = useStyles()
  const [address, setAddress] = useState(wallet || '')
  const [order, setOrder] = useState(
    sourceOrder
      ? { ...sourceOrder, status: OrderStatus.NEW }
      : { ...emptyOrder, address }
  )
  const [email, setEmail] = useState(initialEmail)
  const [ui, setUi] = useState(initialUI)
  const [unavailable, setUnavailable] = useState(
    state?.invalidReferrer || false
  )
  const [formChanging, setFormChanging] = useState(false)
  const [customer, setCustomer] = useState<any>({
    kycStatus: null,
    kycFlow: null,
  })
  const [processingError, setProcessingError] = useState<
    OrderProcessingError | undefined
  >()
  const [{ data: currencies }, currenciesLoading, loadCurrencies] =
    useCurrencies()
  const [{ data: uiServer }, uiLoading, loadUi] = useMerchantSettings()
  const lang = language.split('-')[0] as ILangType
  const [toCurrency, setToCurrency] = useState(extToCurrency)
  const [fromCurrency, setFromCurrency] = useState(extFromCurrency)
  const [isLoading, setIsloading] = useState(true)
  const [showPromo, setShowPromo] = useState(false)
  const [unavailableCurrency, setUnavailableCurrency] = useState(false)

  const { isCurrencyByIpLoading, currencyByIp, ipCountry, loadCountryByIp } =
    useCurrencyByIp(!state?.fromCurrency)

  useEffect(() => {
    if (sid.length && ipCountry.length) {
      loadCurrencies({ sid, country: ipCountry })
    }
  }, [sid, ipCountry])

  useEffect(() => {
    if (ipCountry !== USA_CODE || !currencies.length) {
      return
    }

    if (lockCrypto && !currencies.find((c: any) => c.currency === toCurrency)) {
      setUnavailableCurrency(true)
    }
  }, [ipCountry, currencies])

  useEffect(() => {
    if (uiLoading || currenciesLoading || isCurrencyByIpLoading) {
      return
    }

    if (!currencyByIp) {
      setIsloading(false)
      return
    }

    const isIpCurrencyEnabled = currencies.find((curr: ICurrency) => {
      return curr.currency === currencyByIp && curr.enabled
    })

    if (isIpCurrencyEnabled) {
      setFromCurrency(currencyByIp)
    }
    setIsloading(false)
  }, [uiLoading, currenciesLoading, isCurrencyByIpLoading])

  const nextStep = (step: string) => {
    history.replace(`/order/${order.id}/${step}`, {
      ...state,
      sourceOrder: undefined,
      sourceUrl: undefined,
    })
  }

  useEffect(() => {
    if (step !== 'transaction') {
      nextStep('transaction')
    }
    if (state?.fromCurrency) {
      loadCountryByIp()
    }
  }, [])

  useEffect(() => {
    setUi(merge(initialUI, uiServer))
  }, [uiServer])

  useEffect(() => {
    if (sid.length) {
      loadUi({ sid })
    }
  }, [sid])

  useEffect(() => {
    setOrder({ ...order, address })
  }, [address])

  useEffect(() => {
    order?.acceptedByEmail && setEmail(order.acceptedByEmail)
  }, [order?.acceptedByEmail])

  const progressWidth = '33%'

  useEffect(() => {
    dispatch(setOrderDataRdx(order))
  }, [order.id])

  if (unavailable) {
    return (
      <Layout
        step={step}
        paymentCurrency={fromCurrency}
        currency={toCurrency}
        hideRightSideInfo
      >
        <div className={classes.root}>
          <Typography
            variant="h4"
            align="left"
            style={{ lineHeight: '39,84px' }}
          >
            {t('Your request was not authorized')}
          </Typography>
          <Typography
            variant="body1"
            align="left"
            style={{
              fontSize: 18,
              marginTop: 12,
              marginBottom: 168,
              lineHeight: '24px',
            }}
          >
            {t('To make the transaction, create a new order')}
          </Typography>
          <div className={globalClasses.payButtonWrapper}>
            <Button
              className={globalClasses.button}
              variant="contained"
              color="primary"
              href={
                isChainValley ? 'https://chainvalley.pro' : 'https://utorg.pro'
              }
            >
              {t('Create a New order')}
            </Button>
          </div>
        </div>
      </Layout>
    )
  }

  if (processingError) {
    return <ProcessingError error={processingError} />
  }

  if (unavailableCurrency) {
    return (
      <Layout>
        <CurrencyRestricted currency={toCurrency} country={ipCountry} />
      </Layout>
    )
  }

  return (
    <Layout
      showPromo={showPromo}
      closePromo={() => setShowPromo(false)}
      step={step}
      paymentCurrency={fromCurrency}
      currency={toCurrency}
    >
      <div className={globalClasses.delimeter} />
      <Loader loading={isLoading}>
        <div className={classes.root}>
          <div className={classes.progress}>
            <div className="currentProgress" style={{ width: progressWidth }} />
          </div>
          <AuthForm
            order={order}
            setStep={nextStep}
            setEmail={setEmail}
            email={email}
            t={t}
            setCustomer={setCustomer}
            customer={customer}
            ui={ui}
            language={lang}
            currencies={currencies}
            currenciesLoading={currenciesLoading}
            address={address}
            setAddress={setAddress}
            sid={sid}
            setOrder={setOrder}
            setUnavailable={setUnavailable}
            referralId={referralId}
            fromCurrency={fromCurrency}
            setFromCurrency={setFromCurrency}
            toCurrency={toCurrency}
            setToCurrency={setToCurrency}
            externalId={externalId}
            amount={amount}
            paymentAmount={paymentAmount}
            lockCrypto={lockCrypto}
            lockEmail={lockEmail}
            formChanging={formChanging}
            setFormChanging={setFormChanging}
            fromOrder={!!acceptAddressUrl}
            setCreateNewOrder={(v: boolean) => {
              if (!v && acceptAddressUrl) {
                history.push(acceptAddressUrl, {})
              }
            }}
            setProcessingError={setProcessingError}
            extTimestamp={extTimestamp}
            extPublicKey={extPublicKey}
            extSignature={extSignature}
            extAlg={extAlg}
            nftId={nftId}
            apm={apm}
          />
        </div>
      </Loader>
    </Layout>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  wrapper: {
    'height': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'maxWidth': '1000px',
    'margin': '0 auto',

    '& > div': {
      width: '100%',
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  progress: {
    'position': 'absolute',
    'left': 0,
    'top': 0,
    'height': 4,
    'width': '100%',
    'background': 'rgba(0, 0, 0, 0.12)',
    '& .currentProgress': {
      background: theme.palette.primary.main,
      height: 4,
      transition: 'width 500ms ease 0s',
    },
  },
  returnLink: {
    'marginTop': theme.spacing(2),
    'textAlign': 'center',
    '& a': {
      'display': 'inline-flex',
      'alignItems': 'center',
      '& svg': {
        height: 15,
        marginTop: 1,
        marginRight: -5,
      },
    },
  },
  resultTitle: {
    marginTop: -45,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  resultText: {
    marginTop: 46,
    color: 'rgba(0,0,0,0.6)',
  },
  resultDetails: {
    marginTop: 16,
    marginBottom: 16,
    color: 'rgba(0,0,0,0.87)',
  },
  resultKyc: {
    background: '#fefaed',
    margin: '33px -40px',
    padding: '26px 40px',
  },
}))
