import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import { trackPaymentError, trackErrorScreen } from '@/helpers/analytics'
import { IOrder } from '@/api/order'
import { ICustomer } from '@/types/customer'
import { IAPM } from '@/api/settings'
import useFinalErrorText from '@/hooks/useFinalErrorText'

interface IProps {
  isUnavailableApm: boolean
  error?: string
  isPayout?: boolean
  order?: IOrder | undefined
  customer?: ICustomer
  apm?: IAPM
}

export function ErrorFinal({
  isUnavailableApm,
  error,
  isPayout,
  order,
  apm,
  customer,
}: IProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { titleText, descriptionText } = useFinalErrorText({
    apm,
    error,
    order,
    isPayout,
    isUnavailableApm,
  })

  useEffect(() => {
    if (!isUnavailableApm) {
      trackPaymentError(
        'payment_error',
        order,
        apm?.name,
        'Error',
        t(titleText),
        customer?.level
      )
    }
  }, [])

  if (isUnavailableApm) {
    trackErrorScreen(
      'view_payment_method_error',
      order,
      t('unavailableApm.title'),
      'not available'
    )
    return (
      <div>
        <div className={classes.result}>
          <Typography
            variant="h5"
            align="center"
            component="div"
            color="textPrimary"
          >
            {t('unavailableApm.title')}
          </Typography>
        </div>
        <div className={classes.result}>
          <Typography
            variant="body2"
            align="center"
            component="div"
            color="textSecondary"
          >
            {t('unavailableApm.subtitle')}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={classes.result}>
        <Typography
          variant="h5"
          align="center"
          component="div"
          color="textPrimary"
        >
          {titleText}
        </Typography>
      </div>
      <div className={classes.result}>
        <Typography
          variant="body2"
          align="center"
          component="div"
          color="textSecondary"
          dangerouslySetInnerHTML={{
            __html: descriptionText,
          }}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  result: {
    'textAlign': 'center',
    'marginBottom': theme.spacing(1),
    '& .MuiSkeleton-root': {
      margin: '0 auto',
      height: 34,
      [theme.breakpoints.down('xs')]: {
        height: 26,
      },
    },
  },
}))
