import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useAPMConvert } from '@/api/private/orders';
import { IOrder } from '@/api/order';
import { ICustomer } from '@/types/customer';
import { IAPM, useAPMs } from '@/api/settings';
import { setApmDataRdx, setLogo } from '@/store/actions/appData';
import apmMap from '@/helpers/apmMap'
import apmConfig from '@/config/apm.json'
import { defineNFTFlow } from '@/helpers/nftHelpers/nftFlow';
import { getApmImageSrc } from '@/helpers/getApmView'

const env = process.env.REACT_APP_WIDGET_ENV || 'dev'

interface IApmLoadRequest {
  country: string
  currency: string
  orderId: string
  locale?: string
}

interface IAPMHook {
  apm?: string;
  apms: IAPM[];
  loadAPMs(request: IApmLoadRequest): void;
  loadingAPMs: boolean;
  setApm(apmId: string): void;
  apmAmount?: number;
  apmAmountConverting: boolean;
  apmsLoaded?: boolean
}

export const getCardOption = (t: TFunction) => {
  return {
    id: 'cc_card',
    name: t('Bank card'),
    logo: '/img/apm/bank_cards.svg',
    fee: { deposit: 0, depositFixed: 0, depositFixedCurrency: 'EUR' },
  }
}

export default function useAPM(order: IOrder, customer: ICustomer, showApm = true): IAPMHook {
  const { t } = useTranslation()
  const cardOption = getCardOption(t)
  const isNFTFlow = defineNFTFlow(order.currency)
  const dispatch = useDispatch();
  const selectedApm = !showApm ? cardOption.id : window.localStorage.getItem('sa-' + order.acceptedById);
  let initialApm = selectedApm === cardOption.id ? selectedApm : undefined
  if (order.apm) {
    initialApm = isNFTFlow ? cardOption.id : order.apm
  }
  const [apm, setApm] = useState<undefined | string>(initialApm)
  const [apmsLoaded, setApmsLoaded] = useState(false)
  const [apmLoadStarted, setApmLoadStarted] = useState(false)

  const [amount, setAmount] = useState<undefined | number>();
  const [{ data: apms }, loadingAPMs, loadAPMs] = useAPMs(true);
  const [convert, converting] = useAPMConvert((res: any) => {
    if (res.success) {
      setAmount(res.data);
      dispatch(setApmDataRdx({
        selected: apm,
        apmAmount: res.data
      }))
    }
  });

  useEffect(() => {
    if (apm && apm !== cardOption.id && customer.id) {
      convert({
        amount: order.paymentAmount,
        currency: order.paymentCurrency,
        apm,
        orderId: order.id,
      })
    } else {
      dispatch(setApmDataRdx({
        selected: apm,
        apmAmount: undefined
      }))
    }
  }, [apm, customer])

  useEffect(() => {
    if (showApm && !loadingAPMs && !order.apm) {
      if (
        apms.length &&
        // @ts-ignore
        apmConfig.apmsOnly[env].includes(order.mId)
      ) {
        setApm(selectedApm && !!apms.find((i: IAPM) => i.id === selectedApm) ? String(selectedApm) : apms[0].id);
        return
      }
      if (apms.length && selectedApm !== cardOption.id) {
        setApm(selectedApm && !!apms.find((i: IAPM) => i.id === selectedApm) ? String(selectedApm) : undefined);
      } else {
        setApm(cardOption.id);
      }
    }
    if (loadingAPMs) {
      setApmLoadStarted(true)
    }
  }, [apms, loadingAPMs])

  useEffect(() => {
    if (!loadingAPMs && apmLoadStarted) {
      setTimeout(() => setApmsLoaded(true), 15)
    }
  }, [loadingAPMs, apmLoadStarted])

  const setApmWrapper = (val: any) => {
    setApm(val);
    window.localStorage.setItem('sa-' + order.acceptedById, val);
  }

  const remapApms = (apms: IAPM[]) => {
    return apms.map((item: IAPM) => {
      //@ts-ignore
      const local = apmMap[item.id];
      return {
        ...item,
        name: local?.name || item?.name,
        logo: getApmImageSrc(item),
      }
    })
  }

  const selected = remapApms(apms).find((o) => o.id === apm)

  useEffect(() => {
    if (selected?.id) {
      dispatch(setLogo(selected.logo))
    }
  }, [selected?.id])

  return {
    apm,
    apms: remapApms(apms),
    loadAPMs,
    apmsLoaded,
    loadingAPMs,
    setApm: setApmWrapper,
    apmAmount: amount,
    apmAmountConverting: converting
  }
}
