import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ServerMessage from '../../../../components/UI/ServerMessage';

interface IProps {
  title: string;
  enabled: boolean;
  onOpen?: any;
  open: boolean;
  changed: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  state: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 0 40%',
    width: '40%'
  },
  enabled: {
    color: theme.palette.success.main
  },
  disabled: {
    color: theme.palette.secondary.light
  },
}));

export default function SettingsItem({ title, enabled, onOpen, changed, open }: IProps) {

  const { t } = useTranslation()

  const classes = useStyles();
  return (
    <>
      <TableRow>
        <TableCell className="title">
          <Typography variant="body2">{title}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2" className={classes[enabled ? 'enabled' : 'disabled']}>
            {enabled ? t('Enabled') : t('Disabled')}
          </Typography>
        </TableCell>
        <TableCell align="right" className="switcher">
          {onOpen && (
            <Switch checked={enabled} onChange={onOpen} color="primary" />
          )}
        </TableCell>
      </TableRow>
      {changed.length > 0 && (
        <TableRow>
          <TableCell colSpan={3}>
            <ServerMessage type="success" text={changed} isAccount />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
