const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeIDKgZAAAAABpUZ9JyPtgHEf_OwylbsKDcrPBR';

export default function captchaRequest(action: string, cb: any, data: any, onError?: any, headers = {}) {
  // @ts-ignore
  if (window.grecaptcha) {
    // @ts-ignore
    window.grecaptcha.ready(function () {
      // @ts-ignore
      window.grecaptcha.execute(reCaptchaKey, { action })
        .then(function (token: any) {
          cb(data, { headers: { ...headers, 'X-RECAPTCHA': token } });
        });
    });
  } else if (typeof (onError) === 'function') {
    onError();
  }
}