import makeStyles from '@material-ui/core/styles/makeStyles'

export const useSettlementConfirmationStyles = makeStyles((theme) => ({
  root: {},
  modal: {
    width: '449px',
    overflowY: 'scroll',
    padding: '26px 24px',
    background: '#FFF',
    borderRadius: 8,
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down(515)]: {
      width: '100%',
    },
  },
  button: {
    width: '100%',
    maxWidth: '100%',
    margin: '0px',
    height: '52px',
  },
  actions: {
    padding: '0px',
    display: 'block',
  },
  actionsButtons: {
    columnGap: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  cancelButton: {
    'backgroundColor': 'rgba(125, 147, 168, 0.08)',
    'color': 'rgba(0, 0, 0, 1)',
    '&:hover': {
      backgroundColor: 'rgba(125, 147, 168, 0.12)',
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(125, 147, 168, 0.08)',
      color: 'rgba(0, 0, 0, 1)',
    },
  },
  loader: {
    color: '#FFF',
    height: 'auto',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.5,
    letterSpacing: '0.01em',
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01px',
    marginBottom: theme.spacing(3),
  },
  content: {
    'padding': '0px',
    '&:first-child': {
      padding: '0px',
    },
    'marginBottom': theme.spacing(5),
  },
  staticRow: {
    paddingBottom: theme.spacing(3),
    cursor: 'pointer',
    fontWeight: 600,
  },
  tableCellIcon: {
    width: '44px',
  },
  expandableRow: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  totalCell: {
    fontWeight: 600,
  },
}))
