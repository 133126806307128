import MuiInput from './MuiInput'
import MuiInputLabel from './MuiInputLabel'
import MuiFormHelperText from './MuiFormHelperText'
import MuiSelect from './MuiSelect'
import MuiTableCell from './MuiTableCell'
import MuiPaper from './MuiPaper'
import MuiCardContent from './MuiCardContent'
import MuiTypography from './MuiTypography'
import MuiFilledInput from './MuiFilledInput'
import MuiFormControl from './MuiFormControl'
import MuiFormLabel from './MuiFormLabel'
import MuiCheckbox from './MuiCheckbox'
import MuiAutocomplete from './MuiAutocomplete'
import MuiFormControlLabel from './MuiFormControlLabel'
import MuiDialog from './MuiDialog'
import MuiDivider from './MuiDivider'
import { IPalette } from '../../api/merchant'

export default function (override: IPalette) {
  return {
    MuiInput: MuiInput(override),
    MuiFormHelperText,
    MuiInputLabel: MuiInputLabel(override),
    MuiSelect,
    MuiTableCell,
    MuiPaper,
    MuiCardContent,
    MuiTypography,
    MuiFilledInput: MuiFilledInput(override),
    MuiFormControl,
    MuiFormLabel,
    MuiCheckbox: MuiCheckbox(override),
    MuiAutocomplete,
    MuiFormControlLabel,
    MuiDialog,
    MuiDivider,
  }
}
