
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core';
import { useSwipeable } from 'react-swipeable';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  bottomSheet: {
    height: 0,
    maxWidth: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    transition: 'height 0.2s ease-in-out',

    '&.opened': {
      height: 'calc(100% - 50px)'
    }
  },
  fade: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgb(0, 0, 0)',
    left: 0,
    top: 0,
    paddingTop: 32,
    
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.5s, opacity 0.4s ease-in',

    '&.show': {
      visibility: 'visible',
      opacity: 0.6,
      transition: 'visibility 0s, opacity 0.4s ease-out',
    }
  },
  swipeArea: {
    position: 'absolute',
    top: 0,
    zIndex: 5,
    width: '100%',
    height: 20,
    backgroundColor: 'transparent',
  }
}))

class Portal extends React.Component {
  constructor(props: any) {
    super(props);
    //@ts-ignore
    this.el = document.createElement('div');
  }

  componentDidMount() {
    //@ts-ignore
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    //@ts-ignore
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      //@ts-ignore
      this.el
    );
  }
}

export default function BottomSheet2({apmDescriptionIsVisible, setApmDescriptionIsVisible, children}: any) {
  const [collapsed, setCollapsed] = useState(true);
  const classes = useStyles();
  const maxDelta = 100;

  let isDown = false;
  let startY = 0;
  let startHeight = 0;

  useEffect(() => {
    if (apmDescriptionIsVisible) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [apmDescriptionIsVisible])

  const swipeArticle = () => {
    setApmDescriptionIsVisible(false);
  }

  const onMouseDown = (e: any) => {
    isDown = true;
    startY = e.pageY || (e.touches.length ? e.touches[0].pageY : 0);
    startHeight = e.target.closest('.bottomSheet').outerHeight;
  }
  const onMouseUp = (e: any) => {
    isDown = false;
    const endY = e.pageY || (e.touches.length ? e.touches[0].pageY : null);
    const parent = e.target.closest('.bottomSheet');
    const dragDelta = endY - startY;

    // if (endY === null || dragDelta < maxDelta) {
      parent.style.transform = `translate(0, 0)`;
    // } 
  }
  const onMouseLeave = (e: any) => {
    isDown = false;
    const parent = e.target.closest('.bottomSheet');
    setTimeout(() => parent.style.transform = `translate(0, 0)`, 500)
  }
  const onMouseMove = (e: any) => {
    if(!isDown) return;
    const endY = e.pageY || e.touches[0].pageY;
    const dragDelta = endY - startY;    
    const parent = e.target.closest('.bottomSheet');
    if (dragDelta < maxDelta) {
      parent.style.transform = `translate(0, ${dragDelta}px)`
    } else {
      // parent.style.transform = `translate(0, 0)`;

      setApmDescriptionIsVisible(false);
    }
    
  }

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => swipeArticle(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    trackTouch: true,
    delta: maxDelta,
    onTap: ({ event }: any) => {
      event.stopPropagation();
      event.stopPropagation();
    }
  });

  const modal = (<>
    <div
      className={clsx("fade", classes.fade, {'show': apmDescriptionIsVisible})}
      onClick={() => setApmDescriptionIsVisible(false)}
    />
    <div 
      className={clsx("bottomSheet", classes.bottomSheet, {'opened': !collapsed})} 
      // {...swipeHandlers}
      // onMouseDown={(e: any) => {onMouseDown(e)}}
      // onMouseUp={onMouseUp}
      // onMouseLeave={onMouseLeave}
      // onMouseMove={onMouseMove}
      // onTouchStart={(e: any) => {onMouseDown(e)}}
      // onTouchEnd={onMouseUp}
      // onTouchCancel={onMouseLeave}
      // onTouchMove={onMouseMove} 
    >
      <div className={classes.swipeArea}
        onMouseDown={(e: any) => {onMouseDown(e)}}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onTouchStart={(e: any) => {onMouseDown(e)}}
        onTouchEnd={onMouseUp}
        onTouchCancel={onMouseLeave}
        onTouchMove={onMouseMove}
      />
      {children}
    </div>
  </>)

  return (
    (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 960
      ? <Portal>{modal}</Portal>
      : modal
  )
}